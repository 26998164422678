export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A local date string (i.e., with no associated timezone) in `YYYY-MM-DD` format, e.g. `2020-01-01`. */
  LocalDate: string;
};

/** Запросы на получение данных */
export type RootQuery = {
  __typename?: 'RootQuery';
  node: Node;
  /** Гео-сущности, которые используются для гео-саджеста */
  geo: Geo;
  geo2: Geo2;
  /** Гео сущности которые исопльзуются для карточек сохраеннных поисков. */
  geoAll?: Maybe<GeoAll>;
  /** Запрос за регионом пользователя */
  userRegion: GeoObject;
  /**
   * Получаем в удобном виде сущности для вывода на клиенте
   * @deprecated use geo2
   */
  searchOffersTips?: Maybe<SearchOffersTips>;
  /** Получение списка офферов подходящих под поисковой запрос (включая сео-теги, похожие новостроечные офферы) */
  searchOffers: SearchOffersType;
  /** Получение кол-ва офферов подходящих под поисковой запрос */
  searchOffersCount: OffersCount;
  /** Получение сводки о офферах подходящих под поисковой запрос */
  searchOffersSummary?: Maybe<OffersSummary>;
  /** Получение SEO данных под поисковой запрос */
  searchOffersSeo?: Maybe<SearchOffersSeo>;
  /** Получение списка офферов-ТОЧЕК подходящих под поисковой запрос */
  searchOffersMap: ProfOfferOnMap;
  /** Поиск инфраструктуры */
  getInfrastructure?: Maybe<InfrastructureList>;
  /** Получение списка офферов у ближайшего оффера */
  searchOffersMapByOffer: ProfOfferOnMap;
  /** Получение списка новостроек, подходящих под поисковой запрос */
  searchNewBuildings?: Maybe<NewBuildingsList>;
  /** Список поддерживаемых регионов в классифайде */
  geoRootRegions?: Maybe<Array<RegionSection>>;
  /** Дефолтный регион */
  geoRootRegionDefault?: Maybe<GeoObject>;
  /** Запрос за количеством ЖК */
  newBuildingCount?: Maybe<NewBuildingCount>;
  /** Поиск ЖК на карте */
  searchMapNewBuildings?: Maybe<MapNewBuildingsList>;
  /** Получение списка корпусов в ЖК */
  getBuildings?: Maybe<BuildingsList>;
  /** Получение списка офферов подходящих под поисковой запрос */
  professionalSearchOffers?: Maybe<ProfOffersList>;
  /** Получение списка офферов в карточке новостройки */
  searchNewBuildingOffers?: Maybe<NewBuildingOffersList>;
  /** Получение статистики по выдаче новостроек */
  newBuildingsSummary?: Maybe<NewBuildingsSummary>;
  /** Статистика по новостройкам в регионе */
  newBuildingsStatistics?: Maybe<NewBuildingsStatistics>;
  /** Получение параметров облака ссылок для заданных параметров поиска */
  newBuildingsLinkCloud?: Maybe<NewBuildingsLinkCloud>;
  /** Получение списка застройщиков по региону */
  searchDevelopers?: Maybe<DeveloperList>;
  /** Получение карточки новостройки */
  newBuilding: NewBuildingCard;
  /** Запрос за телефоном новостройки */
  newBuildingPhone?: Maybe<Phone>;
  /** Запрос за квизом */
  newBuildingQuiz?: Maybe<NewBuildingQuiz>;
  /** Получение списка офферов по ID */
  professionalSearchOffersByIds?: Maybe<Array<Maybe<ProfOfferSnippet>>>;
  /** Запрос за телефонами */
  offerPhones?: Maybe<OfferPhones>;
  /** Запрос за телефоном */
  offerPhone?: Maybe<OfferPhone>;
  /** Запрос за телефонами офферов */
  offersPhones?: Maybe<Array<Maybe<OffersPhones>>>;
  /** Генерация случайного числа для баннеров */
  randomNumber: RandomNumber;
  /** Получить OfferSearchQuery по фильтрам */
  getOfferSearchQueryString: OfferSearchQueryString;
  /** Запрос за лендингом застройщика (спецпроект) */
  developerPromoLanding?: Maybe<NewBuildingDeveloperPromoLanding>;
  /** Запрос за баннером по спецпроекту для избранного */
  developerPromoFavorites?: Maybe<FavoritesDeveloperPromo>;
  /**
   * Меню для шапки
   * @deprecated use shellConfig2 (includes fields for header/footer)
   */
  header: Header;
  /**
   * Данные для футера
   * @deprecated use shellConfig2 (includes fields for header/footer)
   */
  footer: Footer;
  /**
   * Данные для сайдбара
   * @deprecated use shellConfig2 (includes fields for header/footer)
   */
  sidebar?: Maybe<Sidebar>;
  /** SEO ссылки для тегов в футере на главной */
  seoLinksQuery: SeoLinkCloudType;
  /** Саджест на получение всего и вся */
  suggest?: Maybe<Array<Maybe<SuggestList>>>;
  /** Саджест на получение всего и вся, get-geo-suggest в root-bff */
  geoSuggest?: Maybe<Array<Maybe<GeoSuggestItem>>>;
  /** Получение гео объекта по regionId https://gitlab.m2.ru/vtblife/frontend/root/-/blob/master/src/bff/api/geo/geo.service.ts */
  getGeoObjectById?: Maybe<GeoObject>;
  /** Список поддерживаемых регионов в новостройках */
  newBuildingRootRegions?: Maybe<Array<Maybe<RegionSection>>>;
  /** Список предложений от банков */
  banksMortgageOffers: Array<BankMortgageOffer>;
  /** Список предложений от банков */
  minimalBanksMortgageOffers: Array<MinimalBankMortgageOffer>;
  favoritePersonal: Array<Favorite>;
  searchesPersonal?: Maybe<SearchesPersonal>;
  agent?: Maybe<PreferencesBusiness>;
  favoriteOffers2ByCustomer: Array<FavoriteOffer2>;
  businessCustomer?: Maybe<Array<Maybe<Customer>>>;
  businessFavorite?: Maybe<Array<Maybe<BusinessCustomerFavorite>>>;
  businessCustomerSearches?: Maybe<BusinessCustomerSearches>;
  /**
   * Получение информации об авторизованном пользователе (все что лежит в токене)
   * @deprecated Auth-api более не отдаёт информацию о пользователе, кроме телефона
   */
  userDetails?: Maybe<UserDetails>;
  /** Получение информации об профиле пользователе */
  userProfile: UserProfileResponse;
  userSavedSearch?: Maybe<UserSavedSearch>;
  personalDetails?: Maybe<Array<Maybe<PersonalDetailItem>>>;
  featureTourInitial?: Maybe<FeatureTourInitial>;
  /** Получить карточку застройщика */
  developer: DeveloperCard;
  /** Запрос за сео текстами */
  newBuildingSeo: NewBuildingSeo;
  /** Запрос на список ссылок для ловца ботов */
  crawlCandidates?: Maybe<GetCrawlCandidates>;
  favoriteOffers?: Maybe<FavoriteOffers>;
  favoriteOffers2: Array<FavoriteOffer2>;
  /** Офферы в избранном аренда и продажа вместе, ИМЕЮЩИЕ подписку (EMAIL, не пуши) */
  favoriteOffersObservables: Array<FavoriteOffer>;
  /** Получение избранных новостроек */
  favoriteNewBuildings: Array<NewBuildingCard>;
  /** Оффер */
  offer: ProfOfferResponseType;
  /** Получение эмэйла пользователя */
  userEmails: UserEmails;
  hiddenOffers: HiddenOffers;
  /** Запрос за статистикой просмотров/расхлопов офферов */
  getOfferStats?: Maybe<ClickerGetOfferStatsResult>;
  /** Запрос за статистикой просмотров/расхлопов фида */
  getUserFeedStats?: Maybe<ClickerGetUserFeedStatsResult>;
  /** Запрос за последними поисками */
  getLastSearches?: Maybe<GetLastSearchesResult>;
  /** Запрос за фидами */
  getUserFeeds?: Maybe<GetUserFeedsResult>;
  /** Запрос за счетчиками офферов в фиде */
  getUserFeedCountOffers?: Maybe<GetUserFeedCountOffersResult>;
  /** Запрос за регионами в фиде */
  getUserFeedRegions?: Maybe<GetUserFeedRegionsResult>;
  searchBannedOffers?: Maybe<SearchBannedOffersResult>;
  searchNotBannedOffers?: Maybe<SearchNotBannedOffersResult>;
  /** Получение информации о company */
  getCompanyInfo?: Maybe<GetCompanyInfo>;
  offersByIds?: Maybe<Array<ProfOfferSnippet>>;
  /** Получение информации о person */
  person?: Maybe<Person>;
  /** Поиск сотрудников компании */
  personSearch?: Maybe<PersonSearch>;
  draftOffers?: Maybe<DraftOffersList>;
  getPlacementOffer?: Maybe<GetPlacementOffer>;
  getWalletBalance?: Maybe<GetWalletBalance>;
  /** Запрос за ценами для одного типа васа */
  getPrices?: Maybe<GetPrices>;
  /** Запрос за ценами сразу для всех типов васов */
  getPricesAll?: Maybe<GetPricesAll>;
  getQuantityDiscount?: Maybe<GetQuantityDiscount>;
  /** Получение о компании (пока только из бэка Регистратура) */
  getCompanyPreferences?: Maybe<CompanyPreferencesResponse>;
  /**
   * Конфигурация из root-configs
   * @deprecated use shellConfig2 (includes fields for header/footer)
   */
  shellConfig: ShellConfig;
  /** Конфигурация из root-configs */
  shellConfig2: ShellConfig2;
  /** Корневое query для запросов ktzk */
  hoosegow: HoosegowRootResponse;
  /** Корневое query для запросов ИБ */
  mortgage?: Maybe<MortgageRoot>;
  /** Корневое query для запросов в новый ИБ v2 */
  mortgage2: Mg2Root;
  /** API для размещения заказов на оценку */
  srg?: Maybe<SrgOrderPlaceResponse>;
  /** Вычислить минимальную ставку по ипотеке */
  minimalMortgageRate: MinimalMortgageRateBag;
};


/** Запросы на получение данных */
export type RootQueryGeoArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
  geoIds?: InputMaybe<Array<Scalars['Int']>>;
};


/** Запросы на получение данных */
export type RootQueryGeo2Args = {
  geos: Array<GeoSlugInput>;
};


/** Запросы на получение данных */
export type RootQueryGeoAllArgs = {
  regionIds?: Array<Scalars['Int']>;
  kinds?: Array<KindEnum>;
};


/** Запросы на получение данных */
export type RootQueryUserRegionArgs = {
  ipAddress: Scalars['String'];
};


/** Запросы на получение данных */
export type RootQuerySearchOffersTipsArgs = {
  newBuildingIds?: InputMaybe<Array<Scalars['Int']>>;
  regionId?: InputMaybe<Scalars['Int']>;
  geoSlugs?: InputMaybe<Array<Scalars['String']>>;
  addressIds?: InputMaybe<Array<Scalars['Int']>>;
  districtIds?: InputMaybe<Array<Scalars['Int']>>;
  metroIds?: InputMaybe<Array<Scalars['Int']>>;
  developerIds?: InputMaybe<Array<Scalars['Int']>>;
};


/** Запросы на получение данных */
export type RootQuerySearchOffersArgs = {
  paging: PagingInput;
  sort?: InputMaybe<Array<SortInput>>;
  filters?: InputMaybe<ProfessionalSearchInput>;
};


/** Запросы на получение данных */
export type RootQuerySearchOffersCountArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
};


/** Запросы на получение данных */
export type RootQuerySearchOffersSummaryArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
};


/** Запросы на получение данных */
export type RootQuerySearchOffersSeoArgs = {
  pathName?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ProfessionalSearchInput>;
  offerId?: InputMaybe<Scalars['String']>;
};


/** Запросы на получение данных */
export type RootQuerySearchOffersMapArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
  taglessOnly?: InputMaybe<Scalars['Boolean']>;
};


/** Запросы на получение данных */
export type RootQueryGetInfrastructureArgs = {
  bounds: ProfessionalSearchInputBounds;
  poiLimit: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQuerySearchOffersMapByOfferArgs = {
  filters: SearchOffersMapByOfferInput;
};


/** Запросы на получение данных */
export type RootQuerySearchNewBuildingsArgs = {
  sort?: InputMaybe<NewBuildingSortEnum>;
  paging?: InputMaybe<PagingInput>;
  filters?: InputMaybe<NewBuildingsInput>;
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
  isSimilar?: InputMaybe<Scalars['Boolean']>;
  isOthersFromDeveloper?: InputMaybe<Scalars['Boolean']>;
  isQuiz?: InputMaybe<Scalars['Boolean']>;
  promo?: InputMaybe<Scalars['Boolean']>;
  promoQuiz?: InputMaybe<Scalars['Boolean']>;
};


/** Запросы на получение данных */
export type RootQueryGeoRootRegionsArgs = {
  appType?: InputMaybe<GeoRootRegionAppEnum>;
};


/** Запросы на получение данных */
export type RootQueryGeoRootRegionDefaultArgs = {
  appType?: InputMaybe<GeoRootRegionAppEnum>;
  suggestedGeoId: Scalars['ID'];
};


/** Запросы на получение данных */
export type RootQueryNewBuildingCountArgs = {
  filters?: InputMaybe<NewBuildingsInput>;
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQuerySearchMapNewBuildingsArgs = {
  filters?: InputMaybe<NewBuildingsInput>;
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
  resultTypes?: InputMaybe<Array<MapResultTypeEnum>>;
  limit?: InputMaybe<Scalars['Int']>;
  showSalesHints?: InputMaybe<Scalars['Boolean']>;
};


/** Запросы на получение данных */
export type RootQueryGetBuildingsArgs = {
  paging?: InputMaybe<PagingInput>;
  filters: NewBuildingOffersInput;
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQueryProfessionalSearchOffersArgs = {
  paging: PagingInput;
  sort?: InputMaybe<Array<SortInput>>;
  filters?: InputMaybe<ProfessionalSearchInput>;
};


/** Запросы на получение данных */
export type RootQuerySearchNewBuildingOffersArgs = {
  filters: NewBuildingOffersInput;
  paging: PagingInput;
  sort?: InputMaybe<NewBuildingOffersSortEnum>;
};


/** Запросы на получение данных */
export type RootQueryNewBuildingsSummaryArgs = {
  filters?: InputMaybe<NewBuildingsInput>;
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQueryNewBuildingsStatisticsArgs = {
  regionId?: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQueryNewBuildingsLinkCloudArgs = {
  filters?: InputMaybe<NewBuildingsInput>;
  sort?: InputMaybe<NewBuildingSortEnum>;
};


/** Запросы на получение данных */
export type RootQuerySearchDevelopersArgs = {
  paging: PagingInput;
  filters?: InputMaybe<SearchDevelopersInput>;
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQueryNewBuildingArgs = {
  id: Scalars['Int'];
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQueryNewBuildingPhoneArgs = {
  newBuildingId: Scalars['Int'];
  developerId: Scalars['Int'];
  phoneNum: Scalars['Int'];
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQueryNewBuildingQuizArgs = {
  id: Scalars['String'];
};


/** Запросы на получение данных */
export type RootQueryProfessionalSearchOffersByIdsArgs = {
  ids: Array<Scalars['ID']>;
  paging: PagingInput;
  sort: SortInput;
};


/** Запросы на получение данных */
export type RootQueryOfferPhonesArgs = {
  offerId: Scalars['ID'];
};


/** Запросы на получение данных */
export type RootQueryOfferPhoneArgs = {
  isCard?: InputMaybe<Scalars['Boolean']>;
  offerId: Scalars['ID'];
  phoneIndex?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQueryOffersPhonesArgs = {
  offerIds: Array<Scalars['ID']>;
};


/** Запросы на получение данных */
export type RootQueryRandomNumberArgs = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQueryGetOfferSearchQueryStringArgs = {
  filters: ProfessionalSearchInput;
};


/** Запросы на получение данных */
export type RootQueryDeveloperPromoLandingArgs = {
  developerName: DeveloperPromoEnum;
};


/** Запросы на получение данных */
export type RootQueryDeveloperPromoFavoritesArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQueryHeaderArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQueryFooterArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQuerySidebarArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQuerySeoLinksQueryArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQuerySuggestArgs = {
  userRegionId?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  suggestTypes?: InputMaybe<Array<SuggestTypeEnum>>;
  rootRegionsApp?: InputMaybe<RootRegionsApplicationEnum>;
  limit?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQueryGeoSuggestArgs = {
  text: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQueryGetGeoObjectByIdArgs = {
  regionId: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQueryNewBuildingRootRegionsArgs = {
  whitelabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQueryBanksMortgageOffersArgs = {
  term: Scalars['Int'];
  propertyCost: Scalars['Float'];
  ownAmount: Scalars['Float'];
  purpose?: MortgagePurpose;
  propertyType?: InputMaybe<MortgagePropertyType>;
  regionId: Scalars['Int'];
  programCode: MortgageProgramCodeType;
};


/** Запросы на получение данных */
export type RootQueryMinimalBanksMortgageOffersArgs = {
  term: Scalars['Int'];
  propertyCost: Scalars['Float'];
  ownAmount: Scalars['Float'];
  purpose?: MortgagePurpose;
  propertyType?: InputMaybe<MortgagePropertyType>;
  isPreferential?: InputMaybe<Scalars['Boolean']>;
  regionId: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQuerySearchesPersonalArgs = {
  userId?: InputMaybe<Scalars['ID']>;
  searchId?: InputMaybe<Scalars['ID']>;
};


/** Запросы на получение данных */
export type RootQueryFavoriteOffers2ByCustomerArgs = {
  customerId: Scalars['ID'];
};


/** Запросы на получение данных */
export type RootQueryBusinessCustomerArgs = {
  customerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


/** Запросы на получение данных */
export type RootQueryBusinessFavoriteArgs = {
  customerIds: Array<Scalars['ID']>;
};


/** Запросы на получение данных */
export type RootQueryBusinessCustomerSearchesArgs = {
  agentId: Scalars['ID'];
  customerId: Scalars['ID'];
  searchId?: InputMaybe<Scalars['ID']>;
};


/** Запросы на получение данных */
export type RootQueryUserDetailsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


/** Запросы на получение данных */
export type RootQueryUserSavedSearchArgs = {
  userId: Scalars['ID'];
  searchId: Scalars['ID'];
  isFresh?: InputMaybe<Scalars['Boolean']>;
};


/** Запросы на получение данных */
export type RootQueryFeatureTourInitialArgs = {
  userId: Scalars['ID'];
};


/** Запросы на получение данных */
export type RootQueryDeveloperArgs = {
  developerId: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQueryNewBuildingSeoArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Запросы на получение данных */
export type RootQueryCrawlCandidatesArgs = {
  limit: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQueryOfferArgs = {
  id: Scalars['String'];
};


/** Запросы на получение данных */
export type RootQueryGetOfferStatsArgs = {
  start: Scalars['String'];
  end: Scalars['String'];
  offerIds: Array<Scalars['ID']>;
};


/** Запросы на получение данных */
export type RootQueryGetUserFeedStatsArgs = {
  userId: Scalars['ID'];
  vasEnabled?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['String'];
  end: Scalars['String'];
};


/** Запросы на получение данных */
export type RootQueryGetLastSearchesArgs = {
  onlyUnique: Scalars['Boolean'];
  limit: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQueryGetUserFeedCountOffersArgs = {
  feedId: Scalars['Int'];
  filter?: InputMaybe<UserFeedCountOffersInputFilter>;
};


/** Запросы на получение данных */
export type RootQueryGetUserFeedRegionsArgs = {
  feedId: Scalars['Int'];
};


/** Запросы на получение данных */
export type RootQuerySearchBannedOffersArgs = {
  paging: PagingInput;
};


/** Запросы на получение данных */
export type RootQuerySearchNotBannedOffersArgs = {
  paging: PagingInput;
  filters?: InputMaybe<ProfessionalSearchInput>;
};


/** Запросы на получение данных */
export type RootQueryGetCompanyInfoArgs = {
  companyId: Scalars['ID'];
};


/** Запросы на получение данных */
export type RootQueryOffersByIdsArgs = {
  offerIds: Array<Scalars['String']>;
};


/** Запросы на получение данных */
export type RootQueryPersonSearchArgs = {
  companyId: Scalars['ID'];
};


/** Запросы на получение данных */
export type RootQueryDraftOffersArgs = {
  userId?: InputMaybe<Scalars['String']>;
  paging: PlacementPagingInput;
};


/** Запросы на получение данных */
export type RootQueryGetPlacementOfferArgs = {
  userId?: InputMaybe<Scalars['String']>;
  offerId: Scalars['String'];
};


/** Запросы на получение данных */
export type RootQueryGetPricesArgs = {
  offersParams?: InputMaybe<Array<OrderOfferVasOfferParam>>;
  offerIds?: InputMaybe<Array<Scalars['ID']>>;
  vases: Array<BuyableVasGqlEnum>;
  promocode?: InputMaybe<Scalars['String']>;
};


/** Запросы на получение данных */
export type RootQueryGetPricesAllArgs = {
  offersParams?: InputMaybe<Array<OrderOfferVasOfferParam>>;
  offerIds?: InputMaybe<Array<Scalars['ID']>>;
  promocode?: InputMaybe<Scalars['String']>;
};


/** Запросы на получение данных */
export type RootQueryGetCompanyPreferencesArgs = {
  id: Scalars['ID'];
};


/** Запросы на получение данных */
export type RootQueryShellConfigArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQueryShellConfig2Args = {
  regionId?: InputMaybe<Scalars['Int']>;
};


/** Запросы на получение данных */
export type RootQuerySrgArgs = {
  authorId: Scalars['String'];
  idOfMortgageLendingRequest: Scalars['String'];
  borrowerFIO: Scalars['String'];
  companyId: Scalars['String'];
  bank: Scalars['String'];
};


/** Запросы на получение данных */
export type RootQueryMinimalMortgageRateArgs = {
  input: Mg2CalculationInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

export type Geo = {
  __typename?: 'Geo';
  metro: Array<MetroStation>;
  lines: Array<MetroLine>;
  districts: Array<GeoObject>;
};

/** Станция метро */
export type MetroStation = {
  __typename?: 'MetroStation';
  /** id станции в гео-дереве */
  id: Scalars['Int'];
  kind: KindEnum;
  /** @deprecated use name */
  title?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  name: Scalars['String'];
  mainName?: Maybe<Scalars['String']>;
  /** Короткое имя */
  shortDisplayName?: Maybe<Scalars['String']>;
  /** Имя в родительном падеже */
  locativeDisplayName?: Maybe<Scalars['String']>;
  allRoutesColorsList?: Maybe<Array<Scalars['String']>>;
  /** @deprecated use allRoutesColorsList */
  lines?: Maybe<Array<Scalars['String']>>;
  lines2?: Maybe<Array<Scalars['String']>>;
  interchangedStations?: Maybe<Array<Scalars['Int']>>;
  route: MetroStationRoute;
  coordinates?: Maybe<Point>;
};

export enum KindEnum {
  Unknown = 'UNKNOWN',
  House = 'HOUSE',
  Street = 'STREET',
  Metro = 'METRO',
  District = 'DISTRICT',
  Locality = 'LOCALITY',
  Area = 'AREA',
  Province = 'PROVINCE',
  Country = 'COUNTRY',
  Hydro = 'HYDRO',
  Vegetation = 'VEGETATION',
  Airport = 'AIRPORT',
  Other = 'OTHER',
  RailwayStation = 'RAILWAY_STATION',
  Route = 'ROUTE',
  Station = 'STATION',
  Entrance = 'ENTRANCE',
  CompositeObject = 'COMPOSITE_OBJECT',
  StreetAddress = 'STREET_ADDRESS',
  Apartment = 'APARTMENT'
}

export type MetroStationRoute = {
  __typename?: 'MetroStationRoute';
  id: Scalars['Int'];
};

/** Point */
export type Point = {
  __typename?: 'Point';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Линия метро, в геобаза как ROUTE */
export type MetroLine = {
  __typename?: 'MetroLine';
  /** id линии в гео-дереве */
  id: Scalars['Int'];
  kind: KindEnum;
  /**
   * Устарело?
   * @deprecated Используем name?
   */
  title?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortDisplayName?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type GeoObject = {
  __typename?: 'GeoObject';
  locality?: Maybe<BaseGeoObject>;
  id: Scalars['Int'];
  parentId: Scalars['Int'];
  kind: KindEnum;
  /** Дублирует mainName, лучше для консистентности с бэком использовать mainName */
  title?: Maybe<Scalars['String']>;
  mainName?: Maybe<Scalars['String']>;
  displayNameWithContext?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  shortDisplayName?: Maybe<Scalars['String']>;
  locative?: Maybe<Scalars['String']>;
  translit?: Maybe<Scalars['String']>;
  bounds?: Maybe<Bounds>;
  isIntracity: Scalars['Boolean'];
  regionId?: Maybe<Scalars['Int']>;
  narrowRegion?: Maybe<GeoObjectCore>;
  wideRegion?: Maybe<GeoObjectCore>;
  color?: Maybe<Scalars['String']>;
};

export type BaseGeoObject = {
  __typename?: 'BaseGeoObject';
  id: Scalars['Int'];
  parentId: Scalars['Int'];
  kind: KindEnum;
  /** Дублирует mainName, лучше для консистентности с бэком использовать mainName */
  title?: Maybe<Scalars['String']>;
  mainName?: Maybe<Scalars['String']>;
  displayNameWithContext?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  shortDisplayName?: Maybe<Scalars['String']>;
  locative?: Maybe<Scalars['String']>;
  translit?: Maybe<Scalars['String']>;
  bounds?: Maybe<Bounds>;
  isIntracity: Scalars['Boolean'];
  regionId?: Maybe<Scalars['Int']>;
  narrowRegion?: Maybe<GeoObjectCore>;
  wideRegion?: Maybe<GeoObjectCore>;
  color?: Maybe<Scalars['String']>;
};

export type Bounds = {
  __typename?: 'Bounds';
  min: BoundsPoint;
  max: BoundsPoint;
};

export type BoundsPoint = {
  __typename?: 'BoundsPoint';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type GeoObjectCore = {
  __typename?: 'GeoObjectCore';
  id: Scalars['Int'];
  kind: KindEnum;
  name: Scalars['String'];
  translit?: Maybe<Scalars['String']>;
  allRoutesColorsList?: Maybe<Array<Scalars['String']>>;
};

export type Geo2 = {
  __typename?: 'Geo2';
  /** null в элементе, означает что он не найден */
  items: Array<GeoObjectBatch>;
};

export type GeoObjectBatch = BaseLoaderItemError | GeoObject;

export type BaseLoaderItemError = {
  __typename?: 'BaseLoaderItemError';
  itemId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type GeoSlugInput = {
  /** Число в строке (id) или slug (чпу id) */
  smart: Scalars['String'];
  /** Уникальна только пара slug + regionId, если regionId не задан, возьмется slug с большим id */
  regionId?: InputMaybe<Scalars['Int']>;
};

export type GeoAll = {
  __typename?: 'GeoAll';
  metro?: Maybe<Array<Maybe<MetroStation>>>;
  lines?: Maybe<Array<Maybe<MetroLine>>>;
  districts?: Maybe<Array<Maybe<GeoObject>>>;
};

export type SearchOffersTips = {
  __typename?: 'SearchOffersTips';
  /** Сейчас умеем искать только по 1 новостройке, newBuildingIds — задел на будщее  */
  newBuildings?: Maybe<Array<Maybe<TipNewBuilding>>>;
  /** @deprecated use geo2 */
  districts?: Maybe<Array<Maybe<TipDistrict>>>;
  /** @deprecated use geo2 */
  addresses?: Maybe<Array<Maybe<TipAddress>>>;
  /** Возможно пока не надо дергать, так как пока везде грузится геобаза с районами и метро */
  metros?: Maybe<Array<Maybe<TipMetroStation>>>;
};

export type TipNewBuilding = {
  __typename?: 'TipNewBuilding';
  /** id Новостройки */
  id: Scalars['Int'];
  /** Имя новостройки + Тип */
  title?: Maybe<Scalars['String']>;
  type: TipTypeEnum;
};

export enum TipTypeEnum {
  NewBuilding = 'NEW_BUILDING',
  Building = 'BUILDING',
  Metro = 'METRO',
  Address = 'ADDRESS',
  Developer = 'DEVELOPER',
  District = 'DISTRICT'
}

export type TipDistrict = {
  __typename?: 'TipDistrict';
  /** id адреса в гео дереве */
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: TipTypeEnum;
  translit?: Maybe<Scalars['String']>;
  locative?: Maybe<Scalars['String']>;
};

export type TipAddress = {
  __typename?: 'TipAddress';
  /** id адреса в гео дереве */
  id: Scalars['Int'];
  translit?: Maybe<Scalars['String']>;
  /** Название улицы */
  title?: Maybe<Scalars['String']>;
  type: TipTypeEnum;
  locative?: Maybe<Scalars['String']>;
};

export type TipMetroStation = {
  __typename?: 'TipMetroStation';
  /** id адреса в гео дереве */
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  allRoutesColors?: Maybe<Array<Scalars['String']>>;
  type: TipTypeEnum;
};

/** Список офферов */
export type SearchOffersType = {
  __typename?: 'SearchOffersType';
  /** Постраничная навигация */
  paging?: Maybe<Paging>;
  /** Список офферов */
  items: Array<ProfOfferSnippet>;
  seoTags?: Maybe<SearchOffersSeoTags>;
  /** Похожие новостройки */
  similarNb?: Maybe<Array<Maybe<ProfOfferSnippet>>>;
  /** Заголовок при пустом ответе */
  emptyResultTitle?: Maybe<Scalars['String']>;
};

/** Параметры постраничной навигации */
export type Paging = {
  __typename?: 'Paging';
  /** Общее количество элементов в коллекции */
  total?: Maybe<Scalars['Int']>;
  /** Номер запрошенной страницы */
  pageNumber: Scalars['Int'];
  /** Количество запрошенных элементов */
  pageSize: Scalars['Int'];
  /** Количество записей (фактическое может отличаться от желаемого) */
  limit?: Maybe<Scalars['Int']>;
  /** Сколько записей пропустить */
  offset?: Maybe<Scalars['Int']>;
  hasNext: Scalars['Boolean'];
};

/** Оффер + related + buildingRelated */
export type ProfOfferSnippet = {
  __typename?: 'ProfOfferSnippet';
  /** Информация о статусе объявления */
  status?: Maybe<OfferStatus>;
  stats?: Maybe<ProfOfferStats>;
  /** Уникальный id оффера */
  id: Scalars['ID'];
  text?: Maybe<ProfOfferText>;
  /** Описание оффера */
  description?: Maybe<Scalars['String']>;
  /** Информация о сделке */
  dealType?: Maybe<ProfOfferDealType>;
  isMortgageAllowed: Scalars['Boolean'];
  mortgagePurpose: MortgagePurpose;
  realtyObject?: Maybe<ProfRealityObject>;
  /** Информация о продавце */
  seller: Seller;
  abstractOptions?: Maybe<ProfOfferAbstractOptions>;
  /** Информация о инфраструктуре */
  infrastructure?: Maybe<ProfOfferInfrastructure>;
  badges?: Maybe<ProfOfferBadges>;
  location: ProfOfferLocation;
  gallery?: Maybe<Gallery>;
  /** @deprecated use gallery.images */
  photo?: Maybe<Array<Image>>;
  /** @deprecated use gallery.mainImage */
  preview?: Maybe<Image>;
  isFavoriteVisible: Scalars['Boolean'];
  /** Платная ли новостройка,  */
  isNewBuildingPaid: Scalars['Boolean'];
  /** Школы рядом из ТОП рейтинга */
  ratedSchools: Array<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  /** Информация о новостройке */
  newBuildingCard?: Maybe<NewBuildingCard>;
  /** Участвует в программе реновации */
  renovationProgram?: Maybe<Scalars['Boolean']>;
  /** Серия дома */
  buildingSeries?: Maybe<Scalars['String']>;
  /** Эпоха постройки */
  buildEpoch?: Maybe<BuildEpochEnum>;
  /** Тип отопления */
  heatingType?: Maybe<BuildHeatingTypeEnum>;
  /** Интересные места рядом */
  infrastructureEntitiesList: Array<InfrastructureEntitiesListEnum>;
  /** Инфраструктура во дворе */
  yardEntityList: Array<YardEntityEnum>;
  /** Онлайн просмотр */
  onlineView?: Maybe<Scalars['Boolean']>;
  /** Оффер от циана */
  fromCian?: Maybe<Scalars['Boolean']>;
  /** Список похожих офферов */
  related?: Maybe<Array<ProfOfferSearchBase>>;
  /** Квартиры/комнаты в этом доме */
  buildingRelated: ProfOfferBuildingRelated;
  /** Похожие новостройки */
  similarNb?: Maybe<Array<ProfOfferSearchBase>>;
};

/** Информация о статусе объявления */
export type OfferStatus = {
  __typename?: 'OfferStatus';
  /** Время создания для выдачи (может отличаться от фактического) */
  createdAt?: Maybe<Date>;
  /** Время проверки актуальности инфы в оффере */
  actualizedAt?: Maybe<Date>;
  /** Время изменения инфы в оффере */
  updatedAt?: Maybe<Date>;
  createdActualizeFormatted?: Maybe<Scalars['String']>;
  /** Время распубликации оффера (если отсутствует, значит оффер не распубликован) */
  removedAt?: Maybe<Date>;
  isDeleted: Scalars['Boolean'];
  /**
   * Время создания для выдачи (может отличаться от фактического)
   * @deprecated use createdAt.iso
   */
  createTime?: Maybe<Scalars['String']>;
  /**
   * Время обновления для выдачи (может отличаться от фактического)
   * @deprecated use updatedAt.iso
   */
  updateTime?: Maybe<Scalars['String']>;
  /**
   * Время распубликации оффера (если отсутствует, значит оффер не распубликован)
   * @deprecated use removedAt.iso
   */
  removeTime?: Maybe<Scalars['String']>;
  /** Уровень видимости оффера */
  visibility?: Maybe<OfferVisibilityEnum>;
  /** Информация о кластеризации */
  cluster?: Maybe<Cluster>;
  /** Информация о VAS */
  vas?: Maybe<Array<VasValueCaseEnumType>>;
  hasTop: Scalars['Boolean'];
  hasHighlight: Scalars['Boolean'];
  /** Метка о том что оффер имеет любую ВАСовость при размещении */
  hasAnyPriority: Scalars['Boolean'];
};

/** Дата и время с форматированием */
export type Date = {
  __typename?: 'Date';
  seconds: Scalars['Int'];
  iso: Scalars['String'];
  formattedFull: Scalars['String'];
  formattedAutoDate: Scalars['String'];
  formattedTime: Scalars['String'];
  /** 16 ноября 2021 г. 09:45 */
  formattedDateAndTime: Scalars['String'];
  /** 16.02.99 09:45 */
  formattedShortDateAndTime: Scalars['String'];
};

export enum OfferVisibilityEnum {
  Unknown = 'UNKNOWN',
  Nobody = 'NOBODY',
  ProfessionalsOnly = 'PROFESSIONALS_ONLY',
  Everyone = 'EVERYONE',
  Employee = 'EMPLOYEE'
}

/** Информация о кластеризации */
export type Cluster = {
  __typename?: 'Cluster';
  /** Идентификатор кластера */
  id: Scalars['ID'];
  /** Количество элементов кластера без представителя. Результирующее значение для показа на выдаче в зависимости от роли пользователя */
  tailSize?: Maybe<Scalars['Int']>;
  /** Внутреннее представление кластера. Не для выдачи */
  internal?: Maybe<InternalRepresentation>;
};

/** Внутреннее представление кластера. Не для выдачи */
export type InternalRepresentation = {
  __typename?: 'InternalRepresentation';
  /** Количество элементов кластера с видимостью ВСЕ. Только для внутреннего использвания */
  everyoneTailSize?: Maybe<Scalars['Int']>;
  /** Количество элементов кластера с видимостью ПРОФЕССИОНАЛЫ. Только для внутреннего использвания */
  employeeTailSize?: Maybe<Scalars['Int']>;
  /** Количество элементов кластера с видимостью СОТРУДНИКИ. Только для внутреннего использвания */
  professionalsTailSize?: Maybe<Scalars['Int']>;
};

export type VasValueCaseEnumType = {
  __typename?: 'VasValueCaseEnumType';
  valueCase?: Maybe<VasValueCaseEnum>;
};

export enum VasValueCaseEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Priority = 'PRIORITY',
  Top = 'TOP',
  Highlight = 'HIGHLIGHT'
}

/** Статистика просмотров */
export type ProfOfferStats = {
  __typename?: 'ProfOfferStats';
  /** Просматривал ли текущий пользователь этот оффер ранее */
  isViewed?: Maybe<Scalars['Boolean']>;
  /** За все время */
  all?: Maybe<OfferPlacementStats>;
  /** За день */
  today?: Maybe<OfferPlacementStats>;
};

export type OfferPlacementStats = {
  __typename?: 'OfferPlacementStats';
  offerViews?: Maybe<Scalars['Float']>;
  phoneViews?: Maybe<Scalars['Float']>;
};

export type ProfOfferText = {
  __typename?: 'ProfOfferText';
  slug: Scalars['String'];
  pageDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  metaDescription: Scalars['String'];
  titleSnippet: Scalars['String'];
  titleCard: Scalars['String'];
  titleSnippetMini: Scalars['String'];
  titleBuildingSavedSearch: Scalars['String'];
};

/** Информация о сделке */
export type ProfOfferDealType = ProfOfferDealSell | ProfOfferDealRent;

/** Информация о продаже */
export type ProfOfferDealSell = {
  __typename?: 'ProfOfferDealSell';
  commission?: Maybe<ProfOfferCommission>;
  /** Тип продажи */
  sellType?: Maybe<SellTypeEnum>;
  sellTypeFormatted?: Maybe<Scalars['String']>;
  /** Возможность торга */
  bargainAllowed?: Maybe<ProfOfferBargain>;
  /** Есть ли обременение */
  hasBankPledge: Scalars['Boolean'];
  /** Информация по обременению */
  pledgeInfo?: Maybe<ProfOfferBankPledge>;
  /** Информация о цене */
  price?: Maybe<ProfOfferPriceSell>;
  /** Возможность продажи в ипотеку */
  mortgageAllowed?: Maybe<Scalars['Boolean']>;
};

export type ProfOfferCommission = {
  __typename?: 'ProfOfferCommission';
  /** Коммиссия агенту в процентах */
  agentCommissionPercent?: Maybe<Scalars['Int']>;
  /** Коммиссия клиенту в процентах */
  clientCommissionPercent?: Maybe<Scalars['Int']>;
  has: Scalars['Boolean'];
  formatted: Scalars['String'];
};

export enum SellTypeEnum {
  Unknown = 'UNKNOWN',
  Primary = 'PRIMARY',
  PrimaryReassignment = 'PRIMARY_REASSIGNMENT',
  Secondary = 'SECONDARY',
  SecondaryPrimary = 'SECONDARY_PRIMARY',
  Alternative = 'ALTERNATIVE'
}

export type ProfOfferBargain = {
  __typename?: 'ProfOfferBargain';
  has: Scalars['Boolean'];
  formatted?: Maybe<Scalars['String']>;
};

/** Информация об обременении банка */
export type ProfOfferBankPledge = {
  __typename?: 'ProfOfferBankPledge';
  bankName?: Maybe<Scalars['String']>;
};

/** Информация о цене по сделке */
export type ProfOfferPriceSell = {
  __typename?: 'ProfOfferPriceSell';
  /** Цена в минимальных единицах */
  value: Scalars['Float'];
  /** Отформатированная цена в виде "8 млн ₽" */
  formatted: Scalars['String'];
  /** Цена в копейках у валютных офферов */
  rubPrice?: Maybe<Scalars['Float']>;
  /** Валюта */
  currency: CurrencyEnum;
  /** История изменения цены */
  history?: Maybe<Array<HistoricalPrice>>;
  /** Цена в за единицу площади */
  valuePerAreaUnit?: Maybe<Scalars['Float']>;
  /** Цена за единицу площади в рублях у валютных офферов */
  valuePerAreaUnitRub?: Maybe<Scalars['Float']>;
  /** Единица площади */
  areaUnit?: Maybe<AreaUnitEnum>;
  areaUnitFormatted?: Maybe<Scalars['String']>;
  areaUnitFormattedFor?: Maybe<Scalars['String']>;
};

export enum CurrencyEnum {
  Unknown = 'UNKNOWN',
  Rub = 'RUB',
  Usd = 'USD',
  Eur = 'EUR'
}

/** Информация о истории цены */
export type HistoricalPrice = {
  __typename?: 'HistoricalPrice';
  /** Цена в минимальных единицах (копейки, центы, eвроценты) */
  value: Scalars['Float'];
  /** Цена в копейках */
  valueRub: Scalars['Float'];
  /** Предыдущая цена в минимальных единицах, при смене валюты, предыдущее значение приводится к валюте текущего */
  valuePrevInCurrentCurrency?: Maybe<Scalars['Float']>;
  /** Разнице цен между текущей и предыдущей по времени записью в истории */
  diff?: Maybe<PriceFixedRateTypeDiff>;
  /** Валюта цены */
  currency: CurrencyRateEnum;
  /** Период аренды: день/месяц/год */
  period?: Maybe<OfferDealRentPeriod>;
  /** Курс валюты на момент изменения цены */
  currencyRate: Scalars['Float'];
  /** Дата изменения цены */
  date2?: Maybe<Date>;
  /**
   * Дата изменения цены
   * @deprecated use date2.iso
   */
  date?: Maybe<Scalars['String']>;
};

/** Разница цен в истории */
export type PriceFixedRateTypeDiff = {
  __typename?: 'PriceFixedRateTypeDiff';
  /** Разница цен в рублях, с учетом курса на момент записи в историю */
  rub?: Maybe<Scalars['Float']>;
  /** Разница цен в валюте оффера, при смене валюты, предыдущее значение приводится к валюте текущего */
  native?: Maybe<Scalars['Float']>;
};

export enum CurrencyRateEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Rub = 'RUB',
  Eur = 'EUR',
  Usd = 'USD'
}

/** Расчетный период */
export enum OfferDealRentPeriod {
  Unknown = 'UNKNOWN',
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR'
}

export enum AreaUnitEnum {
  Unknown = 'UNKNOWN',
  Meter = 'METER',
  Are = 'ARE',
  Hectare = 'HECTARE'
}

/** Информация об аренде */
export type ProfOfferDealRent = {
  __typename?: 'ProfOfferDealRent';
  commission?: Maybe<ProfOfferCommission>;
  /** Возможность торга */
  bargainAllowed?: Maybe<ProfOfferBargain>;
  /** Информация о цене */
  price?: Maybe<ProfOfferPriceRent>;
  /** Расчетный период */
  period?: Maybe<OfferDealRentPeriod>;
  /** Коммунальные услуги */
  utilities?: Maybe<RentUtilities>;
  /** Требования к арендаторам жилой недвижимости */
  livingTenantRequirements?: Maybe<RentLivingTenantRequirements>;
  hasPrepayment?: Maybe<HasPrepayment>;
  hasDeposit?: Maybe<HasDeposit>;
};

/** Информация о цене */
export type ProfOfferPriceRent = {
  __typename?: 'ProfOfferPriceRent';
  /** Цена в минимальных единицах (копейки, центы, eвроценты) */
  value: Scalars['Float'];
  /** Цена в рублях у валютных офферов */
  rubPrice?: Maybe<Scalars['Float']>;
  /** Валюта */
  currency: CurrencyEnum;
  /** История изменения цены */
  history?: Maybe<Array<HistoricalPrice>>;
  formatted: Scalars['String'];
  formattedWithCurrency: Scalars['String'];
  /** Единица площади */
  areaUnit?: Maybe<AreaUnitEnum>;
};

/** Коммунальные услуги */
export type RentUtilities = {
  __typename?: 'RentUtilities';
  /** Стоимость коммунальных услуг */
  price?: Maybe<Price>;
  /** Включеные ли коммунальные услуги */
  has?: Maybe<Scalars['Boolean']>;
  formatted: Scalars['String'];
};

/** Информация о цене */
export type Price = {
  __typename?: 'Price';
  /** Цена в минимальных единицах (копейки, центы, eвроценты) */
  value: Scalars['Float'];
  /** Валюта */
  currency: CurrencyEnum;
  formatted: Scalars['String'];
  /** Единица площади */
  areaUnit?: Maybe<AreaUnitEnum>;
};

/** Требования к арендаторам (для жилой недвижимости) */
export type RentLivingTenantRequirements = {
  __typename?: 'RentLivingTenantRequirements';
  /** Можно с детьми */
  childrenAllowed?: Maybe<ChildrenAllowed>;
  /** Можно с домашними животными */
  petsAllowed?: Maybe<PetsAllowed>;
  /** Допустимые типы арендаторов */
  tenantTypes?: Maybe<Array<Maybe<LivingTenantsRequirementsType>>>;
};

export type ChildrenAllowed = {
  __typename?: 'ChildrenAllowed';
  has: Scalars['Boolean'];
  formatted?: Maybe<Scalars['String']>;
};

export type PetsAllowed = {
  __typename?: 'PetsAllowed';
  has: Scalars['Boolean'];
  formatted?: Maybe<Scalars['String']>;
};

/** Тип арендаторов */
export enum LivingTenantsRequirementsType {
  Unknown = 'UNKNOWN',
  Any = 'ANY',
  Family = 'FAMILY',
  Male = 'MALE',
  Female = 'FEMALE'
}

/** Информация о предоплате */
export type HasPrepayment = {
  __typename?: 'HasPrepayment';
  /** Сумма предоплаты */
  price?: Maybe<Price>;
  has: Scalars['Boolean'];
  formatted: Scalars['String'];
  /** Предоплата в арендных платах за период */
  ofPricePerPeriod?: Maybe<Scalars['Int']>;
};

/** Информация о залоге */
export type HasDeposit = {
  __typename?: 'HasDeposit';
  /** Сумма депозита */
  price?: Maybe<Price>;
  has: Scalars['Boolean'];
  formatted: Scalars['String'];
  /** Сумма депозита в арендных платах за период */
  ofPricePerPeriod?: Maybe<Scalars['Int']>;
};

/** Ипотека для? */
export enum MortgagePurpose {
  /** Новостройка */
  Primary = 'PRIMARY',
  /** Вторичка */
  Secondary = 'SECONDARY',
  /** Рефинансирование */
  Refinance = 'REFINANCE',
  /** Семейная */
  Family = 'FAMILY',
  /** Что-то специфичное: ИЖС для Казани в ВТБ */
  Php = 'PHP',
  /** Не определено */
  Unknown = 'UNKNOWN'
}

/** Информация об объекте недвижимости */
export type ProfRealityObject = ProfOfferFlat | ProfOfferRoom | ProfOfferHouse | ProfOfferLand;

export type ProfOfferFlat = {
  __typename?: 'ProfOfferFlat';
  flatType?: Maybe<FlatTypeCaseEnum>;
  totalArea?: Maybe<AreaType>;
  kitchenArea?: Maybe<AreaType>;
  livingArea?: Maybe<AreaType>;
  /** @deprecated use totalArea */
  totalAreaValue?: Maybe<Scalars['Float']>;
  /** @deprecated use kitchenArea */
  kitchenAreaValue?: Maybe<Scalars['Float']>;
  /** @deprecated use livingArea */
  livingAreaValue?: Maybe<Scalars['Float']>;
  /** @deprecated use kitchenArea */
  kitchenAreaUnit?: Maybe<AreaUnitEnum>;
  /** @deprecated use totalArea */
  totalAreaUnit?: Maybe<AreaUnitEnum>;
  /** @deprecated use livingArea */
  livingAreaUnit?: Maybe<AreaUnitEnum>;
  roomsCount?: Maybe<Scalars['Int']>;
  rooms?: Maybe<Array<Maybe<ProfOfferLivingRoom>>>;
  /** Тип квартиры */
  roomType?: Maybe<RoomsEnum>;
  floor?: Maybe<Scalars['Int']>;
  livingFacilities?: Maybe<ProfOfferLivingFacilities>;
  characteristics?: Maybe<ProfOfferFlatCharacteristics>;
  building?: Maybe<ProfOfferBuilding>;
  /** Является апартаментами */
  isApartments?: Maybe<Scalars['Boolean']>;
};

export enum FlatTypeCaseEnum {
  FlatTypeNotSet = 'FLAT_TYPE_NOT_SET',
  Regular = 'REGULAR',
  Studio = 'STUDIO',
  OpenPlan = 'OPEN_PLAN'
}

export type AreaType = {
  __typename?: 'AreaType';
  unit: AreaUnitEnum;
  unitFormatted: Scalars['String'];
  unitFormattedFor: Scalars['String'];
  value: Scalars['Float'];
  valueFormatted: Scalars['String'];
  formatted: Scalars['String'];
};

/** Информация о комнате */
export type ProfOfferLivingRoom = {
  __typename?: 'ProfOfferLivingRoom';
  livingArea?: Maybe<AreaType>;
  /** @deprecated use livingArea */
  value?: Maybe<Scalars['Float']>;
  /** @deprecated use livingArea */
  area?: Maybe<AreaUnitEnum>;
  relativeFloor?: Maybe<Scalars['Int']>;
};

export enum RoomsEnum {
  Unknown = 'UNKNOWN',
  Studio = 'STUDIO',
  Room_1 = 'ROOM_1',
  Room_2 = 'ROOM_2',
  Room_3 = 'ROOM_3',
  Room_4 = 'ROOM_4',
  Room_5AndMore = 'ROOM_5_AND_MORE',
  OpenPlan = 'OPEN_PLAN',
  Room_4AndMore = 'ROOM_4_AND_MORE'
}

/** Информация об удобстве квартиры */
export type ProfOfferLivingFacilities = {
  __typename?: 'ProfOfferLivingFacilities';
  /** Наличие кондиционера */
  hasAirConditioner?: Maybe<Scalars['Boolean']>;
  /** Наличие посудомойки */
  hasDishwasher?: Maybe<Scalars['Boolean']>;
  /** Наличие холодильника */
  hasFridge?: Maybe<Scalars['Boolean']>;
  /** Наличие мебели */
  hasFurniture?: Maybe<Scalars['Boolean']>;
  /** Наличие интернета */
  hasInternet?: Maybe<Scalars['Boolean']>;
  /** Наличие кухонной мебели */
  hasKitchenFurniture?: Maybe<Scalars['Boolean']>;
  /** Наличие ТВ */
  hasTv?: Maybe<Scalars['Boolean']>;
  /** Наличие стиральной машины */
  hasWashingMachine?: Maybe<Scalars['Boolean']>;
};

/** Информация о квартире */
export type ProfOfferFlatCharacteristics = {
  __typename?: 'ProfOfferFlatCharacteristics';
  hasBalcony?: Maybe<Scalars['Boolean']>;
  ceilingHeightM?: Maybe<Scalars['String']>;
  bathroomCount?: Maybe<Scalars['Int']>;
  bathroomType?: Maybe<BathroomEnum>;
  renovationType?: Maybe<RenovationTypeEnum>;
  nbFinishingType?: Maybe<NbFinishingType>;
  floor?: Maybe<Scalars['Int']>;
  windowsDirection?: Maybe<WindowsDirectionOfferEnum>;
};

export enum BathroomEnum {
  Unknown = 'UNKNOWN',
  Combined = 'COMBINED',
  Parted = 'PARTED',
  Several = 'SEVERAL'
}

export enum RenovationTypeEnum {
  Unknown = 'UNKNOWN',
  Design = 'DESIGN',
  Euro = 'EURO',
  Need = 'NEED',
  Good = 'GOOD',
  Partial = 'PARTIAL',
  PreFine = 'PRE_FINE',
  Fine = 'FINE',
  Rough = 'ROUGH',
  WithFurniture = 'WITH_FURNITURE',
  PreFinishing = 'PRE_FINISHING'
}

export type NbFinishingType = {
  __typename?: 'NbFinishingType';
  type?: Maybe<FinishingBuildingEnum>;
  text: Scalars['String'];
};

export enum FinishingBuildingEnum {
  Unknown = 'UNKNOWN',
  Fine = 'FINE',
  PreFinishing = 'PRE_FINISHING',
  WithoutFinishing = 'WITHOUT_FINISHING',
  WithFurniture = 'WITH_FURNITURE'
}

export enum WindowsDirectionOfferEnum {
  Unknown = 'UNKNOWN',
  Rear = 'REAR',
  Front = 'FRONT',
  Both = 'BOTH'
}

/** Информация о здании */
export type ProfOfferBuilding = {
  __typename?: 'ProfOfferBuilding';
  buildingId?: Maybe<Scalars['Int']>;
  newBuildingId?: Maybe<Scalars['Int']>;
  isNewbuilding?: Maybe<Scalars['Boolean']>;
  hasLift?: Maybe<Scalars['Boolean']>;
  constructionState?: Maybe<ProfOfferFlatBuildingConstructionStateCase>;
  hasGarbageChute?: Maybe<Scalars['Boolean']>;
  /** Статус корпуса новостройки/дома: построен/сдан, бейджи, даты */
  newBuildingStatus?: Maybe<ProfOfferNewBuildingStatus>;
  floorsTotal?: Maybe<Scalars['Int']>;
  wallsType?: Maybe<WallsTypeEnum>;
  /** Базовая информация о новостройке */
  newBuildingBasicInfo?: Maybe<ProfOfferNewBuildingBasicInfo>;
};

export enum ProfOfferFlatBuildingConstructionStateCase {
  ConstructionStateNotSet = 'CONSTRUCTION_STATE_NOT_SET',
  InProgress = 'IN_PROGRESS',
  Built = 'BUILT'
}

/** Статус новостройки (даты сдачи, бейджи) */
export type ProfOfferNewBuildingStatus = {
  __typename?: 'ProfOfferNewBuildingStatus';
  formattedInProgress: Scalars['String'];
  builtYear?: Maybe<Scalars['Int']>;
  isDoneBadge?: Maybe<Scalars['Boolean']>;
  isKeysComingSoonBadge?: Maybe<Scalars['Boolean']>;
};

export enum WallsTypeEnum {
  Unknown = 'UNKNOWN',
  Monolith = 'MONOLITH',
  BrickMonolith = 'BRICK_MONOLITH',
  Brick = 'BRICK',
  Panel = 'PANEL',
  Block = 'BLOCK',
  Wood = 'WOOD'
}

/** Базовая информация о новостройке */
export type ProfOfferNewBuildingBasicInfo = {
  __typename?: 'ProfOfferNewBuildingBasicInfo';
  title: Scalars['String'];
  slug: Scalars['String'];
};

export type ProfOfferRoom = {
  __typename?: 'ProfOfferRoom';
  /** Квартира, в которой находится комната */
  enclosingFlat?: Maybe<ProfOfferFlat>;
  /** Кол-во комнат */
  roomCount: Scalars['Int'];
  rooms?: Maybe<Array<Maybe<ProfOfferLivingRoom>>>;
  livingArea?: Maybe<AreaType>;
  /** Площадь комнат -> 9 / 16 м² */
  roomsLivingAreaFormatted?: Maybe<Scalars['String']>;
};

export type ProfOfferHouse = {
  __typename?: 'ProfOfferHouse';
  /** @deprecated use category2.value */
  category?: Maybe<HouseCategoryEnum>;
  category2?: Maybe<HouseCategory>;
  totalArea?: Maybe<AreaType>;
  livingFacilities?: Maybe<ProfOfferLivingFacilities>;
  floorsTotal?: Maybe<Scalars['Int']>;
  builtYear?: Maybe<Scalars['Int']>;
  hasBathroom?: Maybe<Scalars['Boolean']>;
  bathroomLocationType?: Maybe<BathroomLocationEnum>;
  hasHeating?: Maybe<Scalars['Boolean']>;
  heatingType?: Maybe<HeatingTypeEnum>;
  land?: Maybe<ProfOfferLand>;
  /** Кол-во комнат */
  roomsCount?: Maybe<Scalars['Int']>;
  rooms?: Maybe<Array<ProfOfferLivingRoom>>;
};

export enum HouseCategoryEnum {
  Unknown = 'UNKNOWN',
  Standalone = 'STANDALONE',
  PartOfHouse = 'PART_OF_HOUSE',
  Duplex = 'DUPLEX',
  Townhouse = 'TOWNHOUSE'
}

export type HouseCategory = {
  __typename?: 'HouseCategory';
  value: HouseCategoryEnum;
  formatted: Scalars['String'];
};

export enum BathroomLocationEnum {
  Unknown = 'UNKNOWN',
  Inside = 'INSIDE',
  Outside = 'OUTSIDE'
}

export enum HeatingTypeEnum {
  Unknown = 'UNKNOWN',
  AutonomousGas = 'AUTONOMOUS_GAS',
  CentralGas = 'CENTRAL_GAS',
  CentralCoal = 'CENTRAL_COAL',
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  Fireplace = 'FIREPLACE',
  SolidFuelBoiler = 'SOLID_FUEL_BOILER',
  Stove = 'STOVE'
}

/** Участок */
export type ProfOfferLand = {
  __typename?: 'ProfOfferLand';
  area?: Maybe<AreaType>;
  supply?: Maybe<ProfOfferSupply>;
  /** @deprecated use status2.value */
  status: LandStatusEnum;
  status2?: Maybe<LandStatus>;
};

export type ProfOfferSupply = {
  __typename?: 'ProfOfferSupply';
  formatted?: Maybe<Array<GenericInfo>>;
  /** Газ */
  hasGas?: Maybe<Scalars['Boolean']>;
  /** Вода */
  hasWater?: Maybe<Scalars['Boolean']>;
  /** Электричество */
  hasElectricity?: Maybe<Scalars['Boolean']>;
  /** Канализация */
  hasSewerage?: Maybe<Scalars['Boolean']>;
};

export type GenericInfo = {
  __typename?: 'GenericInfo';
  title: Scalars['String'];
  value: Scalars['String'];
};

export enum LandStatusEnum {
  Unknown = 'UNKNOWN',
  Izhs = 'IZHS',
  Snt = 'SNT',
  Dnp = 'DNP',
  Industrial = 'INDUSTRIAL',
  Agricultural = 'AGRICULTURAL',
  Invest = 'INVEST'
}

/** Статус участка */
export type LandStatus = {
  __typename?: 'LandStatus';
  value: LandStatusEnum;
  formatted: Scalars['String'];
};

/** Продавец */
export type Seller = {
  __typename?: 'Seller';
  /** Id продавца */
  ownerId?: Maybe<Scalars['Int']>;
  /** Имя продавца или контактного лица (в зависимости от типа) */
  name?: Maybe<Scalars['String']>;
  /** Адрес электронной почты */
  email?: Maybe<Scalars['String']>;
  /** Название организации (агентство, застройщик etc.) */
  organizationName?: Maybe<Scalars['String']>;
  /** Название организации с типом */
  organizationNameFull?: Maybe<Scalars['String']>;
  /** Тип продавца */
  sellerType: SellerTypeEnum;
  logo?: Maybe<Image2>;
  /** Наличие у продавца круглого логотипа */
  isZhekaLogo?: Maybe<Scalars['Boolean']>;
  mainPhone?: Maybe<Phone>;
  /** Список телефонов */
  phoneList?: Maybe<Array<Phone>>;
  /** Id пользователя из плейсмента */
  placementUserId?: Maybe<Scalars['String']>;
};

export enum SellerTypeEnum {
  Unknown = 'UNKNOWN',
  Owner = 'OWNER',
  Agent = 'AGENT',
  Agency = 'AGENCY',
  Developer = 'DEVELOPER',
  Pit = 'PIT',
  Aggregator = 'AGGREGATOR'
}

/** Картинка */
export type Image2 = {
  __typename?: 'Image2';
  id: Scalars['String'];
  attributesType?: Maybe<ImageAttributesTypeEnum>;
  originPath: Scalars['String'];
  origin: Scalars['String'];
  huge: Scalars['String'];
  large: Scalars['String'];
  medium: Scalars['String'];
  small: Scalars['String'];
  preview1: Scalars['String'];
  preview2: Scalars['String'];
};

export enum ImageAttributesTypeEnum {
  Unknown = 'UNKNOWN',
  Layout = 'LAYOUT'
}

/** Телефон */
export type Phone = {
  __typename?: 'Phone';
  countryCode: Scalars['String'];
  number: Scalars['String'];
  hiddenSuffixLength: Scalars['Int'];
  isDoppelganger: Scalars['Boolean'];
  /** Должен ли телефон подменяться через Calltouch */
  isReplaceable: Scalars['Boolean'];
  href: Scalars['String'];
  formattedNumber: Scalars['String'];
};

/** Абстрактные свойства оффера */
export type ProfOfferAbstractOptions = {
  __typename?: 'ProfOfferAbstractOptions';
  /** Онлайн просмотр */
  onlineView: Scalars['Boolean'];
};

export type ProfOfferInfrastructure = {
  __typename?: 'ProfOfferInfrastructure';
  /** Наличие консьержа */
  hasConcierge?: Maybe<Scalars['Boolean']>;
  /** Наличие закрытой территории */
  hasGuardedArea?: Maybe<Scalars['Boolean']>;
  /** Информация о парковке */
  parking?: Maybe<Array<Maybe<ProfOfferInfrastructureParking>>>;
};

export type ProfOfferInfrastructureParking = {
  __typename?: 'ProfOfferInfrastructureParking';
  available?: Maybe<Scalars['Boolean']>;
  places?: Maybe<Scalars['Int']>;
  type?: Maybe<ProfOfferInfrastructureParkingType>;
};

export enum ProfOfferInfrastructureParkingType {
  Unknown = 'UNKNOWN',
  Closed = 'CLOSED',
  Underground = 'UNDERGROUND',
  Open = 'OPEN'
}

export type ProfOfferBadges = {
  __typename?: 'ProfOfferBadges';
  isRgrMember: Scalars['Boolean'];
  isNewbuilding: Scalars['Boolean'];
};

export type ProfOfferLocation = {
  __typename?: 'ProfOfferLocation';
  /** @deprecated use narrowRegion.id */
  regionId?: Maybe<Scalars['Int']>;
  narrowRegion?: Maybe<GeoObjectCore>;
  wideDistrict?: Maybe<GeoObjectCore>;
  narrowDistrict?: Maybe<GeoObjectCore>;
  /** Город или село */
  locality?: Maybe<GeoObjectCore>;
  street?: Maybe<GeoObjectCore>;
  house?: Maybe<GeoObjectCore>;
  routesList?: Maybe<Array<Route>>;
  coordinates?: Maybe<Point>;
  formattedAddressShort?: Maybe<Scalars['String']>;
  formattedAddressLong?: Maybe<Scalars['String']>;
  /** @deprecated use formattedAddressShort */
  formattedAddress?: Maybe<Scalars['String']>;
  /** intended for use in offer card mobile/desk */
  clickableAddress?: Maybe<Array<GeoObjectCore>>;
  clickableAddressShort?: Maybe<Array<GeoObjectCore>>;
  cityCenterInfo?: Maybe<CityCenterInfo>;
  isMskMO: Scalars['Boolean'];
  isSPbLO: Scalars['Boolean'];
};

/** Расстояние до геобьекта */
export type Route = RouteMetro | DefaultRouter;

/** Путь до метро */
export type RouteMetro = {
  __typename?: 'RouteMetro';
  /** Тип траспорта до метро */
  transportType: TransportTypeEnum;
  /** Время до метро */
  timeMinutes: Scalars['Int'];
  station?: Maybe<MetroStation>;
};

export enum TransportTypeEnum {
  Unknown = 'UNKNOWN',
  Foot = 'FOOT',
  PublicTransport = 'PUBLIC_TRANSPORT'
}

/** Путь до метро */
export type DefaultRouter = {
  __typename?: 'DefaultRouter';
  /** Тип траспорта до метро */
  transportType?: Maybe<TransportTypeEnum>;
  /** Время до метро */
  timeMinutes?: Maybe<Scalars['Int']>;
};

/** CityCenterInfo */
export type CityCenterInfo = {
  __typename?: 'CityCenterInfo';
  metersToCentralSquare?: Maybe<Scalars['Int']>;
  locatedInCenter?: Maybe<Scalars['Boolean']>;
  /** @deprecated deprcated: use field "formatted" */
  metersToCentralSquareFormatted?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
};

/** Картинки */
export type Gallery = {
  __typename?: 'Gallery';
  baseUrl: Scalars['String'];
  images: Array<Image2>;
  layoutImage?: Maybe<Image2>;
  mainImage: Image2;
};

/** Картинка */
export type Image = {
  __typename?: 'Image';
  /** Подтип типа картинки */
  type: Scalars['String'];
  originPath: Scalars['String'];
  origin: Scalars['String'];
  largeX2: Scalars['String'];
  galleryPhoto: Scalars['String'];
  promoSnippet: Scalars['String'];
  large: Scalars['String'];
  medium: Scalars['String'];
  newBuildingSnippet: Scalars['String'];
  mediumMobile: Scalars['String'];
  galleryThumbnail: Scalars['String'];
  small: Scalars['String'];
  smallLogo: Scalars['String'];
  mobileGallery: Scalars['String'];
  placemarkHint: Scalars['String'];
};

export type NewBuildingCard = {
  __typename?: 'NewBuildingCard';
  id: Scalars['Int'];
  narrowRegionId: Scalars['Int'];
  narrowRegionLocative?: Maybe<Scalars['String']>;
  backofficeRegionId?: Maybe<Scalars['Int']>;
  ranking: RankingEnum;
  hasChat: Scalars['Boolean'];
  /** Есть ли квартиры */
  hasFlats: Scalars['Boolean'];
  /** Только ли квартиры */
  hasFlatsOnly: Scalars['Boolean'];
  /** Есть ли апартаменты */
  hasApartments: Scalars['Boolean'];
  /** Только ли апартаменты */
  hasApartmentsOnly: Scalars['Boolean'];
  /** Всё продано */
  isSoldOut: Scalars['Boolean'];
  /** Скоро в продаже */
  isSoon: Scalars['Boolean'];
  hasMilitaryMortgage: Scalars['Boolean'];
  /** Название новостройки */
  name: Scalars['String'];
  /** Тип новостройки */
  type: Scalars['String'];
  /** Тип + название новостройки */
  title: NewBuildingTitle;
  /** Текст для SEO-элементов на странице карточки новостройки */
  seo: NewBuildingCardSeo;
  /** Текст для SEO-элементов на странице хода строительства новостройки */
  constructionSeo: NewBuildingConstructionSeo;
  /** Текст для SEO-элементов для поиска офферов в новостройке */
  offersSeo: NewBuildingOffersSeo;
  description?: Maybe<Scalars['String']>;
  /** Полное описание новостройки из CMS */
  fullDescription?: Maybe<NewBuildingFullDescription>;
  position?: Maybe<NewBuildingPositionEnum>;
  snippetDescription?: Maybe<Scalars['String']>;
  buildingClass?: Maybe<NewBuildingClass>;
  buildingStatus?: Maybe<Array<NewBuildingStatus>>;
  year?: Maybe<Scalars['Int']>;
  isMskMO: Scalars['Boolean'];
  isSPbLO: Scalars['Boolean'];
  /** Признак, что новостройка из региона со спецпроектом */
  isSpecProjectRegion: Scalars['Boolean'];
  hasPreferentialMortgage: Scalars['Boolean'];
  coordinates: Array<Scalars['Float']>;
  polygons: Array<Array<Array<Scalars['Float']>>>;
  address: Scalars['String'];
  /** Маршруты до ближайших геобьектов */
  routes?: Maybe<Array<Maybe<Route>>>;
  /** Галерея */
  gallery?: Maybe<NewBuildingGallery>;
  /** ID видеоролика */
  videoId: Scalars['String'];
  /** Минимальная цена в ЖК */
  priceMin?: Maybe<Scalars['Float']>;
  /** Максимальная цена в ЖК */
  priceMax?: Maybe<Scalars['Float']>;
  /** Минимальная цена за М2 */
  pricePerM2Min?: Maybe<Scalars['Int']>;
  /** Максимальная цена за М2 */
  pricePerM2Max?: Maybe<Scalars['Int']>;
  formatAreaRange?: Maybe<Scalars['String']>;
  /** Минимальный платеж по ипотеке ВТБ */
  mortgageMinPay?: Maybe<NewBuildingVtbMortgageMinPayType>;
  /** Список карточек застройщиков */
  developerCards: Array<DeveloperCard>;
  /** Промобаннер застройщика в зависимости от региона ЖК */
  developerPromoBanner?: Maybe<NewBuildingCardDeveloperPromo>;
  buildings?: Maybe<Array<Building>>;
  /** Список квартир в новостройке */
  flats?: Maybe<Array<NewBuildingFlat>>;
  /** Список квартир в новостройке */
  offersSummaryInfo: NewBuildingOffersSummaryInfo;
  offersUpdateDate?: Maybe<Scalars['Int']>;
  info?: Maybe<NewBuildingInfo>;
  documents: NewBuildingDocuments;
  routeParams: RouteParams;
  mortgageParams: NewBuildingMortgageParams;
  shortBanksMortgage?: Maybe<NewBuildingCardShortBanksMortgage>;
  noOffers: Scalars['Boolean'];
  similarParams: SimilarParams;
  developerRegionIds: Array<Scalars['Int']>;
  /** Акции, скидки, подарки */
  specialEvents: Array<NewBuildingSpecialEvent>;
  /** Данные о ходе строительства новостройки и ее корпусов */
  constructionProgress: Array<NewBuildingConstructionProgress>;
  /** Статус проверки ЖК в стройнадзоре */
  inspectionStatus?: Maybe<NewBuildingInspectionStatus>;
  /** @deprecated Currently built on client */
  breadcrumbs?: Maybe<NewBuildingCardBreadcrumbsSeo>;
  /** Новости ЖК */
  news?: Maybe<Array<NewBuildingNews>>;
  /** Добавлена ли новостройка в избранное пользователя */
  isFavorite: Scalars['Boolean'];
  /** Параметр для сортировки ЖК в избранном */
  added?: Maybe<Scalars['Float']>;
  /** Информация для рекламной карточки */
  infoList: Array<Scalars['String']>;
  /** Список преимуществ для рекламной карточки */
  advantagesList: Array<Scalars['String']>;
  /** Условия для рекламной карточки */
  conditions?: Maybe<NewBuildingConditions>;
  /** Заголовок рекламной карточки */
  simplifiedCardHeader: Scalars['String'];
  /** Средняя оценка пользователей */
  rating?: Maybe<Scalars['Float']>;
  /** Количество оценок пользователей */
  reviewsCount?: Maybe<Scalars['Int']>;
  buildingStats: NewBuildingCardBuildingStats;
  m2Pro?: Maybe<NewBuildingM2Pro>;
};


export type NewBuildingCardNarrowRegionIdArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


export type NewBuildingCardNarrowRegionLocativeArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


export type NewBuildingCardHasChatArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


export type NewBuildingCardSeoArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


export type NewBuildingCardConstructionSeoArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


export type NewBuildingCardOffersSeoArgs = {
  dealType?: InputMaybe<DealTypeEnum>;
  realtyObject?: InputMaybe<RealtyObjectEnum>;
};


export type NewBuildingCardRoutesArgs = {
  type?: InputMaybe<Array<InputMaybe<RoutePoiTypeEnum>>>;
};


export type NewBuildingCardPriceMinArgs = {
  promo?: InputMaybe<Scalars['Boolean']>;
};


export type NewBuildingCardInspectionStatusArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


export type NewBuildingCardBreadcrumbsArgs = {
  id: Scalars['Int'];
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};

/** Типы индексов в новостройках */
export enum NewBuildingWhitelabelEnum {
  Default = 'Default',
  Vtb = 'Vtb'
}

export enum RankingEnum {
  Unknown = 'UNKNOWN',
  Paid = 'PAID',
  SuperPaid = 'SUPER_PAID',
  SpecialProject = 'SPECIAL_PROJECT'
}

export type NewBuildingTitle = {
  __typename?: 'NewBuildingTitle';
  nominative: Scalars['String'];
  locative: Scalars['String'];
  genitive: Scalars['String'];
  accusative: Scalars['String'];
  dative: Scalars['String'];
  simplified: Scalars['String'];
};

export type NewBuildingCardSeo = {
  __typename?: 'NewBuildingCardSeo';
  /** SEO-название ЖК, заполняется в бэкофисе (для ЖК с названием на латинице) */
  seoTitle?: Maybe<Scalars['String']>;
  /** Текст для тега title */
  title: Scalars['String'];
  /** Текст для метатега description */
  metaDescription: Scalars['String'];
};

export type NewBuildingConstructionSeo = {
  __typename?: 'NewBuildingConstructionSeo';
  /** Текст для тега title */
  title: Scalars['String'];
  /** Текст для метатега description */
  metaDescription: Scalars['String'];
};

export type NewBuildingOffersSeo = {
  __typename?: 'NewBuildingOffersSeo';
  /** Текст для тега h1 */
  h1: Scalars['String'];
  /** Текст для тега title */
  title: Scalars['String'];
  /** Текст для мета-тега description */
  metaDescription: Scalars['String'];
};

export enum DealTypeEnum {
  Unknown = 'UNKNOWN',
  Sell = 'SELL',
  Rent = 'RENT'
}

export enum RealtyObjectEnum {
  Unknown = 'UNKNOWN',
  Flat = 'FLAT',
  Room = 'ROOM',
  House = 'HOUSE',
  Land = 'LAND',
  CountryEstate = 'COUNTRY_ESTATE'
}

export type NewBuildingFullDescription = {
  __typename?: 'NewBuildingFullDescription';
  markdown: Scalars['String'];
  html: Scalars['String'];
};

export enum NewBuildingPositionEnum {
  Unknown = 'UNKNOWN',
  Complex = 'COMPLEX',
  Standalone = 'STANDALONE'
}

export type NewBuildingClass = {
  __typename?: 'NewBuildingClass';
  value: NewBuildingClassEnum;
  label: Scalars['String'];
  fullLabel: Scalars['String'];
};

export enum NewBuildingClassEnum {
  Unknown = 'UNKNOWN',
  Economy = 'ECONOMY',
  Comfort = 'COMFORT',
  ComfortPlus = 'COMFORT_PLUS',
  Business = 'BUSINESS',
  Elite = 'ELITE'
}

/** Статус строительства */
export type NewBuildingStatus = {
  __typename?: 'NewBuildingStatus';
  title: Scalars['String'];
  isFrozen?: Maybe<Scalars['Boolean']>;
  isCommissioned?: Maybe<Scalars['Boolean']>;
};

export enum RoutePoiTypeEnum {
  Metro = 'METRO',
  Route = 'ROUTE'
}

export type NewBuildingGallery = {
  __typename?: 'NewBuildingGallery';
  baseUrl: Scalars['String'];
  /** Главная фотография новостройки */
  main?: Maybe<Image>;
  /** Список фотографий */
  images?: Maybe<Array<Image>>;
};


export type NewBuildingGalleryImagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

/** Расчет минимального платежа для ипотеки в ВТБ */
export type NewBuildingVtbMortgageMinPayType = {
  __typename?: 'NewBuildingVtbMortgageMinPayType';
  /** макс. сумма кредита */
  creditAmount: Scalars['Int'];
  /** срок кредита */
  term: Scalars['Int'];
  /** % ставка */
  rate: Scalars['Float'];
  /** % первоначального взноса */
  initialFee: Scalars['Int'];
  /** Размер платежа */
  minPay: Scalars['Int'];
  variant: NewBuildingVtbMortgageVariant;
};

export enum NewBuildingVtbMortgageVariant {
  /** Для квартир стоимости до 15 млн в МиМО, СПбЛО: округление {0,8*цена*дробь}, где в дроби ставка 6,5, срок 361; */
  Variant_1MskMoSpbLo = 'VARIANT_1_MSK_MO_SPB_LO',
  /** Для квартир стоимости до 7,5 млн: округление {0,8*цена*дробь}, где в дроби ставка 6,5, срок 361; */
  Variant_1OtherRegions = 'VARIANT_1_OTHER_REGIONS',
  /** Для квартир стоимости от 15 млн до 24 млн в МиМО, СПбЛО: округление {12 млн *дробь}, где в дроби ставка 6,5, срок 361 */
  Variant_2MskMoSpbLo = 'VARIANT_2_MSK_MO_SPB_LO',
  /** Для квартир стоимости от 7,5 млн до 12 млн в ост регионы: округление {6 млн *дробь}, где в дроби ставка 6,5, срок 361 */
  Variant_2OtherRegions = 'VARIANT_2_OTHER_REGIONS',
  /** Для квартир стоимостью от 16 млн руб. и апартаментов остаются текущие условия и дисклемер */
  Variant_3 = 'VARIANT_3'
}

/** Карточка застройщика */
export type DeveloperCard = {
  __typename?: 'DeveloperCard';
  /** Застройщик */
  developer?: Maybe<Developer>;
  /** Количество сданных объектов */
  commissionedCount?: Maybe<SummaryItem>;
  /** Количество строящихся объектов */
  inProgressCount?: Maybe<SummaryItem>;
  regions?: Maybe<Array<Maybe<GeoObject>>>;
};

export type Developer = {
  __typename?: 'Developer';
  id: Scalars['Int'];
  isSamolet: Scalars['Boolean'];
  title: Scalars['String'];
  /** label аналогичный лейблу застройщика в саджесте */
  label: Scalars['String'];
  translitTitle: Scalars['String'];
  url: Scalars['String'];
  image?: Maybe<Image>;
  icon?: Maybe<Image>;
  phones: Array<Maybe<Phone>>;
  webSite: Scalars['String'];
};

/** Пункт статистики в блоке застройщика */
export type SummaryItem = {
  __typename?: 'SummaryItem';
  shortLabel: Scalars['String'];
  fullLabel: Scalars['String'];
  value: Scalars['String'];
};

export type NewBuildingCardDeveloperPromo = {
  __typename?: 'NewBuildingCardDeveloperPromo';
  /** id новостройки из баннера */
  id?: Maybe<Scalars['Int']>;
  /** Ссылка для баннера */
  url: Scalars['String'];
  long: Scalars['String'];
  square: Scalars['String'];
  developerName: Scalars['String'];
};

export type Building = {
  __typename?: 'Building';
  id: Scalars['Int'];
  hasApartmentsOnly: Scalars['Boolean'];
  noOffers: Scalars['Boolean'];
  /** Всё продано */
  isSoldOut: Scalars['Boolean'];
  /** Скоро в продаже */
  isSoon: Scalars['Boolean'];
  accreditedByVtb?: Maybe<Scalars['Boolean']>;
  livingFlatsCount?: Maybe<Scalars['Int']>;
  apartmentsCount?: Maybe<Scalars['Int']>;
  polygons?: Maybe<Array<Array<Array<Scalars['Float']>>>>;
  commissioningDate?: Maybe<Scalars['String']>;
  commissioningYear?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  priceRange?: Maybe<Scalars['String']>;
  /** Список квартир в корпусе */
  flats?: Maybe<Array<Maybe<NewBuildingFlat>>>;
  infoTitle?: Maybe<Scalars['String']>;
  saleDetails?: Maybe<BuildingSaleDetails>;
  info: BuildingInfo;
  isCommissioned?: Maybe<Scalars['Boolean']>;
  isFrozen?: Maybe<Scalars['Boolean']>;
  /** Статус проверки корпуса в стройнадзоре */
  inspectionStatus?: Maybe<NewBuildingInspectionStatus>;
  m2Pro?: Maybe<NewBuildingM2Pro>;
};


export type BuildingInspectionStatusArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};

export type NewBuildingFlat = {
  __typename?: 'NewBuildingFlat';
  label?: Maybe<Scalars['String']>;
  labelShort?: Maybe<Scalars['String']>;
  labelFull?: Maybe<Scalars['String']>;
  rooms?: Maybe<Scalars['String']>;
  priceMin?: Maybe<Scalars['Float']>;
  priceMax?: Maybe<Scalars['Float']>;
  formatPriceRange?: Maybe<Scalars['String']>;
  formatPriceRangeShort?: Maybe<Scalars['String']>;
  formatAreaRange?: Maybe<Scalars['String']>;
  isSoldOut?: Maybe<Scalars['Boolean']>;
  isSoon?: Maybe<Scalars['Boolean']>;
  isTemporaryNotOnSale?: Maybe<Scalars['Boolean']>;
};


export type NewBuildingFlatLabelArgs = {
  type?: InputMaybe<NewBuildingFlatLabelType>;
};

export enum NewBuildingFlatLabelType {
  Snippet = 'SNIPPET',
  Card = 'CARD'
}

export type BuildingSaleDetails = {
  __typename?: 'BuildingSaleDetails';
  escrow?: Maybe<BooleanField>;
};

/** Примитивный тип для прокидывания значений и локализации в структурированном виде */
export type BooleanField = {
  __typename?: 'BooleanField';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
};

/** Информация о корпусе */
export type BuildingInfo = {
  __typename?: 'BuildingInfo';
  /** Имя застройщка внутри корпуса */
  legal?: Maybe<Scalars['String']>;
  buildingClass?: Maybe<Array<Maybe<TextField>>>;
  status?: Maybe<Scalars['String']>;
  buildingStatus?: Maybe<BuildingStatus>;
  hasApartments: Scalars['Boolean'];
  walls?: Maybe<NewBuildingWalls>;
  /** Этажность */
  floor?: Maybe<NewBuildingFloor>;
  /** Отделка внутри корпуса */
  finishing?: Maybe<Finishing>;
  /** Пассажирский лифт */
  elevatorPassenger?: Maybe<Elevator>;
  /** Грузовой лифт */
  elevatorCargo?: Maybe<Elevator>;
  /** Коммерческие помещения на 1 этаже */
  commercialFirstFloor?: Maybe<TextField>;
  /** Кладовки */
  storageRooms?: Maybe<TextField>;
  /** Высота потолков внутри корпуса */
  ceilingHeight?: Maybe<CeilingHeight>;
};


/** Информация о корпусе */
export type BuildingInfoCeilingHeightArgs = {
  unit?: InputMaybe<HeightUnitEnum>;
};

/** Примитивный тип для прокидывания значений и локализации в структурированном виде */
export type TextField = {
  __typename?: 'TextField';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Статус строительства корпуса */
export type BuildingStatus = {
  __typename?: 'BuildingStatus';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Список типов стен в новостройках */
export type NewBuildingWalls = {
  __typename?: 'NewBuildingWalls';
  values?: Maybe<Array<Maybe<TextField>>>;
};

export type NewBuildingFloor = {
  __typename?: 'NewBuildingFloor';
  /** Минимальный этаж */
  min?: Maybe<Scalars['Int']>;
  /** Максимальный этаж */
  max?: Maybe<Scalars['Int']>;
  formatFloor?: Maybe<Scalars['String']>;
};

export type Finishing = {
  __typename?: 'Finishing';
  label: Scalars['String'];
  key: Scalars['String'];
  values: Array<FinishingItem>;
};

export type FinishingItem = {
  __typename?: 'FinishingItem';
  value: FinishingBuildingEnum;
  label: Scalars['String'];
};

export type Elevator = {
  __typename?: 'Elevator';
  /** Тип лифта */
  type?: Maybe<Scalars['String']>;
  /** Кол-во */
  count?: Maybe<Scalars['Int']>;
};

export type CeilingHeight = {
  __typename?: 'CeilingHeight';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export enum HeightUnitEnum {
  Cm = 'CM',
  Meter = 'METER'
}

export type NewBuildingInspectionStatus = {
  __typename?: 'NewBuildingInspectionStatus';
  isVerified?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
  linkText: Scalars['String'];
  statusText: Scalars['String'];
  detailText: Scalars['String'];
  desktopLink?: Maybe<Scalars['String']>;
  mobileLink?: Maybe<Scalars['String']>;
};

/** Поля для m2Pro */
export type NewBuildingM2Pro = {
  __typename?: 'NewBuildingM2Pro';
  /** Аванс */
  prepayment: Scalars['Boolean'];
  cooperationTerms?: Maybe<NewBuildingM2ProFile>;
  nbPresentation?: Maybe<NewBuildingM2ProFile>;
  /** Комиссионное вознаграждение */
  commission?: Maybe<M2ProCommission>;
  /** Окончание действия КВ */
  endOfAction?: Maybe<Scalars['String']>;
  /** Дополнительные условия */
  additionalTerms?: Maybe<Scalars['String']>;
  /** Только у М2 */
  exclusive: Scalars['Boolean'];
};

export type NewBuildingM2ProFile = {
  __typename?: 'NewBuildingM2ProFile';
  title: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type M2ProCommission = {
  __typename?: 'm2ProCommission';
  isRange?: Maybe<Scalars['Boolean']>;
  isValue?: Maybe<Scalars['Boolean']>;
  range?: Maybe<M2ProCommissionRange>;
  value?: Maybe<M2ProCommissionValue>;
};

export type M2ProCommissionRange = {
  __typename?: 'm2ProCommissionRange';
  percent?: Maybe<M2ProCommissionRangePercent>;
  roubles?: Maybe<M2ProCommissionRangeRoubles>;
};

export type M2ProCommissionRangePercent = {
  __typename?: 'm2ProCommissionRangePercent';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type M2ProCommissionRangeRoubles = {
  __typename?: 'm2ProCommissionRangeRoubles';
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
};

export type M2ProCommissionValue = {
  __typename?: 'm2ProCommissionValue';
  percent?: Maybe<M2ProCommissionValuePercent>;
  roubles?: Maybe<M2ProCommissionValueRoubles>;
  roublesPerSquareMeter?: Maybe<M2ProCommissionValueRoublesPerSquareMeter>;
};

export type M2ProCommissionValuePercent = {
  __typename?: 'm2ProCommissionValuePercent';
  value?: Maybe<Scalars['String']>;
};

export type M2ProCommissionValueRoubles = {
  __typename?: 'm2ProCommissionValueRoubles';
  value?: Maybe<Scalars['String']>;
};

export type M2ProCommissionValueRoublesPerSquareMeter = {
  __typename?: 'm2ProCommissionValueRoublesPerSquareMeter';
  value?: Maybe<Scalars['String']>;
};

export type NewBuildingOffersSummaryInfo = {
  __typename?: 'NewBuildingOffersSummaryInfo';
  /** Список квартир в новостройке */
  finishingTypesList: Array<SelectFinishingFlatOption>;
};

/** Опции для селекта отделки в выдаче квартир новостройки */
export type SelectFinishingFlatOption = {
  __typename?: 'SelectFinishingFlatOption';
  value: FinishingTypeEnum;
  label: Scalars['String'];
};

export enum FinishingTypeEnum {
  Unknown = 'UNKNOWN',
  WithFurniture = 'WITH_FURNITURE',
  Fine = 'FINE',
  WithoutFinishing = 'WITHOUT_FINISHING',
  PreFinishing = 'PRE_FINISHING'
}

/** Детальная информация о новостройке */
export type NewBuildingInfo = {
  __typename?: 'NewBuildingInfo';
  /** Срок сдачи */
  commissioningDate?: Maybe<InfoItem>;
  /** Класс ЖК */
  buildingClass?: Maybe<InfoItem>;
  /** Кол-во корпусов */
  buildingsCount?: Maybe<InfoItem>;
  /** Общее кол-во квартир/апартаментов */
  flatsAndApartmentsCount?: Maybe<InfoItem>;
  /** Кол-во квартир */
  flatsCount?: Maybe<InfoItem>;
  /** Кол-во апартаментов */
  apartmentsCount?: Maybe<InfoItem>;
  /** Общее кол-во квартир/апартаментов для сниппета */
  totalCount?: Maybe<InfoItem>;
  /** Тип жилья */
  apartments?: Maybe<InfoItem>;
  /** Высота потолков */
  ceilingHeight?: Maybe<InfoItem>;
  /** Отделка */
  finishing?: Maybe<InfoItem>;
  /** Этажность */
  floorsCount?: Maybe<InfoItem>;
  /** Стены */
  walls?: Maybe<Array<InfoItem>>;
  /** Закрытая территория */
  closedArea?: Maybe<InfoItem>;
  /** Открытая парковка */
  openParking?: Maybe<InfoItem>;
  /** Закрытая парковка */
  closedParking?: Maybe<InfoItem>;
  /** Подземная парковка */
  undergroundParking?: Maybe<InfoItem>;
  /** Двор без машин */
  carFreeCourtyard?: Maybe<InfoItem>;
  /** Консьерж */
  concierge?: Maybe<InfoItem>;
  /** Охрана */
  guardedArea?: Maybe<InfoItem>;
  /** Бренд лифта */
  elevatorBrand?: Maybe<InfoItem>;
  /** ФЗ-214 */
  law214?: Maybe<InfoItem>;
  /** Эскроу */
  escrow?: Maybe<InfoItem>;
  /** Участие */
  contractType?: Maybe<InfoItem>;
  /** Материнский капитал */
  motherCapital?: Maybe<InfoItem>;
  /** Коммерческие помещения на 1 этаже */
  commercialFirstFloor?: Maybe<InfoItem>;
  /** Офис продаж на объекте */
  officeOnSite?: Maybe<InfoItem>;
  /** Шоурум на объекте */
  showroom?: Maybe<InfoItem>;
  /** Безбарьерная среда */
  barrierFreeEnv?: Maybe<InfoItem>;
  /** Строительство школы в проекте */
  school?: Maybe<InfoItem>;
  /** Строительство детского сада в проекте */
  kindergarten?: Maybe<InfoItem>;
  /** Кладовки */
  storageRooms?: Maybe<InfoItem>;
};


/** Детальная информация о новостройке */
export type NewBuildingInfoCeilingHeightArgs = {
  unit?: InputMaybe<HeightUnitEnum>;
};

/** Параметр новостройки в блоке описания */
export type InfoItem = {
  __typename?: 'InfoItem';
  /** Вес параметра в списке */
  weight: Scalars['Int'];
  /** Название параметра */
  label: Scalars['String'];
  /** Название параметра в сниппете */
  snippetLabel?: Maybe<Scalars['String']>;
  /** Значение параметра */
  value: Scalars['String'];
  /** Значение параметра в сниппете */
  snippetValue?: Maybe<Scalars['String']>;
  /** Ссылка на поиск с этим значением */
  url?: Maybe<Scalars['String']>;
  /** Наименование иконки из svg-sprite */
  iconName: NewBuildingInfoIconNameEnum;
};

/** Наименования иконок для блока описания новостройки */
export enum NewBuildingInfoIconNameEnum {
  CommissioningDate = 'CommissioningDate',
  BuildingClass = 'BuildingClass',
  NewBuilding = 'NewBuilding',
  Apartments = 'Apartments',
  Living = 'Living',
  CeilingHeight = 'CeilingHeight',
  MonolithWalls = 'MonolithWalls',
  BrickMonolithWalls = 'BrickMonolithWalls',
  BrickWalls = 'BrickWalls',
  BlockWalls = 'BlockWalls',
  PanelWalls = 'PanelWalls',
  WoodWalls = 'WoodWalls',
  Flat = 'Flat',
  PreFineFinishing = 'PreFineFinishing',
  FineFinishing = 'FineFinishing',
  TurnKeyFinishing = 'TurnKeyFinishing',
  ClosedArea = 'ClosedArea',
  OpenParking = 'OpenParking',
  ClosedParking = 'ClosedParking',
  UndergroundParking = 'UndergroundParking',
  CarFreeCourtyard = 'CarFreeCourtyard',
  Concierge = 'Concierge',
  GuardedArea = 'GuardedArea',
  Law214 = 'Law214',
  Escrow = 'Escrow',
  Contract = 'Contract',
  MotherCapital = 'MotherCapital',
  Elevator = 'Elevator',
  CommercialFirstFloor = 'CommercialFirstFloor',
  OfficeOnSite = 'OfficeOnSite',
  Showroom = 'Showroom',
  Floors = 'Floors',
  BarrierFreeEnv = 'BarrierFreeEnv',
  School = 'School',
  Kindergarten = 'Kindergarten',
  StorageRooms = 'StorageRooms'
}

export type NewBuildingDocuments = {
  __typename?: 'NewBuildingDocuments';
  constructionApprovals: Array<NewBuildingDocument>;
  projectDeclarations: Array<NewBuildingDocument>;
  actsOfCommissioning: Array<NewBuildingDocument>;
};

export type NewBuildingDocument = {
  __typename?: 'NewBuildingDocument';
  /** Название файла без расширения */
  title: Scalars['String'];
  fileName: Scalars['String'];
  url: Scalars['String'];
};

export type RouteParams = {
  __typename?: 'RouteParams';
  regionId: Scalars['Int'];
  region: Scalars['String'];
  subRegion?: Maybe<Scalars['String']>;
  subRegionDisplayName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['Int'];
  /** @deprecated use ID */
  siteId: Scalars['Int'];
};

/** Параметры для формирования урла на страницу ипотеки */
export type NewBuildingMortgageParams = {
  __typename?: 'NewBuildingMortgageParams';
  regionId: Scalars['Int'];
  isMainRegion: Scalars['Boolean'];
  term: Scalars['Int'];
  propertyCost: Scalars['Float'];
  ownAmount: Scalars['Float'];
  purpose: MortgagePurpose;
  purposeValue: Scalars['Int'];
  /** Тип недвижимости */
  claimType: Scalars['Int'];
};

export type NewBuildingCardShortBanksMortgage = {
  __typename?: 'NewBuildingCardShortBanksMortgage';
  count: Scalars['Int'];
  minimalPayment: Scalars['Int'];
  minimalPaymentText: Scalars['String'];
};

/** Параметры для запроса за похожими ЖК */
export type SimilarParams = {
  __typename?: 'SimilarParams';
  regionIds: Array<Scalars['Int']>;
  buildingClasses: Array<NewBuildingClassEnum>;
};

export type NewBuildingSpecialEvent = {
  __typename?: 'NewBuildingSpecialEvent';
  type: NewBuildingSpecialEventTypeEnum;
  title: Scalars['String'];
  titleRaw: Scalars['String'];
  description: NewBuildingSpecialEventText;
  duration: NewBuildingSpecialEventText;
  buildingIds: Array<Scalars['Int']>;
  isHighlighted: Scalars['Boolean'];
  metaStartDate: Scalars['String'];
  metaEndDate: Scalars['String'];
};

export enum NewBuildingSpecialEventTypeEnum {
  ImplNotSet = 'IMPL_NOT_SET',
  Promo = 'PROMO',
  Discount = 'DISCOUNT',
  InstallmentPlan = 'INSTALLMENT_PLAN',
  SalesStart = 'SALES_START',
  Gift = 'GIFT',
  TradeIn = 'TRADE_IN',
  Arbitrary = 'ARBITRARY'
}

export type NewBuildingSpecialEventText = {
  __typename?: 'NewBuildingSpecialEventText';
  short: Scalars['String'];
  full: Scalars['String'];
};

export type NewBuildingConstructionProgress = {
  __typename?: 'NewBuildingConstructionProgress';
  image: Image;
  year: Scalars['Int'];
  quarter: Scalars['Int'];
  address: Scalars['String'];
};

/** Хлебные крошки на странице карточки ЖК */
export type NewBuildingCardBreadcrumbsSeo = {
  __typename?: 'NewBuildingCardBreadcrumbsSeo';
  items?: Maybe<Array<Maybe<NewBuildingCardBreadcrumbsItem>>>;
  rdfMeta: Scalars['String'];
};

export type NewBuildingCardBreadcrumbsItem = {
  __typename?: 'NewBuildingCardBreadcrumbsItem';
  title: Scalars['String'];
  path: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
};

export type NewBuildingNews = {
  __typename?: 'NewBuildingNews';
  id: Scalars['String'];
  title: Scalars['String'];
  images: Array<Maybe<Scalars['String']>>;
  slug: Scalars['String'];
  link: Scalars['String'];
  categories: Array<Maybe<NewsSluggable>>;
  subCategories: Array<Maybe<NewsSluggable>>;
  date: NativeDate;
  tags: Array<Maybe<NewsSluggable>>;
  viewsCount: Scalars['Int'];
  readTime: Scalars['Int'];
};

export type NewsSluggable = {
  __typename?: 'NewsSluggable';
  slug: Scalars['String'];
  label: Scalars['String'];
};

/** Дата и время с форматированием */
export type NativeDate = {
  __typename?: 'NativeDate';
  seconds: Scalars['Int'];
  iso: Scalars['String'];
  formattedFull: Scalars['String'];
  formattedShort: Scalars['String'];
  formattedAutoDate: Scalars['String'];
};

export type NewBuildingConditions = {
  __typename?: 'NewBuildingConditions';
  image?: Maybe<Image2>;
  description: Scalars['String'];
};

/** Статистика статусов строительства корпусов */
export type NewBuildingCardBuildingStats = {
  __typename?: 'NewBuildingCardBuildingStats';
  commissioned: Scalars['Int'];
  inProgress: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
};

export enum BuildEpochEnum {
  Unknown = 'UNKNOWN',
  Stalin = 'STALIN',
  Brezhnev = 'BREZHNEV',
  Khrushchev = 'KHRUSHCHEV'
}

export enum BuildHeatingTypeEnum {
  SealedValueOptionalNotSet = 'SEALED_VALUE_OPTIONAL_NOT_SET',
  Central = 'CENTRAL',
  IndividualHeatingPlant = 'INDIVIDUAL_HEATING_PLANT',
  DirectHeating = 'DIRECT_HEATING',
  BoilerRoom = 'BOILER_ROOM'
}

export enum InfrastructureEntitiesListEnum {
  SealedValueOptionalNotSet = 'SEALED_VALUE_OPTIONAL_NOT_SET',
  School = 'SCHOOL',
  Kindergarten = 'KINDERGARTEN',
  FitnessClub = 'FITNESS_CLUB',
  ShoppingMall = 'SHOPPING_MALL',
  Park = 'PARK'
}

export enum YardEntityEnum {
  SealedValueOptionalNotSet = 'SEALED_VALUE_OPTIONAL_NOT_SET',
  ChildrenPlayground = 'CHILDREN_PLAYGROUND',
  SportsGround = 'SPORTS_GROUND'
}

/** Оффер */
export type ProfOfferSearchBase = {
  __typename?: 'ProfOfferSearchBase';
  /** Информация о статусе объявления */
  status?: Maybe<OfferStatus>;
  stats?: Maybe<ProfOfferStats>;
  /** Уникальный id оффера */
  id: Scalars['ID'];
  text?: Maybe<ProfOfferText>;
  /** Описание оффера */
  description?: Maybe<Scalars['String']>;
  /** Информация о сделке */
  dealType?: Maybe<ProfOfferDealType>;
  isMortgageAllowed: Scalars['Boolean'];
  mortgagePurpose: MortgagePurpose;
  realtyObject?: Maybe<ProfRealityObject>;
  /** Информация о продавце */
  seller: Seller;
  abstractOptions?: Maybe<ProfOfferAbstractOptions>;
  /** Информация о инфраструктуре */
  infrastructure?: Maybe<ProfOfferInfrastructure>;
  badges?: Maybe<ProfOfferBadges>;
  location: ProfOfferLocation;
  gallery?: Maybe<Gallery>;
  /** @deprecated use gallery.images */
  photo?: Maybe<Array<Image>>;
  /** @deprecated use gallery.mainImage */
  preview?: Maybe<Image>;
  isFavoriteVisible: Scalars['Boolean'];
  /** Платная ли новостройка,  */
  isNewBuildingPaid: Scalars['Boolean'];
  /** Школы рядом из ТОП рейтинга */
  ratedSchools: Array<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  /** Информация о новостройке */
  newBuildingCard?: Maybe<NewBuildingCard>;
  /** Участвует в программе реновации */
  renovationProgram?: Maybe<Scalars['Boolean']>;
  /** Серия дома */
  buildingSeries?: Maybe<Scalars['String']>;
  /** Эпоха постройки */
  buildEpoch?: Maybe<BuildEpochEnum>;
  /** Тип отопления */
  heatingType?: Maybe<BuildHeatingTypeEnum>;
  /** Интересные места рядом */
  infrastructureEntitiesList: Array<InfrastructureEntitiesListEnum>;
  /** Инфраструктура во дворе */
  yardEntityList: Array<YardEntityEnum>;
  /** Онлайн просмотр */
  onlineView?: Maybe<Scalars['Boolean']>;
  /** Оффер от циана */
  fromCian?: Maybe<Scalars['Boolean']>;
};

/** Квартиры/комнаты в этом доме */
export type ProfOfferBuildingRelated = {
  __typename?: 'ProfOfferBuildingRelated';
  /** Продажа/аренда квартир/комнат */
  title: Scalars['String'];
  list: Array<ProfOfferSearchBase>;
};

export type SearchOffersSeoTags = {
  __typename?: 'searchOffersSeoTags';
  watchAlso: Array<SeoLinkItemType>;
  recommended: Array<SeoLinkItemType>;
};

export type SeoLinkItemType = {
  __typename?: 'SeoLinkItemType';
  id: Scalars['String'];
  title: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  url: Scalars['String'];
};

/** Параметры постраничной навигации */
export type PagingInput = {
  /** Количество запрошенных элементов на странице. По умолчанию – 10 */
  pageSize?: Scalars['Int'];
  /** Страница, с которой запрашиваются элементы. По умолчанию – 1 */
  pageNumber?: Scalars['Int'];
};

export type SortInput = {
  direction: SortDirectionInput;
  field: SortFieldInput;
};

export enum SortDirectionInput {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortFieldInput {
  Unknown = 'UNKNOWN',
  FirstMetDate = 'FIRST_MET_DATE',
  Price = 'PRICE',
  PriceSqMeter = 'PRICE_SQ_METER',
  RoomsCount = 'ROOMS_COUNT',
  Floor = 'FLOOR',
  TotalArea = 'TOTAL_AREA',
  LivingArea = 'LIVING_AREA',
  KitchenArea = 'KITCHEN_AREA',
  TimeToMetro = 'TIME_TO_METRO',
  Relevance = 'RELEVANCE',
  CreateDate = 'CREATE_DATE',
  DistanceToCityCenter = 'DISTANCE_TO_CITY_CENTER',
  UpdateDate = 'UPDATE_DATE'
}

export type ProfessionalSearchInput = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  dealType?: InputMaybe<DealTypeEnum>;
  realtyObject: RealtyObjectEnum;
  newBuilding?: InputMaybe<Array<NewBuildingEnum>>;
  regionId: Scalars['Int'];
  rooms?: InputMaybe<Array<RoomsEnum>>;
  priceMin?: InputMaybe<Scalars['Float']>;
  priceMax?: InputMaybe<Scalars['Float']>;
  priceUnit?: InputMaybe<AreaUnitEnum>;
  newBuildingIds?: InputMaybe<Array<Scalars['Int']>>;
  mortgagePayment?: InputMaybe<Scalars['Float']>;
  addressIds?: InputMaybe<Array<Scalars['Int']>>;
  metroIds?: InputMaybe<Array<Scalars['Int']>>;
  districtIds?: InputMaybe<Array<Scalars['Int']>>;
  geoSlugs?: InputMaybe<Array<Scalars['String']>>;
  transport?: InputMaybe<TransportTypeEnum>;
  transportTimeLimit?: InputMaybe<TransportTimeLimitEnum>;
  totalAreaMin?: InputMaybe<Scalars['Float']>;
  totalAreaMax?: InputMaybe<Scalars['Float']>;
  livingAreaMin?: InputMaybe<Scalars['Float']>;
  livingAreaMax?: InputMaybe<Scalars['Float']>;
  kitchenAreaMin?: InputMaybe<Scalars['Float']>;
  kitchenAreaMax?: InputMaybe<Scalars['Float']>;
  floorMin?: InputMaybe<Scalars['Int']>;
  floorMax?: InputMaybe<Scalars['Int']>;
  floorLast?: InputMaybe<FloorConstraintsEnum>;
  floorFirst?: InputMaybe<FloorConstraintsEnum>;
  floorsTotalMin?: InputMaybe<Scalars['Int']>;
  floorsTotalMax?: InputMaybe<Scalars['Int']>;
  builtYearMin?: InputMaybe<Scalars['Int']>;
  builtYearMax?: InputMaybe<Scalars['Int']>;
  ceilingHeight?: InputMaybe<Array<CeilingHeightEnum>>;
  renovation?: InputMaybe<Array<RenovationEnum>>;
  bathroom?: InputMaybe<Array<BathroomEnum>>;
  isApartments?: InputMaybe<IsApartmentsOptionsEnum>;
  balcony?: InputMaybe<BalconyOptionsEnum>;
  parking?: InputMaybe<Array<ParkingEnum>>;
  walls?: InputMaybe<Array<WallsTypeEnum>>;
  lift?: InputMaybe<LiftOptionsEnum>;
  infrastructure?: InputMaybe<Array<InfrastructureOptionsEnum>>;
  author?: InputMaybe<Array<AuthorTypeOptionsEnum>>;
  mortgageAllowed?: InputMaybe<SellMortgageAllowedEnum>;
  seoMortgageAllowed?: InputMaybe<Scalars['Boolean']>;
  sellType?: InputMaybe<Array<SellTypeEnum>>;
  photo?: InputMaybe<PhotoOptionsEnum>;
  nearbyOptions?: InputMaybe<Array<NearbyOptionsEnum>>;
  renovationProgram?: InputMaybe<Scalars['String']>;
  sellerOrganizationName?: InputMaybe<Scalars['String']>;
  layoutImageOptions?: InputMaybe<Array<LayoutImageOptionsEnum>>;
  buildingFloorMin?: InputMaybe<Scalars['Int']>;
  buildingFloorMax?: InputMaybe<Scalars['Int']>;
  landStatus?: InputMaybe<Array<LandStatusEnum>>;
  landAreaMin?: InputMaybe<Scalars['Float']>;
  landAreaMax?: InputMaybe<Scalars['Float']>;
  livingFacilityOptions?: InputMaybe<Array<LivingFacilitiesOptionsEnum>>;
  livingTenantOptions?: InputMaybe<Array<LivingTenantsOptionsEnum>>;
  furniture?: InputMaybe<Array<FurnitureOptionsEnum>>;
  utilitiesIncluded?: InputMaybe<Scalars['Boolean']>;
  commisionType?: InputMaybe<CommissionTypeEnum>;
  depositType?: InputMaybe<DepositTypeEnum>;
  rentPrepayment?: InputMaybe<PaymentTypeEnum>;
  gas?: InputMaybe<Array<GasOptionsEnum>>;
  water?: InputMaybe<Array<WaterOptionsEnum>>;
  electricity?: InputMaybe<Array<ElectricityOptionsEnum>>;
  sewerage?: InputMaybe<Array<SewerageOptionsEnum>>;
  roomsInOffer?: InputMaybe<Array<RoomsOfferedOptionsEnum>>;
  houseCategory?: InputMaybe<Array<HouseCategoryEnum>>;
  houseAreaMin?: InputMaybe<Scalars['Int']>;
  houseAreaMax?: InputMaybe<Scalars['Int']>;
  bathroomLocation?: InputMaybe<Array<BathroomLocationEnum>>;
  heating?: InputMaybe<Array<HeatingOptionsEnum>>;
  windowsDirection?: InputMaybe<Array<WindowsDirectionSearchOffersEnum>>;
  bankPledgeOptions?: InputMaybe<BankPledgeOptionsEnum>;
  bounds?: InputMaybe<ProfessionalSearchInputBounds>;
  polygon?: InputMaybe<Array<ProfessionalSearchInputPolygonPoint>>;
  geoHash?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  buildEpoch?: InputMaybe<Array<BuildEpochEnum>>;
  onlineView?: InputMaybe<Scalars['String']>;
  finishingType?: InputMaybe<Array<FinishingTypeEnum>>;
  commissioning?: InputMaybe<Scalars['String']>;
};

export enum NewBuildingEnum {
  Unknown = 'UNKNOWN',
  New = 'NEW',
  Second = 'SECOND'
}

export enum TransportTimeLimitEnum {
  Unknown = 'UNKNOWN',
  Max_5Minutes = 'MAX_5_MINUTES',
  Max_10Minutes = 'MAX_10_MINUTES',
  Max_15Minutes = 'MAX_15_MINUTES',
  Max_20Minutes = 'MAX_20_MINUTES',
  Max_25Minutes = 'MAX_25_MINUTES',
  Max_30Minutes = 'MAX_30_MINUTES',
  Max_40Minutes = 'MAX_40_MINUTES',
  Max_50Minutes = 'MAX_50_MINUTES',
  Max_60Minutes = 'MAX_60_MINUTES'
}

export enum FloorConstraintsEnum {
  Unknown = 'UNKNOWN',
  Only = 'ONLY',
  Exclude = 'EXCLUDE'
}

export enum CeilingHeightEnum {
  Unknown = 'UNKNOWN',
  From_2_5 = 'FROM_2_5',
  From_2_7 = 'FROM_2_7',
  From_3 = 'FROM_3',
  From_4 = 'FROM_4'
}

export enum RenovationEnum {
  Unknown = 'UNKNOWN',
  Euro = 'EURO',
  Cosmetic = 'COSMETIC',
  Designed = 'DESIGNED',
  Need = 'NEED',
  None = 'NONE'
}

export enum IsApartmentsOptionsEnum {
  Unknown = 'UNKNOWN',
  NoApartments = 'NO_APARTMENTS',
  ApartmentsOnly = 'APARTMENTS_ONLY'
}

export enum BalconyOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum ParkingEnum {
  Unknown = 'UNKNOWN',
  Closed = 'CLOSED',
  Underground = 'UNDERGROUND',
  Open = 'OPEN'
}

export enum LiftOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum InfrastructureOptionsEnum {
  Unknown = 'UNKNOWN',
  ClosedArea = 'CLOSED_AREA',
  Concierge = 'CONCIERGE'
}

export enum AuthorTypeOptionsEnum {
  Unknown = 'UNKNOWN',
  Owner = 'OWNER',
  Agency = 'AGENCY',
  Partner = 'PARTNER',
  Developer = 'DEVELOPER',
  Pit = 'PIT',
  Aggregator = 'AGGREGATOR'
}

export enum SellMortgageAllowedEnum {
  Unknown = 'UNKNOWN',
  Yes = 'YES',
  No = 'NO'
}

export enum PhotoOptionsEnum {
  Unknown = 'UNKNOWN',
  WithPhoto = 'WITH_PHOTO'
}

export enum NearbyOptionsEnum {
  Unknown = 'UNKNOWN',
  GoodSchool = 'GOOD_SCHOOL'
}

export enum LayoutImageOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum LivingFacilitiesOptionsEnum {
  Unknown = 'UNKNOWN',
  Internet = 'INTERNET',
  WashingMachine = 'WASHING_MACHINE',
  Fridge = 'FRIDGE',
  Dishwasher = 'DISHWASHER',
  AirConditioner = 'AIR_CONDITIONER',
  Tv = 'TV'
}

export enum LivingTenantsOptionsEnum {
  Unknown = 'UNKNOWN',
  ChildrenAllowed = 'CHILDREN_ALLOWED',
  PetsAllowed = 'PETS_ALLOWED'
}

export enum FurnitureOptionsEnum {
  Unknown = 'UNKNOWN',
  No = 'NO',
  Kitchen = 'KITCHEN',
  All = 'ALL'
}

export enum CommissionTypeEnum {
  Unknown = 'UNKNOWN',
  NoClientCommission = 'NO_CLIENT_COMMISSION'
}

export enum DepositTypeEnum {
  Unknown = 'UNKNOWN',
  NoDeposit = 'NO_DEPOSIT'
}

export enum PaymentTypeEnum {
  Unknown = 'UNKNOWN',
  NoPrepayment = 'NO_PREPAYMENT',
  UpTo_1Price = 'UP_TO_1_PRICE',
  UpTo_2Price = 'UP_TO_2_PRICE',
  UpTo_3Price = 'UP_TO_3_PRICE'
}

export enum GasOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum WaterOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum ElectricityOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum SewerageOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum RoomsOfferedOptionsEnum {
  Unknown = 'UNKNOWN',
  Room_1 = 'ROOM_1',
  Room_2 = 'ROOM_2',
  Room_3 = 'ROOM_3',
  Room_4AndMore = 'ROOM_4_AND_MORE'
}

export enum HeatingOptionsEnum {
  Unknown = 'UNKNOWN',
  HasAny = 'HAS_ANY'
}

export enum WindowsDirectionSearchOffersEnum {
  Unknown = 'UNKNOWN',
  Rear = 'REAR',
  Front = 'FRONT',
  Both = 'BOTH'
}

export enum BankPledgeOptionsEnum {
  Unknown = 'UNKNOWN',
  InPledge = 'IN_PLEDGE',
  NotInPledge = 'NOT_IN_PLEDGE'
}

export type ProfessionalSearchInputBounds = {
  lx: Scalars['Float'];
  ly: Scalars['Float'];
  rx: Scalars['Float'];
  ry: Scalars['Float'];
};

export type ProfessionalSearchInputPolygonPoint = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

/** Кол-во офферов */
export type OffersCount = {
  __typename?: 'OffersCount';
  count: Scalars['Int'];
  formattedRealty?: Maybe<Scalars['String']>;
};


/** Кол-во офферов */
export type OffersCountFormattedRealtyArgs = {
  realtyObject: RealtyObjectEnum;
};

/** Сводка о подходящих офферах */
export type OffersSummary = {
  __typename?: 'OffersSummary';
  /** Максимальная и минимальная цены среди офферов */
  priceRange?: Maybe<OffersSummaryPriceRange>;
  /** СЕО ratingValue */
  ratingValue: Scalars['Float'];
  /** СЕО bestRating */
  bestRating: Scalars['Float'];
  /** СЕО reviewCount */
  reviewCount: Scalars['Int'];
  /** Кол-во офферов */
  count: Scalars['Int'];
  formattedRealty?: Maybe<Scalars['String']>;
  formattedPriceRange?: Maybe<Scalars['String']>;
  formattedPriceFrom?: Maybe<Scalars['String']>;
  formattedPricePerUnitRange?: Maybe<Scalars['String']>;
  formattedAreaRange?: Maybe<Scalars['String']>;
  formattedAveragePrice?: Maybe<Scalars['String']>;
  avgPricePerUnit?: Maybe<Scalars['Float']>;
};


/** Сводка о подходящих офферах */
export type OffersSummaryFormattedRealtyArgs = {
  realtyObject: RealtyObjectEnum;
};

export type OffersSummaryPriceRange = {
  __typename?: 'OffersSummaryPriceRange';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type SearchOffersSeo = {
  __typename?: 'SearchOffersSeo';
  /** Текст для H1 */
  h1?: Maybe<Scalars['String']>;
  /** Текст для title */
  metaTitle?: Maybe<Scalars['String']>;
  /** Текст для meta-description */
  metaDescription?: Maybe<Scalars['String']>;
  /** Доп тексты для страницы */
  autotext?: Maybe<Scalars['String']>;
};

/** Список офферов */
export type ProfOfferOnMap = {
  __typename?: 'ProfOfferOnMap';
  tileUrl: Scalars['String'];
  /** Список офферов */
  points: Array<OfferPoint>;
};


/** Список офферов */
export type ProfOfferOnMapTileUrlArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
};

/** Сниппеты на карте */
export type OfferPoint = TaglessOfferPoint | SingleOfferPointFlat | SingleOfferPointRoom | SingleOfferPointHouse | SingleOfferPointLand | MultiOfferPointFlat | MultiOfferPointRoom | MultiOfferPointHouse | MultiOfferPointLand;

/** Информация об оффере-прыще на карте */
export type TaglessOfferPoint = {
  __typename?: 'TaglessOfferPoint';
  coordinates: Point;
  geoHash: Scalars['String'];
};

/** Информация об оффере квартир на карте */
export type SingleOfferPointFlat = {
  __typename?: 'SingleOfferPointFlat';
  /** Уникальный id точки */
  id: Scalars['ID'];
  /** Оффер */
  offer: ProfOfferSnippet;
};

/** Информация об оффере комнат на карте */
export type SingleOfferPointRoom = {
  __typename?: 'SingleOfferPointRoom';
  /** Уникальный id точки */
  id: Scalars['ID'];
  /** Оффер */
  offer: ProfOfferSnippet;
};

/** Информация об оффере домов на карте */
export type SingleOfferPointHouse = {
  __typename?: 'SingleOfferPointHouse';
  /** Уникальный id точки */
  id: Scalars['ID'];
  /** Оффер */
  offer: ProfOfferSnippet;
};

/** Информация об оффере участков на карте */
export type SingleOfferPointLand = {
  __typename?: 'SingleOfferPointLand';
  /** Уникальный id точки */
  id: Scalars['ID'];
  /** Оффер */
  offer: ProfOfferSnippet;
};

/** Информация об офферах квартир на карте */
export type MultiOfferPointFlat = {
  __typename?: 'MultiOfferPointFlat';
  /** Уникальный id точки */
  id: Scalars['ID'];
  coordinates: Point;
  /** @deprecated Геообъект максимальной доступной точности, обычно дом или улица. Используется версткой временно, для реализации клика по многодому (пока не появился бейдж по geo_hash) */
  addressId: Scalars['Int'];
  geoHash: Scalars['String'];
  count: Scalars['Int'];
  minPriceRub?: Maybe<Scalars['Int']>;
  minPrice: Price;
  minArea: AreaType;
  maxArea: AreaType;
  isNewBuilding?: Maybe<Scalars['Boolean']>;
  hasHighlight: Scalars['Boolean'];
};

/** Информация об офферах комнат на карте */
export type MultiOfferPointRoom = {
  __typename?: 'MultiOfferPointRoom';
  /** Уникальный id точки */
  id: Scalars['ID'];
  coordinates: Point;
  /** @deprecated Геообъект максимальной доступной точности, обычно дом или улица. Используется версткой временно, для реализации клика по многодому (пока не появился бейдж по geo_hash) */
  addressId: Scalars['Int'];
  geoHash: Scalars['String'];
  count: Scalars['Int'];
  minPriceRub?: Maybe<Scalars['Int']>;
  minPrice: Price;
  minArea: AreaType;
  maxArea: AreaType;
  isNewBuilding?: Maybe<Scalars['Boolean']>;
  hasHighlight: Scalars['Boolean'];
};

/** Информация об офферах домов на карте */
export type MultiOfferPointHouse = {
  __typename?: 'MultiOfferPointHouse';
  /** Уникальный id точки */
  id: Scalars['ID'];
  coordinates: Point;
  /** @deprecated Геообъект максимальной доступной точности, обычно дом или улица. Используется версткой временно, для реализации клика по многодому (пока не появился бейдж по geo_hash) */
  addressId: Scalars['Int'];
  geoHash: Scalars['String'];
  count: Scalars['Int'];
  minPriceRub?: Maybe<Scalars['Int']>;
  minPrice: Price;
  minArea: AreaType;
  maxArea: AreaType;
  isNewBuilding?: Maybe<Scalars['Boolean']>;
  hasHighlight: Scalars['Boolean'];
};

/** Информация об офферах участков на карте */
export type MultiOfferPointLand = {
  __typename?: 'MultiOfferPointLand';
  /** Уникальный id точки */
  id: Scalars['ID'];
  coordinates: Point;
  /** @deprecated Геообъект максимальной доступной точности, обычно дом или улица. Используется версткой временно, для реализации клика по многодому (пока не появился бейдж по geo_hash) */
  addressId: Scalars['Int'];
  geoHash: Scalars['String'];
  count: Scalars['Int'];
  minPriceRub?: Maybe<Scalars['Int']>;
  minPrice: Price;
  minArea: AreaType;
  maxArea: AreaType;
  isNewBuilding?: Maybe<Scalars['Boolean']>;
  hasHighlight: Scalars['Boolean'];
};

export type InfrastructureList = {
  __typename?: 'InfrastructureList';
  /** Список объектов инфраструктуры */
  poiList: Array<NewBuildingPoi>;
};

/** Объект инфраструктуры (point of interest) на карте */
export type NewBuildingPoi = {
  __typename?: 'NewBuildingPoi';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Название объекта */
  name: Scalars['String'];
  /** Тип объекта */
  kind: PoiKindEnum;
  /** Категория объекта */
  category: Scalars['String'];
  description: Scalars['String'];
  address: Scalars['String'];
  /** Специализация объекта (для мед. учреждения) */
  specialization: Scalars['String'];
  /** Круглосуточная работа */
  noctidial: Scalars['Boolean'];
};

export enum PoiKindEnum {
  Unknown = 'UNKNOWN',
  School = 'SCHOOL',
  University = 'UNIVERSITY',
  Kindergarten = 'KINDERGARTEN',
  Hospital = 'HOSPITAL',
  Pharmacy = 'PHARMACY',
  Shop = 'SHOP',
  FoodService = 'FOOD_SERVICE',
  SportCentre = 'SPORT_CENTRE'
}

export type SearchOffersMapByOfferInput = {
  offerId: Scalars['ID'];
  bounds: ProfessionalSearchInputBounds;
};

/** Список новостроек */
export type NewBuildingsList = {
  __typename?: 'NewBuildingsList';
  tips?: Maybe<NewBuildingTips>;
  /** Постраничная навигация */
  paging?: Maybe<Paging>;
  /** Сниппеты */
  items: Array<Maybe<SearchNewBuildingSnippet>>;
  /** Средняя оценка пользователей */
  rating?: Maybe<Scalars['Float']>;
  /** Количество оценок пользователей */
  reviewsCount?: Maybe<Scalars['Int']>;
  /** Пустая выдача */
  isEmpty?: Maybe<Scalars['Boolean']>;
  specProjectPlaceholders?: Maybe<DeveloperPromoPlaceholders>;
};


/** Список новостроек */
export type NewBuildingsListItemsArgs = {
  touch?: InputMaybe<Scalars['Boolean']>;
};

/** Возвращаемые значения по набору фильтров */
export type NewBuildingTips = {
  __typename?: 'NewBuildingTips';
  developers?: Maybe<Array<Maybe<TipDeveloper>>>;
  newBuildings?: Maybe<Array<Maybe<TipNewBuilding>>>;
  addresses?: Maybe<Array<Maybe<TipAddress>>>;
  districts?: Maybe<Array<Maybe<TipDistrict>>>;
  metros?: Maybe<Array<Maybe<TipMetroStation>>>;
};

export type TipDeveloper = {
  __typename?: 'TipDeveloper';
  /** id Застройщика */
  id: Scalars['Int'];
  /** Названия застройщика */
  title?: Maybe<Scalars['String']>;
  type: TipTypeEnum;
};

/** Тип сниппета */
export type SearchNewBuildingSnippet = NewBuildingSnippet | NewBuildingAddBanner | NewBuildingPromoSnippet | NewBuildingSearchPromoBanner | NewBuildingSearchQuickSelectionBanner | NewBuildingSearchSamoletPlusBanner | NewBuildingSearchMortgagePromoBanner;

/** Сниппет новостройки в выдаче */
export type NewBuildingSnippet = {
  __typename?: 'NewBuildingSnippet';
  /** Уникальный id новостройки */
  id: Scalars['ID'];
  narrowRegionId: Scalars['Int'];
  ranking: RankingEnum;
  /** Тип жилья */
  hasApartments: Scalars['Boolean'];
  /** Тип жилья */
  hasApartmentsOnly: Scalars['Boolean'];
  /** Тип жилья для сниппета в квизе */
  housingTypeQuiz: Scalars['String'];
  /** Всё продано */
  isSoldOut: Scalars['Boolean'];
  /** Скоро в продаже */
  isSoon: Scalars['Boolean'];
  buildingStatus?: Maybe<Array<NewBuildingStatus>>;
  /** Сроки сдачи для сниппета в квизе */
  buildingStatusQuiz: Scalars['String'];
  year?: Maybe<Scalars['Int']>;
  /** Минимальная цена в ЖК */
  priceMin?: Maybe<Scalars['Float']>;
  /** Название новостройки */
  name: Scalars['String'];
  /** Тип + название новостройки */
  title: NewBuildingTitle;
  buildingClass?: Maybe<NewBuildingClass>;
  address: Scalars['String'];
  shortAddress: Scalars['String'];
  /** Галерея */
  gallery?: Maybe<NewBuildingGallery>;
  /** Описание новостройки */
  description?: Maybe<Scalars['String']>;
  coordinates: Array<Scalars['Float']>;
  /** Маршруты до ближайших геобьектов */
  routes?: Maybe<Array<Maybe<Route>>>;
  /** Список квартир в новостройке */
  flats?: Maybe<Array<Maybe<NewBuildingFlat>>>;
  routeParams: RouteParams;
  /** Минимальный платеж по ипотеке ВТБ */
  mortgageMinPay?: Maybe<NewBuildingVtbMortgageMinPayType>;
  /** Список карточек застройщиков */
  developerCards: Array<DeveloperCard>;
  info?: Maybe<NewBuildingInfo>;
  hasEscrow?: Maybe<Scalars['Boolean']>;
  buildingsAccreditedByVtb?: Maybe<Array<Scalars['String']>>;
  isMskMO: Scalars['Boolean'];
  isSPbLO: Scalars['Boolean'];
  /** Признак, что новостройка из региона со спецпроектом */
  isSpecProjectRegion: Scalars['Boolean'];
  /** Проверка на ипотеку в 6.5% */
  hasPreferentialMortgage: Scalars['Boolean'];
  mortgageParams: NewBuildingMortgageParams;
  /** Акции, скидки, подарки */
  specialEvents: Array<NewBuildingSpecialEvent>;
  /** Статус проверки ЖК в стройнадзоре */
  inspectionStatus?: Maybe<NewBuildingInspectionStatus>;
  /** Добавлена ли новостройка в избранное пользователя */
  isFavorite: Scalars['Boolean'];
  hasChat: Scalars['Boolean'];
  noOffers: Scalars['Boolean'];
  m2Pro?: Maybe<NewBuildingM2Pro>;
  /** Список ссылок на застройщика */
  developerLinks: Array<Scalars['String']>;
};


/** Сниппет новостройки в выдаче */
export type NewBuildingSnippetNarrowRegionIdArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Сниппет новостройки в выдаче */
export type NewBuildingSnippetPriceMinArgs = {
  promo?: InputMaybe<Scalars['Boolean']>;
};


/** Сниппет новостройки в выдаче */
export type NewBuildingSnippetRoutesArgs = {
  type?: InputMaybe<Array<InputMaybe<RoutePoiTypeEnum>>>;
};


/** Сниппет новостройки в выдаче */
export type NewBuildingSnippetInspectionStatusArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Сниппет новостройки в выдаче */
export type NewBuildingSnippetHasChatArgs = {
  whiteLabel?: InputMaybe<NewBuildingWhitelabelEnum>;
};


/** Сниппет новостройки в выдаче */
export type NewBuildingSnippetDeveloperLinksArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type NewBuildingAddBanner = {
  __typename?: 'NewBuildingAddBanner';
  isActive: Scalars['Boolean'];
};

export type NewBuildingPromoSnippet = {
  __typename?: 'NewBuildingPromoSnippet';
  isActive: Scalars['Boolean'];
  id: Scalars['ID'];
  logoColor: Scalars['String'];
  logoWhite: Scalars['String'];
  disclaimer: Scalars['String'];
  developerName: Scalars['String'];
  erid: Scalars['String'];
};

export type NewBuildingSearchPromoBanner = {
  __typename?: 'NewBuildingSearchPromoBanner';
  isActive: Scalars['Boolean'];
  id: Scalars['ID'];
  logoColor: Scalars['String'];
  logoWhite: Scalars['String'];
  disclaimerList: Array<Scalars['String']>;
  developerName: Scalars['String'];
  erid: Scalars['String'];
};

export type NewBuildingSearchQuickSelectionBanner = {
  __typename?: 'NewBuildingSearchQuickSelectionBanner';
  isActive: Scalars['Boolean'];
};

export type NewBuildingSearchSamoletPlusBanner = {
  __typename?: 'NewBuildingSearchSamoletPlusBanner';
  isActive: Scalars['Boolean'];
  logoColor: Scalars['String'];
  logoWhite: Scalars['String'];
  caption: Scalars['String'];
  subtitle: Scalars['String'];
  buttonText: Scalars['String'];
  url: Scalars['String'];
  imageDesktop: Scalars['String'];
  imageTouch: Scalars['String'];
};

export type NewBuildingSearchMortgagePromoBanner = {
  __typename?: 'NewBuildingSearchMortgagePromoBanner';
  isActive: Scalars['Boolean'];
  caption: Scalars['String'];
  subtitle: Scalars['String'];
  buttonText: Scalars['String'];
  badgeText: Scalars['String'];
  imageDesktop: Scalars['String'];
  imageTouch: Scalars['String'];
};

export type DeveloperPromoPlaceholders = {
  __typename?: 'DeveloperPromoPlaceholders';
  developerName?: Maybe<Scalars['String']>;
  newBuilding: Scalars['String'];
  developer: Scalars['String'];
};

export enum NewBuildingSortEnum {
  RelevanceDesc = 'RELEVANCE_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  DateOfCommissioningAsc = 'DATE_OF_COMMISSIONING_ASC',
  DateOfCommissioningDesc = 'DATE_OF_COMMISSIONING_DESC',
  TimeToMetroAsc = 'TIME_TO_METRO_ASC',
  SqmPriceAsc = 'SQM_PRICE_ASC',
  SqmPriceDesc = 'SQM_PRICE_DESC',
  TotalAreaAsc = 'TOTAL_AREA_ASC',
  TotalAreaDesc = 'TOTAL_AREA_DESC'
}

/** Набор возможных фильтров для новостроечной выдачи */
export type NewBuildingsInput = {
  /** Регион поиска по дефолту Москва - 3 */
  regionId?: Scalars['Int'];
  /** Запасной регион поиска, если по основному не нашлось полной страницы */
  backupRegionId?: InputMaybe<Scalars['Int']>;
  /** id новостройки */
  newBuildingId?: InputMaybe<Scalars['Int']>;
  /** id новостройки, которую нужно отфильтровать из выдачи */
  newBuildingIdToSkip?: InputMaybe<Scalars['Int']>;
  /** id застройщики */
  developerId?: InputMaybe<Scalars['Int']>;
  /** id районов в геобазе */
  districtId?: InputMaybe<Array<Scalars['Int']>>;
  /** id метро в геобазе */
  metroId?: InputMaybe<Array<Scalars['Int']>>;
  /** id улиц в геобазе */
  addressId?: InputMaybe<Array<Scalars['Int']>>;
  /** Кол-во комнат в квартире */
  rooms?: InputMaybe<Array<RoomsEnum>>;
  /** Тип стен */
  wallsType?: InputMaybe<Array<WallsTypeEnum>>;
  priceMin?: InputMaybe<Scalars['Float']>;
  priceMax?: InputMaybe<Scalars['Float']>;
  priceType?: InputMaybe<AreaUnitEnum>;
  /** Общая площадь от */
  totalAreaRangeMin?: InputMaybe<Scalars['Int']>;
  /** Общая площадь до */
  totalAreaRangeMax?: InputMaybe<Scalars['Int']>;
  /** Общая площадь от */
  livingAreaRangeMin?: InputMaybe<Scalars['Int']>;
  /** Общая площадь до */
  livingAreaRangeMax?: InputMaybe<Scalars['Int']>;
  /** Общая площадь до */
  kitchenAreaRangeMin?: InputMaybe<Scalars['Int']>;
  /** Этажность от */
  floorsTotalMin?: InputMaybe<Scalars['Int']>;
  /** Этажность до */
  floorsTotalMax?: InputMaybe<Scalars['Int']>;
  /** Этажность от */
  floorMin?: InputMaybe<Scalars['Int']>;
  /** Этажность до */
  floorMax?: InputMaybe<Scalars['Int']>;
  floorLast?: InputMaybe<FloorConstraintsEnum>;
  floorFirst?: InputMaybe<FloorConstraintsEnum>;
  ceilingHeight?: InputMaybe<CeilingHeightEnum>;
  bathroom?: InputMaybe<Array<InputMaybe<BathroomEnum>>>;
  transportType?: InputMaybe<TransportTypeEnum>;
  maxMinutes?: InputMaybe<TransportTimeLimitEnum>;
  /** Тип парковки */
  parking?: InputMaybe<Array<InputMaybe<ParkingEnum>>>;
  motherCapital?: InputMaybe<Scalars['Boolean']>;
  /** Рассрочка */
  installment?: InputMaybe<Scalars['Boolean']>;
  armyMortgage?: InputMaybe<Scalars['Boolean']>;
  escrow?: InputMaybe<Scalars['Boolean']>;
  law214?: InputMaybe<Scalars['Boolean']>;
  apartments?: InputMaybe<IsApartmentsOptionsEnum>;
  /** Класс ЖК */
  buildingClass?: InputMaybe<Array<NewBuildingClassEnum>>;
  commissioning?: InputMaybe<CommissioningEnum>;
  /** Показываем новостройки которые аккредитовал ВТБ */
  accreditedByVTB?: InputMaybe<Scalars['Boolean']>;
  /** Закрытая территория */
  closedArea?: InputMaybe<Scalars['Boolean']>;
  /** Консьерж */
  concierge?: InputMaybe<Scalars['Boolean']>;
  /** Охрана */
  guardedArea?: InputMaybe<Scalars['Boolean']>;
  /** Наличие балкона */
  balcony?: InputMaybe<Scalars['Boolean']>;
  /** Одиночное здание */
  standalone?: InputMaybe<Scalars['Boolean']>;
  /**
   * Наличие отделки
   * @deprecated Больше не нужен, используем только finishingType
   */
  hasFinishing?: InputMaybe<FinishingEnum>;
  /** Тип отделки */
  finishingType?: InputMaybe<Array<FinishingTypeEnum>>;
  /** ЖК с закрытыми продажами */
  closedSales?: InputMaybe<Scalars['Boolean']>;
  /** Координаты для поиска по карте */
  mapBounds?: InputMaybe<MapInput>;
  /** Акции и скидки [Квартиры] */
  flatsSpecialEvents?: InputMaybe<Array<FlatsSpecialEventsEnum>>;
  /** Акции и скидки [Паркинг, кладовки] */
  otherSpecialEvents?: InputMaybe<Array<OtherSpecialEventsEnum>>;
  /** Акции и скидки [Другое] */
  miscSpecialEvents?: InputMaybe<Array<MiscSpecialEventsEnum>>;
  /** Показывать не только М2Pro для соответствующих ролей */
  notOnlyM2Pro?: InputMaybe<Scalars['Boolean']>;
  /** Эксклюзивность для М2Pro */
  exclusivity?: InputMaybe<Scalars['Boolean']>;
};

export enum CommissioningEnum {
  Unknown = 'UNKNOWN',
  InProgress = 'IN_PROGRESS',
  Commissioned = 'COMMISSIONED',
  Frozen = 'FROZEN',
  Third_2024 = 'THIRD_2024',
  Fourth_2024 = 'FOURTH_2024',
  First_2025 = 'FIRST_2025',
  Second_2025 = 'SECOND_2025',
  Third_2025 = 'THIRD_2025',
  Fourth_2025 = 'FOURTH_2025',
  First_2026 = 'FIRST_2026',
  Second_2026 = 'SECOND_2026',
  Third_2026 = 'THIRD_2026',
  Fourth_2026 = 'FOURTH_2026',
  First_2027 = 'FIRST_2027',
  Second_2027 = 'SECOND_2027',
  Third_2027 = 'THIRD_2027'
}

export enum FinishingEnum {
  Available = 'AVAILABLE'
}

export type MapInput = {
  bounds?: InputMaybe<BoundsInput>;
  polygon?: InputMaybe<Array<PointInput>>;
};

export type BoundsInput = {
  min: PointInput;
  max: PointInput;
};

export type PointInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export enum FlatsSpecialEventsEnum {
  Unknown = 'UNKNOWN',
  FlatDiscount = 'FLAT_DISCOUNT',
  FlatInstallmentPlan = 'FLAT_INSTALLMENT_PLAN',
  FlatSalesStart = 'FLAT_SALES_START'
}

export enum OtherSpecialEventsEnum {
  Unknown = 'UNKNOWN',
  OtherDiscount = 'OTHER_DISCOUNT',
  OtherInstallmentPlan = 'OTHER_INSTALLMENT_PLAN',
  OtherSalesStart = 'OTHER_SALES_START'
}

export enum MiscSpecialEventsEnum {
  Unknown = 'UNKNOWN',
  Gift = 'GIFT',
  TradeIn = 'TRADE_IN',
  Promo = 'PROMO',
  Arbitrary = 'ARBITRARY'
}

export type RegionSection = {
  __typename?: 'RegionSection';
  rootRegion?: Maybe<GeoObject>;
  regions?: Maybe<Array<GeoObject>>;
};

export enum GeoRootRegionAppEnum {
  Classified = 'CLASSIFIED',
  NewBuilding = 'NEW_BUILDING',
  NewBuildingWl = 'NEW_BUILDING_WL'
}

/** Количество ЖК */
export type NewBuildingCount = {
  __typename?: 'NewBuildingCount';
  count?: Maybe<Scalars['Int']>;
};

/** Выдача ЖК */
export type MapNewBuildingsList = {
  __typename?: 'MapNewBuildingsList';
  tips?: Maybe<NewBuildingTips>;
  points?: Maybe<Array<NewBuildingPoint>>;
  taglessPoints?: Maybe<Array<NewBuildingTaglessPoint>>;
  specProjectPlaceholders?: Maybe<DeveloperPromoPlaceholders>;
};

/** Пин ЖК на карте */
export type NewBuildingPoint = {
  __typename?: 'NewBuildingPoint';
  /** Тип + название новостройки */
  title: NewBuildingTitle;
  buildingStatusList?: Maybe<Array<NewBuildingStatus>>;
  year?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Float']>;
  ranking: RankingEnum;
  gallery?: Maybe<NewBuildingGallery>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  /** Цветовая палитра пина основанная на данных из mangoust-api */
  mangoColor?: Maybe<MapPointColor>;
  routeParams: RouteParams;
};

/** Цвет пина на карте */
export type MapPointColor = {
  __typename?: 'MapPointColor';
  default: Scalars['String'];
  active: Scalars['String'];
};

/** Прыщ ЖК на карте */
export type NewBuildingTaglessPoint = {
  __typename?: 'NewBuildingTaglessPoint';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Типы ответа в поиске по карте */
export enum MapResultTypeEnum {
  Unknown = 'UNKNOWN',
  Tagless = 'TAGLESS',
  NewBuildings = 'NEW_BUILDINGS'
}

/** Список корпусов */
export type BuildingsList = {
  __typename?: 'BuildingsList';
  /** Постраничная навигация */
  paging?: Maybe<Paging>;
  /** Корпуса */
  ids: Array<Scalars['Int']>;
  /** Типсы в фильтрах */
  tips: Array<TipBuilding>;
};

export type TipBuilding = {
  __typename?: 'TipBuilding';
  id: Scalars['Int'];
  title: Scalars['String'];
  type: TipTypeEnum;
};

/** Набор возможных фильтров для выдачи на карточке новостройки */
export type NewBuildingOffersInput = {
  newBuildingId: Scalars['Int'];
  /** @deprecated теперь используем только finishingTypeList */
  finishingType?: InputMaybe<FinishingFlatEnum>;
  finishingTypeList: Array<FinishingTypeEnum>;
  floorMin?: InputMaybe<Scalars['Int']>;
  floorMax?: InputMaybe<Scalars['Int']>;
  floorFirst?: InputMaybe<FloorConstraintsEnum>;
  floorLast?: InputMaybe<FloorConstraintsEnum>;
  bathroom?: InputMaybe<Array<BathroomEnum>>;
  rooms?: InputMaybe<Array<RoomsEnum>>;
  priceMin?: InputMaybe<Scalars['Float']>;
  priceMax?: InputMaybe<Scalars['Float']>;
  priceType?: InputMaybe<AreaUnitEnum>;
  totalAreaRangeMin?: InputMaybe<Scalars['Int']>;
  totalAreaRangeMax?: InputMaybe<Scalars['Int']>;
  livingAreaRangeMin?: InputMaybe<Scalars['Int']>;
  livingAreaRangeMax?: InputMaybe<Scalars['Int']>;
  kitchenAreaRangeMin?: InputMaybe<Scalars['Int']>;
  buildingId?: InputMaybe<Array<Scalars['Int']>>;
};

export enum FinishingFlatEnum {
  NotRelevant = 'NOT_RELEVANT',
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE'
}

/** Список офферов */
export type ProfOffersList = {
  __typename?: 'ProfOffersList';
  /** Постраничная навигация */
  paging?: Maybe<Paging>;
  /** Список офферов */
  items?: Maybe<Array<Maybe<ProfOfferSnippet>>>;
};

/** Список офферов в карточке новостройки */
export type NewBuildingOffersList = {
  __typename?: 'NewBuildingOffersList';
  /** Постраничная навигация */
  paging?: Maybe<Paging>;
  /** Список офферов */
  items: Array<NewBuildingOffer>;
};

/** Сниппет оффера на карточке новостройки */
export type NewBuildingOffer = {
  __typename?: 'NewBuildingOffer';
  /** Является апартаментами */
  isApartments?: Maybe<Scalars['Boolean']>;
  /** Планировка */
  layout?: Maybe<Image>;
  /** Комнатность */
  rooms: Scalars['String'];
  /** Комнатность для заголовка */
  roomsTitle: Scalars['String'];
  /** Площадь */
  area: Scalars['String'];
  /** Жилая площадь */
  livingArea: Scalars['String'];
  /** Площадь кухни */
  kitchenArea: Scalars['String'];
  /** Этаж */
  floor: Scalars['String'];
  /** Этажей в доме */
  floorsTotal: Scalars['String'];
  /** Этаж и этажей в доме для заголовка */
  floorTitle: Scalars['String'];
  /** Отделка */
  finishing?: Maybe<Scalars['String']>;
  /** Балкон/лоджия */
  balcony?: Maybe<Array<Scalars['String']>>;
  /** Санузел */
  bathroom: Scalars['String'];
  /** Id корпуса */
  buildingId?: Maybe<Scalars['Int']>;
  /** Стоимость в численном выражении */
  priceValue: Scalars['Float'];
  /** Стоимость */
  price: Scalars['String'];
  /** Стоимость за единицу площади */
  pricePerAreaUnit: Scalars['String'];
  routeParams: NewBuildingOfferRouteParams;
  mortgageParams: NewBuildingOfferMortgageParams;
};

export type NewBuildingOfferRouteParams = {
  __typename?: 'NewBuildingOfferRouteParams';
  offerId: Scalars['ID'];
  dealType: Scalars['Int'];
  categoryType: Scalars['Int'];
  newBuilding: Scalars['Int'];
  room: Scalars['Int'];
  region?: Maybe<Scalars['Int']>;
};

/** Параметры офера для заявки в ИБ */
export type NewBuildingOfferMortgageParams = {
  __typename?: 'NewBuildingOfferMortgageParams';
  term: Scalars['Int'];
  propertyCost: Scalars['Float'];
  ownAmount: Scalars['Float'];
  purposeValue: Scalars['Int'];
  /** Тип недвижимости */
  claimType: Scalars['Int'];
};

export enum NewBuildingOffersSortEnum {
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PriceSqMeterAsc = 'PRICE_SQ_METER_ASC',
  PriceSqMeterDesc = 'PRICE_SQ_METER_DESC',
  TotalAreaAsc = 'TOTAL_AREA_ASC',
  TotalAreaDesc = 'TOTAL_AREA_DESC'
}

/** Статистика по выдаче новостроек */
export type NewBuildingsSummary = {
  __typename?: 'NewBuildingsSummary';
  count: Scalars['Int'];
  totalPriceMin?: Maybe<Scalars['Float']>;
  totalPriceMax?: Maybe<Scalars['Float']>;
  pricePerMeterMin?: Maybe<Scalars['Int']>;
  pricePerMeterMax?: Maybe<Scalars['Int']>;
  totalAreaMin?: Maybe<Scalars['Float']>;
  totalAreaMax?: Maybe<Scalars['Float']>;
};

/** Статистика по новостройкам в регионе */
export type NewBuildingsStatistics = {
  __typename?: 'NewBuildingsStatistics';
  /** Кол-во новостроек в регионе */
  newBuildingsCount: Scalars['Int'];
  /**
   * Кол-во квартир
   * @deprecated Не работает, всегда 0
   */
  flatsCount?: Maybe<Scalars['Int']>;
  /** Мин цена квартиры */
  minFlatPrice?: Maybe<Scalars['Int']>;
};

/** Облако ссылок по разделам */
export type NewBuildingsLinkCloud = {
  __typename?: 'NewBuildingsLinkCloud';
  /** Ссылки для раздела `Метро` */
  metro: Array<LinkParams>;
  /** Ссылки для раздела `Район`/`Округ и район` */
  district: Array<LinkParams>;
  /** Ссылки для раздела `Город` */
  city: Array<LinkParams>;
  /** Ссылки для раздела `Посмотрите также` */
  seeAlso?: Maybe<SeeAlsoLinks>;
};

/** Параметры ссылки */
export type LinkParams = {
  __typename?: 'LinkParams';
  /** Присутствует ли параметр в запросе */
  isCurrent: Scalars['Boolean'];
  region: Scalars['Int'];
  metroId?: Maybe<Scalars['Int']>;
  districtId?: Maybe<Scalars['Int']>;
  /** Комнатность */
  rooms?: Maybe<Scalars['String']>;
  /** Наличие отделки */
  hasFinishing: Scalars['String'];
  /** Тип отделки */
  finishingType?: Maybe<Scalars['String']>;
  /** Класс жилья */
  buildingClass?: Maybe<Scalars['String']>;
  transportType?: Maybe<Scalars['String']>;
  maxMinutes?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  priceMax?: Maybe<Scalars['Float']>;
  priceType?: Maybe<Scalars['String']>;
  /** Срок сдачи */
  commissioning?: Maybe<Scalars['String']>;
  /** Материал стен */
  wallsType?: Maybe<Scalars['String']>;
  /** Этаж: последний/не последний */
  floorLast?: Maybe<Scalars['String']>;
  law214: Scalars['Boolean'];
  armyMortgage: Scalars['Boolean'];
  installment: Scalars['Boolean'];
  escrow: Scalars['Boolean'];
  parking: Array<Scalars['String']>;
  concierge: Scalars['Boolean'];
  closedArea: Scalars['Boolean'];
  ceilingHeight?: Maybe<Scalars['String']>;
  kitchenAreaRangeMin?: Maybe<Scalars['Int']>;
};

/** Сгруппированные по типу параметры ссылок раздела `Посмотрите также` */
export type SeeAlsoLinks = {
  __typename?: 'SeeAlsoLinks';
  /** Параметры для ссылок с комнатностями */
  roomList: Array<LinkParams>;
  /** Параметры для ссылок с отделками */
  finishingList: Array<LinkParams>;
  /** Параметры для ссылок с классами жилья */
  buildingClassList: Array<LinkParams>;
  /** Параметры для ссылок со сроками сдачи */
  dateOfCommissioningList: Array<LinkParams>;
  /** Параметры для ссылок с материалами стен */
  wallsTypeList: Array<LinkParams>;
  /** Параметры для ссылок с Последний/не последний этаж */
  floorLastList: Array<LinkParams>;
  /** Параметры для ссылок с условиями продажи */
  sellConditionsList: Array<LinkParams>;
  /** Параметры для ссылки с дешёвыми новостройками */
  cheapList: Array<LinkParams>;
  /** Параметры для ссылки `Рядом с метро` */
  closeToMetro?: Maybe<LinkParams>;
  /** Параметры для ссылок с ценами */
  priceMaxList: Array<LinkParams>;
  /** Параметры для ссылок с парковкой */
  parkingList: Array<LinkParams>;
  /** Параметры для ссылок `Безопасность` */
  securityList: Array<LinkParams>;
  /** Параметры для ссылок `С высокими потолками` */
  highCeilingsList: Array<LinkParams>;
  /** Параметры для ссылок `С большой кухней` */
  bigKitchenList: Array<LinkParams>;
  /** Параметры для ссылок c европланировками */
  euroKindList: Array<LinkParams>;
};

/** Список застройщиков */
export type DeveloperList = {
  __typename?: 'DeveloperList';
  /** Постраничная навигация */
  paging?: Maybe<Paging>;
  /** Офферы */
  items: Array<DeveloperSnippet>;
};

export type DeveloperSnippet = {
  __typename?: 'DeveloperSnippet';
  id: Scalars['Int'];
  translitName: Scalars['String'];
  name: Scalars['String'];
  total: Scalars['Int'];
  commissioned: Scalars['Int'];
  inProgress: Scalars['Int'];
  images?: Maybe<Array<Image>>;
  icon?: Maybe<Image>;
  baseUrl: Scalars['String'];
};


export type DeveloperSnippetImagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

/** Набор возможных фильтров для выдачи застройщиков */
export type SearchDevelopersInput = {
  /** Регион поиска */
  regionId?: Scalars['Int'];
};

export type NewBuildingQuiz = {
  __typename?: 'NewBuildingQuiz';
  email: QuizEmail;
  internalName: Scalars['String'];
  steps: Array<QuizStep>;
  lastStep: QuizLastStep;
  sidebar: QuizSidebar;
};

export type QuizEmail = {
  __typename?: 'QuizEmail';
  address: Scalars['String'];
  subject: Scalars['String'];
};

export type QuizStep = {
  __typename?: 'QuizStep';
  order: Scalars['Int'];
  title: Scalars['String'];
  subtitle: Scalars['String'];
  internalName: Scalars['String'];
  answer: QuizStepAnswer;
  additional?: Maybe<QuizStepAdditional>;
};

export type QuizStepAnswer = {
  __typename?: 'QuizStepAnswer';
  isMulti?: Maybe<Scalars['Boolean']>;
  options: Array<Scalars['String']>;
};

export type QuizStepAdditional = {
  __typename?: 'QuizStepAdditional';
  title: Scalars['String'];
  answer: QuizStepAnswer;
};

export type QuizLastStep = {
  __typename?: 'QuizLastStep';
  title: Scalars['String'];
  bonusList: Array<Bonus>;
  buttonText: Scalars['String'];
  withAgreement?: Maybe<Scalars['Boolean']>;
};

export type Bonus = {
  __typename?: 'Bonus';
  iconName: Scalars['String'];
  iconColor?: Maybe<Scalars['String']>;
  point: Scalars['String'];
  promoCode: Scalars['String'];
};

export type QuizSidebar = {
  __typename?: 'QuizSidebar';
  title: Scalars['String'];
  subtitle: Scalars['String'];
  buttonText: Scalars['String'];
  buttonLink?: Maybe<Scalars['String']>;
  imageSrc: Scalars['String'];
};

export type OfferPhones = {
  __typename?: 'OfferPhones';
  phoneList?: Maybe<Array<Maybe<Phone>>>;
};

export type OfferPhone = {
  __typename?: 'OfferPhone';
  phone: Phone;
  isReplaced?: Maybe<Scalars['Boolean']>;
};

export type OffersPhones = {
  __typename?: 'OffersPhones';
  offerId?: Maybe<Scalars['ID']>;
  phoneList?: Maybe<Array<Maybe<Phone>>>;
};

export type RandomNumber = {
  __typename?: 'randomNumber';
  value: Scalars['Int'];
};

export type OfferSearchQueryString = {
  __typename?: 'OfferSearchQueryString';
  query: Scalars['String'];
};

export type NewBuildingDeveloperPromoLanding = {
  __typename?: 'NewBuildingDeveloperPromoLanding';
  developerId: Scalars['Int'];
  regionId: Scalars['Int'];
  logoColor: Scalars['String'];
  logoWhite: Scalars['String'];
  title: Scalars['String'];
  heading: Scalars['String'];
  phone: Scalars['String'];
  landing: DeveloperPromoLanding;
  appLanding: DeveloperPromoAppLanding;
};

export type DeveloperPromoLanding = {
  __typename?: 'DeveloperPromoLanding';
  newBuildingIds: Array<Scalars['Int']>;
  skipNewBuildingTypes?: Maybe<Scalars['Boolean']>;
  slides: Array<DeveloperPromoLandingSlide>;
  disclaimers: Array<Scalars['String']>;
  erid: Scalars['String'];
};

export type DeveloperPromoLandingSlide = {
  __typename?: 'DeveloperPromoLandingSlide';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  description: Array<Scalars['String']>;
  withPriceFrom?: Maybe<Scalars['Boolean']>;
  buttonText?: Maybe<Scalars['String']>;
  imageWide?: Maybe<Scalars['String']>;
  imageNarrow?: Maybe<Scalars['String']>;
};

export type DeveloperPromoAppLanding = {
  __typename?: 'DeveloperPromoAppLanding';
  newBuildingsTitle: Scalars['String'];
  newBuildings: Array<DeveloperPromoAppLandingNewBuildings>;
  headerTitle: Scalars['String'];
  headerDescription: Scalars['String'];
  headerDisclaimer: Scalars['String'];
  layoutsDescription: Scalars['String'];
  layouts: Array<DeveloperPromoAppLandingLayouts>;
  renovationsDescription: Scalars['String'];
  renovations: Array<Scalars['String']>;
};

export type DeveloperPromoAppLandingNewBuildings = {
  __typename?: 'DeveloperPromoAppLandingNewBuildings';
  id: Scalars['Int'];
  url: Scalars['String'];
};

export type DeveloperPromoAppLandingLayouts = {
  __typename?: 'DeveloperPromoAppLandingLayouts';
  image: Scalars['String'];
  title: Scalars['String'];
  price: Scalars['String'];
  description: Scalars['String'];
};

export enum DeveloperPromoEnum {
  Cds = 'Cds',
  Samolet = 'Samolet'
}

export type FavoritesDeveloperPromo = {
  __typename?: 'FavoritesDeveloperPromo';
  /** Ссылка для баннера */
  url: Scalars['String'];
  long: Scalars['String'];
  square: Scalars['String'];
};

export type Header = {
  __typename?: 'Header';
  items: Array<HeaderTab>;
  partners?: Maybe<HeaderPartnersType>;
  personalButtons?: Maybe<HeaderPersonalButtonsType>;
  loginPaths?: Maybe<HeaderLoginPathsType>;
  isAuth?: Maybe<Scalars['Boolean']>;
};

export type HeaderTab = {
  __typename?: 'HeaderTab';
  id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  dropdownMenu?: Maybe<Array<HeaderTabItem>>;
};

export type HeaderTabItem = {
  __typename?: 'HeaderTabItem';
  id: Scalars['String'];
  displayName: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  dropdownMenu?: Maybe<Array<HeaderBaseItem>>;
};

export type HeaderBaseItem = {
  __typename?: 'HeaderBaseItem';
  id: Scalars['String'];
  displayName: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type HeaderPartnersType = {
  __typename?: 'HeaderPartnersType';
  samolet?: Maybe<HeaderPartnerItemType>;
};

export type HeaderPartnerItemType = {
  __typename?: 'HeaderPartnerItemType';
  id: Scalars['String'];
  logo: Scalars['String'];
  logoWhite: Scalars['String'];
  url: Scalars['String'];
};

export type HeaderPersonalButtonsType = {
  __typename?: 'HeaderPersonalButtonsType';
  favorites?: Maybe<HeaderPersonalButtonType>;
  offerPlacement?: Maybe<HeaderPersonalButtonType>;
  savedSearch?: Maybe<HeaderPersonalButtonType>;
};

export type HeaderPersonalButtonType = {
  __typename?: 'HeaderPersonalButtonType';
  url: Scalars['String'];
  professionalUrl?: Maybe<Scalars['String']>;
  authorizedUrl?: Maybe<Scalars['String']>;
};

export type HeaderLoginPathsType = {
  __typename?: 'HeaderLoginPathsType';
  default?: Maybe<HeaderLoginPathType>;
  client?: Maybe<HeaderLoginPathType>;
  partner?: Maybe<HeaderLoginPathType>;
  professional?: Maybe<HeaderLoginPathType>;
};

export type HeaderLoginPathType = {
  __typename?: 'HeaderLoginPathType';
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Footer = {
  __typename?: 'Footer';
  downloadAppQrCode: Scalars['String'];
  socialLinks?: Maybe<Array<FooterSocialLink>>;
  top?: Maybe<Array<Array<FooterLink>>>;
  middleLinks?: Maybe<Array<FooterMiddleCol>>;
};

export type FooterSocialLink = {
  __typename?: 'FooterSocialLink';
  name: Scalars['String'];
  icon: Scalars['String'];
  href: Scalars['String'];
};

export type FooterLink = {
  __typename?: 'FooterLink';
  name: Scalars['String'];
  to?: Maybe<Scalars['String']>;
  href?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['Boolean']>;
};

export type FooterMiddleCol = {
  __typename?: 'FooterMiddleCol';
  title: Scalars['String'];
  links: Array<Maybe<FooterLink>>;
};

export type Sidebar = {
  __typename?: 'Sidebar';
  items?: Maybe<Array<Maybe<SidebarLink>>>;
  footerItems?: Maybe<Array<Maybe<SidebarLink>>>;
};

export type SidebarLink = {
  __typename?: 'SidebarLink';
  id: Scalars['String'];
  displayName: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  showInDesktopProfileMenu?: Maybe<Scalars['Boolean']>;
  dropdownMenu?: Maybe<Array<DropdownMenu>>;
};

export type DropdownMenu = {
  __typename?: 'DropdownMenu';
  id: Scalars['String'];
  displayName: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  showInDesktopProfileMenu?: Maybe<Scalars['Boolean']>;
};

export type SeoLinkCloudType = {
  __typename?: 'SeoLinkCloudType';
  blocks: Array<SeoLinkBlockType>;
};

export type SeoLinkBlockType = {
  __typename?: 'SeoLinkBlockType';
  id: Scalars['ID'];
  title: Scalars['String'];
  links: Array<SeoLinkItemType>;
};

/** Список элементов */
export type SuggestList = SuggestItemNewBuilding | SuggestItemMetro | SuggestItemDistrict | SuggestItemAddress | SuggestItemDeveloper | SuggestItemDefault;

export type SuggestItemNewBuilding = {
  __typename?: 'SuggestItemNewBuilding';
  label: Scalars['String'];
  data: SuggestItemNewBuildingData;
  newBuilding?: Maybe<NewBuildingSuggest>;
};

export type SuggestItemNewBuildingData = {
  __typename?: 'SuggestItemNewBuildingData';
  id: Scalars['Int'];
  scope?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['Int']>;
};

export type NewBuildingSuggest = {
  __typename?: 'NewBuildingSuggest';
  id: Scalars['Int'];
  type: Scalars['String'];
  name: Scalars['String'];
  title?: Maybe<NewBuildingTitle>;
};

export type SuggestItemMetro = {
  __typename?: 'SuggestItemMetro';
  label: Scalars['String'];
  data: SuggestItemData;
  metroStation?: Maybe<MetroStation>;
};

export type SuggestItemData = {
  __typename?: 'SuggestItemData';
  /** Id в геобазе */
  id: Scalars['Int'];
  /** Регион внутри которого находится обьект, по нему можно принять решение о смене региона на клиенте */
  regionId?: Maybe<Scalars['Int']>;
  /** Контекст гео-обьекта */
  scope?: Maybe<Scalars['String']>;
  kind: KindEnum;
};

export type SuggestItemDistrict = {
  __typename?: 'SuggestItemDistrict';
  label: Scalars['String'];
  data: SuggestItemData;
  district: GeoObject;
};

export type SuggestItemAddress = {
  __typename?: 'SuggestItemAddress';
  label: Scalars['String'];
  data: SuggestItemData;
  address: GeoObject;
};

export type SuggestItemDeveloper = {
  __typename?: 'SuggestItemDeveloper';
  label: Scalars['String'];
  data: SuggestItemDeveloperData;
  developer?: Maybe<DeveloperSuggest>;
};

export type SuggestItemDeveloperData = {
  __typename?: 'SuggestItemDeveloperData';
  id: Scalars['Int'];
  scope?: Maybe<Scalars['String']>;
};

export type DeveloperSuggest = {
  __typename?: 'DeveloperSuggest';
  id: Scalars['Int'];
  title: Scalars['String'];
  comment: Scalars['String'];
};

export type SuggestItemDefault = {
  __typename?: 'SuggestItemDefault';
  label: Scalars['String'];
  data: SuggestItemData;
};

export enum SuggestTypeEnum {
  Unknown = 'UNKNOWN',
  NewBuilding = 'NEW_BUILDING',
  Developer = 'DEVELOPER',
  Bank = 'BANK',
  Geo = 'GEO'
}

export enum RootRegionsApplicationEnum {
  Unknown = 'UNKNOWN',
  /** Классифайд */
  OffersSerp = 'OFFERS_SERP',
  /** Новостройки */
  NbSerp = 'NB_SERP',
  /** Новостройки вайтлейбл ВТБ */
  Vtb = 'VTB'
}

export type GeoSuggestItem = {
  __typename?: 'GeoSuggestItem';
  geoObject: GeoObject;
};

export type BankMortgageOffer = {
  __typename?: 'BankMortgageOffer';
  programCode?: Maybe<MortgageOfferProgramCode>;
  bankId: Scalars['String'];
  icon?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  minimalPercent: Scalars['Float'];
  minimalPercentFormatted?: Maybe<Scalars['String']>;
  minimalPayment: Scalars['Float'];
  minimalPaymentFormatted?: Maybe<Scalars['String']>;
};

export enum MortgageOfferProgramCode {
  Base = 'BASE',
  Family = 'FAMILY',
  StateProgram_2020 = 'STATE_PROGRAM_2020',
  FarEast = 'FAR_EAST',
  ItMortgage = 'IT_MORTGAGE'
}

/** Тип недвижимости */
export enum MortgagePropertyType {
  /** Апартаменты */
  Apartment = 'APARTMENT',
  /** Квартира */
  Flat = 'FLAT',
  /** Дом с участком */
  HouseWithLot = 'HOUSE_WITH_LOT',
  /** Таунхаус */
  Townhouse = 'TOWNHOUSE',
  /** Земельный участок */
  Lot = 'LOT'
}

/** Код ипотечной программы */
export enum MortgageProgramCodeType {
  /** Базовая программа */
  Base = 'BASE',
  /** Семейная ипотека */
  Family = 'FAMILY',
  /** Господдержка */
  StateProgram_2020 = 'STATE_PROGRAM_2020',
  /** Дальневосточная */
  FarEast = 'FAR_EAST',
  /** Для ИТ специалистов */
  ItMortgage = 'IT_MORTGAGE'
}

export type MinimalBankMortgageOffer = {
  __typename?: 'MinimalBankMortgageOffer';
  programCode?: Maybe<MortgageOfferProgramCode>;
  type?: Maybe<MortgageOfferType>;
  bankId: Scalars['String'];
  icon?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  minimalPercent: Scalars['Float'];
  minimalPercentFormatted?: Maybe<Scalars['String']>;
  minimalPayment: Scalars['Float'];
  minimalPaymentFormatted?: Maybe<Scalars['String']>;
};

export enum MortgageOfferType {
  Family = 'FAMILY',
  Preferential = 'PREFERENTIAL',
  ItMortgage = 'IT_MORTGAGE'
}

export type Favorite = {
  __typename?: 'Favorite';
  offerId: Scalars['ID'];
  added: Scalars['Float'];
};

export type SearchesPersonal = {
  __typename?: 'SearchesPersonal';
  items: Array<Search>;
  freshOffersCount?: Maybe<Scalars['Int']>;
};

export type Search = {
  __typename?: 'Search';
  id: Scalars['ID'];
  title: Scalars['String'];
  freshOffersCount?: Maybe<Scalars['Int']>;
  subscription?: Maybe<SearchSubscription>;
  query: Scalars['String'];
  /** Параметры для SearchRoute в виде строки */
  querySearchParams: Scalars['String'];
  added: Scalars['Float'];
  searchParams?: Maybe<Scalars['String']>;
};

/** Подписка. Поля вне channels касающиеся каналов подписки, относятся к email-каналу. */
export type SearchSubscription = {
  __typename?: 'SearchSubscription';
  id: Scalars['ID'];
  added: Scalars['Float'];
  /** @deprecated нет в модели */
  updated?: Maybe<Scalars['Float']>;
  emails: Array<Scalars['String']>;
  frequency?: Maybe<FrequencyEnum>;
  isSubscribe: Scalars['Boolean'];
  hasEmailSubscription: Scalars['Boolean'];
  /** Каналы подписки помимо основного(email), чтобы не потерять при пересоздании подписки */
  channels: Array<SubscriptionChannel>;
};

export enum FrequencyEnum {
  SealedValueOptionalNotSet = 'SEALED_VALUE_OPTIONAL_NOT_SET',
  Immediately = 'IMMEDIATELY',
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK'
}

export type SubscriptionChannel = {
  __typename?: 'SubscriptionChannel';
  type: SubscriptionChannelEnum;
  isActive: Scalars['Boolean'];
  frequency: FrequencyEnum;
  email?: Maybe<Scalars['String']>;
};

export enum SubscriptionChannelEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Mail = 'MAIL',
  Push = 'PUSH'
}

export type PreferencesBusiness = {
  __typename?: 'PreferencesBusiness';
  searchesList: Array<BusinessCustomerSearches>;
  favoritesList: Array<BusinessCustomerFavorite>;
  customersList: Array<Customer>;
  mailList: Array<Maybe<AgentItemEmail>>;
};

export type BusinessCustomerSearches = {
  __typename?: 'BusinessCustomerSearches';
  customerId: Scalars['ID'];
  searches: Array<Search>;
  searchesCount: Scalars['Int'];
};

export type BusinessCustomerFavorite = {
  __typename?: 'BusinessCustomerFavorite';
  customerId: Scalars['ID'];
  favorites: Array<Favorite>;
  favoritesCount: Scalars['Int'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  name: Scalars['String'];
  note: Scalars['String'];
  created: Scalars['Float'];
  updated?: Maybe<Scalars['Float']>;
  phone: Scalars['String'];
  email: Scalars['String'];
};

export type AgentItemEmail = {
  __typename?: 'AgentItemEmail';
  email?: Maybe<Scalars['String']>;
  status?: Maybe<EmailStatus>;
};

export enum EmailStatus {
  ValueNotSet = 'VALUE_NOT_SET',
  Accepted = 'ACCEPTED',
  CodeSent = 'CODE_SENT',
  Verified = 'VERIFIED'
}

/** Сниппет оффера в избранном */
export type FavoriteOffer2 = {
  __typename?: 'FavoriteOffer2';
  /** Базовый интерфейс оффера */
  offer?: Maybe<ProfOfferSearchBase>;
  title: Scalars['String'];
  added?: Maybe<Scalars['Float']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  subscriptionEmail?: Maybe<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  frequency?: Maybe<FrequencyEnum>;
};

/** Информация об авторизованном пользователе (все что лежит в токене) */
export type UserDetails = {
  __typename?: 'UserDetails';
  userId: Scalars['String'];
  phone: Scalars['String'];
  /** @deprecated Auth-api больше не отдаёт информацию о пользователе */
  username: Scalars['String'];
  /** @deprecated Auth-api больше не отдаёт информацию о пользователе */
  firstname: Scalars['String'];
  /** @deprecated Auth-api больше не отдаёт информацию о пользователе */
  surname: Scalars['String'];
  /** @deprecated Auth-api больше не отдаёт информацию о пользователе */
  patronymic: Scalars['String'];
  /** @deprecated Auth-api больше не отдаёт информацию о пользователе */
  email: Scalars['String'];
  companyId: Scalars['String'];
  hasOfferWithoutVas?: Maybe<Scalars['Boolean']>;
};

export type UserProfileResponse = UnauthenticatedError | UserProfile;

/** Пользователь не залогинен */
export type UnauthenticatedError = BaseError & {
  __typename?: 'UnauthenticatedError';
  message?: Maybe<Scalars['String']>;
};

export type BaseError = {
  message?: Maybe<Scalars['String']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  /** Данные про пользователя */
  person: Person;
  /** Если пользователь является сотрудником какой-либо компании */
  employee: EmployeeResponse;
};

/** Person */
export type Person = Node & {
  __typename?: 'Person';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  /** @deprecated Заменено полем primaryPhone */
  phone?: Maybe<Scalars['String']>;
  /** @deprecated Заменено полем primaryPhone */
  extraPhone?: Maybe<Scalars['String']>;
  primaryPhone?: Maybe<PersonPhone>;
  secondaryPhones?: Maybe<Array<Maybe<PersonPhone>>>;
  email?: Maybe<Scalars['String']>;
  agreements?: Maybe<PersonAgreements>;
};

/** Телефон */
export type PersonPhone = {
  __typename?: 'PersonPhone';
  number: Scalars['String'];
  formattedNumber: Scalars['String'];
  extraNumber: Scalars['String'];
};

export type PersonAgreements = {
  __typename?: 'PersonAgreements';
  processingPersonalData: Scalars['Boolean'];
  sendingSms: Scalars['Boolean'];
  sendingEmail: Scalars['Boolean'];
  commonUserAgreement: Scalars['Boolean'];
  mortgageProcessingPersonalData: Scalars['Boolean'];
  postReviews: Scalars['Boolean'];
};

export type EmployeeResponse = Employee | EmployeeNotFoundError;

export type Employee = Node & {
  __typename?: 'Employee';
  /** Синтетический id */
  id: Scalars['ID'];
  companyId: Scalars['ID'];
  company: EmployeeCompany;
  /** Список бранчей (филиалов) на которые у пользователя есть права */
  branches: Array<Maybe<CompanyBranchActionType>>;
};


export type EmployeeBranchesArgs = {
  action?: InputMaybe<Scalars['String']>;
};

export type EmployeeCompany = {
  __typename?: 'EmployeeCompany';
  info: CompanyInfo;
  branches?: Maybe<Array<Maybe<CompanyBranch>>>;
  preferences: CompanyPreferences;
  isVtbPartner: Scalars['Boolean'];
};

/** CompanyInfo */
export type CompanyInfo = Node & {
  __typename?: 'CompanyInfo';
  id: Scalars['ID'];
  /** @deprecated Используй id */
  companyId: Scalars['ID'];
  companyFullName: Scalars['String'];
};

export type CompanyBranch = Node & {
  __typename?: 'CompanyBranch';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type CompanyPreferences = Node & {
  __typename?: 'CompanyPreferences';
  id: Scalars['ID'];
  brokerVariant: BrokerVariantEnum;
  bankFilter: CompanyPreferencesBankFilter;
};

export enum BrokerVariantEnum {
  SealedValueNotSet = 'SEALED_VALUE_NOT_SET',
  Hetero = 'HETERO',
  Vtb = 'VTB'
}

export type CompanyPreferencesBankFilter = {
  __typename?: 'CompanyPreferencesBankFilter';
  onlyBanks: Array<Scalars['String']>;
  excludeBanks: Array<Scalars['String']>;
};

export type CompanyBranchActionType = Node & {
  __typename?: 'CompanyBranchActionType';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  actions: Array<Scalars['String']>;
};

/** Сотрудник не найден */
export type EmployeeNotFoundError = BaseError & {
  __typename?: 'EmployeeNotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type UserSavedSearch = {
  __typename?: 'userSavedSearch';
  freshOffersCount?: Maybe<Scalars['Int']>;
  searchParams?: Maybe<Scalars['String']>;
};

export type PersonalDetailItem = {
  __typename?: 'PersonalDetailItem';
  mail?: Maybe<PersonalDetailItemEmail>;
};

export type PersonalDetailItemEmail = {
  __typename?: 'PersonalDetailItemEmail';
  email?: Maybe<Scalars['String']>;
  status?: Maybe<EmailStatus>;
};

export type FeatureTourInitial = {
  __typename?: 'FeatureTourInitial';
  /** ID тура */
  id?: Maybe<Scalars['String']>;
  /** Флаг прохождения основного туда в ЛК проф пользователя */
  isInitialComplete?: Maybe<Scalars['Boolean']>;
};

export type NewBuildingSeo = {
  __typename?: 'NewBuildingSeo';
  developerPage?: Maybe<DeveloperPage>;
};


export type NewBuildingSeoDeveloperPageArgs = {
  regionId?: InputMaybe<Scalars['Int']>;
  developerId: Scalars['Int'];
};

export type DeveloperPage = {
  __typename?: 'DeveloperPage';
  title: Scalars['String'];
  /** Текст для тега h1 */
  header: Scalars['String'];
  /** Текст для meta-description */
  metaDescription: Scalars['String'];
  /** Текст последней хлебной крошки */
  breadcrumb: Scalars['String'];
};

export type GetCrawlCandidates = {
  __typename?: 'GetCrawlCandidates';
  crawlCandidatesList?: Maybe<Array<Maybe<CrawlCandidate>>>;
};

export type CrawlCandidate = {
  __typename?: 'CrawlCandidate';
  url: Scalars['String'];
  title: Scalars['String'];
  metaTitle: Scalars['String'];
};

/** Офферы в избранном */
export type FavoriteOffers = {
  __typename?: 'FavoriteOffers';
  /**
   * Офферы в избранном - аренда
   * @deprecated use list(FavoriteOffer2)
   */
  rent: Array<FavoriteOffer>;
  /**
   * Офферы в избранном - продажа
   * @deprecated use list(FavoriteOffer2)
   */
  sell: Array<FavoriteOffer>;
};

/** Сниппет оффера в избранном */
export type FavoriteOffer = {
  __typename?: 'FavoriteOffer';
  /** Уникальный id оффера */
  id: Scalars['ID'];
  preview?: Maybe<Image2>;
  title: Scalars['String'];
  location: ProfOfferLocation;
  price?: Maybe<FavoritePrice>;
  isUnpublished: Scalars['Boolean'];
  paymentsInfo?: Maybe<FavoritePaymentsInfo>;
  priceDetail?: Maybe<FavoritePriceDetail>;
  seller?: Maybe<FavoriteSeller>;
  thumbnails?: Maybe<Array<Maybe<Image2>>>;
  dealType?: Maybe<Scalars['Int']>;
  offerType?: Maybe<Scalars['Int']>;
  isNewBuilding?: Maybe<Scalars['Boolean']>;
  isPledge?: Maybe<Scalars['Boolean']>;
  realtyObject?: Maybe<ProfRealityObject>;
  roomType?: Maybe<Scalars['Int']>;
  added?: Maybe<Scalars['Float']>;
  hasSubscription?: Maybe<Scalars['Boolean']>;
  subscriptionEmail?: Maybe<Scalars['String']>;
  noteText?: Maybe<Scalars['String']>;
  frequency?: Maybe<FrequencyEnum>;
  /** Платная ли новостройка,  */
  isNewBuildingPaid: Scalars['Boolean'];
  mortgagePurpose: MortgagePurpose;
};

/** Информация о цене */
export type FavoritePrice = {
  __typename?: 'FavoritePrice';
  priceValue?: Maybe<Scalars['Float']>;
  priceInitial?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['Int']>;
  priceUnit?: Maybe<Scalars['Int']>;
  rentPeriod?: Maybe<Scalars['Int']>;
  /** История изменения цены */
  history: Array<HistoricalPrice>;
};

/** Дополнительная информация о сделке */
export type FavoritePaymentsInfo = {
  __typename?: 'FavoritePaymentsInfo';
  deposit?: Maybe<Scalars['String']>;
  hasBargain?: Maybe<Scalars['String']>;
  utilities?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  prepayment?: Maybe<Scalars['String']>;
};

/** Дополнительная информация о цене */
export type FavoritePriceDetail = {
  __typename?: 'FavoritePriceDetail';
  priceValue?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['Int']>;
  totalAreaValue?: Maybe<Scalars['Float']>;
  totalAreaUnit?: Maybe<Scalars['Int']>;
};

/** Информация продавце */
export type FavoriteSeller = {
  __typename?: 'FavoriteSeller';
  /** Id продавца */
  ownerId?: Maybe<Scalars['Int']>;
  organizationName?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ProfOfferResponseType = ProfOfferSnippet | ProfOfferError;

export type ProfOfferError = BaseError & {
  __typename?: 'ProfOfferError';
  message?: Maybe<Scalars['String']>;
};

export type UserEmails = {
  __typename?: 'UserEmails';
  emailsList: Array<Scalars['String']>;
  primaryEmail: Scalars['String'];
};

/** Офферы в скрытых объявлениях */
export type HiddenOffers = {
  __typename?: 'HiddenOffers';
  /** Скрытые офферы - аренда */
  rent: Array<HiddenOffer>;
  /** Скрытые офферы - продажа */
  sell: Array<HiddenOffer>;
};

/** Сниппет оффера в скрытых объявлениях */
export type HiddenOffer = {
  __typename?: 'HiddenOffer';
  /** Базовый интерфейс оффера */
  offer?: Maybe<ProfOfferSearchBase>;
  title: Scalars['String'];
};

export type ClickerGetOfferStatsResult = {
  __typename?: 'ClickerGetOfferStatsResult';
  success?: Maybe<Array<Maybe<ClickerOfferStats>>>;
  errors?: Maybe<Array<Maybe<ClickerOfferStatsErrorEnum>>>;
};

export type ClickerOfferStats = {
  __typename?: 'ClickerOfferStats';
  offerId?: Maybe<Scalars['String']>;
  stats?: Maybe<ClickerStatistics>;
};

export type ClickerStatistics = {
  __typename?: 'ClickerStatistics';
  counter?: Maybe<ClickerCounter>;
  timeSeries?: Maybe<Array<ClickerTimePointCounter>>;
};

export type ClickerCounter = {
  __typename?: 'ClickerCounter';
  offerViews?: Maybe<Scalars['Int']>;
  phoneViews?: Maybe<Scalars['Int']>;
};

export type ClickerTimePointCounter = {
  __typename?: 'ClickerTimePointCounter';
  counter?: Maybe<ClickerCounter>;
  timePoint?: Maybe<ClickerTimePoint>;
};

export type ClickerTimePoint = {
  __typename?: 'ClickerTimePoint';
  start: Scalars['String'];
  timeFrame?: Maybe<ClickerTimeFrameEnum>;
};

export enum ClickerTimeFrameEnum {
  SealedValueOptionalNotSet = 'SEALED_VALUE_OPTIONAL_NOT_SET',
  Hour = 'HOUR',
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR'
}

export enum ClickerOfferStatsErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  EmptyOfferIds = 'EMPTY_OFFER_IDS',
  EmptyTimeRange = 'EMPTY_TIME_RANGE',
  EmptyTimeFrame = 'EMPTY_TIME_FRAME',
  InvalidOfferId = 'INVALID_OFFER_ID',
  InvalidTimeZone = 'INVALID_TIME_ZONE',
  InvalidTimeRange = 'INVALID_TIME_RANGE'
}

export type ClickerGetUserFeedStatsResult = {
  __typename?: 'ClickerGetUserFeedStatsResult';
  success?: Maybe<ClickerFeedStatsResponse>;
  errors?: Maybe<Array<Maybe<ClickerUserFeedStatsEnum>>>;
};

export type ClickerFeedStatsResponse = {
  __typename?: 'ClickerFeedStatsResponse';
  feeds: Array<Maybe<ClickerFeedStats>>;
  sumStats?: Maybe<ClickerStatistics>;
};

export type ClickerFeedStats = {
  __typename?: 'ClickerFeedStats';
  feedId?: Maybe<Scalars['String']>;
  stats?: Maybe<ClickerStatistics>;
};

export enum ClickerUserFeedStatsEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  EmptyTimeRange = 'EMPTY_TIME_RANGE',
  EmptyTimeFrame = 'EMPTY_TIME_FRAME',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidTimeZone = 'INVALID_TIME_ZONE',
  InvalidTimeRange = 'INVALID_TIME_RANGE'
}

export type GetLastSearchesResult = {
  __typename?: 'GetLastSearchesResult';
  lastSearches?: Maybe<Array<Maybe<LastSearch>>>;
};

export type LastSearch = {
  __typename?: 'LastSearch';
  searchUrl: Scalars['String'];
  searchText: Scalars['String'];
};

export type GetUserFeedsResult = {
  __typename?: 'GetUserFeedsResult';
  feedsList: Array<UserFeed>;
};

export type UserFeed = {
  __typename?: 'UserFeed';
  feedId: Scalars['Int'];
  url: Scalars['String'];
  download?: Maybe<UserFeedDownload>;
};

export type UserFeedDownload = UserFeedDownloadSuccess | UserFeedDownloadFailure;

export type UserFeedDownloadSuccess = {
  __typename?: 'UserFeedDownloadSuccess';
  downloadTime?: Maybe<Date>;
};

export type UserFeedDownloadFailure = {
  __typename?: 'UserFeedDownloadFailure';
  downloadTime?: Maybe<Date>;
  error: UserFeedDownloadFailureEnum;
};

export enum UserFeedDownloadFailureEnum {
  Unknown = 'UNKNOWN',
  BadRequest = 'BAD_REQUEST',
  Unauthorized = 'UNAUTHORIZED',
  Forbidden = 'FORBIDDEN',
  NotFound = 'NOT_FOUND',
  Internal = 'INTERNAL'
}

export type GetUserFeedCountOffersResult = {
  __typename?: 'GetUserFeedCountOffersResult';
  banned: Scalars['Float'];
  invalid: Scalars['Float'];
  published: Scalars['Float'];
  restricted: Scalars['Float'];
};

export type UserFeedCountOffersInputFilter = {
  deal?: InputMaybe<UserFeedCountOffersDealInputEnum>;
  realty?: InputMaybe<UserFeedCountOffersRealtyInputEnum>;
  building?: InputMaybe<UserFeedCountOffersBuildingInputEnum>;
  region?: InputMaybe<Scalars['String']>;
};

export enum UserFeedCountOffersDealInputEnum {
  Unknown = 'UNKNOWN',
  Sell = 'SELL',
  Rent = 'RENT'
}

export enum UserFeedCountOffersRealtyInputEnum {
  Unknown = 'UNKNOWN',
  Flat = 'FLAT',
  Room = 'ROOM',
  House = 'HOUSE',
  Land = 'LAND'
}

export enum UserFeedCountOffersBuildingInputEnum {
  Unknown = 'UNKNOWN',
  New = 'NEW',
  Second = 'SECOND'
}

export type GetUserFeedRegionsResult = {
  __typename?: 'GetUserFeedRegionsResult';
  regionsList: Array<Scalars['String']>;
};

export type SearchBannedOffersResult = {
  __typename?: 'SearchBannedOffersResult';
  list: Array<SearchBannedOffer>;
  total: Scalars['Int'];
};

export type SearchBannedOffer = {
  __typename?: 'SearchBannedOffer';
  offer: ProfOfferSearchBase;
  meta?: Maybe<SearchBannedOfferMeta>;
  banReasons?: Maybe<Array<SearchBannedOfferBanReasonEnum>>;
};

export type SearchBannedOfferMeta = {
  __typename?: 'SearchBannedOfferMeta';
  firstMetDate?: Maybe<Date>;
  createDate?: Maybe<Date>;
  updateDate?: Maybe<Date>;
  removeDate?: Maybe<Date>;
  actualizeDate?: Maybe<Date>;
};

export enum SearchBannedOfferBanReasonEnum {
  Unknown = 'UNKNOWN',
  RuleViolation = 'RULE_VIOLATION',
  NotRelevant = 'NOT_RELEVANT',
  InvalidPrice = 'INVALID_PRICE',
  InvalidDeal = 'INVALID_DEAL',
  InvalidRealty = 'INVALID_REALTY',
  InvalidGeo = 'INVALID_GEO',
  InvalidPhoto = 'INVALID_PHOTO',
  InvalidFloor = 'INVALID_FLOOR',
  InvalidArea = 'INVALID_AREA',
  InvalidRoom = 'INVALID_ROOM',
  InvalidDescription = 'INVALID_DESCRIPTION',
  Other = 'OTHER'
}

export type SearchNotBannedOffersResult = {
  __typename?: 'SearchNotBannedOffersResult';
  list: Array<SearchBannedOffer>;
  total: Scalars['Int'];
};

export type GetCompanyInfo = {
  __typename?: 'GetCompanyInfo';
  companyInfo?: Maybe<CompanyInfo>;
};

export type PersonSearch = {
  __typename?: 'PersonSearch';
  persons?: Maybe<Array<Maybe<Person>>>;
};

/** Список черновиков */
export type DraftOffersList = {
  __typename?: 'DraftOffersList';
  /** Постраничная навигация */
  paging?: Maybe<DraftOffersPaging>;
  /** Список офферов */
  items?: Maybe<Array<Maybe<PlacementOffer>>>;
  bffError?: Maybe<BffErrosEnum>;
  errors?: Maybe<Array<GetOffersErrorEnum>>;
};

export type DraftOffersPaging = {
  __typename?: 'DraftOffersPaging';
  total?: Maybe<Scalars['Int']>;
};

export type PlacementOffer = {
  __typename?: 'PlacementOffer';
  moderationStatus?: Maybe<PlacementOfferModerationStatus>;
  created: Scalars['Float'];
  updated: Scalars['Float'];
  status: OfferPlacementStatus;
  offer: OfferPlacementDraftOffer;
};

export type PlacementOfferModerationStatus = {
  __typename?: 'PlacementOfferModerationStatus';
  value?: Maybe<PlacementModerationStatusEnum>;
  banReasons?: Maybe<Array<PlacementModerationBanReasonsEnum>>;
};

export enum PlacementModerationStatusEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
  Expired = 'EXPIRED',
  Banned = 'BANNED'
}

export enum PlacementModerationBanReasonsEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  RuleViolation = 'RULE_VIOLATION',
  NotRelevant = 'NOT_RELEVANT',
  InvalidPrice = 'INVALID_PRICE',
  InvalidDeal = 'INVALID_DEAL',
  InvalidRealty = 'INVALID_REALTY',
  InvalidGeo = 'INVALID_GEO',
  InvalidPhoto = 'INVALID_PHOTO',
  InvalidFloor = 'INVALID_FLOOR',
  InvalidArea = 'INVALID_AREA',
  InvalidRoom = 'INVALID_ROOM',
  InvalidDescription = 'INVALID_DESCRIPTION',
  Other = 'OTHER'
}

export enum OfferPlacementStatus {
  ValueNotSet = 'VALUE_NOT_SET',
  Draft = 'DRAFT',
  Publishing = 'PUBLISHING',
  Unpublishing = 'UNPUBLISHING',
  Archive = 'ARCHIVE',
  Expired = 'EXPIRED'
}

/** Черновик оффер */
export type OfferPlacementDraftOffer = {
  __typename?: 'OfferPlacementDraftOffer';
  id: Scalars['String'];
  stats?: Maybe<OfferPlacementStats>;
  statsPerWeek?: Maybe<OfferPlacementStatsPerWeek>;
  vasList?: Maybe<Array<Maybe<OfferPlacementDraftOfferVasState>>>;
  realty?: Maybe<OfferPlacementDraftOfferRealty>;
  dealType?: Maybe<DealTypeOpEnum>;
  deal?: Maybe<OfferPlacementDraftOfferDeal>;
  images?: Maybe<Array<OfferPlacementDraftOfferImage>>;
  description: Scalars['String'];
  location?: Maybe<OfferPlacementDraftOfferLocation>;
  seller?: Maybe<OfferPlacementDraftOfferSeller>;
  abstractOptions?: Maybe<PlacementOfferAbstractOptions>;
};

export type OfferPlacementStatsPerWeek = {
  __typename?: 'OfferPlacementStatsPerWeek';
  byDate?: Maybe<Array<OfferPlacementStatsLastWeekItem>>;
};

export type OfferPlacementStatsLastWeekItem = {
  __typename?: 'OfferPlacementStatsLastWeekItem';
  offerViews: Scalars['Float'];
  phoneViews: Scalars['Float'];
  total: Scalars['Float'];
  date: Scalars['String'];
};

export type OfferPlacementDraftOfferVasState = {
  __typename?: 'OfferPlacementDraftOfferVasState';
  condition?: Maybe<VasConditionEnum>;
  until?: Maybe<Date>;
  duration?: Maybe<Scalars['Int']>;
  vas?: Maybe<VasEnum>;
  status?: Maybe<VasStatusEnum>;
};

export enum VasConditionEnum {
  SealedValueNotSet = 'SEALED_VALUE_NOT_SET',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum VasEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Priority = 'PRIORITY',
  Top = 'TOP',
  Highlight = 'HIGHLIGHT'
}

export enum VasStatusEnum {
  SealedValueNotSet = 'SEALED_VALUE_NOT_SET',
  VasStatusRequested = 'VAS_STATUS_REQUESTED',
  VasStatusEnabled = 'VAS_STATUS_ENABLED'
}

/** Информация об объекте */
export type OfferPlacementDraftOfferRealty = OfferPlacementDraftOfferFlat | OfferPlacementDraftOfferApartment | OfferPlacementDraftOfferFlatRoom | OfferPlacementDraftOfferLand | OfferPlacementDraftOfferHouse;

export type OfferPlacementDraftOfferFlat = {
  __typename?: 'OfferPlacementDraftOfferFlat';
  totalArea?: Maybe<OfferPlacementDraftOfferTotalArea>;
  layout?: Maybe<OfferPlacementDraftOfferLayout>;
  building?: Maybe<OfferPlacementDraftOfferBuilding>;
  features?: Maybe<OfferPlacementDraftOfferFeatures>;
  infrastructure?: Maybe<OfferPlacementDraftOfferInfrastructure>;
  rentFeatures?: Maybe<OfferPlacementDraftOfferRentFeatures>;
};

export type OfferPlacementDraftOfferTotalArea = {
  __typename?: 'OfferPlacementDraftOfferTotalArea';
  area: Scalars['Float'];
  areaUnit: AreaUnitEnum;
};

/** Информация об объекте */
export type OfferPlacementDraftOfferLayout = OfferPlacementDraftOfferLayoutStandard | OfferPlacementDraftOfferLayoutOpenPlan | OfferPlacementDraftOfferLayoutStudio | OfferPlacementDraftOfferLayoutUnknown;

export type OfferPlacementDraftOfferLayoutStandard = {
  __typename?: 'OfferPlacementDraftOfferLayoutStandard';
  rooms: RoomsExtendedGqlEnum;
  roomAreas?: Maybe<Array<Maybe<Scalars['Float']>>>;
  livingArea?: Maybe<Scalars['Float']>;
  kitchenArea?: Maybe<Scalars['Float']>;
  bathrooms?: Maybe<Array<Maybe<OfferPlacementDraftOfferBathroom>>>;
};

export enum RoomsExtendedGqlEnum {
  Unknown = 'UNKNOWN',
  OpenPlan = 'OPEN_PLAN',
  Studio = 'STUDIO',
  Room_1 = 'ROOM_1',
  Room_2 = 'ROOM_2',
  Room_3 = 'ROOM_3',
  Room_4 = 'ROOM_4',
  Room_5 = 'ROOM_5',
  Room_6 = 'ROOM_6',
  Room_7AndMore = 'ROOM_7_AND_MORE'
}

/** Черновик оффер */
export type OfferPlacementDraftOfferBathroom = {
  __typename?: 'OfferPlacementDraftOfferBathroom';
  isCombined: Scalars['String'];
  isSeparate: Scalars['String'];
  type?: Maybe<BathroomOpEnum>;
};

export enum BathroomOpEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Combined = 'COMBINED',
  Separate = 'SEPARATE'
}

export type OfferPlacementDraftOfferLayoutOpenPlan = {
  __typename?: 'OfferPlacementDraftOfferLayoutOpenPlan';
  rooms: RoomsExtendedGqlEnum;
  livingArea?: Maybe<Scalars['Float']>;
  kitchenArea?: Maybe<Scalars['Float']>;
  bathrooms?: Maybe<Array<Maybe<OfferPlacementDraftOfferBathroom>>>;
};

export type OfferPlacementDraftOfferLayoutStudio = {
  __typename?: 'OfferPlacementDraftOfferLayoutStudio';
  rooms: RoomsExtendedGqlEnum;
  bathrooms?: Maybe<Array<Maybe<OfferPlacementDraftOfferBathroom>>>;
};

export type OfferPlacementDraftOfferLayoutUnknown = {
  __typename?: 'OfferPlacementDraftOfferLayoutUnknown';
  rooms: RoomsExtendedGqlEnum;
};

export type OfferPlacementDraftOfferBuilding = {
  __typename?: 'OfferPlacementDraftOfferBuilding';
  hasLift?: Maybe<Scalars['Boolean']>;
  floorsTotal?: Maybe<Scalars['Int']>;
  builtYear?: Maybe<Scalars['Int']>;
  wallsType?: Maybe<WallsTypeGqlEnum>;
};

export enum WallsTypeGqlEnum {
  Unknown = 'UNKNOWN',
  Monolith = 'MONOLITH',
  BrickMonolith = 'BRICK_MONOLITH',
  Brick = 'BRICK',
  Panel = 'PANEL',
  Block = 'BLOCK',
  Wood = 'WOOD'
}

export type OfferPlacementDraftOfferFeatures = {
  __typename?: 'OfferPlacementDraftOfferFeatures';
  hasBalcony?: Maybe<Scalars['Boolean']>;
  ceilingHeight?: Maybe<Scalars['Float']>;
  floor?: Maybe<Scalars['Int']>;
  renovation?: Maybe<RenovationTypeGqlEnum>;
  windows?: Maybe<WindowsOpgqlEnum>;
  livingFacilities?: Maybe<OfferPlacementDraftOfferLivingFacilities>;
};

export enum RenovationTypeGqlEnum {
  Unknown = 'UNKNOWN',
  Design = 'DESIGN',
  Euro = 'EURO',
  Need = 'NEED',
  Good = 'GOOD',
  Partial = 'PARTIAL',
  PreFine = 'PRE_FINE',
  Fine = 'FINE',
  Rough = 'ROUGH',
  WithFurniture = 'WITH_FURNITURE',
  PreFinishing = 'PRE_FINISHING'
}

export enum WindowsOpgqlEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Rear = 'REAR',
  Front = 'FRONT',
  Both = 'BOTH'
}

export type OfferPlacementDraftOfferLivingFacilities = {
  __typename?: 'OfferPlacementDraftOfferLivingFacilities';
  airConditioner?: Maybe<Scalars['Boolean']>;
  dishwasher?: Maybe<Scalars['Boolean']>;
  washingMachine?: Maybe<Scalars['Boolean']>;
  tv?: Maybe<Scalars['Boolean']>;
  internet?: Maybe<Scalars['Boolean']>;
  furniture?: Maybe<Scalars['Boolean']>;
  kitchenFurniture?: Maybe<Scalars['Boolean']>;
  fridge?: Maybe<Scalars['Boolean']>;
};

export type OfferPlacementDraftOfferInfrastructure = {
  __typename?: 'OfferPlacementDraftOfferInfrastructure';
  hasConcierge?: Maybe<Scalars['Boolean']>;
  isCloseTerritory?: Maybe<Scalars['Boolean']>;
};

export type OfferPlacementDraftOfferRentFeatures = {
  __typename?: 'OfferPlacementDraftOfferRentFeatures';
  tenantRequirements?: Maybe<OfferPlacementDraftOfferTenantRequirements>;
};

export type OfferPlacementDraftOfferTenantRequirements = {
  __typename?: 'OfferPlacementDraftOfferTenantRequirements';
  childrenAllowed?: Maybe<Scalars['Boolean']>;
  petsAllowed?: Maybe<Scalars['Boolean']>;
};

export type OfferPlacementDraftOfferApartment = {
  __typename?: 'OfferPlacementDraftOfferApartment';
  price?: Maybe<OfferPlacementDraftOfferTotalAreaPrice>;
  totalArea?: Maybe<OfferPlacementDraftOfferTotalArea>;
  layout?: Maybe<OfferPlacementDraftOfferLayout>;
  building?: Maybe<OfferPlacementDraftOfferBuilding>;
  features?: Maybe<OfferPlacementDraftOfferFeatures>;
  infrastructure?: Maybe<OfferPlacementDraftOfferInfrastructure>;
  rentFeatures?: Maybe<OfferPlacementDraftOfferRentFeatures>;
};

export type OfferPlacementDraftOfferTotalAreaPrice = {
  __typename?: 'OfferPlacementDraftOfferTotalAreaPrice';
  value: Scalars['Float'];
  /** Цена в за единицу площади */
  valuePerAreaUnit?: Maybe<Scalars['Float']>;
  areaUnit: AreaUnitEnum;
  currency: CurrencyGqlEnum;
};

export enum CurrencyGqlEnum {
  Unknown = 'UNKNOWN',
  Rub = 'RUB',
  Usd = 'USD',
  Eur = 'EUR'
}

export type OfferPlacementDraftOfferFlatRoom = {
  __typename?: 'OfferPlacementDraftOfferFlatRoom';
  totalArea?: Maybe<OfferPlacementDraftOfferTotalArea>;
  totalRoomsArea?: Maybe<OfferPlacementDraftOfferTotalRoomsArea>;
  layout?: Maybe<OfferPlacementDraftOfferLayoutPart>;
  building?: Maybe<OfferPlacementDraftOfferBuilding>;
  features?: Maybe<OfferPlacementDraftOfferFeatures>;
  infrastructure?: Maybe<OfferPlacementDraftOfferInfrastructure>;
  rentFeatures?: Maybe<OfferPlacementDraftOfferRentFeatures>;
};

export type OfferPlacementDraftOfferTotalRoomsArea = {
  __typename?: 'OfferPlacementDraftOfferTotalRoomsArea';
  area: Scalars['Float'];
  areaUnit?: Maybe<AreaUnitEnum>;
};

export type OfferPlacementDraftOfferLayoutPart = {
  __typename?: 'OfferPlacementDraftOfferLayoutPart';
  rooms: RoomsExtendedGqlEnum;
  roomAreas?: Maybe<Array<Maybe<Scalars['Float']>>>;
  livingArea?: Maybe<Scalars['Float']>;
  kitchenArea?: Maybe<Scalars['Float']>;
  bathrooms?: Maybe<Array<Maybe<OfferPlacementDraftOfferBathroom>>>;
  roomsInOffer: RoomsExtendedGqlEnum;
  roomsInOfferAreas?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type OfferPlacementDraftOfferLand = {
  __typename?: 'OfferPlacementDraftOfferLand';
  landArea?: Maybe<OfferPlacementDraftOfferLandArea>;
  landStatus?: Maybe<LandStatusOptionsEnum>;
  landFeatures?: Maybe<OfferPlacementDraftOfferLandFeatures>;
};

export type OfferPlacementDraftOfferLandArea = {
  __typename?: 'OfferPlacementDraftOfferLandArea';
  area: Scalars['Float'];
  areaUnit: AreaUnitEnum;
};

export enum LandStatusOptionsEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Lph = 'LPH',
  Snt = 'SNT',
  Dnp = 'DNP',
  Izhs = 'IZHS'
}

export type OfferPlacementDraftOfferLandFeatures = {
  __typename?: 'OfferPlacementDraftOfferLandFeatures';
  gas?: Maybe<Scalars['Boolean']>;
  water?: Maybe<Scalars['Boolean']>;
  electricity?: Maybe<Scalars['Boolean']>;
  sewerage?: Maybe<Scalars['Boolean']>;
};

export type OfferPlacementDraftOfferHouse = {
  __typename?: 'OfferPlacementDraftOfferHouse';
  totalArea?: Maybe<OfferPlacementDraftOfferTotalArea>;
  construction?: Maybe<ConstructionOpEnum>;
  builtYear?: Maybe<Scalars['Int']>;
  floorsTotal?: Maybe<Scalars['Int']>;
  houseRooms?: Maybe<Scalars['Int']>;
  landArea?: Maybe<OfferPlacementDraftOfferLandArea>;
  landStatus?: Maybe<LandStatusOptionsEnum>;
  landFeatures?: Maybe<OfferPlacementDraftOfferLandFeatures>;
  houseFeatures?: Maybe<OfferPlacementDraftOfferHouseFeatures>;
  rentFeatures?: Maybe<OfferPlacementDraftOfferRentFeatures>;
};

export enum ConstructionOpEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Standalone = 'STANDALONE',
  PartOfHouse = 'PART_OF_HOUSE',
  Duplex = 'DUPLEX',
  Townhouse = 'TOWNHOUSE'
}

export type OfferPlacementDraftOfferHouseFeatures = {
  __typename?: 'OfferPlacementDraftOfferHouseFeatures';
  heating?: Maybe<HeatingOpEnum>;
  houseBathroom?: Maybe<HouseBathroomOpEnum>;
  livingFacilities?: Maybe<OfferPlacementDraftOfferLivingFacilities>;
};

export enum HeatingOpEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  WithoutHeating = 'WITHOUT_HEATING',
  AutonomousGas = 'AUTONOMOUS_GAS',
  CentralGas = 'CENTRAL_GAS',
  CentralCoal = 'CENTRAL_COAL',
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  Fireplace = 'FIREPLACE',
  SolidFuelBoiler = 'SOLID_FUEL_BOILER',
  Stove = 'STOVE'
}

export enum HouseBathroomOpEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Inside = 'INSIDE',
  Outside = 'OUTSIDE'
}

export enum DealTypeOpEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Sell = 'SELL',
  Rent = 'RENT'
}

export type OfferPlacementDraftOfferDeal = OfferPlacementDraftOfferDealRent | OfferPlacementDraftOfferDealSell;

export type OfferPlacementDraftOfferDealRent = {
  __typename?: 'OfferPlacementDraftOfferDealRent';
  price?: Maybe<OfferPlacementDraftOfferRentPrice>;
  prepayment?: Maybe<Scalars['Int']>;
  deposit?: Maybe<OfferPlacementDraftOfferDeposit>;
  advertiser?: Maybe<OfferPlacementDraftOfferAdvertiser>;
  communalPayments?: Maybe<OfferPlacementDraftOfferCommunalPayments>;
};

export type OfferPlacementDraftOfferRentPrice = {
  __typename?: 'OfferPlacementDraftOfferRentPrice';
  value: Scalars['Float'];
  currency: CurrencyGqlEnum;
};

export type OfferPlacementDraftOfferDeposit = {
  __typename?: 'OfferPlacementDraftOfferDeposit';
  value?: Maybe<Scalars['Float']>;
  currency: CurrencyGqlEnum;
  depositFree: Scalars['Boolean'];
};

export type OfferPlacementDraftOfferAdvertiser = {
  __typename?: 'OfferPlacementDraftOfferAdvertiser';
  agent?: Maybe<OfferPlacementDraftOfferAdvertiserAgent>;
};

export type OfferPlacementDraftOfferAdvertiserAgent = {
  __typename?: 'OfferPlacementDraftOfferAdvertiserAgent';
  commissionClient?: Maybe<OfferPlacementDraftOfferCommission>;
  commissionAgent?: Maybe<OfferPlacementDraftOfferCommission>;
};

export type OfferPlacementDraftOfferCommission = {
  __typename?: 'OfferPlacementDraftOfferCommission';
  value?: Maybe<Scalars['Float']>;
  commissionFree?: Maybe<Scalars['Boolean']>;
};

export type OfferPlacementDraftOfferCommunalPayments = {
  __typename?: 'OfferPlacementDraftOfferCommunalPayments';
  value?: Maybe<Scalars['Float']>;
  currency?: Maybe<CurrencyGqlEnum>;
  included?: Maybe<Scalars['Boolean']>;
};

export type OfferPlacementDraftOfferDealSell = {
  __typename?: 'OfferPlacementDraftOfferDealSell';
  price?: Maybe<OfferPlacementDraftOfferSellPrice>;
};

export type OfferPlacementDraftOfferSellPrice = OfferPlacementDraftOfferFlatRoomPrice | OfferPlacementDraftOfferTotalAreaPrice | OfferPlacementDraftOfferLandPrice;

export type OfferPlacementDraftOfferFlatRoomPrice = {
  __typename?: 'OfferPlacementDraftOfferFlatRoomPrice';
  value: Scalars['Float'];
  /** Цена в за единицу площади */
  valuePerAreaUnit?: Maybe<Scalars['Float']>;
  areaUnit: AreaUnitEnum;
  currency: CurrencyGqlEnum;
};

export type OfferPlacementDraftOfferLandPrice = {
  __typename?: 'OfferPlacementDraftOfferLandPrice';
  value: Scalars['Float'];
  /** Цена в за единицу площади */
  valuePerAreaUnit?: Maybe<Scalars['Float']>;
  areaUnit: AreaUnitEnum;
  currency: CurrencyGqlEnum;
};

export type OfferPlacementDraftOfferImage = {
  __typename?: 'OfferPlacementDraftOfferImage';
  pathPrefix?: Maybe<Scalars['String']>;
};

export type OfferPlacementDraftOfferLocation = {
  __typename?: 'OfferPlacementDraftOfferLocation';
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  geoId?: Maybe<Scalars['String']>;
  point?: Maybe<OfferPlacementDraftOfferCoords>;
  regionId?: Maybe<Scalars['Int']>;
};

export type OfferPlacementDraftOfferCoords = {
  __typename?: 'OfferPlacementDraftOfferCoords';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

export type OfferPlacementDraftOfferSeller = {
  __typename?: 'OfferPlacementDraftOfferSeller';
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  participant?: Maybe<OfferPlacementDraftOfferParticipant>;
  agency?: Maybe<OfferPlacementDraftOfferSellerAgency>;
};

/** Информация о продавце */
export type OfferPlacementDraftOfferParticipant = OfferPlacementDraftOfferIndividual | OfferPlacementDraftOfferAgency | OfferPlacementDraftOfferAgent | OfferPlacementDraftOfferPit;

export type OfferPlacementDraftOfferIndividual = {
  __typename?: 'OfferPlacementDraftOfferIndividual';
  name?: Maybe<Scalars['String']>;
};

export type OfferPlacementDraftOfferAgency = {
  __typename?: 'OfferPlacementDraftOfferAgency';
  name?: Maybe<Scalars['String']>;
};

export type OfferPlacementDraftOfferAgent = {
  __typename?: 'OfferPlacementDraftOfferAgent';
  name?: Maybe<Scalars['String']>;
};

export type OfferPlacementDraftOfferPit = {
  __typename?: 'OfferPlacementDraftOfferPit';
  name?: Maybe<Scalars['String']>;
};

export type OfferPlacementDraftOfferSellerAgency = {
  __typename?: 'OfferPlacementDraftOfferSellerAgency';
  title: Scalars['String'];
};

export type PlacementOfferAbstractOptions = {
  __typename?: 'PlacementOfferAbstractOptions';
  /** Онлайн просмотр */
  onlineView: Scalars['Boolean'];
};

export enum BffErrosEnum {
  NotEnoughParameters = 'NOT_ENOUGH_PARAMETERS',
  EmptyPhone = 'EMPTY_PHONE',
  EmptyEmail = 'EMPTY_EMAIL',
  NotAuthorized = 'NOT_AUTHORIZED',
  NotFound = 'NOT_FOUND'
}

export enum GetOffersErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOffset = 'INVALID_OFFSET',
  InvalidLimit = 'INVALID_LIMIT',
  InvalidLimitRange = 'INVALID_LIMIT_RANGE'
}

export type PlacementPagingInput = {
  pageSize?: Scalars['Int'];
  pageNumber?: Scalars['Int'];
};

export type GetPlacementOffer = {
  __typename?: 'GetPlacementOffer';
  item?: Maybe<PlacementOffer>;
  bffError?: Maybe<BffErrosEnum>;
  errors?: Maybe<Array<GetOfferErrorEnum>>;
};

export enum GetOfferErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOfferId = 'INVALID_OFFER_ID'
}

export type GetWalletBalance = {
  __typename?: 'GetWalletBalance';
  balance?: Maybe<Scalars['Float']>;
};

export type GetPrices = {
  __typename?: 'GetPrices';
  list?: Maybe<Array<PlacementDaysPrice>>;
  bffError?: Maybe<BffErrosEnum>;
};

export type PlacementDaysPrice = {
  __typename?: 'PlacementDaysPrice';
  /** Days for vas */
  daysAmount: Scalars['Float'];
  /** Discount in percents for days amount */
  daysQuantityDiscountPercent: Scalars['Float'];
  /** Vas till date */
  dateTill?: Maybe<LocalDateOld>;
  /**
   * Base price per one offer for days_amount
   * @deprecated Deprecated
   */
  offerBasePrice: Scalars['Float'];
  /**
   * Price per one offer for days_amount with days_discount applied
   * @deprecated Deprecated
   */
  offerDiscountPrice: Scalars['Float'];
  /** Number of offers */
  offersAmount: Scalars['Float'];
  /** Discount in percents for offers quantity */
  offersQuantityDiscountPercent: Scalars['Float'];
  /** Discount in percents for unique users. Used only for testing purposes. */
  offersUniqueDiscountPercent: Scalars['Float'];
  /** Total price without discounts */
  totalBasePrice: Scalars['Float'];
  /** Total price with discounts applied */
  totalPrice: Scalars['Float'];
  /** Discount in rubles for offers quantity */
  offersQuantityDiscount: Scalars['Float'];
  /** Discount in rubles for packet usage */
  offersPacketDiscount: Scalars['Float'];
  /** Discount in percents for packet usage */
  offersPacketDiscountPercent: Scalars['Float'];
  /** Price for offers for days_amount with days_discount applied */
  offersDaysDiscountPrice: Scalars['Float'];
  promocodeInfo?: Maybe<PlacementPromocodeInfo>;
};

export type LocalDateOld = {
  __typename?: 'LocalDateOld';
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type PlacementPromocodeInfo = {
  __typename?: 'PlacementPromocodeInfo';
  success: Scalars['Boolean'];
  status: PlacementPromocodeInfoEnum;
  discountPercent?: Maybe<Scalars['Float']>;
  productPrefix?: Maybe<Scalars['String']>;
};

export enum PlacementPromocodeInfoEnum {
  SealedValueNotSet = 'SEALED_VALUE_NOT_SET',
  Success = 'SUCCESS',
  Expired = 'EXPIRED',
  InvalidProduct = 'INVALID_PRODUCT',
  LimitReached = 'LIMIT_REACHED',
  NotFound = 'NOT_FOUND'
}

export type OrderOfferVasOfferParam = {
  offerId: Scalars['ID'];
  regionId: Scalars['Int'];
};

export enum BuyableVasGqlEnum {
  SealedValueOptionalNotSet = 'SEALED_VALUE_OPTIONAL_NOT_SET',
  Top = 'TOP',
  Highlight = 'HIGHLIGHT'
}

export type GetPricesAll = {
  __typename?: 'GetPricesAll';
  top?: Maybe<Array<PlacementDaysPrice>>;
  highlight?: Maybe<Array<PlacementDaysPrice>>;
  topHighlight?: Maybe<Array<PlacementDaysPrice>>;
  bffError?: Maybe<BffErrosEnum>;
};

export type GetQuantityDiscount = {
  __typename?: 'GetQuantityDiscount';
  list?: Maybe<Array<QuantityDiscountPercent>>;
  bffError?: Maybe<BffErrosEnum>;
};

export type QuantityDiscountPercent = {
  __typename?: 'QuantityDiscountPercent';
  quantity: Scalars['Float'];
  discountPercent: Scalars['Float'];
};

export type CompanyPreferencesResponse = InternalError | CompanyPreferences;

export type InternalError = BaseError & {
  __typename?: 'InternalError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ShellConfig = {
  __typename?: 'ShellConfig';
  cdnUrl: Scalars['String'];
  supportPhone: Scalars['String'];
  copyright: Scalars['String'];
};

export type ShellConfig2 = {
  __typename?: 'ShellConfig2';
  cdnUrl: Scalars['String'];
  supportPhone: Scalars['String'];
  copyright: Scalars['String'];
  header?: Maybe<Header>;
  sidebar?: Maybe<Sidebar>;
  footer: Footer;
  yandexCaptcha: CaptchaType;
  features?: Maybe<Array<FeatureType>>;
  mortgageABTest: Scalars['String'];
};

export type CaptchaType = {
  __typename?: 'CaptchaType';
  clientKey: Scalars['String'];
};

export type FeatureType = {
  __typename?: 'FeatureType';
  name: Scalars['String'];
};

export type HoosegowRootResponse = HoosegowRootSuccess | HoosegowRootFailure;

export type HoosegowRootSuccess = {
  __typename?: 'HoosegowRootSuccess';
  /** Текущий модератор */
  currentOfficer?: Maybe<HoosegowOfficerResponse>;
  /** Модератор по id */
  officer?: Maybe<HoosegowOfficerResponse>;
  /** Поиск модераторов */
  officers: HoosegowOfficersListResponseResponse;
  /** Поиск задач */
  searchTasks: HoosegowSearchTasksListResponse;
  /** Список банков */
  bankList?: Maybe<HoosegowBankList>;
  /** Проверка документов */
  shawshankTasks: HoosegowShawshankTasksResponse;
  /** Поиск задач */
  paroleTasksSending: HoosegowParoleTasksSendingResponse;
  /** Поиск задач */
  paroleTasksAnswer: HoosegowParoleTasksAnswerResponse;
  /** Количество задач по проверке документов */
  shawshankPersonTasksCount?: Maybe<HoosegowCount>;
  /** Количество задач по проверке документов */
  shawshankPropertyTasksCount?: Maybe<HoosegowCount>;
  /** Количество задач по отправке в банк */
  paroleTasksCountSending: HoosegowParoleTasksCountSendingResponse;
  /** Количество задач по отправке в банк */
  paroleTasksCountPropertySending: HoosegowParoleTasksCountSendingPropertyResponse;
  /** Количество задач по ответу от банка */
  paroleTasksCountAnswer: HoosegowParoleTasksCountAnswerResponse;
  /** Экспорт в CSV */
  paroleExport: HoosegowParoleTasksExportResponse;
  /** Задача проверки документов */
  documentVerificationTask: HoosegowDocumentVerificationTaskResponse;
  /** Задача проверки документов */
  bankTask: HoosegowBankTaskResponse;
  /** Причины доработок */
  reworkReasons: Array<HoosegowReworkReasonItem>;
  /** Поиск задач */
  paroleRelatedTasks: Array<HoosegowTaskParole>;
};


export type HoosegowRootSuccessOfficerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type HoosegowRootSuccessOfficersArgs = {
  filters?: InputMaybe<HoosegowOfficersFilterInput>;
  paging: PagingInput;
};


export type HoosegowRootSuccessSearchTasksArgs = {
  filters: HoosegowSearchTasksFilterInput;
  sort?: InputMaybe<HoosegowSearchTasksSortInput>;
  paging: PagingInput;
};


export type HoosegowRootSuccessShawshankTasksArgs = {
  filters?: InputMaybe<HoosegowShawshankTasksFilterInput>;
  sort?: InputMaybe<HoosegowSearchTasksSortInput>;
  paging: PagingInput;
};


export type HoosegowRootSuccessParoleTasksSendingArgs = {
  filters?: InputMaybe<HoosegowParoleTasksFilterInput>;
  sort?: InputMaybe<HoosegowSearchTasksSortInput>;
  paging: PagingInput;
};


export type HoosegowRootSuccessParoleTasksAnswerArgs = {
  filters?: InputMaybe<HoosegowParoleTasksFilterInput>;
  sort?: InputMaybe<HoosegowSearchTasksSortInput>;
  paging: PagingInput;
};


export type HoosegowRootSuccessParoleExportArgs = {
  taskIds: Array<Scalars['ID']>;
};


export type HoosegowRootSuccessDocumentVerificationTaskArgs = {
  id: Scalars['ID'];
};


export type HoosegowRootSuccessBankTaskArgs = {
  id: Scalars['ID'];
};


export type HoosegowRootSuccessParoleRelatedTasksArgs = {
  taskId: Scalars['String'];
};

export type HoosegowOfficerResponse = NotFoundError | InternalError | HoosegowOfficer | AuthUserIdError;

export type NotFoundError = BaseError & {
  __typename?: 'NotFoundError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type HoosegowOfficer = Node & {
  __typename?: 'HoosegowOfficer';
  /** id для relay */
  id: Scalars['ID'];
  officerId: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  authUserId?: Maybe<Scalars['String']>;
  status?: Maybe<HoosegowOfficerStatusEnum>;
  contacts?: Maybe<HoosegowOfficerContact>;
  city?: Maybe<Scalars['String']>;
};

export enum HoosegowOfficerStatusEnum {
  UnknownStatus = 'UNKNOWN_STATUS',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type HoosegowOfficerContact = {
  __typename?: 'HoosegowOfficerContact';
  telegram?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AuthUserIdError = BaseError & {
  __typename?: 'AuthUserIdError';
  message?: Maybe<Scalars['String']>;
};

export type HoosegowOfficersListResponseResponse = HoosegowOfficersListResponseSuccess | HoosegowOfficersListResponseFailure;

export type HoosegowOfficersListResponseSuccess = {
  __typename?: 'HoosegowOfficersListResponseSuccess';
  list?: Maybe<Array<HoosegowOfficer>>;
  paging: Paging;
};

export type HoosegowOfficersListResponseFailure = {
  __typename?: 'HoosegowOfficersListResponseFailure';
  error: HoosegowOfficersListFailureError;
  traceId: Scalars['String'];
};

export type HoosegowOfficersListFailureError = HoosegowOfficerFailureErrorInvalidParam | HoosegowOfficerFailureErrorUnknown;

export type HoosegowOfficerFailureErrorInvalidParam = {
  __typename?: 'HoosegowOfficerFailureErrorInvalidParam';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowOfficerFailureErrorUnknown = {
  __typename?: 'HoosegowOfficerFailureErrorUnknown';
  message: Scalars['String'];
  code: Scalars['String'];
};

/** Набор возможных фильтров для поиска модераторов */
export type HoosegowOfficersFilterInput = {
  search?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<HoosegowOfficersSearchTypeEnum>;
  officeType?: InputMaybe<HoosegowOfficersOfficeTypeEnum>;
  authUserIdsList?: InputMaybe<Array<Scalars['String']>>;
  statusesList?: InputMaybe<Array<HoosegowOfficerStatusEnum>>;
  contactsList?: InputMaybe<Array<HoosegowOfficersFilterContactInput>>;
  lastNamesList?: InputMaybe<Array<Scalars['String']>>;
};

export enum HoosegowOfficersSearchTypeEnum {
  Fio = 'FIO',
  Shift = 'SHIFT'
}

export enum HoosegowOfficersOfficeTypeEnum {
  BackOffice = 'BACK_OFFICE',
  MidleOffice = 'MIDLE_OFFICE'
}

export type HoosegowOfficersFilterContactInput = {
  telegram?: InputMaybe<Scalars['String']>;
  mattermost?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
};

export type HoosegowSearchTasksListResponse = HoosegowSearchTasksListSuccess | HoosegowSearchTasksListFailure;

export type HoosegowSearchTasksListSuccess = {
  __typename?: 'HoosegowSearchTasksListSuccess';
  list?: Maybe<Array<Maybe<HoosegowSearchTasks>>>;
  paging: Paging;
};

export type HoosegowSearchTasks = HoosegowTaskShawshankPerson | HoosegowTaskShawshankProperty | HoosegowTaskParole;

export type HoosegowTaskShawshankPerson = Node & HoosegowTaskShawshankInterface & {
  __typename?: 'HoosegowTaskShawshankPerson';
  id: Scalars['ID'];
  taskId: Scalars['ID'];
  /** Дата создания */
  createdTs: Date;
  /** Дата редактирования */
  modifiedTs?: Maybe<Date>;
  state: HoosegowTaskShawshankState;
  assignment?: Maybe<HoosegowTaskAssignment>;
  mortgage: HoosegowTaskMortgage;
  /** Проверяются данные заемщиков */
  client?: Maybe<HoosegowTaskShawshankSubjectPerson>;
  /** @deprecated Используй client */
  subject?: Maybe<HoosegowTaskShawshankSubject>;
  history?: Maybe<HoosegowTaskShawshankHistory>;
  /** Проверка документов до их отправки в банк */
  verification?: Maybe<HoosegowTaskShawshankPersonVerification>;
  taskName: Scalars['String'];
};

export type HoosegowTaskShawshankInterface = {
  id: Scalars['ID'];
  taskId: Scalars['ID'];
  /** Дата создания */
  createdTs: Date;
  /** Дата редактирования */
  modifiedTs?: Maybe<Date>;
  state: HoosegowTaskShawshankState;
  assignment?: Maybe<HoosegowTaskAssignment>;
  mortgage: HoosegowTaskMortgage;
  history?: Maybe<HoosegowTaskShawshankHistory>;
  taskName: Scalars['String'];
};

export type HoosegowTaskShawshankState = HoosegowTaskShawshankStatePending | HoosegowTaskShawshankStateCompleted;

export type HoosegowTaskShawshankStatePending = {
  __typename?: 'HoosegowTaskShawshankStatePending';
  pending: Scalars['Boolean'];
  label: Scalars['String'];
  text: Scalars['String'];
};

export type HoosegowTaskShawshankStateCompleted = {
  __typename?: 'HoosegowTaskShawshankStateCompleted';
  verdict?: Maybe<HoosegowTaskShawshankStateCompletedVerdict>;
};

export type HoosegowTaskShawshankStateCompletedVerdict = HoosegowTaskShawshankStateCompletedVerdictValid | HoosegowTaskShawshankStateCompletedVerdictRework | HoosegowTaskShawshankStateCompletedVerdictArchive;

export type HoosegowTaskShawshankStateCompletedVerdictValid = {
  __typename?: 'HoosegowTaskShawshankStateCompletedVerdictValid';
  label: Scalars['String'];
  text: Scalars['String'];
};

export type HoosegowTaskShawshankStateCompletedVerdictRework = {
  __typename?: 'HoosegowTaskShawshankStateCompletedVerdictRework';
  comment?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  text: Scalars['String'];
};

export type HoosegowTaskShawshankStateCompletedVerdictArchive = {
  __typename?: 'HoosegowTaskShawshankStateCompletedVerdictArchive';
  comment?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  text: Scalars['String'];
};

export type HoosegowTaskAssignment = {
  __typename?: 'HoosegowTaskAssignment';
  officer?: Maybe<HoosegowOfficer>;
  /** Дата назначения */
  assignmentTs?: Maybe<Date>;
  /** Назначена ли задача на текущего пользователя */
  isAssignedOnCurrentUser: Scalars['Boolean'];
};

/** Данные ипотечной заявки */
export type HoosegowTaskMortgage = {
  __typename?: 'HoosegowTaskMortgage';
  id: Scalars['ID'];
  mortgageId: Scalars['ID'];
  /** Регион недвижимости */
  region?: Maybe<BackofficeRegion>;
  /** Регион проведения сделки */
  dealRegion?: Maybe<BackofficeRegion>;
  purpose: HoosegowMortgagePurposeEnum;
  purposeText?: Maybe<Scalars['String']>;
  role: HoosegowMortgageRoleEnum;
  borrower?: Maybe<HoosegowTaskMortgageBorrower>;
  partner?: Maybe<HoosegowTaskMortgagePartner>;
  additionalInfo?: Maybe<Scalars['String']>;
  /** Ссылка на заявку в ИБ */
  link: Scalars['String'];
  source: HoosegowMortgageSourceEnum;
  copiedFromId: Scalars['String'];
  /** Ссылка на родительскую заявку в ИБ */
  copiedFromIdlink: Scalars['String'];
};

/** Регион из бэкофиса */
export type BackofficeRegion = {
  __typename?: 'BackofficeRegion';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export enum HoosegowMortgagePurposeEnum {
  UnknownPurpose = 'UNKNOWN_PURPOSE',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Refinance = 'REFINANCE',
  Php = 'PHP',
  Family = 'FAMILY'
}

export enum HoosegowMortgageRoleEnum {
  UnknownRole = 'UNKNOWN_ROLE',
  Individual = 'INDIVIDUAL',
  Employee = 'EMPLOYEE',
  Developer = 'DEVELOPER',
  Partner = 'PARTNER',
  Realtor = 'REALTOR',
  Professional = 'PROFESSIONAL'
}

export type HoosegowTaskMortgageBorrower = {
  __typename?: 'HoosegowTaskMortgageBorrower';
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['String']>;
};

export type HoosegowTaskMortgagePartner = {
  __typename?: 'HoosegowTaskMortgagePartner';
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
};

export enum HoosegowMortgageSourceEnum {
  UnknownSource = 'UNKNOWN_SOURCE',
  WebLk = 'WEB_LK',
  MobileApp = 'MOBILE_APP',
  BankiRu = 'BANKI_RU',
  ComfortSearchM2 = 'COMFORT_SEARCH_M2',
  SravniRu = 'SRAVNI_RU',
  WebLanding = 'WEB_LANDING',
  Novostroiky = 'NOVOSTROIKY',
  Zbs = 'ZBS',
  Avito = 'AVITO',
  DealConstructor = 'DEAL_CONSTRUCTOR',
  ExternalApi = 'EXTERNAL_API',
  DealOnline = 'DEAL_ONLINE'
}

/** История изменений задачи */
export type HoosegowTaskShawshankHistory = {
  __typename?: 'HoosegowTaskShawshankHistory';
  stepsList: Array<HoosegowTaskShawshankHistoryStep>;
};

export type HoosegowTaskShawshankHistoryStep = {
  __typename?: 'HoosegowTaskShawshankHistoryStep';
  processingTs?: Maybe<Date>;
  officerId?: Maybe<Scalars['Int']>;
  officer?: Maybe<HoosegowOfficer>;
  event?: Maybe<HoosegowTaskShawshankHistoryStepEvent>;
};

export type HoosegowTaskShawshankHistoryStepEvent = HoosegowTaskShawshankHistoryStepEventAssigned | HoosegowTaskShawshankHistoryStepEventUnassigned | HoosegowTaskShawshankHistoryStepEventClosed | HoosegowTaskShawshankHistoryStepEventReopened;

export type HoosegowTaskShawshankHistoryStepEventAssigned = {
  __typename?: 'HoosegowTaskShawshankHistoryStepEventAssigned';
  assigneeId?: Maybe<Scalars['Int']>;
  officer?: Maybe<HoosegowOfficer>;
  label: Scalars['String'];
};

export type HoosegowTaskShawshankHistoryStepEventUnassigned = {
  __typename?: 'HoosegowTaskShawshankHistoryStepEventUnassigned';
  comment?: Maybe<Scalars['String']>;
  label: Scalars['String'];
};

export type HoosegowTaskShawshankHistoryStepEventClosed = {
  __typename?: 'HoosegowTaskShawshankHistoryStepEventClosed';
  verdict?: Maybe<HoosegowTaskShawshankStateCompletedVerdict>;
};

export type HoosegowTaskShawshankHistoryStepEventReopened = {
  __typename?: 'HoosegowTaskShawshankHistoryStepEventReopened';
  comment?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  text: Scalars['String'];
};

export type HoosegowTaskShawshankSubjectPerson = {
  __typename?: 'HoosegowTaskShawshankSubjectPerson';
  lastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  patronymic?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['Int']>;
  borrowerType?: Maybe<HoosegowBorrowerTypeEnum>;
  borrowerTypeLabel?: Maybe<Scalars['String']>;
};

export enum HoosegowBorrowerTypeEnum {
  UnknownBorrowerType = 'UNKNOWN_BORROWER_TYPE',
  Borrower = 'BORROWER',
  Coborrower = 'COBORROWER'
}

export type HoosegowTaskShawshankSubject = {
  __typename?: 'HoosegowTaskShawshankSubject';
  person?: Maybe<HoosegowTaskShawshankSubjectPerson>;
  property?: Maybe<Scalars['Boolean']>;
};

export type HoosegowTaskShawshankPersonVerification = {
  __typename?: 'HoosegowTaskShawshankPersonVerification';
  banks: Array<HoosegowBank>;
  /** Текущий этап обработки задачи */
  processingStep?: Maybe<HoosegowProcessingStepEnum>;
  /** Проверяемый документ */
  document?: Maybe<HoosegowTaskShawshankDocument>;
};

export type HoosegowBank = Node & {
  __typename?: 'HoosegowBank';
  id: Scalars['ID'];
  mnemonic: Scalars['String'];
  name: Scalars['String'];
  iconUrl: Scalars['String'];
};

export enum HoosegowProcessingStepEnum {
  UnknownStep = 'UNKNOWN_STEP',
  Initial = 'INITIAL',
  Rework = 'REWORK'
}

export type HoosegowTaskShawshankDocument = {
  __typename?: 'HoosegowTaskShawshankDocument';
  id: Scalars['ID'];
  type: EnumTextField;
  /** Изначально загруженные файлы */
  initialFiles?: Maybe<HoosegowTaskShawshankDocumentInitialFiles>;
  /** Список доработок по запросу модератора */
  reworks?: Maybe<Array<HoosegowTaskShawshankDocumentRework>>;
};

/** Примитивный тип для прокидывания значений и локализации в структурированном виде */
export type EnumTextField = {
  __typename?: 'EnumTextField';
  label: Scalars['String'];
  value: HoosegowDocumentTypeEnum;
};

export enum HoosegowDocumentTypeEnum {
  UnknownDocumentType = 'UNKNOWN_DOCUMENT_TYPE',
  Personal = 'PERSONAL',
  SpousePassport = 'SPOUSE_PASSPORT',
  ForeignPassport = 'FOREIGN_PASSPORT',
  Snils = 'SNILS',
  Inn = 'INN',
  Ndfl2 = 'NDFL2',
  BankIncomesStatement = 'BANK_INCOMES_STATEMENT',
  TaxDeclaration = 'TAX_DECLARATION',
  FinanceFlow = 'FINANCE_FLOW',
  FinanceTurnovers = 'FINANCE_TURNOVERS',
  CompanyInfo = 'COMPANY_INFO',
  EmploymentHistory = 'EMPLOYMENT_HISTORY',
  MilitaryInfo = 'MILITARY_INFO',
  Military = 'MILITARY',
  Residence = 'RESIDENCE',
  EmploymentAuth = 'EMPLOYMENT_AUTH',
  PerPersonMortgageFullForm = 'PER_PERSON_MORTGAGE_FULL_FORM',
  PersonalAgreement = 'PERSONAL_AGREEMENT',
  TitleDocuments = 'TITLE_DOCUMENTS',
  Bsa = 'BSA',
  Ddu = 'DDU',
  AcceptanceCertificate = 'ACCEPTANCE_CERTIFICATE',
  NonGratuitousActConfirmationDocuments = 'NON_GRATUITOUS_ACT_CONFIRMATION_DOCUMENTS',
  HouseRegisterExtract = 'HOUSE_REGISTER_EXTRACT',
  RegisteredResidentPassport = 'REGISTERED_RESIDENT_PASSPORT',
  MarriageRelatedDocuments = 'MARRIAGE_RELATED_DOCUMENTS',
  NoOtherHeirsConfirmation = 'NO_OTHER_HEIRS_CONFIRMATION',
  UtilityServicesPaymentConfirmation = 'UTILITY_SERVICES_PAYMENT_CONFIRMATION',
  PassportDocuments = 'PASSPORT_DOCUMENTS',
  ChildProtectionServicesDocuments = 'CHILD_PROTECTION_SERVICES_DOCUMENTS',
  AppraisalReport = 'APPRAISAL_REPORT',
  Egrn = 'EGRN',
  MskUnitedHousingDocument = 'MSK_UNITED_HOUSING_DOCUMENT',
  SpbForm_7 = 'SPB_FORM_7',
  TechPassportBti = 'TECH_PASSPORT_BTI',
  CadastralPassport = 'CADASTRAL_PASSPORT',
  MortgageAgreement = 'MORTGAGE_AGREEMENT',
  MortgageRemainingAmountConfirmation = 'MORTGAGE_REMAINING_AMOUNT_CONFIRMATION',
  ClientBankAccountInfoExtract = 'CLIENT_BANK_ACCOUNT_INFO_EXTRACT',
  RepaymentSchedule = 'REPAYMENT_SCHEDULE',
  AdditionalDocuments = 'ADDITIONAL_DOCUMENTS',
  TemporaryRegistration = 'TEMPORARY_REGISTRATION',
  MortgageFullArchive = 'MORTGAGE_FULL_ARCHIVE',
  PropertyDocumentsArchive = 'PROPERTY_DOCUMENTS_ARCHIVE',
  MortgageFormPaperView = 'MORTGAGE_FORM_PAPER_VIEW',
  AbstractDocumentSet = 'ABSTRACT_DOCUMENT_SET',
  ChildrenCertificateOfBirth = 'CHILDREN_CERTIFICATE_OF_BIRTH',
  FederalRetirementBenefitStatement = 'FEDERAL_RETIREMENT_BENEFIT_STATEMENT',
  PensionerIdCertificate = 'PENSIONER_ID_CERTIFICATE',
  ClientBankAccountOperationsExtract = 'CLIENT_BANK_ACCOUNT_OPERATIONS_EXTRACT',
  MaternityCapitalDocuments = 'MATERNITY_CAPITAL_DOCUMENTS',
  ChildrenDisabilityConfirmation = 'CHILDREN_DISABILITY_CONFIRMATION',
  SelfEmployedTaxesInfo = 'SELF_EMPLOYED_TAXES_INFO',
  SelfEmployedInfo = 'SELF_EMPLOYED_INFO',
  Egrip = 'EGRIP',
  Egrul = 'EGRUL',
  Approval = 'APPROVAL'
}

export type HoosegowTaskShawshankDocumentInitialFiles = {
  __typename?: 'HoosegowTaskShawshankDocumentInitialFiles';
  invalidFiles: Array<HoosegowShawshankFile>;
  files: Array<HoosegowShawshankFile>;
  /** Комментарий заемщика */
  comment?: Maybe<Scalars['String']>;
};

export type HoosegowShawshankFile = Node & HoosegowFileInterface & {
  __typename?: 'HoosegowShawshankFile';
  isInvalid: Scalars['Boolean'];
  id: Scalars['ID'];
  downloadUrl: Scalars['String'];
  /** Будет только у картинок */
  image?: Maybe<Image2>;
  documentView: Scalars['String'];
  name: Scalars['String'];
  sizeInMb: Scalars['String'];
  ext: Scalars['String'];
  uploadedTs?: Maybe<Date>;
  createdTs?: Maybe<Date>;
};

export type HoosegowFileInterface = {
  id: Scalars['ID'];
  /** Будет только у картинок */
  image?: Maybe<Image2>;
  /** Ссылка на скачивание файла */
  downloadUrl: Scalars['String'];
  /** Ссылка на открытие файла */
  documentView: Scalars['String'];
  name: Scalars['String'];
  sizeInMb: Scalars['String'];
  ext: Scalars['String'];
  uploadedTs?: Maybe<Date>;
};

export type HoosegowTaskShawshankDocumentRework = {
  __typename?: 'HoosegowTaskShawshankDocumentRework';
  invalidFiles: Array<HoosegowShawshankFile>;
  /** Список файлов по доработке */
  files: Array<HoosegowShawshankFile>;
  /** Комментарий заемщика */
  comment?: Maybe<Scalars['String']>;
};

export type HoosegowTaskShawshankProperty = Node & HoosegowTaskShawshankInterface & {
  __typename?: 'HoosegowTaskShawshankProperty';
  id: Scalars['ID'];
  taskId: Scalars['ID'];
  /** Дата создания */
  createdTs: Date;
  /** Дата редактирования */
  modifiedTs?: Maybe<Date>;
  state: HoosegowTaskShawshankState;
  assignment?: Maybe<HoosegowTaskAssignment>;
  mortgage: HoosegowTaskMortgage;
  subject?: Maybe<HoosegowTaskShawshankSubject>;
  history?: Maybe<HoosegowTaskShawshankHistory>;
  verification?: Maybe<HoosegowTaskShawshankPropertyVerification>;
  taskName: Scalars['String'];
};

export type HoosegowTaskShawshankPropertyVerification = {
  __typename?: 'HoosegowTaskShawshankPropertyVerification';
  /** Данные банка */
  bank?: Maybe<HoosegowBank>;
  /** Список выполненных доработок по запросу банка */
  bankReworksList: Array<HoosegowTaskShawshankPropertyVerificationBankRework>;
};

export type HoosegowTaskShawshankPropertyVerificationBankRework = {
  __typename?: 'HoosegowTaskShawshankPropertyVerificationBankRework';
  /** Список файлов по доработке банка */
  filesList: Array<HoosegowShawshankFile>;
  bankComment?: Maybe<Scalars['String']>;
  borrowerComment?: Maybe<Scalars['String']>;
};

export type HoosegowTaskParole = Node & {
  __typename?: 'HoosegowTaskParole';
  id: Scalars['ID'];
  state: HoosegowTaskParoleState;
  processingStep?: Maybe<HoosegowProcessingStepEnum>;
  mortgage: HoosegowTaskMortgage;
  bankRequest: HoosegowTaskBankRequestType;
  subject?: Maybe<HoosegowTaskParoleSubject>;
  createdTs: Date;
  modifiedTs?: Maybe<Date>;
  assignment?: Maybe<HoosegowTaskAssignment>;
  history?: Maybe<HoosegowTaskParoleHistory>;
  taskName: Scalars['String'];
};

export type HoosegowTaskParoleState = HoosegowTaskParoleStatePending | HoosegowTaskParoleStateWaiting | HoosegowTaskParoleStateCompleted | HoosegowTaskParoleStateCreated;

export type HoosegowTaskParoleStatePending = {
  __typename?: 'HoosegowTaskParoleStatePending';
  label: Scalars['String'];
};

export type HoosegowTaskParoleStateWaiting = {
  __typename?: 'HoosegowTaskParoleStateWaiting';
  label: Scalars['String'];
};

export type HoosegowTaskParoleStateCompleted = {
  __typename?: 'HoosegowTaskParoleStateCompleted';
  result: HoosegowTaskParoleStateCompletedResult;
};

export type HoosegowTaskParoleStateCompletedResult = HoosegowTaskParoleStateCompletedResultArchive | HoosegowTaskParoleStateCompletedResultResponseReceived;

export type HoosegowTaskParoleStateCompletedResultArchive = {
  __typename?: 'HoosegowTaskParoleStateCompletedResultArchive';
  comment?: Maybe<Scalars['String']>;
  label: Scalars['String'];
};

export type HoosegowTaskParoleStateCompletedResultResponseReceived = {
  __typename?: 'HoosegowTaskParoleStateCompletedResultResponseReceived';
  responseReceived: HoosegowTaskParoleStateCompletedResultResponseReceivedResult;
  label: Scalars['String'];
};

export type HoosegowTaskParoleStateCompletedResultResponseReceivedResult = HoosegowTaskParoleStateCompletedResultResponseReceivedApproved | HoosegowTaskParoleStateCompletedResultResponseReceivedDeclined | HoosegowTaskParoleStateCompletedResultResponseReceivedRework;

export type HoosegowTaskParoleStateCompletedResultResponseReceivedApproved = {
  __typename?: 'HoosegowTaskParoleStateCompletedResultResponseReceivedApproved';
  rate: Scalars['Float'];
  payment: Scalars['Float'];
  term: Scalars['Int'];
  loanAmount: Scalars['Float'];
  validUntil?: Maybe<Date>;
  comment: Scalars['String'];
  ownAmount: Scalars['Float'];
  ownAmountPercents: Scalars['Float'];
  label: Scalars['String'];
  conditions?: Maybe<Scalars['String']>;
};

export type HoosegowTaskParoleStateCompletedResultResponseReceivedDeclined = {
  __typename?: 'HoosegowTaskParoleStateCompletedResultResponseReceivedDeclined';
  comment: Scalars['String'];
  label: Scalars['String'];
};

export type HoosegowTaskParoleStateCompletedResultResponseReceivedRework = {
  __typename?: 'HoosegowTaskParoleStateCompletedResultResponseReceivedRework';
  comment: Scalars['String'];
  label: Scalars['String'];
  reworkCausesList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HoosegowTaskParoleStateCreated = {
  __typename?: 'HoosegowTaskParoleStateCreated';
  label: Scalars['String'];
};

export type HoosegowTaskBankRequestType = {
  __typename?: 'HoosegowTaskBankRequestType';
  bank: HoosegowBank;
  /** Наименование ипотечной программы */
  bankProgram: Scalars['String'];
  /** Запрошенная сумма кредита в рублях */
  loanAmount: Scalars['Float'];
  /** Запрошенный срок кредита в месяцах */
  term: Scalars['Int'];
  /** Запрошенная ставка в % */
  rate: Scalars['Float'];
  /** Запрошенный ежемесячный платеж в рублях */
  payment: Scalars['Float'];
  /** Признак покупной ставки по кредиту */
  rateReducedByPayment?: Maybe<Scalars['Boolean']>;
  /** Описание опций кредита */
  offerDescription?: Maybe<Scalars['String']>;
  /** Признак комбо ипотеки */
  combo?: Maybe<Scalars['Boolean']>;
};

export type HoosegowTaskParoleSubject = {
  __typename?: 'HoosegowTaskParoleSubject';
  subjectType?: Maybe<HoosegowTaskSubjectTypeValueEnum>;
  initial?: Maybe<HoosegowTaskParoleSubjectInitial>;
  reworksList: Array<HoosegowTaskParoleSubjectRework>;
};

export enum HoosegowTaskSubjectTypeValueEnum {
  UnknownSubjectType = 'UNKNOWN_SUBJECT_TYPE',
  Client = 'CLIENT',
  Property = 'PROPERTY'
}

export type HoosegowTaskParoleSubjectInitial = {
  __typename?: 'HoosegowTaskParoleSubjectInitial';
  files?: Maybe<Array<HoosegowParoleFile>>;
};

export type HoosegowParoleFile = Node & HoosegowFileInterface & {
  __typename?: 'HoosegowParoleFile';
  id: Scalars['ID'];
  downloadUrl: Scalars['String'];
  /** Будет только у картинок */
  image?: Maybe<Image2>;
  documentView: Scalars['String'];
  name: Scalars['String'];
  sizeInMb: Scalars['String'];
  ext: Scalars['String'];
  uploadedTs?: Maybe<Date>;
  createdTs?: Maybe<Date>;
};

export type HoosegowTaskParoleSubjectRework = {
  __typename?: 'HoosegowTaskParoleSubjectRework';
  filesList: Array<HoosegowParoleFile>;
  comment?: Maybe<Scalars['String']>;
  reworkCausesList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HoosegowTaskParoleHistory = {
  __typename?: 'HoosegowTaskParoleHistory';
  stepsList: Array<Maybe<HoosegowTaskParoleHistoryStep>>;
};

export type HoosegowTaskParoleHistoryStep = {
  __typename?: 'HoosegowTaskParoleHistoryStep';
  processingTs?: Maybe<Date>;
  officer?: Maybe<HoosegowOfficer>;
  event?: Maybe<HoosegowTaskParoleHistoryStepEvent>;
};

export type HoosegowTaskParoleHistoryStepEvent = HoosegowTaskParoleHistoryStepEventAssigned | HoosegowTaskParoleHistoryStepEventUnassigned | HoosegowTaskParoleHistoryStepEventSent | HoosegowTaskParoleHistoryStepEventReopened | HoosegowTaskParoleHistoryStepEventClosed;

export type HoosegowTaskParoleHistoryStepEventAssigned = {
  __typename?: 'HoosegowTaskParoleHistoryStepEventAssigned';
  label: Scalars['String'];
  assigneeId?: Maybe<Scalars['ID']>;
  officer?: Maybe<HoosegowOfficer>;
};

export type HoosegowTaskParoleHistoryStepEventUnassigned = {
  __typename?: 'HoosegowTaskParoleHistoryStepEventUnassigned';
  label: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['Int']>;
  officer?: Maybe<HoosegowOfficer>;
};

export type HoosegowTaskParoleHistoryStepEventSent = {
  __typename?: 'HoosegowTaskParoleHistoryStepEventSent';
  label: Scalars['String'];
};

export type HoosegowTaskParoleHistoryStepEventReopened = {
  __typename?: 'HoosegowTaskParoleHistoryStepEventReopened';
  label: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type HoosegowTaskParoleHistoryStepEventClosed = {
  __typename?: 'HoosegowTaskParoleHistoryStepEventClosed';
  label: Scalars['String'];
  result?: Maybe<HoosegowTaskParoleStateCompletedResult>;
};

export type HoosegowSearchTasksListFailure = {
  __typename?: 'HoosegowSearchTasksListFailure';
  error: HoosegowSearchTasksListFailureError;
  traceId: Scalars['String'];
};

export type HoosegowSearchTasksListFailureError = HoosegowShawshankTaskFailureErrorInvalidParam | HoosegowShawshankTaskValueNotSet | HoosegowParoleTaskValueNotSet | HoosegowParoleTaskFailureErrorInvalidParam;

export type HoosegowShawshankTaskFailureErrorInvalidParam = {
  __typename?: 'HoosegowShawshankTaskFailureErrorInvalidParam';
  message: Scalars['String'];
  cause?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
};

export type HoosegowShawshankTaskValueNotSet = {
  __typename?: 'HoosegowShawshankTaskValueNotSet';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowParoleTaskValueNotSet = {
  __typename?: 'HoosegowParoleTaskValueNotSet';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowParoleTaskFailureErrorInvalidParam = {
  __typename?: 'HoosegowParoleTaskFailureErrorInvalidParam';
  message: Scalars['String'];
  cause?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code: Scalars['String'];
};

/** Набор возможных фильтров для поиска задач */
export type HoosegowSearchTasksFilterInput = {
  search?: InputMaybe<Scalars['String']>;
  searchType?: InputMaybe<HoosegowTaskShawshankSearchTypeEnum>;
  stateShawshank?: InputMaybe<HoosegowTaskShawshankStateEnum>;
  stateParole?: InputMaybe<HoosegowTaskParoleStateEnum>;
  verificationType?: InputMaybe<HoosegowTaskShawshankStateVerificationEnum>;
  taskType?: HoosegowTaskTypeEnum;
  bankMnemonic?: InputMaybe<Scalars['String']>;
  modifiedDateStart?: InputMaybe<Scalars['String']>;
  modifiedDateEnd?: InputMaybe<Scalars['String']>;
};

export enum HoosegowTaskShawshankSearchTypeEnum {
  Fio = 'FIO',
  Id = 'ID',
  Phone = 'PHONE'
}

export enum HoosegowTaskShawshankStateEnum {
  SealedValueNotSet = 'SEALED_VALUE_NOT_SET',
  Pending = 'PENDING',
  Archive = 'ARCHIVE',
  Resolved = 'RESOLVED'
}

export enum HoosegowTaskParoleStateEnum {
  SealedValueNotSet = 'SEALED_VALUE_NOT_SET',
  Pending = 'PENDING',
  Waiting = 'WAITING',
  Archive = 'ARCHIVE',
  Responded = 'RESPONDED'
}

export enum HoosegowTaskShawshankStateVerificationEnum {
  Initial = 'INITIAL',
  Rework = 'REWORK',
  BankRework = 'BANK_REWORK'
}

export enum HoosegowTaskTypeEnum {
  Shawshank = 'SHAWSHANK',
  Parole = 'PAROLE'
}

/** Набор возможных сотртировок для поиска задач */
export type HoosegowSearchTasksSortInput = {
  field?: InputMaybe<HoosegowTaskShawshankSortFieldsEnum>;
  direction?: InputMaybe<HoosegowTaskShawshankSortDirectionEnum>;
};

export enum HoosegowTaskShawshankSortFieldsEnum {
  CreationDate = 'CREATION_DATE',
  ModifiedDate = 'MODIFIED_DATE'
}

export enum HoosegowTaskShawshankSortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type HoosegowBankList = {
  __typename?: 'HoosegowBankList';
  list?: Maybe<Array<HoosegowBankBackOffice>>;
};

export type HoosegowBankBackOffice = Node & {
  __typename?: 'HoosegowBankBackOffice';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  mnemonic?: Maybe<Scalars['String']>;
};

export type HoosegowShawshankTasksResponse = HoosegowShawshankTasksSuccess | HoosegowShawshankTasksFailure;

export type HoosegowShawshankTasksSuccess = {
  __typename?: 'HoosegowShawshankTasksSuccess';
  list: Array<HoosegowTaskShawshankPerson>;
  paging: Paging;
};

export type HoosegowShawshankTasksFailure = {
  __typename?: 'HoosegowShawshankTasksFailure';
  error: HoosegowShawshankTasksListFailureError;
  traceId: Scalars['String'];
};

export type HoosegowShawshankTasksListFailureError = HoosegowShawshankTaskFailureErrorInvalidParam | HoosegowShawshankTaskValueNotSet;

/** Набор возможных фильтров для фильтрации проверки документов */
export type HoosegowShawshankTasksFilterInput = {
  state?: InputMaybe<HoosegowTaskShawshankStateEnum>;
  verificationType?: InputMaybe<HoosegowTaskShawshankStateVerificationEnum>;
  mortgageId?: InputMaybe<Scalars['String']>;
  ownTasks?: InputMaybe<Scalars['Boolean']>;
  subjectType?: InputMaybe<HoosegowTaskSubjectTypeEnum>;
};

export enum HoosegowTaskSubjectTypeEnum {
  UnknownSubjectType = 'UNKNOWN_SUBJECT_TYPE',
  Client = 'CLIENT',
  Property = 'PROPERTY'
}

export type HoosegowParoleTasksSendingResponse = HoosegowParoleTasksSendingSuccess | HoosegowParoleTasksSendingFailure;

export type HoosegowParoleTasksSendingSuccess = {
  __typename?: 'HoosegowParoleTasksSendingSuccess';
  list: Array<HoosegowTaskParole>;
  paging: Paging;
};

export type HoosegowParoleTasksSendingFailure = {
  __typename?: 'HoosegowParoleTasksSendingFailure';
  error: HoosegowParoleTasksListFailureError;
  traceId: Scalars['String'];
};

export type HoosegowParoleTasksListFailureError = HoosegowParoleTaskFailureErrorInvalidParam | HoosegowParoleTaskValueNotSet;

/** Набор возможных фильтров для задач по отправке */
export type HoosegowParoleTasksFilterInput = {
  bankMnemonic?: InputMaybe<Scalars['String']>;
  modifiedDateStart?: InputMaybe<Scalars['String']>;
  modifiedDateEnd?: InputMaybe<Scalars['String']>;
  subjectType?: InputMaybe<HoosegowTaskSubjectTypeEnum>;
};

export type HoosegowParoleTasksAnswerResponse = HoosegowParoleTasksAnswerSuccess | HoosegowParoleTasksAnswerFailure;

export type HoosegowParoleTasksAnswerSuccess = {
  __typename?: 'HoosegowParoleTasksAnswerSuccess';
  list: Array<HoosegowTaskParole>;
  paging: Paging;
};

export type HoosegowParoleTasksAnswerFailure = {
  __typename?: 'HoosegowParoleTasksAnswerFailure';
  error: HoosegowParoleTasksListFailureError;
  traceId: Scalars['String'];
};

export type HoosegowCount = {
  __typename?: 'HoosegowCount';
  count: Scalars['Int'];
  mortgagesCount: Scalars['Int'];
};

export type HoosegowParoleTasksCountSendingResponse = HoosegowParoleTasksCountSendingSuccess | HoosegowParoleTasksCountSendingFailure;

export type HoosegowParoleTasksCountSendingSuccess = {
  __typename?: 'HoosegowParoleTasksCountSendingSuccess';
  count: Scalars['Int'];
  mortgagesCount: Scalars['Int'];
};

export type HoosegowParoleTasksCountSendingFailure = {
  __typename?: 'HoosegowParoleTasksCountSendingFailure';
  error: HoosegowParoleCountFailureError;
  traceId: Scalars['String'];
};

export type HoosegowParoleCountFailureError = HoosegowParoleTaskFailureErrorInvalidParam | HoosegowParoleTaskValueNotSet;

export type HoosegowParoleTasksCountSendingPropertyResponse = HoosegowParoleTasksCountSendingPropertySuccess | HoosegowParoleTasksCountSendingPropertyFailure;

export type HoosegowParoleTasksCountSendingPropertySuccess = {
  __typename?: 'HoosegowParoleTasksCountSendingPropertySuccess';
  count: Scalars['Int'];
  mortgagesCount: Scalars['Int'];
};

export type HoosegowParoleTasksCountSendingPropertyFailure = {
  __typename?: 'HoosegowParoleTasksCountSendingPropertyFailure';
  error: HoosegowParoleCountFailureError;
  traceId: Scalars['String'];
};

export type HoosegowParoleTasksCountAnswerResponse = HoosegowParoleTasksCountAnswerSuccess | HoosegowParoleTasksCountAnswerFailure;

export type HoosegowParoleTasksCountAnswerSuccess = {
  __typename?: 'HoosegowParoleTasksCountAnswerSuccess';
  count: Scalars['Int'];
  mortgagesCount: Scalars['Int'];
};

export type HoosegowParoleTasksCountAnswerFailure = {
  __typename?: 'HoosegowParoleTasksCountAnswerFailure';
  error: HoosegowParoleCountFailureError;
  traceId: Scalars['String'];
};

export type HoosegowParoleTasksExportResponse = HoosegowParoleTasksExportSuccess | HoosegowParoleTasksExportFailure;

export type HoosegowParoleTasksExportSuccess = {
  __typename?: 'HoosegowParoleTasksExportSuccess';
  id: Scalars['ID'];
  downloadUrl: Scalars['String'];
  /** Будет только у картинок */
  image?: Maybe<Image2>;
  documentView: Scalars['String'];
  name: Scalars['String'];
  sizeInMb: Scalars['String'];
  ext: Scalars['String'];
  uploadedTs?: Maybe<Date>;
  createdTs?: Maybe<Date>;
};

export type HoosegowParoleTasksExportFailure = {
  __typename?: 'HoosegowParoleTasksExportFailure';
  error: HoosegowParoleTasksExportFailureError;
  traceId: Scalars['String'];
};

export type HoosegowParoleTasksExportFailureError = HoosegowParoleTaskFailureErrorNotFound | HoosegowParoleTaskFailureErrorInvalidParam | HoosegowParoleTaskValueNotSet;

export type HoosegowParoleTaskFailureErrorNotFound = {
  __typename?: 'HoosegowParoleTaskFailureErrorNotFound';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowDocumentVerificationTaskResponse = HoosegowTaskShawshankPerson | HoosegowTaskShawshankProperty | HoosegowDocumentVerificationTaskFailure;

export type HoosegowDocumentVerificationTaskFailure = {
  __typename?: 'HoosegowDocumentVerificationTaskFailure';
  error: HoosegowGetTaskError;
  traceId: Scalars['String'];
};

/** Ошибки при получении задач */
export type HoosegowGetTaskError = NotFoundError | InternalError;

export type HoosegowBankTaskResponse = HoosegowTaskParole | HoosegowBankTaskFailure;

export type HoosegowBankTaskFailure = {
  __typename?: 'HoosegowBankTaskFailure';
  error: HoosegowGetTaskError;
  traceId: Scalars['String'];
};

export type HoosegowReworkReasonItem = {
  __typename?: 'HoosegowReworkReasonItem';
  value: Scalars['String'];
  text: Scalars['String'];
};

export type HoosegowRootFailure = {
  __typename?: 'HoosegowRootFailure';
  traceId: Scalars['String'];
  errors: HoosegowRootErrors;
};

export type HoosegowRootErrors = NotFoundError | InternalError | UnauthenticatedError;

export type MortgageRoot = {
  __typename?: 'MortgageRoot';
  /** MortgageBankListQuery */
  banks: MortgageBankList;
  /** Список ипотечных заявок пользователя */
  listMortgages: MortgageListResponse;
  /** Список ипотечных программ */
  programs?: Maybe<MortgageProgramsList>;
  /** Список целей */
  purposes?: Maybe<PurposesList>;
  /** Список регионов для ипотечного калькулятора */
  regions?: Maybe<RegionList>;
};


export type MortgageRootBanksArgs = {
  text?: InputMaybe<Scalars['String']>;
};


export type MortgageRootListMortgagesArgs = {
  paging: PagingInput;
};


export type MortgageRootProgramsArgs = {
  purposeCode?: InputMaybe<MortgagePurposeCode>;
  regionId?: InputMaybe<Scalars['String']>;
  propertyType?: InputMaybe<MortgagePropertyTypeRegistratura>;
};

export type MortgageBankList = {
  __typename?: 'MortgageBankList';
  list?: Maybe<Array<MortgageBank>>;
};

export type MortgageBank = {
  __typename?: 'MortgageBank';
  mnemonic: Scalars['String'];
  name: Scalars['String'];
  /** Иконка */
  icon?: Maybe<Scalars['String']>;
};

export type MortgageListResponse = MortgageList | MortgageListFailure;

export type MortgageList = {
  __typename?: 'MortgageList';
  list?: Maybe<Array<MortgageListItem>>;
};

export type MortgageListItem = Node & {
  __typename?: 'MortgageListItem';
  id: Scalars['ID'];
  createDate: Date;
  status: MortgageListItemStatus;
  purpose: MortgagePurposeView;
  borrower: MortgageListItemBorrower;
  link: MortgageListItemLink;
};

export type MortgageListItemStatus = {
  __typename?: 'MortgageListItemStatus';
  progress: Scalars['Int'];
  value: MortgageStatus;
  label: Scalars['String'];
};

export enum MortgageStatus {
  /** Черновик */
  New = 'NEW',
  CalculatorAllFields = 'CALCULATOR_ALL_FIELDS',
  PreScoringPending = 'PRE_SCORING_PENDING',
  PreScoringDeclined = 'PRE_SCORING_DECLINED',
  BorrowersMainForm = 'BORROWERS_MAIN_FORM',
  WaitForDocuments = 'WAIT_FOR_DOCUMENTS',
  /** Проверка документов заемщиков */
  WaitDocumentsApprove = 'WAIT_DOCUMENTS_APPROVE',
  /** Готово к отправке */
  ReadyToBeSend = 'READY_TO_BE_SEND',
  /** Решение банков */
  PendingSend = 'PENDING_SEND',
  /** Решение банков */
  Sent = 'SENT',
  /** Документы по объекту */
  WaitingForSmPropertyDocuments = 'WAITING_FOR_SM_PROPERTY_DOCUMENTS',
  /** Оценка объекта */
  AppraisalForm = 'APPRAISAL_FORM',
  WaitingForPropertyDocumentsApprove = 'WAITING_FOR_PROPERTY_DOCUMENTS_APPROVE',
  /** Одобрение объекта */
  WaitingForPropertyApprove = 'WAITING_FOR_PROPERTY_APPROVE',
  /** Паспорт сделки */
  DealPassport = 'DEAL_PASSPORT',
  DealPassportCompleted = 'DEAL_PASSPORT_COMPLETED',
  DealSentToBank = 'DEAL_SENT_TO_BANK',
  DealSigned = 'DEAL_SIGNED',
  /** Направлена на сделку */
  Executed = 'EXECUTED',
  /** Заявка удалена */
  Deleted = 'DELETED',
  /** Заявка отклонена */
  Failed = 'FAILED',
  Hidden = 'HIDDEN',
  /** Требуется доработка */
  Rework = 'REWORK',
  /** Требуется доработка по объекту */
  PropertyRework = 'PROPERTY_REWORK'
}

export type MortgagePurposeView = {
  __typename?: 'MortgagePurposeView';
  value: MortgagePurpose;
  label: Scalars['String'];
};

export type MortgageListItemBorrower = {
  __typename?: 'MortgageListItemBorrower';
  shortName?: Maybe<Scalars['String']>;
};

export type MortgageListItemLink = {
  __typename?: 'MortgageListItemLink';
  part: Scalars['String'];
};

export type MortgageListFailure = {
  __typename?: 'MortgageListFailure';
  traceId: Scalars['String'];
};

export type MortgageProgramsList = {
  __typename?: 'MortgageProgramsList';
  list?: Maybe<Array<MortgageProgram>>;
  error?: Maybe<Scalars['String']>;
};

export type MortgageProgram = {
  __typename?: 'MortgageProgram';
  name: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  inactive: Scalars['Boolean'];
};

export enum MortgagePurposeCode {
  /** Строящееся жилье */
  Primary = 'PRIMARY',
  /** Готовое жилье */
  Secondary = 'SECONDARY',
  /** Рефинансирование */
  Refinance = 'REFINANCE',
  /** Строительство дома */
  HouseBuilding = 'HOUSE_BUILDING',
  /** Неизвестная цель ипотеки */
  UnknownPurposeCode = 'UNKNOWN_PURPOSE_CODE'
}

export enum MortgagePropertyTypeRegistratura {
  /** Неизвестно */
  UnknownPropertyType = 'UNKNOWN_PROPERTY_TYPE',
  /** Квартира */
  Flat = 'FLAT',
  /** Комната */
  Room = 'ROOM',
  /** Доля */
  Share = 'SHARE',
  /** Апартаменты */
  Apartment = 'APARTMENT',
  /** Дом */
  House = 'HOUSE',
  /** Дом с участоком */
  HouseWithLot = 'HOUSE_WITH_LOT',
  /** Таунхаус */
  Townhouse = 'TOWNHOUSE',
  /** Коммерческая недвижимость */
  Commercial = 'COMMERCIAL',
  /** Участок */
  Lot = 'LOT',
  /** Машино-место */
  ParkingPlace = 'PARKING_PLACE',
  /** Кладовая */
  Storeroom = 'STOREROOM'
}

export type PurposesList = {
  __typename?: 'PurposesList';
  list: Array<Purpose>;
};

export type Purpose = {
  __typename?: 'Purpose';
  name: Scalars['String'];
  code: PurposeCodeEnum;
  codeId: Scalars['String'];
};

export enum PurposeCodeEnum {
  UnknownPurpose = 'UNKNOWN_PURPOSE',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Refinance = 'REFINANCE',
  Php = 'PHP'
}

export type RegionList = {
  __typename?: 'RegionList';
  list: Array<RegionListItem>;
};

export type RegionListItem = {
  __typename?: 'RegionListItem';
  id: Scalars['Int'];
  name: Scalars['String'];
  geoId: Scalars['Int'];
};

export type Mg2Root = {
  __typename?: 'MG2Root';
  /** Получить текущее состояние расчёта предложений */
  calculation: Mg2CalculationBag;
  /** Рассчитать предложения */
  calculateOffers: Mg2CalculateOffersBag;
  borrower: Mg2BorrowerGetResponseBag;
  fullDynamo: Mg2GetFullDynamoResponseBag;
  linkForDemandEdition: Mg2LinkForDemandEditionResponseBag;
  getFile: Mg2GetFileResponseBag;
  getFilesPreview: Mg2GetFilesPreviewBag;
  getBlankPersonalDataConsent: Mg2GetBlankPersonalDataConsentResponseBag;
  getSignedPersonalDataConsent: Mg2GetSignedPersonalDataConsentResponseBag;
  /** Найти ипотечные заявки */
  searchSummaries: Mg2SearchSummariesBag;
  /** Количество ипотечных заявок */
  countSummaries: Mg2CountSummariesBag;
  docRecognition?: Maybe<Mg2IdxDocRecognitionResponseBag>;
  parsePassport?: Maybe<Mg2IdxParsePassportResponseBag>;
  getUploadToken: Mg2GetUploadTokenResponseBag;
  /** Отправляет письмо c телефоном пользователя админу */
  consultUserByPhone: Mg2ConsultUserByPhoneResponseBag;
  checkReadiness: Mg2CheckReadinessResponseBag;
  getSubmittedOffers: Mg2GetSubmittedOffersResponseBag;
  getDispatch: Mg2GetDispatchResponseBag;
  getReworkFile: Mg2GetReworkFileResponseBag;
  getReworkUploadToken: Mg2GetReworkUploadTokenResponseBag;
  /** Чат ипотечной заявки */
  getChat: Mg2GetChatBag;
  downloadFromReactTemplate: Mg2DownloadFromReactTemplateResponseBag;
  getActiveDeal: Mg2GetActiveDealResponseBag;
};


export type Mg2RootCalculationArgs = {
  id: Scalars['ID'];
};


export type Mg2RootCalculateOffersArgs = {
  input: Mg2CalculationInput;
  source?: InputMaybe<Mg2DemandSourceEnum>;
};


export type Mg2RootBorrowerArgs = {
  borrowerId: Scalars['ID'];
};


export type Mg2RootFullDynamoArgs = {
  bankMnemonicsList: Array<Scalars['String']>;
  demand?: InputMaybe<Mg2CalculationDemandInput>;
  form: Mg2QuestionnaireFormType;
  borrower: Mg2BorrowerInput;
  spouse?: InputMaybe<Mg2CoBorrowerInput>;
  coBorrowersList: Array<Mg2CoBorrowerInput>;
};


export type Mg2RootLinkForDemandEditionArgs = {
  borrowerId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
};


export type Mg2RootGetFileArgs = {
  borrowerId: Scalars['String'];
  coBorrowerId?: InputMaybe<Scalars['ID']>;
  folderType: MortgageFolderKindEnum;
  fileId: Scalars['String'];
};


export type Mg2RootGetFilesPreviewArgs = {
  filesId?: InputMaybe<Array<Scalars['String']>>;
  borrowerId: Scalars['ID'];
};


export type Mg2RootGetBlankPersonalDataConsentArgs = {
  borrowerId: Scalars['ID'];
  coBorrowerId?: InputMaybe<Scalars['ID']>;
};


export type Mg2RootGetSignedPersonalDataConsentArgs = {
  borrowerId: Scalars['ID'];
  coBorrowerId?: InputMaybe<Scalars['ID']>;
};


export type Mg2RootSearchSummariesArgs = {
  query: Mg2SummaryQueryInput;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};


export type Mg2RootCountSummariesArgs = {
  query: Mg2SummaryQueryInput;
};


export type Mg2RootDocRecognitionArgs = {
  fileId: Scalars['String'];
  borrowerId: Scalars['String'];
  correlationId: Scalars['String'];
  userId: Scalars['String'];
  serviceName: Scalars['String'];
};


export type Mg2RootParsePassportArgs = {
  fileId: Scalars['String'];
  borrowerId: Scalars['String'];
  correlationId: Scalars['String'];
  userId: Scalars['String'];
  serviceName: Scalars['String'];
};


export type Mg2RootGetUploadTokenArgs = {
  borrowerId: Scalars['String'];
  coBorrowerId?: InputMaybe<Scalars['ID']>;
  folderType: MortgageFolderKindEnum;
};


export type Mg2RootConsultUserByPhoneArgs = {
  phone: Scalars['String'];
  mortageUrl: Scalars['String'];
};


export type Mg2RootCheckReadinessArgs = {
  id: Scalars['ID'];
};


export type Mg2RootGetSubmittedOffersArgs = {
  id: Scalars['ID'];
};


export type Mg2RootGetDispatchArgs = {
  id: Scalars['ID'];
};


export type Mg2RootGetReworkFileArgs = {
  dispatchId: Scalars['ID'];
  bankMnemonic: Scalars['String'];
  fileId: Scalars['String'];
};


export type Mg2RootGetReworkUploadTokenArgs = {
  dispatchId: Scalars['ID'];
  bankMnemonic: Scalars['String'];
};


export type Mg2RootGetChatArgs = {
  id: Scalars['ID'];
};


export type Mg2RootDownloadFromReactTemplateArgs = {
  templateId: Scalars['String'];
  paramsJson: Scalars['String'];
  skip3a?: InputMaybe<Scalars['Boolean']>;
};


export type Mg2RootGetActiveDealArgs = {
  id: Scalars['ID'];
};

export type Mg2CalculationBag = {
  __typename?: 'MG2CalculationBag';
  data?: Maybe<Mg2Calculation>;
  error?: Maybe<Mg2CalculationError>;
};

/** Данные расчёта предложений */
export type Mg2Calculation = {
  __typename?: 'MG2Calculation';
  /** Завершен ли вввод калькуляции */
  isComplete: Scalars['Boolean'];
  /** Заёмщик */
  borrower?: Maybe<Mg2BorrowerInitial>;
  /** ID созданного заёмщика */
  borrowerId?: Maybe<Scalars['ID']>;
  /** Запросы по ипотеке */
  demand?: Maybe<Mg2CalculationDemand>;
  /** Недвижимость */
  property?: Maybe<Mg2CalculationProperty>;
  /** Партнёр */
  partner?: Maybe<Mg2Partner>;
  /**
   * Предложения банков
   * @deprecated use bankOffers
   */
  offers?: Maybe<Mg2OfferList>;
  /** Предложения банков */
  bankOffers?: Maybe<Mg2BankOfferList>;
  /** Ошибки валидации данных расчёта */
  validationErrors?: Maybe<Array<Mg2ValidationError>>;
  isMono?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Mg2DemandSourceEnum>;
  ownerRole?: Maybe<Scalars['String']>;
};

export type Mg2BorrowerInitial = {
  __typename?: 'MG2BorrowerInitial';
  /** Полное имя */
  fullName?: Maybe<Mg2FullName>;
  /** Дата рождения */
  birthDate?: Maybe<Scalars['LocalDate']>;
  /** Гражданство */
  citizenship?: Maybe<Mg2PassportCitizenship>;
  /**
   * Брак
   * @deprecated use family
   */
  marriage?: Maybe<Mg2FamilyMarriageInitial>;
  /** Семья */
  family?: Maybe<Mg2FamilyInitial>;
  /** Трудоустройство */
  employment?: Maybe<Mg2EmploymentInitial>;
};

export type Mg2FullName = {
  __typename?: 'MG2FullName';
  /** Имя */
  first: Scalars['String'];
  /** Отчество */
  middle: Scalars['String'];
  /** Фамилия */
  last: Scalars['String'];
  /** Признак отсутствия отчества */
  noMiddleName: Scalars['Boolean'];
  shortName: Scalars['String'];
  fullName: Scalars['String'];
};

export enum Mg2PassportCitizenship {
  CitizenshipRussian = 'CITIZENSHIP_RUSSIAN',
  CitizenshipForeign = 'CITIZENSHIP_FOREIGN'
}

export type Mg2FamilyMarriageInitial = {
  __typename?: 'MG2FamilyMarriageInitial';
  /** Семейное положение */
  maritalStatus?: Maybe<Mg2MaritalStatus>;
  /** Брачный контракт */
  marriageContract?: Maybe<Mg2MarriageContract>;
};

export enum Mg2MaritalStatus {
  MaritalStatusMarried = 'MARITAL_STATUS_MARRIED',
  MaritalStatusUnmarried = 'MARITAL_STATUS_UNMARRIED'
}

export enum Mg2MarriageContract {
  MarriageContractNone = 'MARRIAGE_CONTRACT_NONE',
  MarriageContractSigned = 'MARRIAGE_CONTRACT_SIGNED'
}

export type Mg2FamilyInitial = {
  __typename?: 'MG2FamilyInitial';
  /** Семейное положение */
  maritalStatus?: Maybe<Mg2MaritalStatus>;
  /** Участие супруга(и) в сделке */
  spouseInvolvement?: Maybe<Mg2SpouseInvolvement>;
};

export enum Mg2SpouseInvolvement {
  SpouseInvolvementUnknown = 'SPOUSE_INVOLVEMENT_UNKNOWN',
  SpouseInvolvementParticipates = 'SPOUSE_INVOLVEMENT_PARTICIPATES',
  SpouseInvolvementMarriageContract = 'SPOUSE_INVOLVEMENT_MARRIAGE_CONTRACT',
  SpouseInvolvementAgreement = 'SPOUSE_INVOLVEMENT_AGREEMENT'
}

export type Mg2EmploymentInitial = {
  __typename?: 'MG2EmploymentInitial';
  /** Форма занятости */
  employmentType?: Maybe<Mg2EmploymentEnum>;
  /** Подтверждение дохода */
  incomeConfirmation?: Maybe<Mg2CalculationIncomeConfirmation>;
  /** Зарплатный проект */
  salaryBank?: Maybe<Mg2BankSalary>;
};

export enum Mg2EmploymentEnum {
  EmploymentTypeHired = 'EMPLOYMENT_TYPE_HIRED',
  EmploymentTypeBusinessOwner = 'EMPLOYMENT_TYPE_BUSINESS_OWNER',
  EmploymentTypeIndividual = 'EMPLOYMENT_TYPE_INDIVIDUAL',
  EmploymentTypeSelfEmployed = 'EMPLOYMENT_TYPE_SELF_EMPLOYED'
}

export enum Mg2CalculationIncomeConfirmation {
  IncomeConfirmationNone = 'INCOME_CONFIRMATION_NONE',
  IncomeConfirmationNdfl = 'INCOME_CONFIRMATION_NDFL',
  IncomeConfirmationBankForm = 'INCOME_CONFIRMATION_BANK_FORM',
  IncomeConfirmationDeclaration = 'INCOME_CONFIRMATION_DECLARATION',
  IncomeConfirmationSelfEmployedTaxes = 'INCOME_CONFIRMATION_SELF_EMPLOYED_TAXES',
  IncomeConfirmationGosuslugi = 'INCOME_CONFIRMATION_GOSUSLUGI'
}

export type Mg2BankSalary = {
  __typename?: 'MG2BankSalary';
  /** Есть зарплатный проект ? */
  active: Scalars['Boolean'];
  /** Зарплатный банк */
  bank?: Maybe<Mg2Bank>;
};

export type Mg2Bank = {
  __typename?: 'MG2Bank';
  /** Название */
  name?: Maybe<Scalars['String']>;
  mnemonic?: Maybe<Scalars['String']>;
  /** Иконка */
  icon?: Maybe<Scalars['String']>;
};

export type Mg2CalculationDemand = {
  __typename?: 'MG2CalculationDemand';
  /** Цель кредита */
  purpose?: Maybe<Mg2DemandPurposeEnum>;
  /** Код ипотечной программы */
  programCode: Scalars['String'];
  /** Регион проведения сделки */
  dealRegionId: Scalars['Int'];
  /** Срок кредита, в годах */
  loanTermYears: Scalars['Float'];
  /** Первоначальный взнос, в рублях */
  downPayment: Scalars['Float'];
  /** Рефинансирование */
  refinance?: Maybe<Mg2Refinance>;
  /** Материнский капитал */
  maternityCapital?: Maybe<Scalars['Boolean']>;
  /** Сумма материнского капитала */
  maternityCapitalAmount?: Maybe<Scalars['Float']>;
  /** Сумма кредита */
  loanAmount: Scalars['Float'];
  tranche: Scalars['Boolean'];
};

export enum Mg2DemandPurposeEnum {
  PurposeBuyPrimary = 'PURPOSE_BUY_PRIMARY',
  PurposeBuySecondary = 'PURPOSE_BUY_SECONDARY',
  PurposeRefinance = 'PURPOSE_REFINANCE',
  PurposeBuildHouse = 'PURPOSE_BUILD_HOUSE'
}

export type Mg2Refinance = {
  __typename?: 'MG2Refinance';
  target?: Maybe<Mg2RefinanceTargetEnum>;
  remainingAmount: Scalars['Float'];
  needAdditional: Scalars['Boolean'];
  additionalAmount: Scalars['Float'];
  registrationDate?: Maybe<Scalars['LocalDate']>;
  bank?: Maybe<Mg2Bank>;
};

export enum Mg2RefinanceTargetEnum {
  RefinanceTargetPrimary = 'REFINANCE_TARGET_PRIMARY',
  RefinanceTargetSecondary = 'REFINANCE_TARGET_SECONDARY'
}

export type Mg2CalculationProperty = {
  __typename?: 'MG2CalculationProperty';
  /** Регион покупки недвижимости */
  regionId: Scalars['Int'];
  /** Тип недвижимости */
  propertyType?: Maybe<Mg2PropertyTypeEnum>;
  /** Стоимость недвижимости */
  cost: Scalars['Float'];
};

export enum Mg2PropertyTypeEnum {
  PropertyTypeFlat = 'PROPERTY_TYPE_FLAT',
  PropertyTypeRoom = 'PROPERTY_TYPE_ROOM',
  PropertyTypeShare = 'PROPERTY_TYPE_SHARE',
  PropertyTypeApartment = 'PROPERTY_TYPE_APARTMENT',
  PropertyTypeHouse = 'PROPERTY_TYPE_HOUSE',
  PropertyTypeHouseWithLot = 'PROPERTY_TYPE_HOUSE_WITH_LOT',
  PropertyTypeTownhouse = 'PROPERTY_TYPE_TOWNHOUSE',
  PropertyTypeCommercial = 'PROPERTY_TYPE_COMMERCIAL',
  PropertyTypeLot = 'PROPERTY_TYPE_LOT',
  PropertyTypeParkingPlace = 'PROPERTY_TYPE_PARKING_PLACE',
  PropertyTypeStoreroom = 'PROPERTY_TYPE_STOREROOM'
}

export type Mg2Partner = {
  __typename?: 'MG2Partner';
  /** Полное имя */
  fullName?: Maybe<Mg2FullName>;
  /** Телефон */
  phone?: Maybe<Scalars['String']>;
  /** Почта */
  email?: Maybe<Scalars['String']>;
  /** Идентификатор филиала */
  branchId?: Maybe<Scalars['String']>;
};

export type Mg2OfferList = {
  __typename?: 'MG2OfferList';
  /** id Предложений банков */
  selectedOfferIds: Array<Scalars['ID']>;
  /** Предложения банков */
  list: Array<Mg2OfferBank>;
};

/** Ипотечное предложение */
export type Mg2OfferBank = {
  __typename?: 'MG2OfferBank';
  /** Идентификатор банка */
  id: Scalars['ID'];
  /** Банк */
  bank?: Maybe<Mg2Bank>;
  /** Предложения от банка */
  offers: Array<Mg2Offer>;
};

/** Ипотечное предложение */
export type Mg2Offer = {
  __typename?: 'MG2Offer';
  /** Идентификатор предложения для использования при выборе */
  id: Scalars['ID'];
  /** Ставка */
  rate: Scalars['Float'];
  /** Платёж в месяц */
  monthlyPayment: Scalars['Float'];
  /** Комментарий */
  comment?: Maybe<Scalars['String']>;
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Полный оффер */
  available?: Maybe<Mg2OfferAvailable>;
};

/** Доступное для выбора ипотечное предложение */
export type Mg2OfferAvailable = {
  __typename?: 'MG2OfferAvailable';
  /** Переплата */
  overpayment: Scalars['Float'];
  /** Рекомендуемый доход */
  recommendedIncome: Scalars['Float'];
};

export type Mg2BankOfferList = {
  __typename?: 'MG2BankOfferList';
  /** Предложения банков */
  selectedBankOffers: Array<Mg2BankOffer>;
  /** Предложения банков */
  list: Array<Mg2BankOffer>;
};

/** Ипотечное предложение */
export type Mg2BankOffer = {
  __typename?: 'MG2BankOffer';
  /** Мнемоник банка */
  mnemonic: Scalars['String'];
  /** Предложения от банка */
  mortgageOffers: Array<Mg2MortageOffer>;
  /** Название */
  name?: Maybe<Scalars['String']>;
  /** Иконка */
  icon?: Maybe<Scalars['String']>;
  /** Подтверждение дохода через госуслуги */
  isGosuslugi?: Maybe<Scalars['Boolean']>;
  /** Подтверждение дохода выпиской из ПФР */
  pfr?: Maybe<Pfr>;
};

/** Ипотечное предложение */
export type Mg2MortageOffer = {
  __typename?: 'MG2MortageOffer';
  /** Ставка */
  rate: Scalars['Float'];
  /** Платёж в месяц */
  monthlyPayment: Scalars['Float'];
  /** Комментарий */
  comment?: Maybe<Scalars['String']>;
  /** Описание */
  description?: Maybe<Scalars['String']>;
  /** Переплата */
  overpayment: Scalars['Float'];
  /** Рекомендуемый доход */
  recommendedIncome: Scalars['Float'];
  isCombo: Scalars['Boolean'];
};

export type Pfr = {
  __typename?: 'PFR';
  /** Признак ПФР */
  isPFR?: Maybe<Scalars['Boolean']>;
  /** Email банка */
  email?: Maybe<Scalars['String']>;
};

export type Mg2ValidationError = {
  __typename?: 'MG2ValidationError';
  /** Поле */
  field: Mg2ValidationErrorField;
};

export enum Mg2ValidationErrorField {
  UnknownField = 'UNKNOWN_FIELD',
  DemandFieldUnknown = 'DEMAND_FIELD_UNKNOWN',
  DemandFieldPurpose = 'DEMAND_FIELD_PURPOSE',
  DemandFieldProgramCode = 'DEMAND_FIELD_PROGRAM_CODE',
  DemandFieldDealRegionId = 'DEMAND_FIELD_DEAL_REGION_ID',
  DemandFieldLoanTermYears = 'DEMAND_FIELD_LOAN_TERM_YEARS',
  DemandFieldDownPayment = 'DEMAND_FIELD_DOWN_PAYMENT',
  DemandFieldRefinanceTarget = 'DEMAND_FIELD_REFINANCE_TARGET',
  DemandFieldRefinanceRemainingAmount = 'DEMAND_FIELD_REFINANCE_REMAINING_AMOUNT',
  DemandFieldRefinanceAdditionalAmount = 'DEMAND_FIELD_REFINANCE_ADDITIONAL_AMOUNT',
  DemandFieldRefinanceRegistrationDate = 'DEMAND_FIELD_REFINANCE_REGISTRATION_DATE',
  DemandFieldRefinanceBank = 'DEMAND_FIELD_REFINANCE_BANK',
  DemandFieldMaternityCapitalAmount = 'DEMAND_FIELD_MATERNITY_CAPITAL_AMOUNT',
  PropertyFieldUnknown = 'PROPERTY_FIELD_UNKNOWN',
  PropertyFieldRegionId = 'PROPERTY_FIELD_REGION_ID',
  PropertyFieldType = 'PROPERTY_FIELD_TYPE',
  PropertyFieldCost = 'PROPERTY_FIELD_COST',
  BorrowerFieldUnknown = 'BORROWER_FIELD_UNKNOWN',
  BorrowerFieldFirstName = 'BORROWER_FIELD_FIRST_NAME',
  BorrowerFieldMiddleName = 'BORROWER_FIELD_MIDDLE_NAME',
  BorrowerFieldLastName = 'BORROWER_FIELD_LAST_NAME',
  BorrowerFieldBirthDate = 'BORROWER_FIELD_BIRTH_DATE',
  BorrowerFieldCitizenship = 'BORROWER_FIELD_CITIZENSHIP',
  BorrowerFieldMaritalStatus = 'BORROWER_FIELD_MARITAL_STATUS',
  BorrowerFieldSpouseInvolvement = 'BORROWER_FIELD_SPOUSE_INVOLVEMENT',
  BorrowerFieldEmploymentType = 'BORROWER_FIELD_EMPLOYMENT_TYPE',
  BorrowerFieldIncomeConfirmation = 'BORROWER_FIELD_INCOME_CONFIRMATION',
  BorrowerFieldSalaryBankIdentity = 'BORROWER_FIELD_SALARY_BANK_IDENTITY',
  PartnerFieldUnknown = 'PARTNER_FIELD_UNKNOWN',
  PartnerFieldFirstName = 'PARTNER_FIELD_FIRST_NAME',
  PartnerFieldMiddleName = 'PARTNER_FIELD_MIDDLE_NAME',
  PartnerFieldLastName = 'PARTNER_FIELD_LAST_NAME',
  PartnerFieldPhone = 'PARTNER_FIELD_PHONE',
  PartnerFieldEmail = 'PARTNER_FIELD_EMAIL',
  PartnerFieldBranchId = 'PARTNER_FIELD_BRANCH_ID'
}

export enum Mg2DemandSourceEnum {
  SourceWebLk = 'SOURCE_WEB_LK',
  SourceWebLanding = 'SOURCE_WEB_LANDING',
  SourceWebMain = 'SOURCE_WEB_MAIN',
  SourceWebClf = 'SOURCE_WEB_CLF',
  SourceWebNb = 'SOURCE_WEB_NB',
  SourceMobileApp = 'SOURCE_MOBILE_APP',
  SourceDealConstructor = 'SOURCE_DEAL_CONSTRUCTOR',
  SourceDealOnline = 'SOURCE_DEAL_ONLINE',
  SourceAvito = 'SOURCE_AVITO',
  SourceBankiRu = 'SOURCE_BANKI_RU',
  SourceSravniRu = 'SOURCE_SRAVNI_RU',
  SourceExternalApi = 'SOURCE_EXTERNAL_API'
}

export type Mg2CalculationError = ServiceError;

export type ServiceError = BaseError & {
  __typename?: 'ServiceError';
  code: GrpcServiceErrorEnum;
  message?: Maybe<Scalars['String']>;
};

export enum GrpcServiceErrorEnum {
  Cancelled = 'CANCELLED',
  Unknown = 'UNKNOWN',
  InvalidArgument = 'INVALID_ARGUMENT',
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  NotFound = 'NOT_FOUND',
  AlreadyExists = 'ALREADY_EXISTS',
  PermissionDenied = 'PERMISSION_DENIED',
  ResourceExhausted = 'RESOURCE_EXHAUSTED',
  FailedPrecondition = 'FAILED_PRECONDITION',
  Aborted = 'ABORTED',
  OutOfRange = 'OUT_OF_RANGE',
  Unimplemented = 'UNIMPLEMENTED',
  Internal = 'INTERNAL',
  Unavailable = 'UNAVAILABLE',
  DataLoss = 'DATA_LOSS',
  Unauthenticated = 'UNAUTHENTICATED'
}

export type Mg2CalculateOffersBag = {
  __typename?: 'MG2CalculateOffersBag';
  data?: Maybe<Mg2CalculateOffers>;
  error?: Maybe<Mg2CalculateOffersError>;
};

export type Mg2CalculateOffers = {
  __typename?: 'MG2CalculateOffers';
  /** Предложения банков */
  list: Array<Mg2BankEnrichedOffer>;
};

/** Ипотечное предложение */
export type Mg2BankEnrichedOffer = {
  __typename?: 'MG2BankEnrichedOffer';
  mnemonic: Scalars['String'];
  /** Предложение от банка */
  offer: Mg2MortageOffer;
  name: Scalars['String'];
  logoPath: Scalars['String'];
};

export type Mg2CalculateOffersError = ServiceError;

/** Параметры для расчета предложений (для сохранения и обновления сущности CalculationInput ) */
export type Mg2CalculationInput = {
  demand?: InputMaybe<Mg2CalculationDemandInput>;
  property?: InputMaybe<Mg2CalculationPropertyInput>;
  borrower?: InputMaybe<Mg2BorrowerInitialInput>;
};

export type Mg2CalculationDemandInput = {
  purpose?: InputMaybe<Mg2DemandPurposeEnum>;
  programCode: Scalars['String'];
  dealRegionId?: InputMaybe<Scalars['Int']>;
  loanTermYears: Scalars['Float'];
  downPayment: Scalars['Float'];
  refinance?: InputMaybe<Mg2RefinanceInput>;
  maternityCapital?: InputMaybe<Scalars['Boolean']>;
  maternityCapitalAmount?: InputMaybe<Scalars['Float']>;
  loanAmount: Scalars['Float'];
  tranche?: InputMaybe<Scalars['Boolean']>;
};

export type Mg2RefinanceInput = {
  target?: InputMaybe<Mg2RefinanceTargetEnum>;
  remainingAmount: Scalars['Float'];
  needAdditional: Scalars['Boolean'];
  additionalAmount: Scalars['Float'];
  registrationDate?: InputMaybe<Scalars['LocalDate']>;
  bank?: InputMaybe<Mg2BankInput>;
};

export type Mg2BankInput = {
  mnemonic?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Mg2CalculationPropertyInput = {
  regionId?: InputMaybe<Scalars['Int']>;
  propertyType?: InputMaybe<Mg2PropertyTypeEnum>;
  cost: Scalars['Float'];
};

export type Mg2BorrowerInitialInput = {
  fullName?: InputMaybe<Mg2FullNameInput>;
  birthDate?: InputMaybe<Scalars['LocalDate']>;
  citizenship?: InputMaybe<Mg2PassportCitizenship>;
  /** @deprecated use family */
  marriage?: InputMaybe<Mg2FamilyMarriageInitialInput>;
  family?: InputMaybe<Mg2FamilyInitialInput>;
  employment?: InputMaybe<Mg2EmploymentInitialInput>;
};

export type Mg2FullNameInput = {
  first: Scalars['String'];
  /** Отчество */
  middle?: InputMaybe<Scalars['String']>;
  /** Фамилия */
  last: Scalars['String'];
  /** Признак отсутствия отчества */
  noMiddleName?: InputMaybe<Scalars['Boolean']>;
};

export type Mg2FamilyMarriageInitialInput = {
  maritalStatus?: InputMaybe<Mg2MaritalStatus>;
  marriageContract?: InputMaybe<Mg2MarriageContract>;
};

export type Mg2FamilyInitialInput = {
  maritalStatus?: InputMaybe<Mg2MaritalStatus>;
  spouseInvolvement?: InputMaybe<Mg2SpouseInvolvement>;
};

export type Mg2EmploymentInitialInput = {
  employmentType?: InputMaybe<Mg2EmploymentEnum>;
  incomeConfirmation?: InputMaybe<Mg2CalculationIncomeConfirmation>;
  salaryBank?: InputMaybe<Mg2BankSalaryInput>;
};

export type Mg2BankSalaryInput = {
  active: Scalars['Boolean'];
  bank?: InputMaybe<Mg2BankInput>;
};

export type Mg2BorrowerGetResponseBag = {
  __typename?: 'MG2BorrowerGetResponseBag';
  data?: Maybe<Mg2BorrowerGetResponse>;
  error?: Maybe<Mg2BorrowerGetResponseError>;
};

export type Mg2BorrowerGetResponse = {
  __typename?: 'MG2BorrowerGetResponse';
  /** Данные заёмщика */
  main?: Maybe<Mg2Borrower>;
  /** Супруг */
  spouse?: Maybe<Mg2CoBorrower>;
  /** Созаёмщики */
  coBorrowers: Array<Mg2CoBorrower>;
  /** Валидации */
  validation: Mg2BorrowerValidations;
  /** Бумажная ли анкета */
  isPaperForm: Scalars['Boolean'];
};

/** Данные заёмщика (Анкета) */
export type Mg2Borrower = {
  __typename?: 'MG2Borrower';
  id: Scalars['ID'];
  /** Личные данные */
  personal?: Maybe<Mg2Personal>;
  /** Контактные данные */
  contact?: Maybe<Mg2Contact>;
  /** Идентификация */
  identification?: Maybe<Mg2Identification>;
  /** Регистрация */
  residency?: Maybe<Mg2Residency>;
  /** Семья */
  family?: Maybe<Mg2Family>;
  /** Доход */
  incomeInfo?: Maybe<Mg2IncomeInfo>;
  /** Расход */
  expenseInfo?: Maybe<Mg2ExpenseInfo>;
  /** Имущество */
  assetInfo?: Maybe<Mg2AssetInfo>;
  /** Материнский капитал */
  maternityCapital?: Maybe<Mg2MaternityCapital>;
  /** Рефинансирование */
  refinance?: Maybe<Mg2RefinanceFolders>;
  /** Дополнительная информация */
  extraInfo?: Maybe<Mg2BorrowerExtra>;
};

export type Mg2Personal = {
  __typename?: 'MG2Personal';
  /** Изменения ФИО */
  nameChangesList: Array<Mg2PersonalNameChange>;
  /** Образование */
  education?: Maybe<Mg2PersonalEducation>;
  /** Военная служба */
  military?: Maybe<Mg2PersonalMilitary>;
  /** Общий трудовой стаж */
  workExperience?: Maybe<Mg2PersonalWorkExperience>;
};

export type Mg2PersonalNameChange = {
  __typename?: 'MG2PersonalNameChange';
  changeDate?: Maybe<Scalars['LocalDate']>;
  previousName?: Maybe<Mg2FullName>;
};

export enum Mg2PersonalEducation {
  EducationSecondary = 'EDUCATION_SECONDARY',
  EducationSecondarySpecial = 'EDUCATION_SECONDARY_SPECIAL',
  EducationUnfinishedHigher = 'EDUCATION_UNFINISHED_HIGHER',
  EducationHigher = 'EDUCATION_HIGHER',
  EducationSeveralHigher = 'EDUCATION_SEVERAL_HIGHER',
  EducationAcademicDegree = 'EDUCATION_ACADEMIC_DEGREE'
}

export type Mg2PersonalMilitary = {
  __typename?: 'MG2PersonalMilitary';
  /** Воинская обязанность */
  duty?: Maybe<Mg2PersonalMilitaryDuty>;
  /** Военный билет или приписное свидетельство */
  folder?: Maybe<Mg2FileFolder>;
};

export enum Mg2PersonalMilitaryDuty {
  MilitaryDutyNone = 'MILITARY_DUTY_NONE',
  MilitaryDutyUnfit = 'MILITARY_DUTY_UNFIT',
  MilitaryDutyReserve = 'MILITARY_DUTY_RESERVE',
  MilitaryDutyPostponed = 'MILITARY_DUTY_POSTPONED',
  MilitaryDutyServed = 'MILITARY_DUTY_SERVED'
}

export type Mg2FileFolder = {
  __typename?: 'MG2FileFolder';
  fileList: Array<Mg2File>;
  reworkList: Array<Mg2File>;
  verdictSealedValue?: Maybe<Mg2FolderVerdictEnum>;
  rejectedReason?: Maybe<Scalars['String']>;
};

export type Mg2File = {
  __typename?: 'MG2File';
  fileId: Scalars['String'];
  token: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
};

export enum Mg2FolderVerdictEnum {
  Accepted = 'ACCEPTED',
  Skipped = 'SKIPPED',
  Rejected = 'REJECTED'
}

export enum Mg2PersonalWorkExperience {
  WorkExperienceUpTo_6Month = 'WORK_EXPERIENCE_UP_TO_6_MONTH',
  WorkExperienceUpTo_1Year = 'WORK_EXPERIENCE_UP_TO_1_YEAR',
  WorkExperienceUpTo_3Years = 'WORK_EXPERIENCE_UP_TO_3_YEARS',
  WorkExperienceUpTo_5Years = 'WORK_EXPERIENCE_UP_TO_5_YEARS',
  WorkExperienceUpTo_10Years = 'WORK_EXPERIENCE_UP_TO_10_YEARS',
  WorkExperienceMoreThan_10Years = 'WORK_EXPERIENCE_MORE_THAN_10_YEARS'
}

export type Mg2Contact = {
  __typename?: 'MG2Contact';
  /** Телефон */
  phone?: Maybe<Scalars['String']>;
  /** Дополнительный телефон */
  secondaryPhone?: Maybe<Scalars['String']>;
  /** Почта */
  email: Scalars['String'];
};

export type Mg2Identification = {
  __typename?: 'MG2Identification';
  /** СНИЛС */
  snils?: Maybe<Mg2Snils>;
  /** Паспорт гражданина РФ */
  passport?: Maybe<Mg2Passport>;
};

export type Mg2Snils = {
  __typename?: 'MG2Snils';
  number: Scalars['String'];
  folder?: Maybe<Mg2FileFolder>;
};

export type Mg2Passport = {
  __typename?: 'MG2Passport';
  /** ФИО */
  name?: Maybe<Mg2FullName>;
  /** Пол */
  sex?: Maybe<Mg2PersonalSex>;
  /** Гражданство */
  citizenship?: Maybe<Mg2PassportCitizenship>;
  /** Дата рождения */
  birthDate?: Maybe<Scalars['LocalDate']>;
  /** Место рождения */
  birthPlace: Scalars['String'];
  /** Паспорт гражданина РФ */
  russian?: Maybe<Mg2PassportRussian>;
  /** Паспорт иностранного гражданина */
  foreign?: Maybe<Mg2PassportForeign>;
};

export enum Mg2PersonalSex {
  SexMale = 'SEX_MALE',
  SexFemale = 'SEX_FEMALE'
}

export type Mg2PassportRussian = {
  __typename?: 'MG2PassportRussian';
  series: Scalars['String'];
  number: Scalars['String'];
  unitCode: Scalars['String'];
  authority: Scalars['String'];
  issueDate?: Maybe<Scalars['LocalDate']>;
  folder?: Maybe<Mg2FileFolder>;
};

export type Mg2PassportForeign = {
  __typename?: 'MG2PassportForeign';
  number: Scalars['String'];
  authority: Scalars['String'];
  issueDate?: Maybe<Scalars['LocalDate']>;
  expiryDate?: Maybe<Scalars['LocalDate']>;
  identifier: Scalars['String'];
  folder?: Maybe<Mg2FileFolder>;
  residenceFolder?: Maybe<Mg2FileFolder>;
};

export type Mg2Residency = {
  __typename?: 'MG2Residency';
  /** Адрес регистрации */
  legalAddress?: Maybe<Mg2AddressLegal>;
  /** Адрес фактического проживания */
  actualAddress?: Maybe<Mg2AddressActual>;
};

/** Адрес фактического проживания */
export type Mg2AddressLegal = {
  __typename?: 'MG2AddressLegal';
  /** Временный ли адрес */
  temporary: Scalars['Boolean'];
  expiryDate?: Maybe<Scalars['LocalDate']>;
  /** Адрес */
  address?: Maybe<Mg2AddressDadata>;
};

/** DADATA адрес */
export type Mg2AddressDadata = {
  __typename?: 'MG2AddressDadata';
  value?: Maybe<Scalars['String']>;
  unrestrictedValue?: Maybe<Scalars['String']>;
  /** Json из адреса ДаДаты */
  json?: Maybe<Scalars['String']>;
};

/** Адрес фактического проживания */
export type Mg2AddressActual = {
  __typename?: 'MG2AddressActual';
  /** Отличается от адреса регистрации */
  active: Scalars['Boolean'];
  /** Адрес */
  address?: Maybe<Mg2AddressDadata>;
  /** Основание для проживания */
  residenceRight?: Maybe<Mg2AddressActualRight>;
};

export enum Mg2AddressActualRight {
  ResidenceRightOwned = 'RESIDENCE_RIGHT_OWNED',
  ResidenceRightCommercialRent = 'RESIDENCE_RIGHT_COMMERCIAL_RENT',
  ResidenceRightSocialRent = 'RESIDENCE_RIGHT_SOCIAL_RENT',
  ResidenceRightRelatives = 'RESIDENCE_RIGHT_RELATIVES',
  ResidenceRightOther = 'RESIDENCE_RIGHT_OTHER'
}

export type Mg2Family = {
  __typename?: 'MG2Family';
  /** Семейное положение */
  maritalStatus?: Maybe<Mg2MaritalStatus>;
  /** Участие супруга(и) в сделке */
  spouseInvolvement?: Maybe<Mg2SpouseInvolvement>;
  /** Дети */
  children?: Maybe<Mg2FamilyChildren>;
};

/** Дети */
export type Mg2FamilyChildren = {
  __typename?: 'MG2FamilyChildren';
  /** Список детей */
  childrenList: Array<Mg2FamilyChild>;
  /** Свидетельства о рождении детей */
  childrenBirthCertificateFolder?: Maybe<Mg2FileFolder>;
  /** Документы, подтверждающие инвалидность детей */
  childrenDisabilityConfirmationFolder?: Maybe<Mg2FileFolder>;
};

/** Ребёнок */
export type Mg2FamilyChild = {
  __typename?: 'MG2FamilyChild';
  /** Дата рождения */
  birthDate?: Maybe<Scalars['LocalDate']>;
  /** Инвалидность */
  disability: Scalars['Boolean'];
};

export type Mg2IncomeInfo = {
  __typename?: 'MG2IncomeInfo';
  /** Основной доход */
  primary?: Maybe<Mg2IncomePrimary>;
  /** Доход по совместительству */
  secondaryList: Array<Mg2IncomeSecondary>;
  /** Аренда */
  rent?: Maybe<Mg2IncomeBase>;
  /** Пенсия */
  pension?: Maybe<Mg2IncomeBase>;
  /** Иное */
  other?: Maybe<Mg2IncomeBase>;
};

/** Основной доход */
export type Mg2IncomePrimary = {
  __typename?: 'MG2IncomePrimary';
  /** Сумма */
  amount: Scalars['Float'];
  /** Подтверждение дохода */
  confirmation?: Maybe<Mg2CalculationIncomeConfirmation>;
  /** Трудоустройство */
  employment?: Maybe<Mg2Employment>;
  /** Зарплатный проект */
  salaryBank?: Maybe<Mg2BankSalary>;
  /** 2-НДФЛ */
  ndflFolder?: Maybe<Mg2FileFolder>;
  /** Справка по форме Банка */
  bankFormFolder?: Maybe<Mg2FileFolder>;
  /** Налоговая декларация */
  declarationFolder?: Maybe<Mg2FileFolder>;
  /** Выписка со счёта */
  financeFlowFolder?: Maybe<Mg2FileFolder>;
  /** Справка о самозанятости */
  selfEmployedInfoFolder?: Maybe<Mg2FileFolder>;
  /** Справка о доходах самозанятого */
  selfEmployedTaxesInfoFolder?: Maybe<Mg2FileFolder>;
};

/** Трудоустройство (Анкета/Заемщик) */
export type Mg2Employment = {
  __typename?: 'MG2Employment';
  /** Форма занятости */
  employmentType?: Maybe<Mg2EmploymentEnum>;
  /** Организация */
  organization?: Maybe<Mg2Organization>;
  /** Рабочий телефон */
  workPhone: Scalars['String'];
  /** Добавочный номер к рабочему телефону */
  workPhoneExtension?: Maybe<Scalars['String']>;
  /** Должность */
  position: Scalars['String'];
  /** Дата трудоустройства */
  employmentDate?: Maybe<Scalars['LocalDate']>;
  /** Испытательный срок пройден */
  probationPassed: Scalars['Boolean'];
  /** Трудовая книжка */
  employmentRecordFolder?: Maybe<Mg2FileFolder>;
  /** Разрешение на работу */
  workPermitFolder?: Maybe<Mg2FileFolder>;
  /** ЕГРИП */
  egripFolder?: Maybe<Mg2FileFolder>;
  /** ЕГРЮЛ */
  egrulFolder?: Maybe<Mg2FileFolder>;
};

/** Организация */
export type Mg2Organization = {
  __typename?: 'MG2Organization';
  /** Название */
  name: Scalars['String'];
  /** ИНН */
  inn: Scalars['String'];
  /** ОГРН/ОГРНИП */
  ogrn: Scalars['String'];
  /** ОКВЭД */
  okved: Scalars['String'];
  /** Дата регистрации */
  registrationDate?: Maybe<Scalars['LocalDate']>;
  /** Адрес регистрации */
  registrationAddress?: Maybe<Mg2AddressDadata>;
  /** Фактический адрес */
  actualAddress?: Maybe<Mg2AddressDadata>;
  /** Телефон */
  phone?: Maybe<Scalars['String']>;
  /** Сайт */
  site: Scalars['String'];
  /** Сфера деятельности */
  industry: Scalars['String'];
  /** Количество сотрудников */
  employeeCount?: Maybe<Mg2EmployeeCount>;
};

export enum Mg2EmployeeCount {
  EmployeeCountUpTo_10 = 'EMPLOYEE_COUNT_UP_TO_10',
  EmployeeCountUpTo_20 = 'EMPLOYEE_COUNT_UP_TO_20',
  EmployeeCountUpTo_50 = 'EMPLOYEE_COUNT_UP_TO_50',
  EmployeeCountUpTo_100 = 'EMPLOYEE_COUNT_UP_TO_100',
  EmployeeCountUpTo_200 = 'EMPLOYEE_COUNT_UP_TO_200',
  EmployeeCountUpTo_500 = 'EMPLOYEE_COUNT_UP_TO_500',
  EmployeeCountUpTo_1000 = 'EMPLOYEE_COUNT_UP_TO_1000',
  EmployeeCountMoreThan_1000 = 'EMPLOYEE_COUNT_MORE_THAN_1000'
}

/** Доход по совместительству */
export type Mg2IncomeSecondary = {
  __typename?: 'MG2IncomeSecondary';
  /** Сумма */
  amount: Scalars['Float'];
  /** Подтверждение дохода */
  confirmation?: Maybe<Mg2CalculationIncomeConfirmation>;
  /** Трудоустройство */
  employment?: Maybe<Mg2Employment>;
};

/** Расходы: кредит, алименты, аренда, или доходы: аренда, пенсия, иное */
export type Mg2IncomeBase = {
  __typename?: 'MG2IncomeBase';
  /** Есть */
  active: Scalars['Boolean'];
  /** Сумма */
  amount: Scalars['Float'];
};

/** Расходы */
export type Mg2ExpenseInfo = {
  __typename?: 'MG2ExpenseInfo';
  credit?: Maybe<Mg2IncomeBase>;
  rent?: Maybe<Mg2IncomeBase>;
  alimony?: Maybe<Mg2IncomeBase>;
};

/** Имущество */
export type Mg2AssetInfo = {
  __typename?: 'MG2AssetInfo';
  /** Транспортные средства */
  vehiclesList: Array<Mg2AssetVehicle>;
  /** Недвижимость */
  propertiesList: Array<Mg2AssetProperty>;
};

/** Транспортное средство */
export type Mg2AssetVehicle = {
  __typename?: 'MG2AssetVehicle';
  /** Государственный номер */
  regNumber: Scalars['String'];
  /** Модель */
  model: Scalars['String'];
  /** Год выпуска */
  year: Scalars['Float'];
  /** Стоимость */
  cost: Scalars['Float'];
  /** В залоге */
  pledged: Scalars['Boolean'];
};

/** Недвижимость */
export type Mg2AssetProperty = {
  __typename?: 'MG2AssetProperty';
  /** Тип недвижимости */
  propertyType?: Maybe<Mg2AssetPropertyType>;
  /** Тип собственности */
  propertyOwnership?: Maybe<Mg2AssetPropertyOwnership>;
  /** Основание собственности */
  propertyRight?: Maybe<Mg2AssetPropertyRight>;
  /** Адрес */
  address: Mg2AddressDadata;
  /** Кадастровый номер */
  regNumber?: Maybe<Scalars['String']>;
  /** Площадь */
  area: Scalars['Float'];
  /** Год приобретения */
  year: Scalars['Float'];
  /** Стоимость */
  cost: Scalars['Float'];
  /** В залоге? */
  pledged: Scalars['Boolean'];
};

export enum Mg2AssetPropertyType {
  PropertyTypeFlat = 'PROPERTY_TYPE_FLAT',
  PropertyTypeRoom = 'PROPERTY_TYPE_ROOM',
  PropertyTypeShare = 'PROPERTY_TYPE_SHARE',
  PropertyTypeApartment = 'PROPERTY_TYPE_APARTMENT',
  PropertyTypeHouse = 'PROPERTY_TYPE_HOUSE',
  PropertyTypeHouseWithLot = 'PROPERTY_TYPE_HOUSE_WITH_LOT',
  PropertyTypeTownhouse = 'PROPERTY_TYPE_TOWNHOUSE',
  PropertyTypeCommercial = 'PROPERTY_TYPE_COMMERCIAL',
  PropertyTypeLot = 'PROPERTY_TYPE_LOT',
  PropertyTypeParkingPlace = 'PROPERTY_TYPE_PARKING_PLACE',
  PropertyTypeStoreroom = 'PROPERTY_TYPE_STOREROOM'
}

export enum Mg2AssetPropertyOwnership {
  PropertyOwnershipSole = 'PROPERTY_OWNERSHIP_SOLE',
  PropertyOwnershipShare = 'PROPERTY_OWNERSHIP_SHARE',
  PropertyOwnershipJoint = 'PROPERTY_OWNERSHIP_JOINT'
}

export enum Mg2AssetPropertyRight {
  PropertyRightPurchase = 'PROPERTY_RIGHT_PURCHASE',
  PropertyRightPrivatization = 'PROPERTY_RIGHT_PRIVATIZATION',
  PropertyRightHeritage = 'PROPERTY_RIGHT_HERITAGE',
  PropertyRightGrant = 'PROPERTY_RIGHT_GRANT'
}

/** Материнский капитал */
export type Mg2MaternityCapital = {
  __typename?: 'MG2MaternityCapital';
  /** Сертификат и справка о размере (остатке) */
  folder?: Maybe<Mg2FileFolder>;
};

/** Рефинансирование */
export type Mg2RefinanceFolders = {
  __typename?: 'Mg2RefinanceFolders';
  /** Кредитный договор */
  agreementFolder?: Maybe<Mg2FileFolder>;
  /** Справка об остатке задолженности */
  remainingAmountFolder?: Maybe<Mg2FileFolder>;
  /** Справка о реквизитах счёта Клиента */
  accountDetailsFolder?: Maybe<Mg2FileFolder>;
  /** График погашения задолженности */
  paymentScheduleFolder?: Maybe<Mg2FileFolder>;
};

/** Дополнительная информация */
export type Mg2BorrowerExtra = {
  __typename?: 'Mg2BorrowerExtra';
  /** Комментарий */
  comment: Scalars['String'];
  /** Дополнительные файлы */
  folder?: Maybe<Mg2FileFolder>;
  /** Бумажная анкета */
  vtbPaperFolder?: Maybe<Mg2FileFolder>;
  trancheInfo?: Maybe<Mg2TrancheInfo>;
};

export type Mg2TrancheInfo = {
  __typename?: 'MG2TrancheInfo';
  tranchesList: Array<Mg2Tranche>;
};

export type Mg2Tranche = {
  __typename?: 'MG2Tranche';
  amount?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['LocalDate']>;
};

export type Mg2CoBorrower = {
  __typename?: 'MG2CoBorrower';
  /** Данные созаёмщика */
  main?: Maybe<Mg2Borrower>;
  /** Участие доходом */
  incomeParticipation: Scalars['Boolean'];
};

export type Mg2BorrowerValidations = {
  __typename?: 'MG2BorrowerValidations';
  /** Валидации заёмщика */
  borrower?: Maybe<Array<Mg2BorrowerValidationError>>;
  /** Валидации супруга */
  spouse?: Maybe<Array<Mg2BorrowerValidationError>>;
  /** Валидации созаёмщиков */
  coBorrowers: Array<Array<Mg2BorrowerValidationError>>;
};

export type Mg2BorrowerValidationError = {
  __typename?: 'MG2BorrowerValidationError';
  /** Поле */
  field: Mg2BorrowerValidationErrorFieldType;
};

export enum Mg2BorrowerValidationErrorFieldType {
  UnknownField = 'UNKNOWN_FIELD',
  PersonalFieldUnknown = 'PERSONAL_FIELD_UNKNOWN',
  PersonalFieldNameChangeDate = 'PERSONAL_FIELD_NAME_CHANGE_DATE',
  PersonalFieldNameChangePreviousFirstName = 'PERSONAL_FIELD_NAME_CHANGE_PREVIOUS_FIRST_NAME',
  PersonalFieldNameChangePreviousMiddleName = 'PERSONAL_FIELD_NAME_CHANGE_PREVIOUS_MIDDLE_NAME',
  PersonalFieldNameChangePreviousLastName = 'PERSONAL_FIELD_NAME_CHANGE_PREVIOUS_LAST_NAME',
  PersonalFieldEducation = 'PERSONAL_FIELD_EDUCATION',
  PersonalFieldMilitaryDuty = 'PERSONAL_FIELD_MILITARY_DUTY',
  PersonalFieldWorkExperience = 'PERSONAL_FIELD_WORK_EXPERIENCE',
  PersonalFieldMilitaryFolder = 'PERSONAL_FIELD_MILITARY_FOLDER',
  ContactFieldUnknown = 'CONTACT_FIELD_UNKNOWN',
  ContactFieldPhone = 'CONTACT_FIELD_PHONE',
  ContactFieldPhoneSecondary = 'CONTACT_FIELD_PHONE_SECONDARY',
  ContactFieldEmail = 'CONTACT_FIELD_EMAIL',
  IdentificationFieldUnknown = 'IDENTIFICATION_FIELD_UNKNOWN',
  IdentificationFieldSnilsNumber = 'IDENTIFICATION_FIELD_SNILS_NUMBER',
  IdentificationFieldFirstName = 'IDENTIFICATION_FIELD_FIRST_NAME',
  IdentificationFieldMiddleName = 'IDENTIFICATION_FIELD_MIDDLE_NAME',
  IdentificationFieldLastName = 'IDENTIFICATION_FIELD_LAST_NAME',
  IdentificationFieldSex = 'IDENTIFICATION_FIELD_SEX',
  IdentificationFieldCitizenship = 'IDENTIFICATION_FIELD_CITIZENSHIP',
  IdentificationFieldBirthDate = 'IDENTIFICATION_FIELD_BIRTH_DATE',
  IdentificationFieldBirthPlace = 'IDENTIFICATION_FIELD_BIRTH_PLACE',
  IdentificationFieldRussianPassportSeries = 'IDENTIFICATION_FIELD_RUSSIAN_PASSPORT_SERIES',
  IdentificationFieldRussianPassportNumber = 'IDENTIFICATION_FIELD_RUSSIAN_PASSPORT_NUMBER',
  IdentificationFieldRussianPassportUnitCode = 'IDENTIFICATION_FIELD_RUSSIAN_PASSPORT_UNIT_CODE',
  IdentificationFieldRussianPassportAuthority = 'IDENTIFICATION_FIELD_RUSSIAN_PASSPORT_AUTHORITY',
  IdentificationFieldRussianPassportIssueDate = 'IDENTIFICATION_FIELD_RUSSIAN_PASSPORT_ISSUE_DATE',
  IdentificationFieldForeignPassportNumber = 'IDENTIFICATION_FIELD_FOREIGN_PASSPORT_NUMBER',
  IdentificationFieldForeignPassportAuthority = 'IDENTIFICATION_FIELD_FOREIGN_PASSPORT_AUTHORITY',
  IdentificationFieldForeignPassportIssueDate = 'IDENTIFICATION_FIELD_FOREIGN_PASSPORT_ISSUE_DATE',
  IdentificationFieldForeignPassportExpiryDate = 'IDENTIFICATION_FIELD_FOREIGN_PASSPORT_EXPIRY_DATE',
  IdentificationFieldForeignPassportIdentifier = 'IDENTIFICATION_FIELD_FOREIGN_PASSPORT_IDENTIFIER',
  IdentificationFieldSnilsFolder = 'IDENTIFICATION_FIELD_SNILS_FOLDER',
  IdentificationFieldRussianPassportFolder = 'IDENTIFICATION_FIELD_RUSSIAN_PASSPORT_FOLDER',
  IdentificationFieldForeignPassportFolder = 'IDENTIFICATION_FIELD_FOREIGN_PASSPORT_FOLDER',
  IdentificationFieldForeignPassportResidencePermitFolder = 'IDENTIFICATION_FIELD_FOREIGN_PASSPORT_RESIDENCE_PERMIT_FOLDER',
  ResidencyFieldUnknown = 'RESIDENCY_FIELD_UNKNOWN',
  ResidencyFieldLegalAddress = 'RESIDENCY_FIELD_LEGAL_ADDRESS',
  ResidencyFieldTemporaryRegistrationExpiryDate = 'RESIDENCY_FIELD_TEMPORARY_REGISTRATION_EXPIRY_DATE',
  ResidencyFieldActualAddress = 'RESIDENCY_FIELD_ACTUAL_ADDRESS',
  ResidencyFieldActualAddressResidenceRight = 'RESIDENCY_FIELD_ACTUAL_ADDRESS_RESIDENCE_RIGHT',
  FamilyFieldUnknown = 'FAMILY_FIELD_UNKNOWN',
  FamilyFieldMaritalStatus = 'FAMILY_FIELD_MARITAL_STATUS',
  FamilyFieldSpouseInvolvement = 'FAMILY_FIELD_SPOUSE_INVOLVEMENT',
  FamilyFieldChildBirthDate = 'FAMILY_FIELD_CHILD_BIRTH_DATE',
  FamilyFieldChildBirthCertificateFolder = 'FAMILY_FIELD_CHILD_BIRTH_CERTIFICATE_FOLDER',
  FamilyFieldChildDisabilityConfirmationFolder = 'FAMILY_FIELD_CHILD_DISABILITY_CONFIRMATION_FOLDER',
  FamilyFieldChildren = 'FAMILY_FIELD_CHILDREN',
  PrimaryIncomeFieldUnknown = 'PRIMARY_INCOME_FIELD_UNKNOWN',
  PrimaryIncomeFieldIncomeAmount = 'PRIMARY_INCOME_FIELD_INCOME_AMOUNT',
  PrimaryIncomeFieldIncomeConfirmation = 'PRIMARY_INCOME_FIELD_INCOME_CONFIRMATION',
  PrimaryIncomeFieldEmploymentType = 'PRIMARY_INCOME_FIELD_EMPLOYMENT_TYPE',
  PrimaryIncomeFieldOrganizationName = 'PRIMARY_INCOME_FIELD_ORGANIZATION_NAME',
  PrimaryIncomeFieldOrganizationInn = 'PRIMARY_INCOME_FIELD_ORGANIZATION_INN',
  PrimaryIncomeFieldOrganizationOgrn = 'PRIMARY_INCOME_FIELD_ORGANIZATION_OGRN',
  PrimaryIncomeFieldOrganizationOkved = 'PRIMARY_INCOME_FIELD_ORGANIZATION_OKVED',
  PrimaryIncomeFieldOrganizationRegistrationDate = 'PRIMARY_INCOME_FIELD_ORGANIZATION_REGISTRATION_DATE',
  PrimaryIncomeFieldOrganizationRegistrationAddress = 'PRIMARY_INCOME_FIELD_ORGANIZATION_REGISTRATION_ADDRESS',
  PrimaryIncomeFieldOrganizationActualAddress = 'PRIMARY_INCOME_FIELD_ORGANIZATION_ACTUAL_ADDRESS',
  PrimaryIncomeFieldOrganizationPhone = 'PRIMARY_INCOME_FIELD_ORGANIZATION_PHONE',
  PrimaryIncomeFieldOrganizationSite = 'PRIMARY_INCOME_FIELD_ORGANIZATION_SITE',
  PrimaryIncomeFieldOrganizationIndustry = 'PRIMARY_INCOME_FIELD_ORGANIZATION_INDUSTRY',
  PrimaryIncomeFieldOrganizationEmployeeCount = 'PRIMARY_INCOME_FIELD_ORGANIZATION_EMPLOYEE_COUNT',
  PrimaryIncomeFieldWorkPhone = 'PRIMARY_INCOME_FIELD_WORK_PHONE',
  PrimaryIncomeFieldWorkPhoneExtension = 'PRIMARY_INCOME_FIELD_WORK_PHONE_EXTENSION',
  PrimaryIncomeFieldPosition = 'PRIMARY_INCOME_FIELD_POSITION',
  PrimaryIncomeFieldEmploymentDate = 'PRIMARY_INCOME_FIELD_EMPLOYMENT_DATE',
  PrimaryIncomeFieldSalaryBankIdentity = 'PRIMARY_INCOME_FIELD_SALARY_BANK_IDENTITY',
  PrimaryIncomeFieldNdflFolder = 'PRIMARY_INCOME_FIELD_NDFL_FOLDER',
  PrimaryIncomeFieldBankFormFolder = 'PRIMARY_INCOME_FIELD_BANK_FORM_FOLDER',
  PrimaryIncomeFieldDeclarationFolder = 'PRIMARY_INCOME_FIELD_DECLARATION_FOLDER',
  PrimaryIncomeFieldFinanceFlowFolder = 'PRIMARY_INCOME_FIELD_FINANCE_FLOW_FOLDER',
  PrimaryIncomeFieldSelfEmployedTaxesInfoFolder = 'PRIMARY_INCOME_FIELD_SELF_EMPLOYED_TAXES_INFO_FOLDER',
  PrimaryIncomeFieldSelfEmployedInfoFolder = 'PRIMARY_INCOME_FIELD_SELF_EMPLOYED_INFO_FOLDER',
  EmploymentFieldUnknown = 'EMPLOYMENT_FIELD_UNKNOWN',
  EmploymentFieldEmploymentType = 'EMPLOYMENT_FIELD_EMPLOYMENT_TYPE',
  EmploymentFieldWorkPhone = 'EMPLOYMENT_FIELD_WORK_PHONE',
  EmploymentFieldWorkPhoneExtension = 'EMPLOYMENT_FIELD_WORK_PHONE_EXTENSION',
  EmploymentFieldPosition = 'EMPLOYMENT_FIELD_POSITION',
  EmploymentFieldEmploymentDate = 'EMPLOYMENT_FIELD_EMPLOYMENT_DATE',
  EmploymentFieldProbationPassed = 'EMPLOYMENT_FIELD_PROBATION_PASSED',
  EmploymentFieldEmploymentRecordFolder = 'EMPLOYMENT_FIELD_EMPLOYMENT_RECORD_FOLDER',
  EmploymentFieldWorkPermitFolder = 'EMPLOYMENT_FIELD_WORK_PERMIT_FOLDER',
  EmploymentFieldEgripFolder = 'EMPLOYMENT_FIELD_EGRIP_FOLDER',
  EmploymentFieldEgrulFolder = 'EMPLOYMENT_FIELD_EGRUL_FOLDER',
  EmploymentFieldOrganizationName = 'EMPLOYMENT_FIELD_ORGANIZATION_NAME',
  EmploymentFieldOrganizationInn = 'EMPLOYMENT_FIELD_ORGANIZATION_INN',
  EmploymentFieldOrganizationOgrn = 'EMPLOYMENT_FIELD_ORGANIZATION_OGRN',
  EmploymentFieldOrganizationOkved = 'EMPLOYMENT_FIELD_ORGANIZATION_OKVED',
  EmploymentFieldOrganizationRegistrationDate = 'EMPLOYMENT_FIELD_ORGANIZATION_REGISTRATION_DATE',
  EmploymentFieldOrganizationRegistrationAddress = 'EMPLOYMENT_FIELD_ORGANIZATION_REGISTRATION_ADDRESS',
  EmploymentFieldOrganizationActualAddress = 'EMPLOYMENT_FIELD_ORGANIZATION_ACTUAL_ADDRESS',
  EmploymentFieldOrganizationPhone = 'EMPLOYMENT_FIELD_ORGANIZATION_PHONE',
  EmploymentFieldOrganizationSite = 'EMPLOYMENT_FIELD_ORGANIZATION_SITE',
  EmploymentFieldOrganizationIndustry = 'EMPLOYMENT_FIELD_ORGANIZATION_INDUSTRY',
  EmploymentFieldOrganizationEmployeeCount = 'EMPLOYMENT_FIELD_ORGANIZATION_EMPLOYEE_COUNT',
  SecondaryIncomeFieldUnknown = 'SECONDARY_INCOME_FIELD_UNKNOWN',
  SecondaryIncomeFieldIncomeAmount = 'SECONDARY_INCOME_FIELD_INCOME_AMOUNT',
  SecondaryIncomeFieldIncomeConfirmation = 'SECONDARY_INCOME_FIELD_INCOME_CONFIRMATION',
  SecondaryIncomeFieldEmploymentType = 'SECONDARY_INCOME_FIELD_EMPLOYMENT_TYPE',
  SecondaryIncomeFieldOrganizationName = 'SECONDARY_INCOME_FIELD_ORGANIZATION_NAME',
  SecondaryIncomeFieldOrganizationInn = 'SECONDARY_INCOME_FIELD_ORGANIZATION_INN',
  SecondaryIncomeFieldOrganizationOgrn = 'SECONDARY_INCOME_FIELD_ORGANIZATION_OGRN',
  SecondaryIncomeFieldOrganizationOkved = 'SECONDARY_INCOME_FIELD_ORGANIZATION_OKVED',
  SecondaryIncomeFieldOrganizationRegistrationDate = 'SECONDARY_INCOME_FIELD_ORGANIZATION_REGISTRATION_DATE',
  SecondaryIncomeFieldOrganizationRegistrationAddress = 'SECONDARY_INCOME_FIELD_ORGANIZATION_REGISTRATION_ADDRESS',
  SecondaryIncomeFieldOrganizationActualAddress = 'SECONDARY_INCOME_FIELD_ORGANIZATION_ACTUAL_ADDRESS',
  SecondaryIncomeFieldOrganizationPhone = 'SECONDARY_INCOME_FIELD_ORGANIZATION_PHONE',
  SecondaryIncomeFieldOrganizationSite = 'SECONDARY_INCOME_FIELD_ORGANIZATION_SITE',
  SecondaryIncomeFieldOrganizationIndustry = 'SECONDARY_INCOME_FIELD_ORGANIZATION_INDUSTRY',
  SecondaryIncomeFieldOrganizationEmployeeCount = 'SECONDARY_INCOME_FIELD_ORGANIZATION_EMPLOYEE_COUNT',
  SecondaryIncomeFieldWorkPhone = 'SECONDARY_INCOME_FIELD_WORK_PHONE',
  SecondaryIncomeFieldWorkPhoneExtension = 'SECONDARY_INCOME_FIELD_WORK_PHONE_EXTENSION',
  SecondaryIncomeFieldPosition = 'SECONDARY_INCOME_FIELD_POSITION',
  SecondaryIncomeFieldEmploymentDate = 'SECONDARY_INCOME_FIELD_EMPLOYMENT_DATE',
  AdditionalIncomeFieldUnknown = 'ADDITIONAL_INCOME_FIELD_UNKNOWN',
  AdditionalIncomeFieldRentIncomeAmount = 'ADDITIONAL_INCOME_FIELD_RENT_INCOME_AMOUNT',
  AdditionalIncomeFieldPensionIncomeAmount = 'ADDITIONAL_INCOME_FIELD_PENSION_INCOME_AMOUNT',
  AdditionalIncomeFieldOtherIncomeAmount = 'ADDITIONAL_INCOME_FIELD_OTHER_INCOME_AMOUNT',
  ExpenseFieldUnknown = 'EXPENSE_FIELD_UNKNOWN',
  ExpenseFieldCreditAmount = 'EXPENSE_FIELD_CREDIT_AMOUNT',
  ExpenseFieldRentAmount = 'EXPENSE_FIELD_RENT_AMOUNT',
  ExpenseFieldAlimonyAmount = 'EXPENSE_FIELD_ALIMONY_AMOUNT',
  VehicleAssetFieldUnknown = 'VEHICLE_ASSET_FIELD_UNKNOWN',
  VehicleAssetFieldRegNumber = 'VEHICLE_ASSET_FIELD_REG_NUMBER',
  VehicleAssetFieldModel = 'VEHICLE_ASSET_FIELD_MODEL',
  VehicleAssetFieldYear = 'VEHICLE_ASSET_FIELD_YEAR',
  VehicleAssetFieldCost = 'VEHICLE_ASSET_FIELD_COST',
  PropertyAssetFieldUnknown = 'PROPERTY_ASSET_FIELD_UNKNOWN',
  PropertyAssetFieldType = 'PROPERTY_ASSET_FIELD_TYPE',
  PropertyAssetFieldOwnership = 'PROPERTY_ASSET_FIELD_OWNERSHIP',
  PropertyAssetFieldRight = 'PROPERTY_ASSET_FIELD_RIGHT',
  PropertyAssetFieldAddress = 'PROPERTY_ASSET_FIELD_ADDRESS',
  PropertyAssetFieldRegNumber = 'PROPERTY_ASSET_FIELD_REG_NUMBER',
  PropertyAssetFieldArea = 'PROPERTY_ASSET_FIELD_AREA',
  PropertyAssetFieldYear = 'PROPERTY_ASSET_FIELD_YEAR',
  PropertyAssetFieldCost = 'PROPERTY_ASSET_FIELD_COST'
}

export type Mg2BorrowerGetResponseError = ServiceError;

export type Mg2GetFullDynamoResponseBag = {
  __typename?: 'MG2GetFullDynamoResponseBag';
  data?: Maybe<Mg2GetFullDynamoResponse>;
  error?: Maybe<Mg2GetFullDynamoResponseError>;
};

/** Динамические данные всех заемщиков */
export type Mg2GetFullDynamoResponse = {
  __typename?: 'MG2GetFullDynamoResponse';
  main: Mg2DynamoRequirementsType;
  spouse: Mg2DynamoRequirementsType;
  coborrowers: Array<Mg2DynamoCoborrower>;
};

/** Динамические данные заёмщика (Анкета) */
export type Mg2DynamoRequirementsType = {
  __typename?: 'MG2DynamoRequirementsType';
  /** Личные данные */
  personal?: Maybe<Mg2DynamoPersonalType>;
  /** Контактные данные */
  contact?: Maybe<Mg2DynamoContactType>;
  /** Идентификация */
  identification?: Maybe<Mg2DynamoIdentificationType>;
  /** Место проживания */
  residency?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Семья */
  family?: Maybe<Mg2DynamoFamilyType>;
  /** Доход */
  incomeInfo?: Maybe<Mg2DynamoIncomeType>;
  /** Расход */
  expenseInfo?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Имущество */
  assetInfo?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Материнский капитал */
  maternityCapital?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Рефинансирование */
  refinance?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Дополнительная информация */
  extraInfo?: Maybe<Mg2DynamoExtraRequirementsType>;
};

export type Mg2DynamoPersonalType = {
  __typename?: 'MG2DynamoPersonalType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Военная служба */
  military?: Maybe<Mg2DynamoPersonalMilitaryType>;
  /** Общий трудовой стаж */
  workExperience?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoBasicRequirementsType = {
  __typename?: 'MG2DynamoBasicRequirementsType';
  /** Обязательность */
  relevance?: Maybe<Mg2DynamoRelevance>;
};

export enum Mg2DynamoRelevance {
  RelevanceMandatory = 'RELEVANCE_MANDATORY',
  RelevanceOptional = 'RELEVANCE_OPTIONAL',
  RelevanceIrrelevant = 'RELEVANCE_IRRELEVANT'
}

export type Mg2DynamoPersonalMilitaryType = {
  __typename?: 'MG2DynamoPersonalMilitaryType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Военный билет или приписное свидетельство */
  folder?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoContactType = {
  __typename?: 'MG2DynamoContactType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Дополнительный телефон */
  secondaryPhone?: Maybe<Mg2DynamoBasicRequirementsType>;
  email?: Maybe<Mg2DynamoBasicRequirementsType>;
  phone?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoIdentificationType = {
  __typename?: 'MG2DynamoIdentificationType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** СНИЛС */
  snils?: Maybe<Mg2DynamoIdentificationSnilsType>;
  /** Паспорт */
  passport?: Maybe<Mg2DynamoIdentificationPassportType>;
};

export type Mg2DynamoIdentificationSnilsType = {
  __typename?: 'MG2DynamoIdentificationSnilsType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** СНИЛС */
  folder?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoIdentificationPassportType = {
  __typename?: 'MG2DynamoIdentificationPassportType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Данные паспорта */
  data?: Maybe<Mg2DynamoIdentificationPassporDatatType>;
  foreignPassport?: Maybe<Mg2DynamoBlockRequirementsType>;
  russianPassport?: Maybe<Mg2DynamoIdentificationRussianPassportType>;
};

export type Mg2DynamoIdentificationPassporDatatType = {
  __typename?: 'MG2DynamoIdentificationPassporDatatType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  birthDate?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Место рождения */
  birthPlace?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Гражданство */
  citizenship?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** ФИО */
  fullName?: Maybe<Mg2DynamoBasicRequirementsType>;
  sex?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoBlockRequirementsType = {
  __typename?: 'MG2DynamoBlockRequirementsType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoIdentificationRussianPassportType = {
  __typename?: 'MG2DynamoIdentificationRussianPassportType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  data?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Файлы */
  folder?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoFamilyType = {
  __typename?: 'MG2DynamoFamilyType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Дети */
  children?: Maybe<Mg2DynamoFamilyChildrenType>;
};

export type Mg2DynamoFamilyChildrenType = {
  __typename?: 'MG2DynamoFamilyChildrenType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Свидетельства о рождении детей */
  birthCertificateFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Документы, подтверждающие инвалидность детей */
  disabilityConfirmationFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoIncomeType = {
  __typename?: 'MG2DynamoIncomeType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Основной доход */
  primary?: Maybe<Mg2DynamoIncomePrimaryType>;
  /** Дополнительный доход */
  secondary?: Maybe<Mg2DynamoIncomeSecondaryType>;
  /** Аренда */
  rent?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Пенсия */
  pension?: Maybe<Mg2DynamoBlockRequirementsType>;
  /** Иное */
  other?: Maybe<Mg2DynamoBlockRequirementsType>;
};

export type Mg2DynamoIncomePrimaryType = {
  __typename?: 'MG2DynamoIncomePrimaryType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Трудоустройство */
  employment?: Maybe<Mg2DynamoIncomeEmploymentType>;
  /** 2-НДФЛ */
  ndflFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Справка по форме банка */
  bankFormFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Налоговая декларация */
  declarationFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Выписка со счёта */
  financeFlowFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Справка о доходах самозанятого */
  selfEmployedTaxesInfoFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Справка о самозанятости */
  selfEmployedInfoFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoIncomeEmploymentType = {
  __typename?: 'MG2DynamoIncomeEmploymentType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Организация */
  organization?: Maybe<Mg2DynamoIncomePrimaryEmploymentOrganizationType>;
  /** Трудовая книжка */
  employmentRecordFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Трудовая книжка */
  workPermitFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** ЕГРИП */
  egripFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** ЕГРЮЛ */
  egrulFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  employmentType?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Данные о доходе */
  data?: Maybe<Mg2DynamoIncomePrimaryEmploymentDataType>;
};

export type Mg2DynamoIncomePrimaryEmploymentOrganizationType = {
  __typename?: 'MG2DynamoIncomePrimaryEmploymentOrganizationType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Сайт */
  site?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Количество сотрудников */
  employeeCount?: Maybe<Mg2DynamoBasicRequirementsType>;
  registrationDate?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoIncomePrimaryEmploymentDataType = {
  __typename?: 'MG2DynamoIncomePrimaryEmploymentDataType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Организация */
  organization?: Maybe<Mg2DynamoIncomePrimaryEmploymentOrganizationType>;
  /** Рабочий телефон */
  workPhone?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Дополнительный рабочий телефон */
  workPhoneExtension?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Должность */
  position?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Дата трудоустройства */
  employmentDate?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Испытательный срок */
  probationPassed?: Maybe<Mg2DynamoBasicRequirementsType>;
};

export type Mg2DynamoIncomeSecondaryType = {
  __typename?: 'MG2DynamoIncomeSecondaryType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Трудоустройство */
  employment?: Maybe<Mg2DynamoIncomeEmploymentType>;
};

export type Mg2DynamoExtraRequirementsType = {
  __typename?: 'MG2DynamoExtraRequirementsType';
  /** Требования ко всему блоку */
  self?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Комментарий */
  comment?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Дополнительные файлы */
  folder?: Maybe<Mg2DynamoBasicRequirementsType>;
  /** Бумажная анкета ВТБ */
  vtbPaperFolder?: Maybe<Mg2DynamoBasicRequirementsType>;
  trancheInfo?: Maybe<Mg2DynamoBlockRequirementsType>;
};

export type Mg2DynamoCoborrower = {
  __typename?: 'MG2DynamoCoborrower';
  /** Динамические данные */
  requirements?: Maybe<Mg2DynamoRequirementsType>;
  /** id созаемщика */
  coborrowerId: Scalars['ID'];
};

export type Mg2GetFullDynamoResponseError = ServiceError;

export enum Mg2QuestionnaireFormType {
  QuestionnaireFormUnknown = 'QUESTIONNAIRE_FORM_UNKNOWN',
  QuestionnaireFormVtbPaper = 'QUESTIONNAIRE_FORM_VTB_PAPER'
}

/** Данные заёмщика (Анкета) */
export type Mg2BorrowerInput = {
  id?: InputMaybe<Scalars['ID']>;
  /** Личные данные */
  personal?: InputMaybe<Mg2PersonalInput>;
  /** Контактные данные */
  contact?: InputMaybe<Mg2ContactInput>;
  /** Идентификация */
  identification?: InputMaybe<Mg2IdentificationInput>;
  /** Регистрация */
  residency?: InputMaybe<Mg2ResidencyInput>;
  /** Семья */
  family?: InputMaybe<Mg2FamilyInput>;
  /** Доход */
  incomeInfo?: InputMaybe<Mg2IncomeInfoInput>;
  /** Расход */
  expenseInfo?: InputMaybe<Mg2ExpenseInfoInput>;
  /** Имущество */
  assetInfo?: InputMaybe<Mg2AssetInfoInput>;
  /** Материнский капитал */
  maternityCapital?: InputMaybe<Mg2MaternityCapitalInput>;
  /** Рефинансирование */
  refinance?: InputMaybe<Mg2RefinanceFoldersInput>;
  extraInfo?: InputMaybe<Mg2BorrowerExtraInput>;
};

export type Mg2PersonalInput = {
  nameChangesList?: InputMaybe<Array<Mg2PersonalNameChangeInput>>;
  education?: InputMaybe<Mg2PersonalEducation>;
  military?: InputMaybe<Mg2PersonalMilitaryInput>;
  workExperience?: InputMaybe<Mg2PersonalWorkExperience>;
};

export type Mg2PersonalNameChangeInput = {
  changeDate?: InputMaybe<Scalars['LocalDate']>;
  previousName: Mg2FullNameInput;
};

export type Mg2PersonalMilitaryInput = {
  duty?: InputMaybe<Mg2PersonalMilitaryDuty>;
  folder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2FileFolderInput = {
  fileList: Array<Mg2FileInput>;
};

export type Mg2FileInput = {
  fileId: Scalars['String'];
};

export type Mg2ContactInput = {
  phone?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type Mg2IdentificationInput = {
  snils?: InputMaybe<Mg2SnilsInput>;
  passport?: InputMaybe<Mg2PassportInput>;
};

export type Mg2SnilsInput = {
  number: Scalars['String'];
  folder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2PassportInput = {
  name?: InputMaybe<Mg2FullNameInput>;
  sex?: InputMaybe<Mg2PersonalSex>;
  citizenship?: InputMaybe<Mg2PassportCitizenship>;
  birthDate?: InputMaybe<Scalars['LocalDate']>;
  birthPlace?: InputMaybe<Scalars['String']>;
  russian?: InputMaybe<Mg2PassportRussianInput>;
  foreign?: InputMaybe<Mg2PassportForeignInput>;
};

export type Mg2PassportRussianInput = {
  series: Scalars['String'];
  number: Scalars['String'];
  unitCode: Scalars['String'];
  authority: Scalars['String'];
  issueDate?: InputMaybe<Scalars['LocalDate']>;
  folder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2PassportForeignInput = {
  number: Scalars['String'];
  authority: Scalars['String'];
  issueDate?: InputMaybe<Scalars['LocalDate']>;
  expiryDate?: InputMaybe<Scalars['LocalDate']>;
  identifier: Scalars['String'];
  folder?: InputMaybe<Mg2FileFolderInput>;
  residenceFolder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2ResidencyInput = {
  legalAddress?: InputMaybe<Mg2AddressLegalInput>;
  actualAddress?: InputMaybe<Mg2AddressActualInput>;
};

export type Mg2AddressLegalInput = {
  temporary: Scalars['Boolean'];
  expiryDate?: InputMaybe<Scalars['LocalDate']>;
  address?: InputMaybe<Mg2DadataAddressInput>;
};

export type Mg2DadataAddressInput = {
  value?: InputMaybe<Scalars['String']>;
  unrestrictedValue?: InputMaybe<Scalars['String']>;
  json?: InputMaybe<Scalars['String']>;
};

export type Mg2AddressActualInput = {
  active: Scalars['Boolean'];
  address?: InputMaybe<Mg2DadataAddressInput>;
  residenceRight?: InputMaybe<Mg2AddressActualRight>;
};

export type Mg2FamilyInput = {
  maritalStatus: Mg2MaritalStatus;
  spouseInvolvement: Mg2SpouseInvolvement;
  children?: InputMaybe<Mg2FamilyChildrenInput>;
};

export type Mg2FamilyChildrenInput = {
  childrenList?: InputMaybe<Array<Mg2FamilyChildInput>>;
  childrenBirthCertificateFolder?: InputMaybe<Mg2FileFolderInput>;
  childrenDisabilityConfirmationFolder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2FamilyChildInput = {
  birthDate?: InputMaybe<Scalars['LocalDate']>;
  disability: Scalars['Boolean'];
};

export type Mg2IncomeInfoInput = {
  primary?: InputMaybe<Mg2IncomePrimaryInput>;
  secondaryList?: InputMaybe<Array<Mg2IncomeSecondaryInput>>;
  rent?: InputMaybe<Mg2IncomeBaseInput>;
  pension?: InputMaybe<Mg2IncomeBaseInput>;
  other?: InputMaybe<Mg2IncomeBaseInput>;
};

export type Mg2IncomePrimaryInput = {
  amount: Scalars['Float'];
  confirmation?: InputMaybe<Mg2CalculationIncomeConfirmation>;
  employment?: InputMaybe<Mg2EmploymentInput>;
  salaryBank?: InputMaybe<Mg2BankSalaryInput>;
  ndflFolder?: InputMaybe<Mg2FileFolderInput>;
  bankFormFolder?: InputMaybe<Mg2FileFolderInput>;
  declarationFolder?: InputMaybe<Mg2FileFolderInput>;
  financeFlowFolder?: InputMaybe<Mg2FileFolderInput>;
  selfEmployedInfoFolder?: InputMaybe<Mg2FileFolderInput>;
  selfEmployedTaxesInfoFolder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2EmploymentInput = {
  employmentType?: InputMaybe<Mg2EmploymentEnum>;
  organization?: InputMaybe<Mg2OrganizationInput>;
  workPhone: Scalars['String'];
  workPhoneExtension?: InputMaybe<Scalars['String']>;
  position: Scalars['String'];
  employmentDate?: InputMaybe<Scalars['LocalDate']>;
  probationPassed: Scalars['Boolean'];
  employmentRecordFolder?: InputMaybe<Mg2FileFolderInput>;
  workPermitFolder?: InputMaybe<Mg2FileFolderInput>;
  egripFolder?: InputMaybe<Mg2FileFolderInput>;
  egrulFolder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2OrganizationInput = {
  name: Scalars['String'];
  inn: Scalars['String'];
  ogrn: Scalars['String'];
  okved: Scalars['String'];
  registrationDate?: InputMaybe<Scalars['LocalDate']>;
  registrationAddress?: InputMaybe<Mg2DadataAddressInput>;
  actualAddress?: InputMaybe<Mg2DadataAddressInput>;
  phone: Scalars['String'];
  site: Scalars['String'];
  industry: Scalars['String'];
  employeeCount?: InputMaybe<Mg2EmployeeCount>;
};

export type Mg2IncomeSecondaryInput = {
  amount: Scalars['Float'];
  confirmation?: InputMaybe<Mg2CalculationIncomeConfirmation>;
  employment?: InputMaybe<Mg2EmploymentInput>;
};

export type Mg2IncomeBaseInput = {
  active: Scalars['Boolean'];
  amount: Scalars['Float'];
};

export type Mg2ExpenseInfoInput = {
  credit?: InputMaybe<Mg2IncomeBaseInput>;
  rent?: InputMaybe<Mg2IncomeBaseInput>;
  alimony?: InputMaybe<Mg2IncomeBaseInput>;
};

export type Mg2AssetInfoInput = {
  vehiclesList?: InputMaybe<Array<Mg2AssetVehicleInput>>;
  propertiesList?: InputMaybe<Array<Mg2AssetPropertyInput>>;
};

export type Mg2AssetVehicleInput = {
  regNumber: Scalars['String'];
  model: Scalars['String'];
  year: Scalars['Float'];
  cost: Scalars['Float'];
  pledged: Scalars['Boolean'];
};

export type Mg2AssetPropertyInput = {
  propertyType?: InputMaybe<Mg2AssetPropertyType>;
  propertyOwnership?: InputMaybe<Mg2AssetPropertyOwnership>;
  propertyRight?: InputMaybe<Mg2AssetPropertyRight>;
  address: Mg2DadataAddressInput;
  regNumber?: InputMaybe<Scalars['String']>;
  area: Scalars['Float'];
  year: Scalars['Float'];
  cost: Scalars['Float'];
  pledged: Scalars['Boolean'];
};

export type Mg2MaternityCapitalInput = {
  folder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2RefinanceFoldersInput = {
  agreementFolder?: InputMaybe<Mg2FileFolderInput>;
  remainingAmountFolder?: InputMaybe<Mg2FileFolderInput>;
  accountDetailsFolder?: InputMaybe<Mg2FileFolderInput>;
  paymentScheduleFolder?: InputMaybe<Mg2FileFolderInput>;
};

export type Mg2BorrowerExtraInput = {
  comment?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Mg2FileFolderInput>;
  vtbPaperFolder?: InputMaybe<Mg2FileFolderInput>;
  trancheInfo?: InputMaybe<Mg2TrancheInfoInput>;
};

export type Mg2TrancheInfoInput = {
  tranchesList: Array<Mg2TrancheInput>;
};

export type Mg2TrancheInput = {
  amount?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['LocalDate']>;
};

/** Данные созаёмщика (Анкета) */
export type Mg2CoBorrowerInput = {
  coBorrowerId: Scalars['ID'];
  incomeParticipation: Scalars['Boolean'];
  data: Mg2BorrowerInput;
};

export type Mg2LinkForDemandEditionResponseBag = {
  __typename?: 'MG2LinkForDemandEditionResponseBag';
  data?: Maybe<Mg2LinkForDemandEditionResponse>;
  error?: Maybe<Mg2LinkForDemandEditionResponseError>;
};

export type Mg2LinkForDemandEditionResponse = {
  __typename?: 'MG2LinkForDemandEditionResponse';
  /** Ссылка на анкету */
  url: Scalars['String'];
};

export type Mg2LinkForDemandEditionResponseError = ServiceError;

export type Mg2GetFileResponseBag = {
  __typename?: 'MG2GetFileResponseBag';
  data?: Maybe<Mg2GetFileResponse>;
  error?: Maybe<Mg2GetFileResponseError>;
};

export type Mg2GetFileResponse = {
  __typename?: 'MG2GetFileResponse';
  /** Данные для скачивания файла */
  file: Mg2File;
};

export type Mg2GetFileResponseError = ServiceError;

export enum MortgageFolderKindEnum {
  FolderKindSnils = 'FOLDER_KIND_SNILS',
  FolderKindRussianPassport = 'FOLDER_KIND_RUSSIAN_PASSPORT',
  FolderKindForeignPassport = 'FOLDER_KIND_FOREIGN_PASSPORT',
  FolderKindResidencePermit = 'FOLDER_KIND_RESIDENCE_PERMIT',
  FolderKindMilitary = 'FOLDER_KIND_MILITARY',
  FolderKindChildBirthCertificate = 'FOLDER_KIND_CHILD_BIRTH_CERTIFICATE',
  FolderKindChildDisabilityConfirmation = 'FOLDER_KIND_CHILD_DISABILITY_CONFIRMATION',
  FolderKindEmploymentRecord = 'FOLDER_KIND_EMPLOYMENT_RECORD',
  FolderKindWorkPermit = 'FOLDER_KIND_WORK_PERMIT',
  FolderKindIncomeNdfl = 'FOLDER_KIND_INCOME_NDFL',
  FolderKindIncomeBankForm = 'FOLDER_KIND_INCOME_BANK_FORM',
  FolderKindIncomeDeclaration = 'FOLDER_KIND_INCOME_DECLARATION',
  FolderKindIncomeFinanceFlow = 'FOLDER_KIND_INCOME_FINANCE_FLOW',
  FolderKindSelfEmployedTaxes = 'FOLDER_KIND_SELF_EMPLOYED_TAXES',
  FolderKindSelfEmployedInfo = 'FOLDER_KIND_SELF_EMPLOYED_INFO',
  FolderKindMaternityCapital = 'FOLDER_KIND_MATERNITY_CAPITAL',
  FolderKindRefinanceAgreement = 'FOLDER_KIND_REFINANCE_AGREEMENT',
  FolderKindRefinanceRemainingAmount = 'FOLDER_KIND_REFINANCE_REMAINING_AMOUNT',
  FolderKindRefinanceAccountDetails = 'FOLDER_KIND_REFINANCE_ACCOUNT_DETAILS',
  FolderKindRefinancePaymentSchedule = 'FOLDER_KIND_REFINANCE_PAYMENT_SCHEDULE',
  FolderKindExtra = 'FOLDER_KIND_EXTRA',
  FolderKindVtbPaper = 'FOLDER_KIND_VTB_PAPER',
  FolderKindPersonalDataConsent = 'FOLDER_KIND_PERSONAL_DATA_CONSENT',
  FolderKindEgrip = 'FOLDER_KIND_EGRIP',
  FolderKindEgrul = 'FOLDER_KIND_EGRUL'
}

export type Mg2GetFilesPreviewBag = {
  __typename?: 'MG2GetFilesPreviewBag';
  data?: Maybe<Mg2GetFilesPreview>;
  error?: Maybe<Mg2GetFilesPreviewError>;
};

export type Mg2GetFilesPreview = {
  __typename?: 'MG2GetFilesPreview';
  /** Список метаданных для превью документов */
  filesPreview?: Maybe<Array<PreviewFileObject>>;
};

export type PreviewFileObject = {
  __typename?: 'PreviewFileObject';
  fileId: Scalars['String'];
  name: Scalars['String'];
  token: Scalars['String'];
  size: Scalars['Int'];
};

export type Mg2GetFilesPreviewError = ServiceError;

export type Mg2GetBlankPersonalDataConsentResponseBag = {
  __typename?: 'MG2GetBlankPersonalDataConsentResponseBag';
  data?: Maybe<Mg2GetBlankPersonalDataConsentResponse>;
  error?: Maybe<Mg2GetBlankPersonalDataConsentResponseError>;
};

export type Mg2GetBlankPersonalDataConsentResponse = {
  __typename?: 'MG2GetBlankPersonalDataConsentResponse';
  fileData: Scalars['String'];
};

export type Mg2GetBlankPersonalDataConsentResponseError = ServiceError;

export type Mg2GetSignedPersonalDataConsentResponseBag = {
  __typename?: 'MG2GetSignedPersonalDataConsentResponseBag';
  data?: Maybe<Mg2GetSignedPersonalDataConsentResponse>;
  error?: Maybe<Mg2GetSignedPersonalDataConsentResponseError>;
};

export type Mg2GetSignedPersonalDataConsentResponse = {
  __typename?: 'MG2GetSignedPersonalDataConsentResponse';
  /** Подписанные файлы */
  folder?: Maybe<Mg2FileFolder>;
  /** Согласие было подписано на экране */
  signedOnScreen?: Maybe<Scalars['Boolean']>;
};

export type Mg2GetSignedPersonalDataConsentResponseError = ServiceError;

export type Mg2SearchSummariesBag = {
  __typename?: 'MG2SearchSummariesBag';
  data?: Maybe<Mg2SearchSummaries>;
  error?: Maybe<Mg2SearchSummariesError>;
};

/** Данные расчёта предложений */
export type Mg2SearchSummaries = {
  __typename?: 'MG2SearchSummaries';
  /** Список заявок */
  list: Array<Mg2Summary>;
  /** Итого заявок */
  total: Scalars['Int'];
};

export type Mg2Summary = {
  __typename?: 'MG2Summary';
  id: Scalars['String'];
  meta?: Maybe<Mg2Meta>;
  status: Scalars['Int'];
  statusTexts: Mg2StatusTexts;
  canCopy: Scalars['Boolean'];
  banks?: Maybe<Mg2RosterBanks>;
  demand?: Maybe<Mg2RosterDemand>;
  borrower?: Maybe<Mg2RosterBorrower>;
  partner?: Maybe<Mg2RosterPartner>;
};

export type Mg2Meta = {
  __typename?: 'MG2Meta';
  owner?: Maybe<Mg2Owner>;
  createTs?: Maybe<Date>;
  updateTs?: Maybe<Date>;
  archiveTs?: Maybe<Date>;
};

export type Mg2Owner = {
  __typename?: 'MG2Owner';
  id: Scalars['String'];
  role: Scalars['String'];
};

export type Mg2StatusTexts = {
  __typename?: 'MG2StatusTexts';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  button?: Maybe<Scalars['String']>;
};

export type Mg2RosterBanks = {
  __typename?: 'MG2RosterBanks';
  selected?: Maybe<Mg2RosterBank>;
  otherList: Array<Mg2RosterBank>;
};

export type Mg2RosterBank = {
  __typename?: 'MG2RosterBank';
  mnemonic: Scalars['String'];
  /** Статус скоринга заёмщика */
  colorStatus?: Maybe<Scalars['String']>;
  borrowerApproval?: Maybe<Mg2BorrowerApproval>;
  propertyApproval?: Maybe<Mg2PropertyApproval>;
  dealStatus: Mg2DealStatusEnum;
  /** Идентификатор онлайн-сделки */
  dealOnlineId?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  manager?: Maybe<Mg2RosterManager>;
};

/** Одобрение заёмщика */
export type Mg2BorrowerApproval = {
  __typename?: 'MG2BorrowerApproval';
  status: Mg2BorrowerApprovalStatusEnum;
  bankComment: Scalars['String'];
};

export enum Mg2BorrowerApprovalStatusEnum {
  BorrowerApprovalStatusUnknown = 'BORROWER_APPROVAL_STATUS_UNKNOWN',
  BorrowerApprovalStatusPending = 'BORROWER_APPROVAL_STATUS_PENDING',
  BorrowerApprovalStatusRework = 'BORROWER_APPROVAL_STATUS_REWORK',
  BorrowerApprovalStatusApproved = 'BORROWER_APPROVAL_STATUS_APPROVED',
  BorrowerApprovalStatusDeclined = 'BORROWER_APPROVAL_STATUS_DECLINED'
}

/** Одобрение недвижимости */
export type Mg2PropertyApproval = {
  __typename?: 'MG2PropertyApproval';
  status: Mg2PropertyApprovalStatusEnum;
  bankComment: Scalars['String'];
};

export enum Mg2PropertyApprovalStatusEnum {
  PropertyApprovalStatusUnknown = 'PROPERTY_APPROVAL_STATUS_UNKNOWN',
  PropertyApprovalStatusPending = 'PROPERTY_APPROVAL_STATUS_PENDING',
  PropertyApprovalStatusRework = 'PROPERTY_APPROVAL_STATUS_REWORK',
  PropertyApprovalStatusApproved = 'PROPERTY_APPROVAL_STATUS_APPROVED',
  PropertyApprovalStatusDeclined = 'PROPERTY_APPROVAL_STATUS_DECLINED'
}

export enum Mg2DealStatusEnum {
  DealStatusUnknown = 'DEAL_STATUS_UNKNOWN',
  DealStatusPending = 'DEAL_STATUS_PENDING',
  DealStatusDone = 'DEAL_STATUS_DONE',
  DealStatusOnline = 'DEAL_STATUS_ONLINE'
}

export type Mg2RosterManager = {
  __typename?: 'MG2RosterManager';
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  regionName: Scalars['String'];
};

export type Mg2RosterDemand = {
  __typename?: 'MG2RosterDemand';
  purpose?: Maybe<Mg2DemandPurposeEnum>;
  programCode: Scalars['String'];
  propertyType?: Maybe<Mg2PropertyTypeEnum>;
  propertyRegionName: Scalars['String'];
  dealRegionName: Scalars['String'];
  charactersList: Array<Scalars['String']>;
};

export type Mg2RosterBorrower = {
  __typename?: 'MG2RosterBorrower';
  name?: Maybe<Mg2FullName>;
  phone: Scalars['String'];
  email: Scalars['String'];
  birthDate: Scalars['String'];
};

export type Mg2RosterPartner = {
  __typename?: 'MG2RosterPartner';
  name?: Maybe<Mg2FullName>;
  phone: Scalars['String'];
  email: Scalars['String'];
  company?: Maybe<Mg2RosterCompany>;
  show: Scalars['Boolean'];
};

export type Mg2RosterCompany = {
  __typename?: 'MG2RosterCompany';
  id: Scalars['String'];
  name: Scalars['String'];
  branch?: Maybe<Mg2RosterCompanyBranch>;
};

export type Mg2RosterCompanyBranch = {
  __typename?: 'MG2RosterCompanyBranch';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Mg2SearchSummariesError = ServiceError;

/** Параметры для поиска заявок */
export type Mg2SummaryQueryInput = {
  text?: InputMaybe<Scalars['String']>;
  meta?: InputMaybe<Mg2MetaQueryInput>;
  statusList?: InputMaybe<Array<Mg2StatusQueryEnum>>;
  bank?: InputMaybe<Mg2BankQueryInput>;
  demand?: InputMaybe<Mg2DemandQueryInput>;
};

export type Mg2MetaQueryInput = {
  createTs?: InputMaybe<Mg2TimestampRangeInput>;
  updateTs?: InputMaybe<Mg2TimestampRangeInput>;
  archived?: InputMaybe<Scalars['Boolean']>;
};

export type Mg2TimestampRangeInput = {
  /** Время с в UNIX секундах */
  from: Scalars['Float'];
  /** Время по в UNIX секундах */
  to: Scalars['Float'];
};

export enum Mg2StatusQueryEnum {
  StatusQueryCalculation = 'STATUS_QUERY_CALCULATION',
  StatusQueryDraft = 'STATUS_QUERY_DRAFT',
  StatusQueryBorrowerCheck = 'STATUS_QUERY_BORROWER_CHECK',
  StatusQueryBorrowerRework = 'STATUS_QUERY_BORROWER_REWORK',
  StatusQueryBorrowerApprovalUnknown = 'STATUS_QUERY_BORROWER_APPROVAL_UNKNOWN',
  StatusQueryBorrowerApprovalPending = 'STATUS_QUERY_BORROWER_APPROVAL_PENDING',
  StatusQueryBorrowerApprovalRework = 'STATUS_QUERY_BORROWER_APPROVAL_REWORK',
  StatusQueryBorrowerApprovalApproved = 'STATUS_QUERY_BORROWER_APPROVAL_APPROVED',
  StatusQueryBorrowerApprovalDeclined = 'STATUS_QUERY_BORROWER_APPROVAL_DECLINED',
  StatusQueryPropertyApprovalUnknown = 'STATUS_QUERY_PROPERTY_APPROVAL_UNKNOWN',
  StatusQueryPropertyApprovalPending = 'STATUS_QUERY_PROPERTY_APPROVAL_PENDING',
  StatusQueryPropertyApprovalRework = 'STATUS_QUERY_PROPERTY_APPROVAL_REWORK',
  StatusQueryPropertyApprovalApproved = 'STATUS_QUERY_PROPERTY_APPROVAL_APPROVED',
  StatusQueryPropertyApprovalDeclined = 'STATUS_QUERY_PROPERTY_APPROVAL_DECLINED',
  StatusQueryDealUnknown = 'STATUS_QUERY_DEAL_UNKNOWN',
  StatusQueryDealPending = 'STATUS_QUERY_DEAL_PENDING',
  StatusQueryDealDone = 'STATUS_QUERY_DEAL_DONE',
  StatusQueryDealOnline = 'STATUS_QUERY_DEAL_ONLINE'
}

export type Mg2BankQueryInput = {
  mnemonicList: Array<Scalars['String']>;
};

export type Mg2DemandQueryInput = {
  purposeList?: InputMaybe<Array<Mg2DemandPurposeEnum>>;
  programCodeList?: InputMaybe<Array<Scalars['String']>>;
  propertyTypeList?: InputMaybe<Array<Mg2PropertyTypeEnum>>;
  propertyRegionIdList?: InputMaybe<Array<Scalars['Int']>>;
  dealRegionIdList?: InputMaybe<Array<Scalars['Int']>>;
};

export type Mg2CountSummariesBag = {
  __typename?: 'MG2CountSummariesBag';
  data?: Maybe<Mg2CountSummaries>;
  error?: Maybe<Mg2CountSummariesError>;
};

/** Количество заввок */
export type Mg2CountSummaries = {
  __typename?: 'MG2CountSummaries';
  /** Завершен ли вввод калькуляции */
  total: Scalars['Int'];
};

export type Mg2CountSummariesError = ServiceError;

export type Mg2IdxDocRecognitionResponseBag = {
  __typename?: 'MG2IdxDocRecognitionResponseBag';
  data?: Maybe<Mg2IdxDocRecognitionResponse>;
  error?: Maybe<Mg2IdxDocRecognitionResponseError>;
};

export type Mg2IdxDocRecognitionResponse = {
  __typename?: 'MG2IdxDocRecognitionResponse';
  isFeatureAvailable: Scalars['Boolean'];
  resultCode: Scalars['Int'];
  resultMessage: Scalars['String'];
  operationToken: Scalars['String'];
  count: Scalars['Int'];
  elementList: Array<Maybe<Mg2ClassifiedDocumentType>>;
};

/** Динамические данные заёмщика (Анкета) */
export type Mg2ClassifiedDocumentType = {
  __typename?: 'MG2ClassifiedDocumentType';
  /** Тип документа */
  docType: Scalars['String'];
  /** Ареа документа */
  docArea: Scalars['String'];
};

export type Mg2IdxDocRecognitionResponseError = ServiceError;

export type Mg2IdxParsePassportResponseBag = {
  __typename?: 'MG2IdxParsePassportResponseBag';
  data?: Maybe<Mg2IdxParsePassportResponse>;
  error?: Maybe<Mg2IdxParsePassportResponseError>;
};

export type Mg2IdxParsePassportResponse = {
  __typename?: 'MG2IdxParsePassportResponse';
  isFeatureAvailable: Scalars['Boolean'];
  resultCode: Scalars['Int'];
  resultMessage: Scalars['String'];
  operationToken: Scalars['String'];
  items: Array<Maybe<Mg2ClassifiedItemType>>;
};

/** Динамические данные заёмщика (Анкета) */
export type Mg2ClassifiedItemType = {
  __typename?: 'MG2ClassifiedItemType';
  /** Тип документа */
  docType: Scalars['String'];
  /** Место рождения */
  birthPlace: Scalars['String'];
  /** Номер паспорта */
  passportNumber: Scalars['String'];
  /** Код подразделения */
  subdivisionCode: Scalars['String'];
  /** Дата выдачи */
  issueDate: Scalars['String'];
  /** Кем выдан */
  issuingAuthority: Scalars['String'];
  /** Пол */
  sex: Scalars['String'];
};

export type Mg2IdxParsePassportResponseError = ServiceError;

export type Mg2GetUploadTokenResponseBag = {
  __typename?: 'MG2GetUploadTokenResponseBag';
  data?: Maybe<Mg2GetUploadTokenResponse>;
  error?: Maybe<Mg2GetUploadTokenResponseError>;
};

export type Mg2GetUploadTokenResponse = {
  __typename?: 'MG2GetUploadTokenResponse';
  /** Токен для загрузки файла */
  token: Scalars['String'];
};

export type Mg2GetUploadTokenResponseError = ServiceError;

export type Mg2ConsultUserByPhoneResponseBag = {
  __typename?: 'MG2ConsultUserByPhoneResponseBag';
  data?: Maybe<Mg2ConsultUserByPhoneResponse>;
  error?: Maybe<Mg2ConsultUserByPhoneResponseError>;
};

export type Mg2ConsultUserByPhoneResponse = {
  __typename?: 'MG2ConsultUserByPhoneResponse';
  response: Scalars['String'];
};

export type Mg2ConsultUserByPhoneResponseError = ServiceError;

export type Mg2CheckReadinessResponseBag = {
  __typename?: 'MG2CheckReadinessResponseBag';
  data?: Maybe<Mg2CheckReadinessResponse>;
  error?: Maybe<Mg2CheckReadinessResponseError>;
};

export type Mg2CheckReadinessResponse = {
  __typename?: 'MG2CheckReadinessResponse';
  /** Проверка готовности анкеты к отправке */
  readiness: Mg2ReadinessType;
};

export type Mg2ReadinessType = {
  __typename?: 'MG2ReadinessType';
  waiting?: Maybe<Mg2ReadinessWaitingType>;
  pending?: Maybe<Scalars['Boolean']>;
  sent?: Maybe<Scalars['Boolean']>;
  sealedValue?: Maybe<Mg2CheckReadinessSealedValueEnum>;
};

export type Mg2ReadinessWaitingType = {
  __typename?: 'MG2ReadinessWaitingType';
  calculation: Mg2ReadinessWaitingCalculationType;
  borrower: Mg2ReadinessWaitingBorrowerType;
};

export type Mg2ReadinessWaitingCalculationType = {
  __typename?: 'MG2ReadinessWaitingCalculationType';
  ready?: Maybe<Scalars['Boolean']>;
  notReady?: Maybe<Mg2ReadinessWaitingCalculationNotReadyType>;
  sealedValue?: Maybe<Mg2CheckReadinessWaitingCalculationSealedValueEnum>;
};

export type Mg2ReadinessWaitingCalculationNotReadyType = {
  __typename?: 'MG2ReadinessWaitingCalculationNotReadyType';
  cause?: Maybe<Mg2ReadinessWaitingCalculationNotReadyCauseType>;
};

export type Mg2ReadinessWaitingCalculationNotReadyCauseType = {
  __typename?: 'MG2ReadinessWaitingCalculationNotReadyCauseType';
  invalidInput?: Maybe<Mg2ReadinessWaitingCalculationNotReadyCauseInvalidTypeType>;
  sealedValue?: Maybe<Mg2CheckReadinessWaitingCalculationNotReadyCauseSealedValueEnum>;
};

export type Mg2ReadinessWaitingCalculationNotReadyCauseInvalidTypeType = {
  __typename?: 'MG2ReadinessWaitingCalculationNotReadyCauseInvalidTypeType';
  errorsList?: Maybe<Array<Mg2ValidationError>>;
};

export enum Mg2CheckReadinessWaitingCalculationNotReadyCauseSealedValueEnum {
  InvalidInput = 'INVALID_INPUT'
}

export enum Mg2CheckReadinessWaitingCalculationSealedValueEnum {
  NotReady = 'NOT_READY',
  Ready = 'READY'
}

export type Mg2ReadinessWaitingBorrowerType = {
  __typename?: 'MG2ReadinessWaitingBorrowerType';
  ready?: Maybe<Scalars['Boolean']>;
  notReady?: Maybe<Mg2ReadinessWaitingBorrowerNotReadyType>;
  sealedValue?: Maybe<Mg2CheckReadinessWaitingBorrowerSealedValueEnum>;
};

export type Mg2ReadinessWaitingBorrowerNotReadyType = {
  __typename?: 'MG2ReadinessWaitingBorrowerNotReadyType';
  cause?: Maybe<Mg2ReadinessWaitingBorrowerNotReadyCauseType>;
};

export type Mg2ReadinessWaitingBorrowerNotReadyCauseType = {
  __typename?: 'MG2ReadinessWaitingBorrowerNotReadyCauseType';
  invalidBorrower: Array<Mg2BorrowerValidationError>;
  invalidCoBorrowers: Array<Array<Mg2BorrowerValidationError>>;
  noConsent?: Maybe<Scalars['Boolean']>;
  reworks?: Maybe<Scalars['Boolean']>;
  sealedValue?: Maybe<Mg2CheckReadinessWaitingBorrowerNotReadyCauseSealedValueEnum>;
};

export enum Mg2CheckReadinessWaitingBorrowerNotReadyCauseSealedValueEnum {
  InvalidBorrower = 'INVALID_BORROWER',
  InvalidCoBorrowers = 'INVALID_CO_BORROWERS',
  NoConsent = 'NO_CONSENT',
  Reworks = 'REWORKS'
}

export enum Mg2CheckReadinessWaitingBorrowerSealedValueEnum {
  NotReady = 'NOT_READY',
  Ready = 'READY'
}

export enum Mg2CheckReadinessSealedValueEnum {
  Waiting = 'WAITING',
  Pending = 'PENDING',
  Sent = 'SENT'
}

export type Mg2CheckReadinessResponseError = ServiceError;

export type Mg2GetSubmittedOffersResponseBag = {
  __typename?: 'MG2GetSubmittedOffersResponseBag';
  data?: Maybe<Mg2GetSubmittedOffersResponse>;
  error?: Maybe<Mg2GetSubmittedOffersResponseError>;
};

export type Mg2GetSubmittedOffersResponse = {
  __typename?: 'MG2GetSubmittedOffersResponse';
  /**  Список предложений */
  offersList: Array<Mg2BankOffer>;
};

export type Mg2GetSubmittedOffersResponseError = ServiceError;

export type Mg2GetDispatchResponseBag = {
  __typename?: 'MG2GetDispatchResponseBag';
  data?: Maybe<Mg2GetDispatchResponse>;
  error?: Maybe<Mg2GetDispatchResponseError>;
};

export type Mg2GetDispatchResponse = {
  __typename?: 'MG2GetDispatchResponse';
  /** Список предложений */
  offersList: Array<Mg2BankDispatch>;
};

export type Mg2BankDispatch = {
  __typename?: 'MG2BankDispatch';
  /** Мнемоник банка */
  mnemonic: Scalars['String'];
  /** Состояние предложения */
  state: Mg2DispatchState;
};

export type Mg2DispatchState = {
  __typename?: 'MG2DispatchState';
  pending: Scalars['Boolean'];
  sent?: Maybe<Mg2DispatchStateSent>;
  approved?: Maybe<Mg2DispatchStateApproved>;
  rework?: Maybe<Mg2DispatchStateRework>;
  declined?: Maybe<Mg2DispatchStateDeclined>;
  sealedValue?: Maybe<Mg2DispatchStateSealedValueEnum>;
};

export type Mg2DispatchStateSent = {
  __typename?: 'MG2DispatchStateSent';
  date?: Maybe<Date>;
  manager?: Maybe<Mg2DispatchManager>;
};

export type Mg2DispatchManager = {
  __typename?: 'MG2DispatchManager';
  fullName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type Mg2DispatchStateApproved = {
  __typename?: 'MG2DispatchStateApproved';
  manager?: Maybe<Mg2DispatchManager>;
  decision?: Maybe<Mg2DispatchDecision>;
  selected: Scalars['Boolean'];
};

export type Mg2DispatchDecision = {
  __typename?: 'MG2DispatchDecision';
  approvedAmount: Scalars['Float'];
  downPayment: Scalars['Float'];
  rate: Scalars['Float'];
  term: Scalars['Int'];
  monthlyPayment: Scalars['Float'];
  dueDate?: Maybe<Scalars['String']>;
  conditions: Scalars['String'];
  comment: Scalars['String'];
};

export type Mg2DispatchStateRework = {
  __typename?: 'MG2DispatchStateRework';
  manager?: Maybe<Mg2DispatchManager>;
  bankResponse?: Maybe<Scalars['String']>;
  rework?: Maybe<Mg2Rework>;
};

export type Mg2Rework = {
  __typename?: 'MG2Rework';
  /** Комментарий по доработке */
  comment: Scalars['String'];
  /** Файлы */
  filesList: Array<Mg2File>;
};

export type Mg2DispatchStateDeclined = {
  __typename?: 'MG2DispatchStateDeclined';
  manager?: Maybe<Mg2DispatchManager>;
  bankResponse?: Maybe<Scalars['String']>;
};

export enum Mg2DispatchStateSealedValueEnum {
  Pending = 'PENDING',
  Sent = 'SENT',
  ReworkRequested = 'REWORK_REQUESTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export type Mg2GetDispatchResponseError = ServiceError;

export type Mg2GetReworkFileResponseBag = {
  __typename?: 'MG2GetReworkFileResponseBag';
  data?: Maybe<Mg2GetReworkFileResponse>;
  error?: Maybe<Mg2GetReworkFileResponseError>;
};

export type Mg2GetReworkFileResponse = {
  __typename?: 'MG2GetReworkFileResponse';
  /** Список файлов доработки */
  file: Mg2File;
};

export type Mg2GetReworkFileResponseError = ServiceError;

export type Mg2GetReworkUploadTokenResponseBag = {
  __typename?: 'MG2GetReworkUploadTokenResponseBag';
  data?: Maybe<Mg2GetReworkUploadTokenResponse>;
  error?: Maybe<Mg2GetReworkUploadTokenResponseError>;
};

export type Mg2GetReworkUploadTokenResponse = {
  __typename?: 'MG2GetReworkUploadTokenResponse';
  /** Токен */
  token: Scalars['String'];
};

export type Mg2GetReworkUploadTokenResponseError = ServiceError;

export type Mg2GetChatBag = {
  __typename?: 'MG2GetChatBag';
  data?: Maybe<Mg2GetChat>;
  error?: Maybe<Mg2GetChatError>;
};

/** Получить чат для ипотечной заявки */
export type Mg2GetChat = {
  __typename?: 'MG2GetChat';
  /** Чат */
  chat?: Maybe<Mg2Chat>;
};

export type Mg2Chat = {
  __typename?: 'MG2Chat';
  /** Идентификатор чата */
  chatId: Scalars['String'];
  /** Идентификатор заявки */
  mortgageId?: Maybe<Scalars['String']>;
};

export type Mg2GetChatError = ServiceError;

export type Mg2DownloadFromReactTemplateResponseBag = {
  __typename?: 'MG2DownloadFromReactTemplateResponseBag';
  data?: Maybe<Mg2DownloadFromReactTemplateResponse>;
  error?: Maybe<Mg2DownloadFromReactTemplateResponseError>;
};

export type Mg2DownloadFromReactTemplateResponse = {
  __typename?: 'MG2DownloadFromReactTemplateResponse';
  /** Список предложений */
  base64: Scalars['String'];
};

export type Mg2DownloadFromReactTemplateResponseError = ServiceError;

export type Mg2GetActiveDealResponseBag = {
  __typename?: 'MG2GetActiveDealResponseBag';
  data?: Maybe<Mg2GetActiveDealResponse>;
  error?: Maybe<Mg2GetActiveDealResponseError>;
};

export type Mg2GetActiveDealResponse = {
  __typename?: 'MG2GetActiveDealResponse';
  deal?: Maybe<Mg2DealerDeal>;
  bankMnemonic?: Maybe<Scalars['String']>;
  mortgageId?: Maybe<Scalars['ID']>;
};

export type Mg2DealerDeal = {
  __typename?: 'MG2DealerDeal';
  /** Сделка через чат */
  chat?: Maybe<Mg2DealerDealChat>;
  /** Онлайн сделка: ID онлайн сделки */
  dealOnlineId?: Maybe<Scalars['String']>;
};

export type Mg2DealerDealChat = {
  __typename?: 'MG2DealerDealChat';
  status: Mg2ChatDealStatusEnum;
  date: Scalars['String'];
};

export enum Mg2ChatDealStatusEnum {
  ChatDealStatusPropertyDocuments = 'CHAT_DEAL_STATUS_PROPERTY_DOCUMENTS',
  ChatDealStatusPropertyRework = 'CHAT_DEAL_STATUS_PROPERTY_REWORK',
  ChatDealStatusPropertyDeclined = 'CHAT_DEAL_STATUS_PROPERTY_DECLINED',
  ChatDealStatusPropertyApproved = 'CHAT_DEAL_STATUS_PROPERTY_APPROVED',
  ChatDealStatusPending = 'CHAT_DEAL_STATUS_PENDING',
  ChatDealStatusCompleted = 'CHAT_DEAL_STATUS_COMPLETED'
}

export type Mg2GetActiveDealResponseError = ServiceError;

/** Временная ссылка на оценку */
export type SrgOrderPlaceResponse = {
  __typename?: 'SRGOrderPlaceResponse';
  orderId?: Maybe<Scalars['String']>;
  orderLink?: Maybe<Scalars['String']>;
};

export type MinimalMortgageRateBag = {
  __typename?: 'MinimalMortgageRateBag';
  data?: Maybe<MinimalMortgageRate>;
  error?: Maybe<MinimalMortgageRateError>;
};

export type MinimalMortgageRate = {
  __typename?: 'MinimalMortgageRate';
  /** Минимальная ставка по ипотеке */
  rate?: Maybe<Scalars['Float']>;
};

export type MinimalMortgageRateError = ServiceError;

export type RootMutation = {
  __typename?: 'RootMutation';
  editFavoritePersonal: Scalars['Boolean'];
  editFavoritePersonal2: FavoritesCrudResponse;
  editHiddenPersonal: EditHiddenPersonalResponse;
  editFavoriteNewBuildings: EditFavoriteNewBuildingsResponse;
  createSearchesPersonal: Scalars['String'];
  deleteSearchesPersonal: Scalars['Boolean'];
  viewPersonalSearch?: Maybe<ViewPersonalSearchResponse>;
  subscribeToSearchPersonal: Scalars['Boolean'];
  unsubscribeFromSearchPersonal: Scalars['Boolean'];
  businessCreateSearches: Scalars['String'];
  businessDeleteCustomer: Scalars['Boolean'];
  businessCreateCustomer: Customer;
  businessEditFavorite: Scalars['Boolean'];
  businessDeleteSearches: Scalars['Boolean'];
  businessUpdateCustomer: Customer;
  businessSubscribeToSearch: Scalars['Boolean'];
  businessUnsubscribeFromSearch: Scalars['Boolean'];
  businessSavingSearch: BusinessSavingSearchSuccess;
  createClaim: ClaimResponseGql;
  createRepairRequestByPhoneNumber: RepairRequestResult;
  createEmailCallback: EmailCallbackResponse;
  purchaseCallback: EmailCallbackResponse;
  createEmailCallbackSimple: EmailCallbackSimpleResponse;
  createEmailCallbackProverka: CreateEmailCallbackProverkaResponse;
  rieltorsCallback: EmailCallbackResponse;
  featureTourInitialComplete?: Maybe<Scalars['Boolean']>;
  sendCustomerFeedback?: Maybe<SendCustomerFeedbackResponse>;
  trackOffer?: Maybe<TrackOfferResult>;
  trackSearch?: Maybe<TrackSearchResult>;
  classifiedSendMortgageContactsForm: ClassifiedSendMortgageContactsForm;
  subscribeToSearch: SubscriptionsCrudResponse;
  subscribeToPriceChangePersonal: SubscribeToPriceChangeResponse;
  unsubscribeFromSearchOpen: UnsubscribeFromSearchResponse;
  unsubscribeFromPriceChangeOpen: UnsubscribeFromPriceChangeOpenResponseGql;
  unsubscribeFromPriceChangePersonal: UnsubscribeFromPriceChangePersonalResponseGql;
  /** Проверка email */
  confirmEmail?: Maybe<ConfirmEmail>;
  savingSearch: SavingSearchSuccess;
  setSubscriptionFrequency: Scalars['Boolean'];
  /** Сохранение черновика для оффера */
  saveDraftOffer?: Maybe<SaveDraftOfferResult>;
  /** Генерация id при создании оффера */
  createOfferId?: Maybe<CreateOfferIdResult>;
  generateUploadResources?: Maybe<GenerateUploadResourcesResult>;
  /** Публикация оффера */
  publishOffer?: Maybe<PublishOfferResult>;
  removeOffer?: Maybe<RemoveOfferResult>;
  /** Генерация id при создании оффера */
  unpublishOffer?: Maybe<UnpublishOfferIdResult>;
  archiveOffer?: Maybe<ArchiveOfferResult>;
  orderWalletVas: OrderWalletVasResponse;
  orderOfferVas: OrderOfferVasResponse;
  sendEmailForConnectStatistics: SendEmailForConnectStatistics;
  /** Используется командой ремонта! */
  sendRemontMortgageCallBack?: Maybe<RemontMortgageCallBackResult>;
  company: CompanyMutations;
  mortgage: MortgageMutations;
  mortgage2: Mg2Mutations;
  captchaValidate?: Maybe<CaptchaValidateResponse>;
  /** Заявка на ремонт */
  sendRepairContactForm?: Maybe<RepairRequestResult>;
  /** Корневое mutation для запросов ktzk */
  hoosegow: HoosegowMutationsResponse;
  userProfile: UserProfileMutations;
};


export type RootMutationEditFavoritePersonalArgs = {
  isFavorite: Scalars['Boolean'];
  offerId: Scalars['ID'];
};


export type RootMutationEditFavoritePersonal2Args = {
  isFavorite: Scalars['Boolean'];
  noteText?: InputMaybe<Scalars['String']>;
  offerId: Scalars['ID'];
};


export type RootMutationEditHiddenPersonalArgs = {
  isHidden: Scalars['Boolean'];
  offerId: Scalars['ID'];
};


export type RootMutationEditFavoriteNewBuildingsArgs = {
  isFavorite: Scalars['Boolean'];
  newBuildingId: Scalars['ID'];
};


export type RootMutationCreateSearchesPersonalArgs = {
  title: Scalars['String'];
  profSearchFilters?: InputMaybe<ProfessionalSearchInput>;
};


export type RootMutationDeleteSearchesPersonalArgs = {
  searchId: Scalars['ID'];
};


export type RootMutationViewPersonalSearchArgs = {
  searchId: Scalars['ID'];
};


export type RootMutationSubscribeToSearchPersonalArgs = {
  email: Scalars['String'];
  searchId: Scalars['ID'];
  frequency?: InputMaybe<FrequencyEnum>;
  channel?: InputMaybe<SubscriptionChannelEnum>;
};


export type RootMutationUnsubscribeFromSearchPersonalArgs = {
  searchId: Scalars['ID'];
};


export type RootMutationBusinessCreateSearchesArgs = {
  title: Scalars['String'];
  customerId: Scalars['ID'];
  filters: ProfessionalSearchInput;
};


export type RootMutationBusinessDeleteCustomerArgs = {
  customerId: Scalars['ID'];
};


export type RootMutationBusinessCreateCustomerArgs = {
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};


export type RootMutationBusinessEditFavoriteArgs = {
  isFavorite: Scalars['Boolean'];
  customerId: Scalars['ID'];
  offerId: Scalars['ID'];
};


export type RootMutationBusinessDeleteSearchesArgs = {
  customerId: Scalars['ID'];
  searchId: Scalars['ID'];
};


export type RootMutationBusinessUpdateCustomerArgs = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  created: Scalars['Float'];
};


export type RootMutationBusinessSubscribeToSearchArgs = {
  email: Scalars['String'];
  customerId: Scalars['ID'];
  searchId: Scalars['ID'];
};


export type RootMutationBusinessUnsubscribeFromSearchArgs = {
  customerId: Scalars['ID'];
  searchId: Scalars['ID'];
};


export type RootMutationBusinessSavingSearchArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
  customerId: Scalars['ID'];
  title: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
};


export type RootMutationCreateClaimArgs = {
  offerId: Scalars['ID'];
  banReason: BanReasonEnum;
  comment: Scalars['String'];
  userAgent: Scalars['String'];
};


export type RootMutationCreateRepairRequestByPhoneNumberArgs = {
  phone: Scalars['String'];
  source: Scalars['String'];
};


export type RootMutationCreateEmailCallbackArgs = {
  phone: Scalars['String'];
  newBuildingName?: InputMaybe<Scalars['String']>;
  address: Scalars['String'];
  developerName?: InputMaybe<Scalars['String']>;
  newBuildingRanking?: InputMaybe<Scalars['String']>;
  isNewBuilding?: InputMaybe<Scalars['Boolean']>;
  regionId?: InputMaybe<Scalars['Int']>;
  hours?: InputMaybe<Scalars['String']>;
  extra?: InputMaybe<Scalars['String']>;
  subjectExtra?: InputMaybe<Scalars['String']>;
  emailSender: EmailSenderEnum;
  link?: InputMaybe<Scalars['String']>;
  isCallbackRequestCreated?: InputMaybe<Scalars['Boolean']>;
  isOnlineBooking?: InputMaybe<Scalars['Boolean']>;
};


export type RootMutationPurchaseCallbackArgs = {
  startCallbackHour?: InputMaybe<Scalars['String']>;
  endCallbackHour?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  isRequest?: InputMaybe<Scalars['Boolean']>;
};


export type RootMutationCreateEmailCallbackSimpleArgs = {
  subject: Scalars['String'];
  body: Scalars['String'];
  emailRecipient?: InputMaybe<Scalars['String']>;
};


export type RootMutationCreateEmailCallbackProverkaArgs = {
  subject: Scalars['String'];
  body: Scalars['String'];
};


export type RootMutationRieltorsCallbackArgs = {
  phone: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  link: Scalars['String'];
  regionId: Scalars['ID'];
};


export type RootMutationFeatureTourInitialCompleteArgs = {
  userId: Scalars['ID'];
};


export type RootMutationSendCustomerFeedbackArgs = {
  rating: Scalars['Int'];
  message: Scalars['String'];
  applicationId: ApplicationIdEnum;
  complexityOptions?: InputMaybe<Array<Scalars['String']>>;
  contact?: InputMaybe<Scalars['String']>;
  usability?: InputMaybe<CustomerFeedbackAnswer>;
  infoFullness?: InputMaybe<CustomerFeedbackAnswer>;
  extraFeatures?: InputMaybe<Scalars['String']>;
  searchSuccess?: InputMaybe<Scalars['String']>;
};


export type RootMutationTrackOfferArgs = {
  offerId: Scalars['ID'];
  countryCode?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  isPhone?: InputMaybe<Scalars['Boolean']>;
};


export type RootMutationTrackSearchArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
};


export type RootMutationClassifiedSendMortgageContactsFormArgs = {
  utm?: InputMaybe<RecordInput>;
  phone: Scalars['String'];
  startCallbackHour?: InputMaybe<Scalars['String']>;
  endCallbackHour?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  locationPlacement?: InputMaybe<Scalars['String']>;
  applicationId: ApplicationIdEnum;
  regionId?: InputMaybe<Scalars['Int']>;
  subject: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  realtyType?: InputMaybe<RealtyObjectEnum>;
  discounts?: InputMaybe<Array<DiscountCalcEnum>>;
  withGA?: InputMaybe<Scalars['Boolean']>;
  withoutBackofficeEmail?: InputMaybe<Scalars['Boolean']>;
};


export type RootMutationSubscribeToSearchArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
  title: Scalars['String'];
};


export type RootMutationSubscribeToPriceChangePersonalArgs = {
  email: Scalars['String'];
  offerId: Scalars['ID'];
  price: PriceInput;
  frequency?: InputMaybe<FrequencyEnum>;
};


export type RootMutationUnsubscribeFromSearchOpenArgs = {
  userId: Scalars['ID'];
  searchIds: Array<Scalars['ID']>;
};


export type RootMutationUnsubscribeFromPriceChangeOpenArgs = {
  userId: Scalars['ID'];
  offerId: Scalars['ID'];
};


export type RootMutationUnsubscribeFromPriceChangePersonalArgs = {
  offerId: Scalars['ID'];
};


export type RootMutationConfirmEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
};


export type RootMutationSavingSearchArgs = {
  filters?: InputMaybe<ProfessionalSearchInput>;
  title: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
};


export type RootMutationSetSubscriptionFrequencyArgs = {
  frequency: FrequencyEnum;
  searchId: Scalars['String'];
  subscriptionType: Scalars['Int'];
};


export type RootMutationSaveDraftOfferArgs = {
  offer: SaveDraftOfferInput;
  userId?: InputMaybe<Scalars['String']>;
};


export type RootMutationGenerateUploadResourcesArgs = {
  userId?: InputMaybe<Scalars['ID']>;
  offerId: Scalars['ID'];
  count: Scalars['Int'];
};


export type RootMutationPublishOfferArgs = {
  offer: SaveDraftOfferInput;
  userId?: InputMaybe<Scalars['String']>;
};


export type RootMutationRemoveOfferArgs = {
  offerId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};


export type RootMutationUnpublishOfferArgs = {
  offerId: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
};


export type RootMutationArchiveOfferArgs = {
  offerId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type RootMutationOrderWalletVasArgs = {
  offersParams?: InputMaybe<Array<OrderOfferVasOfferParam>>;
  offerIds?: InputMaybe<Array<Scalars['ID']>>;
  vases: Array<BuyableVasGqlEnum>;
  durationDays: Scalars['Int'];
  promocode?: InputMaybe<Scalars['String']>;
};


export type RootMutationOrderOfferVasArgs = {
  offersParams?: InputMaybe<Array<OrderOfferVasOfferParam>>;
  offerIds?: InputMaybe<Array<Scalars['ID']>>;
  vases: Array<BuyableVasGqlEnum>;
  phone?: InputMaybe<Scalars['String']>;
  durationDays: Scalars['Int'];
  promocode?: InputMaybe<Scalars['String']>;
};


export type RootMutationSendEmailForConnectStatisticsArgs = {
  userId: Scalars['ID'];
  companyFullName: Scalars['String'];
  companyEmail: Scalars['String'];
};


export type RootMutationSendRemontMortgageCallBackArgs = {
  phone: Scalars['String'];
  name: Scalars['String'];
};


export type RootMutationCaptchaValidateArgs = {
  token: Scalars['String'];
};


export type RootMutationSendRepairContactFormArgs = {
  phone: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
  totalPrice?: InputMaybe<Scalars['String']>;
};

export type FavoritesCrudResponse = {
  __typename?: 'FavoritesCRUDResponse';
  firstError?: Maybe<FavoritesError>;
  errors?: Maybe<Array<FavoritesError>>;
};

export type FavoritesError = InternalError | FavoritesErrorOfferId | AuthUserIdError | FavoritesErrorLimitExceeded | FavoritesErrorNoteTextSizeExceeded | FavoritesErrorNoteEmpty | FavoritesErrorUnknown;

export type FavoritesErrorOfferId = BaseError & {
  __typename?: 'FavoritesErrorOfferId';
  message?: Maybe<Scalars['String']>;
};

export type FavoritesErrorLimitExceeded = BaseError & {
  __typename?: 'FavoritesErrorLimitExceeded';
  message?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type FavoritesErrorNoteTextSizeExceeded = BaseError & {
  __typename?: 'FavoritesErrorNoteTextSizeExceeded';
  message?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type FavoritesErrorNoteEmpty = BaseError & {
  __typename?: 'FavoritesErrorNoteEmpty';
  message?: Maybe<Scalars['String']>;
};

export type FavoritesErrorUnknown = BaseError & {
  __typename?: 'FavoritesErrorUnknown';
  message?: Maybe<Scalars['String']>;
};

export type EditHiddenPersonalResponse = {
  __typename?: 'EditHiddenPersonalResponse';
  firstError?: Maybe<HiddenError>;
  errors?: Maybe<Array<HiddenError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type HiddenError = InternalError | HiddenErrorInvalidOfferId | HiddenErrorLimitExceeded | HiddenErrorUnknown;

export type HiddenErrorInvalidOfferId = BaseError & {
  __typename?: 'HiddenErrorInvalidOfferId';
  message?: Maybe<Scalars['String']>;
};

export type HiddenErrorLimitExceeded = BaseError & {
  __typename?: 'HiddenErrorLimitExceeded';
  message?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type HiddenErrorUnknown = BaseError & {
  __typename?: 'HiddenErrorUnknown';
  message?: Maybe<Scalars['String']>;
};

export type EditFavoriteNewBuildingsResponse = {
  __typename?: 'EditFavoriteNewBuildingsResponse';
  firstError?: Maybe<FavoriteNewBuildingsError>;
  errors?: Maybe<Array<Maybe<FavoriteNewBuildingsError>>>;
};

export type FavoriteNewBuildingsError = InternalError | FavoriteNewBuildingsIdError | AuthUserIdError | FavoriteNewBuildingsLimitExceededError;

export type FavoriteNewBuildingsIdError = BaseError & {
  __typename?: 'FavoriteNewBuildingsIdError';
  message?: Maybe<Scalars['String']>;
};

export type FavoriteNewBuildingsLimitExceededError = BaseError & {
  __typename?: 'FavoriteNewBuildingsLimitExceededError';
  message?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ViewPersonalSearchResponse = {
  __typename?: 'ViewPersonalSearchResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type BusinessSavingSearchSuccess = {
  __typename?: 'BusinessSavingSearchSuccess';
  isSuccess: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
};

export type ClaimResponseGql = {
  __typename?: 'ClaimResponseGql';
  firstError?: Maybe<ClaimError>;
  errors?: Maybe<Array<ClaimError>>;
};

export type ClaimError = ClaimErrorInvalidReason | ClaimErrorInvalidOfferId | ClaimErrorNoReason | ClaimErrorNoSnitch;

export type ClaimErrorInvalidReason = BaseError & {
  __typename?: 'ClaimErrorInvalidReason';
  message?: Maybe<Scalars['String']>;
};

export type ClaimErrorInvalidOfferId = BaseError & {
  __typename?: 'ClaimErrorInvalidOfferId';
  message?: Maybe<Scalars['String']>;
};

export type ClaimErrorNoReason = BaseError & {
  __typename?: 'ClaimErrorNoReason';
  message?: Maybe<Scalars['String']>;
};

export type ClaimErrorNoSnitch = BaseError & {
  __typename?: 'ClaimErrorNoSnitch';
  message?: Maybe<Scalars['String']>;
};

export enum BanReasonEnum {
  Unknown = 'UNKNOWN',
  Fraud = 'FRAUD',
  Porn = 'PORN',
  SwearingInDescription = 'SWEARING_IN_DESCRIPTION',
  IncorrectPrice = 'INCORRECT_PRICE',
  Inadequate = 'INADEQUATE',
  WrongDealType = 'WRONG_DEAL_TYPE',
  WrongRealtyObject = 'WRONG_REALTY_OBJECT',
  PartialSale = 'PARTIAL_SALE',
  AbroadRealty = 'ABROAD_REALTY',
  DefaultCoordinates = 'DEFAULT_COORDINATES',
  WrongGeo = 'WRONG_GEO',
  NonLivingRealty = 'NON_LIVING_REALTY',
  NonPrimarySale = 'NON_PRIMARY_SALE',
  IncompleteAddress = 'INCOMPLETE_ADDRESS',
  AddressMismatch = 'ADDRESS_MISMATCH',
  AddressOther = 'ADDRESS_OTHER',
  AdsPhotos = 'ADS_PHOTOS',
  PhotosMismatch = 'PHOTOS_MISMATCH',
  PoorPhotos = 'POOR_PHOTOS',
  PhotosPeople = 'PHOTOS_PEOPLE',
  PhotosOther = 'PHOTOS_OTHER',
  WrongFloor = 'WRONG_FLOOR',
  WrongSquare = 'WRONG_SQUARE',
  WrongRooms = 'WRONG_ROOMS',
  ParametersOther = 'PARAMETERS_OTHER',
  AdsDescription = 'ADS_DESCRIPTION',
  MultiobjectDescription = 'MULTIOBJECT_DESCRIPTION',
  ObsceneDescription = 'OBSCENE_DESCRIPTION',
  DescriptionOther = 'DESCRIPTION_OTHER',
  NotARealty = 'NOT_A_REALTY',
  ObjectOther = 'OBJECT_OTHER',
  ScamPicker = 'SCAM_PICKER',
  ScamInform = 'SCAM_INFORM',
  ScamFraud = 'SCAM_FRAUD',
  ScamOther = 'SCAM_OTHER',
  RelevanceSold = 'RELEVANCE_SOLD',
  RelevanceDeposit = 'RELEVANCE_DEPOSIT',
  RelevanceNoReply = 'RELEVANCE_NO_REPLY',
  RelevanceWrongNumber = 'RELEVANCE_WRONG_NUMBER',
  RelevanceOther = 'RELEVANCE_OTHER',
  RelevanceNoShow = 'RELEVANCE_NO_SHOW',
  PhotosPlagiary = 'PHOTOS_PLAGIARY',
  RelevancePaused = 'RELEVANCE_PAUSED',
  OtherQuery = 'OTHER_QUERY'
}

export type RepairRequestResult = {
  __typename?: 'RepairRequestResult';
  errorText: Scalars['String'];
};

export type EmailCallbackResponse = {
  __typename?: 'EmailCallbackResponse';
  emailMessageId?: Maybe<Scalars['String']>;
};

export enum EmailSenderEnum {
  NewBuildingsCallbackDefault = 'NewBuildingsCallbackDefault',
  NewBuildingsCallbackMsk = 'NewBuildingsCallbackMsk',
  NewBuildingsCallbackSpb = 'NewBuildingsCallbackSpb',
  NewBuildingsOnlineBookingMsk = 'NewBuildingsOnlineBookingMsk',
  NewBuildingsOnlineBookingSpb = 'NewBuildingsOnlineBookingSpb',
  Search = 'Search'
}

export type EmailCallbackSimpleResponse = {
  __typename?: 'EmailCallbackSimpleResponse';
  emailMessageId?: Maybe<Scalars['String']>;
};

export type CreateEmailCallbackProverkaResponse = {
  __typename?: 'CreateEmailCallbackProverkaResponse';
  emailMessageId?: Maybe<Scalars['String']>;
};

export type SendCustomerFeedbackResponse = {
  __typename?: 'sendCustomerFeedbackResponse';
  status?: Maybe<SendCustomerFeedbackResponseStatusEnum>;
};

export enum SendCustomerFeedbackResponseStatusEnum {
  Ok = 'OK'
}

export enum ApplicationIdEnum {
  ClassifiedDesktop = 'CLASSIFIED_DESKTOP',
  ClassifiedTouch = 'CLASSIFIED_TOUCH',
  NewBuildingsDesktop = 'NEW_BUILDINGS_DESKTOP',
  NewBuildingsTouch = 'NEW_BUILDINGS_TOUCH'
}

export type CustomerFeedbackAnswer = {
  /** Ответ в булевом представлении (для выбора эмодзи) */
  value?: InputMaybe<Scalars['Boolean']>;
  /** Ответ в текстовом представлении */
  text?: InputMaybe<Scalars['String']>;
};

export type TrackOfferResult = {
  __typename?: 'TrackOfferResult';
  success: Scalars['Boolean'];
};

export type TrackSearchResult = {
  __typename?: 'TrackSearchResult';
  success: Scalars['Boolean'];
};

export type ClassifiedSendMortgageContactsForm = {
  __typename?: 'classifiedSendMortgageContactsForm';
  emailMessageId?: Maybe<Scalars['String']>;
};

export type RecordInput = {
  list: Array<RecordItemInput>;
};

export type RecordItemInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum DiscountCalcEnum {
  SbrElreg = 'SBR_ELREG',
  SalaryProject = 'SALARY_PROJECT'
}

export type SubscriptionsCrudResponse = {
  __typename?: 'SubscriptionsCRUDResponse';
  firstError?: Maybe<SubscriptionsError>;
  errors?: Maybe<Array<Maybe<SubscriptionsError>>>;
  email?: Maybe<Scalars['String']>;
};

export type SubscriptionsError = AuthUserIdError | InternalError;

export type SubscribeToPriceChangeResponse = {
  __typename?: 'SubscribeToPriceChangeResponse';
  isSubscribed: Scalars['Boolean'];
  firstError?: Maybe<SubscribeToPriceChangeResponseError>;
  errors?: Maybe<Array<SubscribeToPriceChangeResponseError>>;
};

export type SubscribeToPriceChangeResponseError = InternalError | AuthUserIdError | SubscribeToPriceChangeErrorInvalidOfferId | SubscribeToPriceChangeErrorEmptyPrice | SubscribeToPriceChangeErrorEmptySubscriber | SubscribeToPriceChangeErrorInvalidEmail | SubscribeToPriceChangeErrorSubscriptionExists;

export type SubscribeToPriceChangeErrorInvalidOfferId = BaseError & {
  __typename?: 'SubscribeToPriceChangeErrorInvalidOfferId';
  message?: Maybe<Scalars['String']>;
};

export type SubscribeToPriceChangeErrorEmptyPrice = BaseError & {
  __typename?: 'SubscribeToPriceChangeErrorEmptyPrice';
  message?: Maybe<Scalars['String']>;
};

export type SubscribeToPriceChangeErrorEmptySubscriber = BaseError & {
  __typename?: 'SubscribeToPriceChangeErrorEmptySubscriber';
  message?: Maybe<Scalars['String']>;
};

export type SubscribeToPriceChangeErrorInvalidEmail = BaseError & {
  __typename?: 'SubscribeToPriceChangeErrorInvalidEmail';
  message?: Maybe<Scalars['String']>;
};

export type SubscribeToPriceChangeErrorSubscriptionExists = BaseError & {
  __typename?: 'SubscribeToPriceChangeErrorSubscriptionExists';
  message?: Maybe<Scalars['String']>;
};

export type PriceInput = {
  value: Scalars['Float'];
  currency: Scalars['Int'];
};

export type UnsubscribeFromSearchResponse = {
  __typename?: 'UnsubscribeFromSearchResponse';
  isUnsubscribed: Scalars['Boolean'];
  firstError?: Maybe<UnsubscribeFromSearchError>;
  errors?: Maybe<Array<UnsubscribeFromSearchError>>;
};

export type UnsubscribeFromSearchError = InternalError | UnsubscribeFromSearchErrorInvalidId | AuthUserIdError | UnsubscribeFromSearchErrorNotExists;

export type UnsubscribeFromSearchErrorInvalidId = BaseError & {
  __typename?: 'UnsubscribeFromSearchErrorInvalidId';
  message?: Maybe<Scalars['String']>;
};

export type UnsubscribeFromSearchErrorNotExists = BaseError & {
  __typename?: 'UnsubscribeFromSearchErrorNotExists';
  message?: Maybe<Scalars['String']>;
};

export type UnsubscribeFromPriceChangeOpenResponseGql = {
  __typename?: 'UnsubscribeFromPriceChangeOpenResponseGql';
  isUnsubscribed: Scalars['Boolean'];
  firstError?: Maybe<UnsubscribeFromPriceChangeOpenError>;
  errors?: Maybe<Array<UnsubscribeFromPriceChangeOpenError>>;
};

export type UnsubscribeFromPriceChangeOpenError = InternalError | AuthUserIdError | UnsubscribeFromPriceChangeOpenErrorInvalidId | UnsubscribeFromPriceChangeOpenErrorNotExists;

export type UnsubscribeFromPriceChangeOpenErrorInvalidId = BaseError & {
  __typename?: 'UnsubscribeFromPriceChangeOpenErrorInvalidId';
  message?: Maybe<Scalars['String']>;
};

export type UnsubscribeFromPriceChangeOpenErrorNotExists = BaseError & {
  __typename?: 'UnsubscribeFromPriceChangeOpenErrorNotExists';
  message?: Maybe<Scalars['String']>;
};

export type UnsubscribeFromPriceChangePersonalResponseGql = {
  __typename?: 'UnsubscribeFromPriceChangePersonalResponseGql';
  isUnsubscribed: Scalars['Boolean'];
  firstError?: Maybe<UnsubscribeFromPriceChangePersonalError>;
  errors?: Maybe<Array<UnsubscribeFromPriceChangePersonalError>>;
};

export type UnsubscribeFromPriceChangePersonalError = InternalError | AuthUserIdError | UnsubscribeFromPriceChangePersonalErrorInvalidId | UnsubscribeFromPriceChangePersonalErrorNotExists;

export type UnsubscribeFromPriceChangePersonalErrorInvalidId = BaseError & {
  __typename?: 'UnsubscribeFromPriceChangePersonalErrorInvalidId';
  message?: Maybe<Scalars['String']>;
};

export type UnsubscribeFromPriceChangePersonalErrorNotExists = BaseError & {
  __typename?: 'UnsubscribeFromPriceChangePersonalErrorNotExists';
  message?: Maybe<Scalars['String']>;
};

export type ConfirmEmail = ConfirmEmailType | SuccessfulMailConfirmation;

/** OTP Session */
export type ConfirmEmailType = {
  __typename?: 'ConfirmEmailType';
  otpSession?: Maybe<OtpSession>;
};

/** OtpSession */
export type OtpSession = {
  __typename?: 'OtpSession';
  id: Scalars['String'];
  passCodeLength: Scalars['Int'];
  retrySendDelayInSec: Scalars['Int'];
};

/** Эмейл успешно подтвержден */
export type SuccessfulMailConfirmation = {
  __typename?: 'SuccessfulMailConfirmation';
  emailConfirmed: Scalars['Boolean'];
};

export type SavingSearchSuccess = {
  __typename?: 'SavingSearchSuccess';
  isSuccess: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
};

/** результат */
export type SaveDraftOfferResult = {
  __typename?: 'SaveDraftOfferResult';
  success: Scalars['Boolean'];
  bffError?: Maybe<BffErrosEnum>;
  errors?: Maybe<Array<Maybe<SaveDraftErrorEnum>>>;
};

export enum SaveDraftErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOfferId = 'INVALID_OFFER_ID',
  EmptyOffer = 'EMPTY_OFFER',
  OfferPublishing = 'OFFER_PUBLISHING',
  OfferIdExpired = 'OFFER_ID_EXPIRED',
  OfferEmpty = 'OFFER_EMPTY',
  ImagesLimitExceeded = 'IMAGES_LIMIT_EXCEEDED',
  RoomsLimitExceeded = 'ROOMS_LIMIT_EXCEEDED',
  RoomsForSaleLimitExceeded = 'ROOMS_FOR_SALE_LIMIT_EXCEEDED',
  LivingRoomsLimitExceeded = 'LIVING_ROOMS_LIMIT_EXCEEDED',
  BathroomsLimitExceeded = 'BATHROOMS_LIMIT_EXCEEDED'
}

export type SaveDraftOfferInput = {
  id: Scalars['String'];
  role: LkRoleGqlEnum;
  dealType?: InputMaybe<DealTypeOpgqlEnum>;
  realtyObject?: InputMaybe<RealtyObjectTypeGqlEnum>;
  newBuilding?: InputMaybe<NewBuildingGqlEnum>;
  regionId?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Array<SaveDraftOfferImage>>;
  rooms?: InputMaybe<RoomsExtendedGqlEnum>;
  houseRooms?: InputMaybe<Scalars['Int']>;
  roomsInOffer?: InputMaybe<RoomsExtendedGqlEnum>;
  wallsType?: InputMaybe<WallsTypeGqlEnum>;
  renovation?: InputMaybe<RenovationTypeGqlEnum>;
  windows?: InputMaybe<WindowsOpgqlEnum>;
  bathroom?: InputMaybe<BathroomGqlEnum>;
  bathrooms?: InputMaybe<Array<BathroomOpgqlEnum>>;
  houseBathroom?: InputMaybe<HouseBathroomOpgqlEnum>;
  heatingType?: InputMaybe<HeatingTypeGqlEnum>;
  landStatus?: InputMaybe<LandStatusOptionsGqlEnum>;
  materialType?: InputMaybe<MaterialTypeGqlEnum>;
  isApartments?: InputMaybe<Scalars['Boolean']>;
  withOnlineView?: InputMaybe<Scalars['Boolean']>;
  ceilingHeight?: InputMaybe<Scalars['Float']>;
  isAdjacent?: InputMaybe<Scalars['Boolean']>;
  isIsolated?: InputMaybe<Scalars['Boolean']>;
  floor?: InputMaybe<Scalars['Int']>;
  floorsTotal?: InputMaybe<Scalars['Int']>;
  builtYear?: InputMaybe<Scalars['String']>;
  builtYearInt?: InputMaybe<Scalars['Int']>;
  address?: InputMaybe<OfferPlacementAddress>;
  price?: InputMaybe<OfferPlacementPrice>;
  prepayment?: InputMaybe<Scalars['Int']>;
  communalPayments?: InputMaybe<OfferPlacementCommunalPayments>;
  communalPaymentsIncluded?: InputMaybe<Scalars['Boolean']>;
  deposit?: InputMaybe<OfferPlacementDeposit>;
  withoutDeposit?: InputMaybe<Scalars['Boolean']>;
  commissionClient?: InputMaybe<Scalars['Float']>;
  commissionAgent?: InputMaybe<Scalars['Float']>;
  withoutCommissionClient?: InputMaybe<Scalars['Boolean']>;
  withoutCommissionAgent?: InputMaybe<Scalars['Boolean']>;
  totalArea?: InputMaybe<OfferPlacementArea>;
  livingArea?: InputMaybe<OfferPlacementArea>;
  kitchenArea?: InputMaybe<OfferPlacementArea>;
  landArea?: InputMaybe<OfferPlacementArea>;
  roomAreas?: InputMaybe<Array<InputMaybe<OfferPlacementArea>>>;
  roomsInOfferAreas?: InputMaybe<Array<InputMaybe<OfferPlacementArea>>>;
  description?: InputMaybe<Scalars['String']>;
  offerOptions?: InputMaybe<OfferPlacementOfferOptions>;
  construction?: InputMaybe<ConstructionOpgqlEnum>;
  seller?: InputMaybe<SaveDraftOfferSellerInput>;
};

export enum LkRoleGqlEnum {
  Client = 'Client',
  Professional = 'Professional',
  Realtor = 'Realtor'
}

export enum DealTypeOpgqlEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Sell = 'SELL',
  Rent = 'RENT'
}

export enum RealtyObjectTypeGqlEnum {
  Unknown = 'UNKNOWN',
  Flat = 'FLAT',
  Room = 'ROOM',
  House = 'HOUSE',
  Land = 'LAND',
  CountryEstate = 'COUNTRY_ESTATE'
}

export enum NewBuildingGqlEnum {
  Unknown = 'UNKNOWN',
  New = 'NEW',
  Second = 'SECOND'
}

export type SaveDraftOfferImage = {
  pathPrefix?: InputMaybe<Scalars['String']>;
};

export enum BathroomGqlEnum {
  Unknown = 'UNKNOWN',
  Combined = 'COMBINED',
  Parted = 'PARTED',
  Several = 'SEVERAL'
}

export enum BathroomOpgqlEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Combined = 'COMBINED',
  Separate = 'SEPARATE'
}

export enum HouseBathroomOpgqlEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Inside = 'INSIDE',
  Outside = 'OUTSIDE'
}

export enum HeatingTypeGqlEnum {
  Unknown = 'UNKNOWN',
  AutonomousGas = 'AUTONOMOUS_GAS',
  CentralGas = 'CENTRAL_GAS',
  CentralCoal = 'CENTRAL_COAL',
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  Fireplace = 'FIREPLACE',
  SolidFuelBoiler = 'SOLID_FUEL_BOILER',
  Stove = 'STOVE'
}

export enum LandStatusOptionsGqlEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Lph = 'LPH',
  Snt = 'SNT',
  Dnp = 'DNP',
  Izhs = 'IZHS'
}

export enum MaterialTypeGqlEnum {
  Unknown = 'UNKNOWN',
  AerocreteBlock = 'AEROCRETE_BLOCK',
  Boards = 'BOARDS',
  Brick = 'BRICK',
  FoamConcreteBlock = 'FOAM_CONCRETE_BLOCK',
  GasSilicateBlock = 'GAS_SILICATE_BLOCK',
  Monolith = 'MONOLITH',
  Wireframe = 'WIREFRAME',
  Wood = 'WOOD'
}

/** Информация об адресе */
export type OfferPlacementAddress = {
  /** id гео объекта */
  id?: InputMaybe<Scalars['Int']>;
  /** адресс гео-объекта */
  displayName?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<OfferPlacementCoords>;
};

/** Координаты при размещении объявления */
export type OfferPlacementCoords = {
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
};

/** Информация о цене */
export type OfferPlacementPrice = {
  /** Цена в минимальных единицах (копейки, центы, eвроценты) */
  value: Scalars['Float'];
  /** Валюта */
  currency: CurrencyGqlEnum;
};

/** Информация о цене */
export type OfferPlacementCommunalPayments = {
  /** Цена в минимальных единицах (копейки, центы, eвроценты) */
  value: Scalars['Float'];
  /** Валюта */
  currency: CurrencyGqlEnum;
  /** Коммуналка включена */
  included?: InputMaybe<Scalars['Boolean']>;
};

/** Информация о цене */
export type OfferPlacementDeposit = {
  /** Цена в минимальных единицах (копейки, центы, eвроценты) */
  value: Scalars['Float'];
  /** Валюта */
  currency: CurrencyGqlEnum;
  /** Без депозита */
  depositFree?: InputMaybe<Scalars['Boolean']>;
};

/** Информация о площади */
export type OfferPlacementArea = {
  /** Размер площади */
  value?: InputMaybe<Scalars['Float']>;
  /** Единица измерения площади */
  unit: AreaUnitGqlEnum;
};

export enum AreaUnitGqlEnum {
  Unknown = 'UNKNOWN',
  Meter = 'METER',
  Are = 'ARE',
  Hectare = 'HECTARE'
}

export type OfferPlacementOfferOptions = {
  hasBalcony?: InputMaybe<Scalars['Boolean']>;
  hasLift?: InputMaybe<Scalars['Boolean']>;
  hasConcierge?: InputMaybe<Scalars['Boolean']>;
  isCloseTerritory?: InputMaybe<Scalars['Boolean']>;
  water?: InputMaybe<Scalars['Boolean']>;
  furniture?: InputMaybe<Scalars['Boolean']>;
  bath?: InputMaybe<Scalars['Boolean']>;
  gas?: InputMaybe<Scalars['Boolean']>;
  kitchenFurniture?: InputMaybe<Scalars['Boolean']>;
  telephone?: InputMaybe<Scalars['Boolean']>;
  electricity?: InputMaybe<Scalars['Boolean']>;
  swimmingPool?: InputMaybe<Scalars['Boolean']>;
  sewerage?: InputMaybe<Scalars['Boolean']>;
  internet?: InputMaybe<Scalars['Boolean']>;
  garage?: InputMaybe<Scalars['Boolean']>;
  tv?: InputMaybe<Scalars['Boolean']>;
  airConditioner?: InputMaybe<Scalars['Boolean']>;
  washingMachine?: InputMaybe<Scalars['Boolean']>;
  dishwasher?: InputMaybe<Scalars['Boolean']>;
  fridge?: InputMaybe<Scalars['Boolean']>;
  withChildren?: InputMaybe<Scalars['Boolean']>;
  withAnimals?: InputMaybe<Scalars['Boolean']>;
};

export enum ConstructionOpgqlEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Standalone = 'STANDALONE',
  PartOfHouse = 'PART_OF_HOUSE',
  Duplex = 'DUPLEX',
  Townhouse = 'TOWNHOUSE'
}

export type SaveDraftOfferSellerInput = {
  name?: InputMaybe<Scalars['String']>;
  agencyName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** результат */
export type CreateOfferIdResult = {
  __typename?: 'CreateOfferIdResult';
  data?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GenerateUploadResourcesResult = {
  __typename?: 'GenerateUploadResourcesResult';
  items?: Maybe<Array<UploadResourceItem>>;
  errors?: Maybe<Array<Maybe<GenerateResourcesErrorEnum>>>;
};

export type UploadResourceItem = {
  __typename?: 'UploadResourceItem';
  signature: Scalars['String'];
  resource: UploadResourceItemResource;
};

/** результат */
export type UploadResourceItemResource = {
  __typename?: 'UploadResourceItemResource';
  serviceId: Scalars['String'];
  offerId: Scalars['String'];
  imageId: Scalars['String'];
};

export enum GenerateResourcesErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOfferId = 'INVALID_OFFER_ID',
  InvalidN = 'INVALID_N',
  Unauthorized = 'UNAUTHORIZED',
  OfferNotExists = 'OFFER_NOT_EXISTS'
}

/** Результат публикации */
export type PublishOfferResult = {
  __typename?: 'PublishOfferResult';
  success: Scalars['Boolean'];
  bffError?: Maybe<BffErrosEnum>;
  errors?: Maybe<Array<Maybe<PublishOfferErrorEnum>>>;
};

export enum PublishOfferErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOfferId = 'INVALID_OFFER_ID',
  EmptyOffer = 'EMPTY_OFFER',
  OfferIdExpired = 'OFFER_ID_EXPIRED',
  EmptyDeal = 'EMPTY_DEAL',
  EmptyPrice = 'EMPTY_PRICE',
  InvalidCentAmount = 'INVALID_CENT_AMOUNT',
  EmptyCurrency = 'EMPTY_CURRENCY',
  EmptySale = 'EMPTY_SALE',
  EmptyRealty = 'EMPTY_REALTY',
  EmptyPortion = 'EMPTY_PORTION',
  EmptyArea = 'EMPTY_AREA',
  EmptyLayout = 'EMPTY_LAYOUT',
  InvalidArea = 'INVALID_AREA',
  EmptyRoomsForSale = 'EMPTY_ROOMS_FOR_SALE',
  EmptyRooms = 'EMPTY_ROOMS',
  EmptyDescription = 'EMPTY_DESCRIPTION',
  EmptyLocation = 'EMPTY_LOCATION',
  EmptyAddressValue = 'EMPTY_ADDRESS_VALUE',
  EmptySeller = 'EMPTY_SELLER',
  EmptySellerName = 'EMPTY_SELLER_NAME',
  EmptySellerPhone = 'EMPTY_SELLER_PHONE',
  EmptySellerParticipant = 'EMPTY_SELLER_PARTICIPANT',
  EmptyAgencyTitle = 'EMPTY_AGENCY_TITLE',
  InvalidRegionId = 'INVALID_REGION_ID',
  InvalidAddressId = 'INVALID_ADDRESS_ID',
  EmptyTotalArea = 'EMPTY_TOTAL_AREA',
  EmptyRoomForSaleArea = 'EMPTY_ROOM_FOR_SALE_AREA',
  InvalidTotalArea = 'INVALID_TOTAL_AREA',
  InvalidKitchenArea = 'INVALID_KITCHEN_AREA',
  InvalidRoomArea = 'INVALID_ROOM_AREA',
  InvalidRoomForSaleArea = 'INVALID_ROOM_FOR_SALE_AREA',
  InvalidLivingArea = 'INVALID_LIVING_AREA',
  EmptyPoint = 'EMPTY_POINT',
  InvalidLatitude = 'INVALID_LATITUDE',
  InvalidLongitude = 'INVALID_LONGITUDE',
  EmptyImage = 'EMPTY_IMAGE',
  EmptyS3PathPrefix = 'EMPTY_S3_PATH_PREFIX',
  InvalidPhoneCountryCode = 'INVALID_PHONE_COUNTRY_CODE',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  EmptyBathroomValue = 'EMPTY_BATHROOM_VALUE',
  InvalidFeaturesFloor = 'INVALID_FEATURES_FLOOR',
  InvalidFeaturesCeilingHeightMeters = 'INVALID_FEATURES_CEILING_HEIGHT_METERS',
  EmptyFeaturesRenovationValue = 'EMPTY_FEATURES_RENOVATION_VALUE',
  InvalidBuildingFloors = 'INVALID_BUILDING_FLOORS',
  InvalidBuildingYearBuilt = 'INVALID_BUILDING_YEAR_BUILT',
  EmptyBuildingWallsValue = 'EMPTY_BUILDING_WALLS_VALUE',
  InvalidAreas = 'INVALID_AREAS',
  EmptyLandStatus = 'EMPTY_LAND_STATUS',
  EmptyLandStatusValue = 'EMPTY_LAND_STATUS_VALUE',
  EmptyLandArea = 'EMPTY_LAND_AREA',
  InvalidLandArea = 'INVALID_LAND_AREA',
  OfferEmpty = 'OFFER_EMPTY',
  EmptyHouseLand = 'EMPTY_HOUSE_LAND',
  EmptyHouseConstruction = 'EMPTY_HOUSE_CONSTRUCTION',
  EmptyHouseTotalArea = 'EMPTY_HOUSE_TOTAL_AREA',
  EmptyHouseHeating = 'EMPTY_HOUSE_HEATING',
  EmptyHouseBathroom = 'EMPTY_HOUSE_BATHROOM',
  EmptyKitchenArea = 'EMPTY_KITCHEN_AREA',
  EmptyFlatFeatures = 'EMPTY_FLAT_FEATURES',
  EmptyFlatFeaturesFloor = 'EMPTY_FLAT_FEATURES_FLOOR',
  EmptyPrepaidRentMonths = 'EMPTY_PREPAID_RENT_MONTHS',
  EmptyRentAdvertiser = 'EMPTY_RENT_ADVERTISER',
  EmptyRentCommission = 'EMPTY_RENT_COMMISSION',
  EmptyRentDeposit = 'EMPTY_RENT_DEPOSIT',
  EmptyRentDepositRefundableMoney = 'EMPTY_RENT_DEPOSIT_REFUNDABLE_MONEY',
  EmptyRentPrice = 'EMPTY_RENT_PRICE',
  EmptyRentUtilityPayments = 'EMPTY_RENT_UTILITY_PAYMENTS',
  EmptyTenantCommissionRequested = 'EMPTY_TENANT_COMMISSION_REQUESTED',
  EmptyTenantsAgentCommissionRequested = 'EMPTY_TENANTS_AGENT_COMMISSION_REQUESTED',
  InvalidMonthlyRentPercent = 'INVALID_MONTHLY_RENT_PERCENT',
  InvalidPrepaidRentMonths = 'INVALID_PREPAID_RENT_MONTHS',
  MalformedCommission = 'MALFORMED_COMMISSION',
  MalformedRentAdvertiser = 'MALFORMED_RENT_ADVERTISER',
  MalformedRentDeposit = 'MALFORMED_RENT_DEPOSIT',
  MalformedRentUtilityPayments = 'MALFORMED_RENT_UTILITY_PAYMENTS',
  RentLand = 'RENT_LAND',
  SellerAdvertiserClash = 'SELLER_ADVERTISER_CLASH',
  EmptyUtilityPaymentsPrice = 'EMPTY_UTILITY_PAYMENTS_PRICE',
  ImagesLimitExceeded = 'IMAGES_LIMIT_EXCEEDED',
  RoomsLimitExceeded = 'ROOMS_LIMIT_EXCEEDED',
  RoomsForSaleLimitExceeded = 'ROOMS_FOR_SALE_LIMIT_EXCEEDED',
  LivingRoomsLimitExceeded = 'LIVING_ROOMS_LIMIT_EXCEEDED',
  BathroomsLimitExceeded = 'BATHROOMS_LIMIT_EXCEEDED',
  EmptyFeaturesWindowsValue = 'EMPTY_FEATURES_WINDOWS_VALUE'
}

export type RemoveOfferResult = {
  __typename?: 'RemoveOfferResult';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<RemoveOfferErrorEnum>>>;
};

export enum RemoveOfferErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOfferId = 'INVALID_OFFER_ID',
  OfferNotExists = 'OFFER_NOT_EXISTS'
}

/** результат */
export type UnpublishOfferIdResult = {
  __typename?: 'UnpublishOfferIdResult';
  success: Scalars['Boolean'];
  bffError?: Maybe<BffErrosEnum>;
  errors?: Maybe<Array<Maybe<UnpublishOfferErrorEnum>>>;
};

export enum UnpublishOfferErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOfferId = 'INVALID_OFFER_ID',
  OfferDraft = 'OFFER_DRAFT',
  OfferNotExists = 'OFFER_NOT_EXISTS',
  OfferArchive = 'OFFER_ARCHIVE',
  OfferEmpty = 'OFFER_EMPTY',
  OfferExpired = 'OFFER_EXPIRED'
}

/** результат */
export type ArchiveOfferResult = {
  __typename?: 'ArchiveOfferResult';
  success: Scalars['Boolean'];
  errors?: Maybe<Array<Maybe<ArchiveOfferErrorEnum>>>;
};

export enum ArchiveOfferErrorEnum {
  ValueNotSet = 'VALUE_NOT_SET',
  Internal = 'INTERNAL',
  InvalidUserId = 'INVALID_USER_ID',
  InvalidOfferId = 'INVALID_OFFER_ID',
  OfferNotExists = 'OFFER_NOT_EXISTS',
  OfferEmpty = 'OFFER_EMPTY'
}

export type OrderWalletVasResponse = {
  __typename?: 'OrderWalletVasResponse';
  success: Scalars['Boolean'];
};

export type OrderOfferVasResponse = {
  __typename?: 'OrderOfferVasResponse';
  redirectUrl?: Maybe<Scalars['String']>;
  bffError?: Maybe<BffErrosEnum>;
};

export type SendEmailForConnectStatistics = {
  __typename?: 'SendEmailForConnectStatistics';
  emailMessageId?: Maybe<Scalars['String']>;
};

/** Варианта ответа */
export type RemontMortgageCallBackResult = InternalError | RemontMortgageCallBackSuccess;

/** Заявка успешно отправлена, возвращается id письма */
export type RemontMortgageCallBackSuccess = {
  __typename?: 'RemontMortgageCallBackSuccess';
  emailMessageId?: Maybe<Scalars['String']>;
};

export type CompanyMutations = {
  __typename?: 'CompanyMutations';
  updatePreferences: UpdatePreferencesResponseUnion;
};


export type CompanyMutationsUpdatePreferencesArgs = {
  id: Scalars['ID'];
  preferences: UpdatePreferencesInput;
};

export type UpdatePreferencesResponseUnion = InternalError | UpdatePreferencesSuccess;

export type UpdatePreferencesSuccess = {
  __typename?: 'UpdatePreferencesSuccess';
  success?: Maybe<Scalars['Boolean']>;
  query?: Maybe<UpdatePreferencesSuccessQuery>;
};

export type UpdatePreferencesSuccessQuery = {
  __typename?: 'UpdatePreferencesSuccessQuery';
  /** Получение о компании (пока только из бэка Регистратура) */
  getCompanyPreferences?: Maybe<CompanyPreferencesResponse>;
};


export type UpdatePreferencesSuccessQueryGetCompanyPreferencesArgs = {
  id: Scalars['ID'];
};

export type UpdatePreferencesInput = {
  brokerVariant?: InputMaybe<BrokerVariantEnum>;
  bankFilter?: InputMaybe<UpdatePreferencesBankFilter>;
};

export type UpdatePreferencesBankFilter = {
  onlyBanks: Array<Scalars['String']>;
  excludeBanks: Array<Scalars['String']>;
};

export type MortgageMutations = {
  __typename?: 'MortgageMutations';
  /** Мутации для обратных звонков */
  callBack?: Maybe<MortgageCallBackMutations>;
};

export type MortgageCallBackMutations = {
  __typename?: 'MortgageCallBackMutations';
  /** Обратные звонки с лендингов ипотеки */
  landing?: Maybe<MortgageCallBackLandingMutations>;
};


export type MortgageCallBackMutationsLandingArgs = {
  phone: Scalars['String'];
  name: Scalars['String'];
  source: MortgageCallBackLandingSource;
};

/** Варианта ответа */
export type MortgageCallBackLandingMutations = InternalError | MortgageCallBackLandingSuccess;

/** Заявка успешно отправлена, возвращается id письма */
export type MortgageCallBackLandingSuccess = {
  __typename?: 'MortgageCallBackLandingSuccess';
  emailMessageId?: Maybe<Scalars['String']>;
};

export enum MortgageCallBackLandingSource {
  /** Лендинг IT ипотеки */
  ItLanding = 'IT_LANDING'
}

export type Mg2Mutations = {
  __typename?: 'MG2Mutations';
  /** Create сalculation */
  createCalculation: Mg2CalculationCreateResponseBag;
  /** Update or create сalculation */
  updateCalculation: Mg2CalculationUpdateResponseBag;
  /** Обновить данные о партнёре */
  updatePartner: Mg2PartnerUpdateResponseBag;
  /**
   * Выбрать ипотечные предложения из списка доступных
   * @deprecated use DispatchService/SubmitOffers
   */
  selectOffers: Mg2OffersSelectResponseBag;
  /**
   * redo сalculation/Вернуться к расчёту
   * @deprecated use undoDispatch instead
   */
  redoCalculation: Mg2RedoCalculationResponseBag;
  removeFile: Mg2RemoveFileResponseBag;
  /** Архивировать заявку */
  archiveSummary: Mg2ArchiveSummaryBag;
  /** Разархивировать заявку */
  restoreSummary: Mg2RestoreSummaryBag;
  /** Update questionnaire form in demand */
  updateQuestionnaireForm: Mg2SetQuestionnaireFormResponseLocalBag;
  /** Скопировать заявку */
  copyDispatch: Mg2CopyDispatchBag;
  updateBorrowerWithFullDynamo: Mg2GetFullDynamoResponseBag;
  updateCoBorrowerWithFullDynamo: Mg2GetFullDynamoResponseBag;
  addCoBorrowerWithFullDynamo: Mg2GetFullDynamoResponseBag;
  removeCoBorrowerWithFullDynamo: Mg2GetFullDynamoResponseBag;
  /** Выбрать ипотечные предложения/Перейти на анкету */
  submitOffers: Mg2SubmitOffersResponseBag;
  /** Отправить в банки/Отправить на проверку */
  initiateDispatch: Mg2InitiateDispatchBag;
  /** Диспатч анкеты на шаг назад */
  undoDispatch: Mg2UndoDispatchResponseBag;
  /** Отправить доработку */
  completeBankRework: Mg2CompleteBankReworkResponseBag;
  /** Убрать файл доработки */
  reworkRemoveFile: Mg2ReworkRemoveFileResponseBag;
  /**
   * Выбрать банк
   * @deprecated deprecated, use activateDeal mutation
   */
  selectBank: Mg2SelectBankResponseBag;
  activateDeal: Mg2ActivateDealResponseBag;
  updateChatDeal: Mg2UpdateChatDealResponseBag;
};


export type Mg2MutationsCreateCalculationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Mg2CalculationInput;
  source?: InputMaybe<Mg2DemandSourceEnum>;
};


export type Mg2MutationsUpdateCalculationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Mg2CalculationInput;
};


export type Mg2MutationsUpdatePartnerArgs = {
  id: Scalars['ID'];
  partner: Mg2PartnerInput;
};


export type Mg2MutationsSelectOffersArgs = {
  id: Scalars['ID'];
  offerIds?: InputMaybe<Array<Scalars['ID']>>;
  bankOffers?: InputMaybe<Array<Mg2BankOfferInput>>;
};


export type Mg2MutationsRedoCalculationArgs = {
  id: Scalars['ID'];
};


export type Mg2MutationsRemoveFileArgs = {
  borrowerId: Scalars['ID'];
  coBorrowerId?: InputMaybe<Scalars['ID']>;
  folderType: MortgageFolderKindEnum;
  fileId: Scalars['String'];
};


export type Mg2MutationsArchiveSummaryArgs = {
  id: Scalars['ID'];
};


export type Mg2MutationsRestoreSummaryArgs = {
  id: Scalars['ID'];
};


export type Mg2MutationsUpdateQuestionnaireFormArgs = {
  id: Scalars['ID'];
  questionnaireForm: Mg2QuestionnaireFormType;
};


export type Mg2MutationsCopyDispatchArgs = {
  id: Scalars['ID'];
};


export type Mg2MutationsUpdateBorrowerWithFullDynamoArgs = {
  borrower: Mg2BorrowerInput;
  bankMnemonicsList: Array<Scalars['String']>;
  demand?: InputMaybe<Mg2CalculationDemandInput>;
  form: Mg2QuestionnaireFormType;
  spouse?: InputMaybe<Mg2CoBorrowerInput>;
  coBorrowersList: Array<Mg2CoBorrowerInput>;
};


export type Mg2MutationsUpdateCoBorrowerWithFullDynamoArgs = {
  coBorrowerId: Scalars['ID'];
  borrower: Mg2BorrowerInput;
  bankMnemonicsList: Array<Scalars['String']>;
  demand?: InputMaybe<Mg2CalculationDemandInput>;
  form: Mg2QuestionnaireFormType;
  spouse?: InputMaybe<Mg2CoBorrowerInput>;
  coBorrowersList: Array<Mg2CoBorrowerInput>;
};


export type Mg2MutationsAddCoBorrowerWithFullDynamoArgs = {
  borrower: Mg2BorrowerInput;
  bankMnemonicsList: Array<Scalars['String']>;
  demand?: InputMaybe<Mg2CalculationDemandInput>;
  form: Mg2QuestionnaireFormType;
  spouse?: InputMaybe<Mg2CoBorrowerInput>;
  coBorrowersList: Array<Mg2CoBorrowerInput>;
};


export type Mg2MutationsRemoveCoBorrowerWithFullDynamoArgs = {
  borrower: Mg2BorrowerInput;
  coBorrowerId: Scalars['ID'];
  bankMnemonicsList: Array<Scalars['String']>;
  demand?: InputMaybe<Mg2CalculationDemandInput>;
  form: Mg2QuestionnaireFormType;
  spouse?: InputMaybe<Mg2CoBorrowerInput>;
  coBorrowersList: Array<Mg2CoBorrowerInput>;
};


export type Mg2MutationsSubmitOffersArgs = {
  id: Scalars['ID'];
  bankOffers?: InputMaybe<Array<Mg2BankOfferInput>>;
};


export type Mg2MutationsInitiateDispatchArgs = {
  id: Scalars['ID'];
};


export type Mg2MutationsUndoDispatchArgs = {
  dispatchId: Scalars['ID'];
};


export type Mg2MutationsCompleteBankReworkArgs = {
  dispatchId: Scalars['ID'];
  bankMnemonic: Scalars['String'];
  borrowerComment?: InputMaybe<Scalars['String']>;
};


export type Mg2MutationsReworkRemoveFileArgs = {
  dispatchId: Scalars['ID'];
  bankMnemonic: Scalars['String'];
  fileId: Scalars['String'];
};


export type Mg2MutationsSelectBankArgs = {
  mortgageId: Scalars['ID'];
  bankMnemonic: Scalars['String'];
};


export type Mg2MutationsActivateDealArgs = {
  mortgageId: Scalars['ID'];
  bankMnemonic: Scalars['String'];
};


export type Mg2MutationsUpdateChatDealArgs = {
  mortgageId: Scalars['ID'];
  bankMnemonic: Scalars['String'];
  deal: Mg2DealerDealInput;
};

export type Mg2CalculationCreateResponseBag = {
  __typename?: 'MG2CalculationCreateResponseBag';
  data?: Maybe<Mg2CalculationCreateResponse>;
  error?: Maybe<Mg2CalculationCreateResponseError>;
};

export type Mg2CalculationCreateResponse = {
  __typename?: 'MG2CalculationCreateResponse';
  calculationId?: Maybe<Scalars['ID']>;
};

export type Mg2CalculationCreateResponseError = ServiceError;

export type Mg2CalculationUpdateResponseBag = {
  __typename?: 'MG2CalculationUpdateResponseBag';
  data?: Maybe<Mg2CalculationUpdateResponse>;
  error?: Maybe<Mg2CalculationUpdateResponseError>;
};

/** Обновить входные данные для расчёта предложений */
export type Mg2CalculationUpdateResponse = {
  __typename?: 'MG2CalculationUpdateResponse';
  /** @deprecated use bankOffers */
  offers?: Maybe<Mg2OfferList>;
  bankOffers: Array<Mg2BankOffer>;
  /** [UUID] Идентификатор калькуляции */
  id: Scalars['ID'];
  /** Ошибки валидации */
  validationErrors?: Maybe<Array<Mg2ValidationError>>;
};

export type Mg2CalculationUpdateResponseError = ServiceError;

export type Mg2PartnerUpdateResponseBag = {
  __typename?: 'MG2PartnerUpdateResponseBag';
  data?: Maybe<Mg2PartnerUpdateResponse>;
  error?: Maybe<Mg2PartnerUpdateResponseError>;
};

/** Обновить данные о партнёре */
export type Mg2PartnerUpdateResponse = {
  __typename?: 'MG2PartnerUpdateResponse';
  status: Scalars['String'];
  /** Предложения банков */
  offers?: Maybe<Mg2OfferList>;
  bankOffers: Array<Mg2BankOffer>;
  /** Ошибки валидации */
  validationErrors?: Maybe<Array<Mg2ValidationError>>;
};

export type Mg2PartnerUpdateResponseError = ServiceError;

export type Mg2PartnerInput = {
  fullName?: InputMaybe<Mg2FullNameInput>;
  phone: Scalars['String'];
  email: Scalars['String'];
  branchId?: InputMaybe<Scalars['String']>;
};

export type Mg2OffersSelectResponseBag = {
  __typename?: 'MG2OffersSelectResponseBag';
  data?: Maybe<Mg2OffersSelectResponse>;
  error?: Maybe<Mg2OffersSelectResponseError>;
};

/** Выбрать ипотечные предложения из списка доступных */
export type Mg2OffersSelectResponse = {
  __typename?: 'MG2OffersSelectResponse';
  status: Scalars['String'];
};

export type Mg2OffersSelectResponseError = ServiceError;

/** Ипотечное предложение */
export type Mg2BankOfferInput = {
  /** Мнемоник банка */
  mnemonic: Scalars['String'];
  /** Предложения от банка */
  mortgageOffers: Array<Mg2MortageOfferInput>;
};

/** Ипотечное предложение */
export type Mg2MortageOfferInput = {
  /** Ставка */
  rate: Scalars['Float'];
  monthlyPayment: Scalars['Float'];
  /** Переплата */
  overpayment?: InputMaybe<Scalars['Float']>;
  recommendedIncome?: InputMaybe<Scalars['Float']>;
  comment?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isCombo?: InputMaybe<Scalars['Boolean']>;
};

export type Mg2RedoCalculationResponseBag = {
  __typename?: 'MG2RedoCalculationResponseBag';
  data?: Maybe<Mg2RedoCalculationResponse>;
  error?: Maybe<Mg2RedoCalculationResponseError>;
};

export type Mg2RedoCalculationResponse = {
  __typename?: 'MG2RedoCalculationResponse';
  status: Scalars['String'];
};

export type Mg2RedoCalculationResponseError = ServiceError;

export type Mg2RemoveFileResponseBag = {
  __typename?: 'MG2RemoveFileResponseBag';
  data?: Maybe<Mg2RemoveFileResponse>;
  error?: Maybe<Mg2RemoveFileResponseError>;
};

export type Mg2RemoveFileResponse = {
  __typename?: 'MG2RemoveFileResponse';
  status: Scalars['String'];
};

export type Mg2RemoveFileResponseError = ServiceError;

export type Mg2ArchiveSummaryBag = {
  __typename?: 'MG2ArchiveSummaryBag';
  data?: Maybe<Mg2ArchiveSummary>;
  error?: Maybe<Mg2ArchiveSummaryError>;
};

export type Mg2ArchiveSummary = {
  __typename?: 'MG2ArchiveSummary';
  /** Заархивирована ли заявка */
  success: Scalars['Boolean'];
};

export type Mg2ArchiveSummaryError = ServiceError;

export type Mg2RestoreSummaryBag = {
  __typename?: 'MG2RestoreSummaryBag';
  data?: Maybe<Mg2RestoreSummary>;
  error?: Maybe<Mg2RestoreSummaryError>;
};

export type Mg2RestoreSummary = {
  __typename?: 'MG2RestoreSummary';
  /** Восстановлена ли заявка */
  success: Scalars['Boolean'];
};

export type Mg2RestoreSummaryError = ServiceError;

export type Mg2SetQuestionnaireFormResponseLocalBag = {
  __typename?: 'MG2SetQuestionnaireFormResponseLocalBag';
  data?: Maybe<Mg2SetQuestionnaireFormResponseLocal>;
  error?: Maybe<Mg2SetQuestionnaireFormResponseLocalError>;
};

export type Mg2SetQuestionnaireFormResponseLocal = {
  __typename?: 'MG2SetQuestionnaireFormResponseLocal';
  response: Scalars['String'];
};

export type Mg2SetQuestionnaireFormResponseLocalError = ServiceError;

export type Mg2CopyDispatchBag = {
  __typename?: 'MG2CopyDispatchBag';
  data?: Maybe<Mg2CopyDispatch>;
  error?: Maybe<Mg2CopyDispatchError>;
};

export type Mg2CopyDispatch = {
  __typename?: 'MG2CopyDispatch';
  /** id новой заявки после копирования */
  newDemandId: Scalars['String'];
};

export type Mg2CopyDispatchError = ServiceError;

export type Mg2SubmitOffersResponseBag = {
  __typename?: 'MG2SubmitOffersResponseBag';
  data?: Maybe<Mg2SubmitOffersResponse>;
  error?: Maybe<Mg2SubmitOffersResponseError>;
};

/** Выбрать ипотечные предложения из списка доступных */
export type Mg2SubmitOffersResponse = {
  __typename?: 'MG2SubmitOffersResponse';
  status: Scalars['String'];
};

export type Mg2SubmitOffersResponseError = ServiceError;

export type Mg2InitiateDispatchBag = {
  __typename?: 'MG2InitiateDispatchBag';
  data?: Maybe<Mg2InitiateDispatch>;
  error?: Maybe<Mg2InitiateDispatchError>;
};

export type Mg2InitiateDispatch = {
  __typename?: 'MG2InitiateDispatch';
  status: Scalars['String'];
};

export type Mg2InitiateDispatchError = ServiceError;

export type Mg2UndoDispatchResponseBag = {
  __typename?: 'MG2UndoDispatchResponseBag';
  data?: Maybe<Mg2UndoDispatchResponse>;
  error?: Maybe<Mg2UndoDispatchResponseError>;
};

export type Mg2UndoDispatchResponse = {
  __typename?: 'MG2UndoDispatchResponse';
  status: Scalars['String'];
};

export type Mg2UndoDispatchResponseError = ServiceError;

export type Mg2CompleteBankReworkResponseBag = {
  __typename?: 'MG2CompleteBankReworkResponseBag';
  data?: Maybe<Mg2CompleteBankReworkResponse>;
  error?: Maybe<Mg2CompleteBankReworkResponseError>;
};

/** Выбрать ипотечные предложения из списка доступных */
export type Mg2CompleteBankReworkResponse = {
  __typename?: 'MG2CompleteBankReworkResponse';
  status: Scalars['String'];
};

export type Mg2CompleteBankReworkResponseError = ServiceError;

export type Mg2ReworkRemoveFileResponseBag = {
  __typename?: 'MG2ReworkRemoveFileResponseBag';
  data?: Maybe<Mg2ReworkRemoveFileResponse>;
  error?: Maybe<Mg2ReworkRemoveFileResponseError>;
};

/** Выбрать ипотечные предложения из списка доступных */
export type Mg2ReworkRemoveFileResponse = {
  __typename?: 'MG2ReworkRemoveFileResponse';
  status: Scalars['String'];
};

export type Mg2ReworkRemoveFileResponseError = ServiceError;

export type Mg2SelectBankResponseBag = {
  __typename?: 'MG2SelectBankResponseBag';
  data?: Maybe<Mg2SelectBankResponse>;
  error?: Maybe<Mg2SelectBankResponseError>;
};

/** Выбрать банк */
export type Mg2SelectBankResponse = {
  __typename?: 'MG2SelectBankResponse';
  status: Scalars['String'];
};

export type Mg2SelectBankResponseError = ServiceError;

export type Mg2ActivateDealResponseBag = {
  __typename?: 'MG2ActivateDealResponseBag';
  data?: Maybe<Mg2ActivateDealResponse>;
  error?: Maybe<Mg2ActivateDealResponseError>;
};

export type Mg2ActivateDealResponse = {
  __typename?: 'MG2ActivateDealResponse';
  bankMnemonic: Scalars['String'];
  mortgageId: Scalars['String'];
  deal: Mg2DealerDeal;
  dealKind: Mg2DealKindEnum;
};

export enum Mg2DealKindEnum {
  Chat = 'CHAT',
  Online = 'ONLINE'
}

export type Mg2ActivateDealResponseError = ServiceError;

export type Mg2UpdateChatDealResponseBag = {
  __typename?: 'MG2UpdateChatDealResponseBag';
  data?: Maybe<Mg2UpdateChatDealResponse>;
  error?: Maybe<Mg2UpdateChatDealResponseError>;
};

export type Mg2UpdateChatDealResponse = {
  __typename?: 'MG2UpdateChatDealResponse';
  status: Scalars['String'];
};

export type Mg2UpdateChatDealResponseError = ServiceError;

/** Сделка через чат */
export type Mg2DealerDealInput = {
  date: Scalars['String'];
  status?: InputMaybe<Mg2ChatDealStatusEnum>;
};

export type CaptchaValidateResponse = {
  __typename?: 'captchaValidateResponse';
  status?: Maybe<CaptchaValidateResponseStatusEnum>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum CaptchaValidateResponseStatusEnum {
  Success = 'success',
  Failure = 'failure'
}

export type HoosegowMutationsResponse = HoosegowMutationsSuccess | HoosegowMutationsFailure;

export type HoosegowMutationsSuccess = {
  __typename?: 'HoosegowMutationsSuccess';
  /** Назначить задачу текущему офицеру */
  assignTasksShawshank: HoosegowShawshankAssignTaskResponse;
  /** Снять задачу */
  unassignTasksShawshank: HoosegowShawshankUnassignTaskResponse;
  /** Назначить задачу текущему офицеру */
  assignTasksParole: HoosegowParoleAssignTasksResponse;
  /** Снять задачу */
  unassignTasksParole: HoosegowParoleUnassignTaskResponse;
  /** Мутации для задач проверки документов */
  documentVerificationTask: ShawshankDocumentVerificationTaskMutation;
  /** Мутации для задач банков */
  bankTask: ParoleBankTaskMutation;
  /** Создать офицера */
  createOfficer: HoosegowCreateOfficerResponse;
  /** Создать офицера */
  editOfficer: HoosegowEditOfficerResponse;
};


export type HoosegowMutationsSuccessAssignTasksShawshankArgs = {
  mortgageId?: InputMaybe<Scalars['ID']>;
  subjectType?: InputMaybe<HoosegowTaskSubjectTypeEnum>;
};


export type HoosegowMutationsSuccessUnassignTasksShawshankArgs = {
  comment?: InputMaybe<Scalars['String']>;
  mortgageId?: InputMaybe<Scalars['ID']>;
};


export type HoosegowMutationsSuccessAssignTasksParoleArgs = {
  mortgageId?: InputMaybe<Scalars['ID']>;
  subjectType?: InputMaybe<HoosegowTaskSubjectTypeEnum>;
};


export type HoosegowMutationsSuccessUnassignTasksParoleArgs = {
  comment?: InputMaybe<Scalars['String']>;
  mortgageId?: InputMaybe<Scalars['ID']>;
};


export type HoosegowMutationsSuccessCreateOfficerArgs = {
  officer: HoosegowOfficerCreateInput;
};


export type HoosegowMutationsSuccessEditOfficerArgs = {
  officer: HoosegowOfficerEditInput;
};

export type HoosegowShawshankAssignTaskResponse = HoosegowShawshankAssignTaskSuccess | HoosegowShawshankAssignTaskFailure;

export type HoosegowShawshankAssignTaskSuccess = {
  __typename?: 'HoosegowShawshankAssignTaskSuccess';
  list: Array<HoosegowTaskShawshankPerson>;
  paging: Paging;
};

export type HoosegowShawshankAssignTaskFailure = {
  __typename?: 'HoosegowShawshankAssignTaskFailure';
  traceId: Scalars['String'];
  errors: HoosegowShawshankAssignTaskFailureError;
};

export type HoosegowShawshankAssignTaskFailureError = InternalError | HoosegowShawshankTaskFailureErrorAlreadyAssigned | HoosegowShawshankTaskFailureErrorNotFound | HoosegowShawshankTaskFailureErrorInvalidParam | HoosegowShawshankTaskValueNotSet;

export type HoosegowShawshankTaskFailureErrorAlreadyAssigned = {
  __typename?: 'HoosegowShawshankTaskFailureErrorAlreadyAssigned';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowShawshankTaskFailureErrorNotFound = {
  __typename?: 'HoosegowShawshankTaskFailureErrorNotFound';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowShawshankUnassignTaskResponse = HoosegowShawshankUnassignTaskSuccess | HoosegowShawshankUnassignTaskFailure;

export type HoosegowShawshankUnassignTaskSuccess = {
  __typename?: 'HoosegowShawshankUnassignTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type HoosegowShawshankUnassignTaskFailure = {
  __typename?: 'HoosegowShawshankUnassignTaskFailure';
  errors: HoosegowShawshankUnassignTaskFailureError;
};

export type HoosegowShawshankUnassignTaskFailureError = InternalError | HoosegowShawshankTaskFailureErrorNotAssigned | HoosegowShawshankTaskFailureErrorNotFound | HoosegowShawshankTaskFailureErrorInvalidParam | HoosegowShawshankTaskValueNotSet;

export type HoosegowShawshankTaskFailureErrorNotAssigned = {
  __typename?: 'HoosegowShawshankTaskFailureErrorNotAssigned';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowParoleAssignTasksResponse = HoosegowParoleAssignTasksSuccess | HoosegowParoleAssignTasksFailure;

export type HoosegowParoleAssignTasksSuccess = {
  __typename?: 'HoosegowParoleAssignTasksSuccess';
  list: Array<HoosegowTaskParole>;
  paging: Paging;
};

export type HoosegowParoleAssignTasksFailure = {
  __typename?: 'HoosegowParoleAssignTasksFailure';
  errors: Array<HoosegowParoleAssignTaskFailureError>;
};

export type HoosegowParoleAssignTaskFailureError = HoosegowParoleTaskFailureErrorAlreadyAssigned | HoosegowParoleTaskFailureErrorNotFound | HoosegowParoleTaskFailureErrorInvalidParam | HoosegowParoleTaskValueNotSet;

export type HoosegowParoleTaskFailureErrorAlreadyAssigned = {
  __typename?: 'HoosegowParoleTaskFailureErrorAlreadyAssigned';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type HoosegowParoleUnassignTaskResponse = HoosegowParoleUnassignTaskSuccess | HoosegowParoleUnassignTaskFailure;

export type HoosegowParoleUnassignTaskSuccess = {
  __typename?: 'HoosegowParoleUnassignTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type HoosegowParoleUnassignTaskFailure = {
  __typename?: 'HoosegowParoleUnassignTaskFailure';
  errors: Array<HoosegowParoleUnassignTaskFailureError>;
};

export type HoosegowParoleUnassignTaskFailureError = HoosegowParoleTaskFailureErrorNotAssigned | HoosegowParoleTaskFailureErrorNotFound | HoosegowParoleTaskFailureErrorInvalidParam | HoosegowParoleTaskValueNotSet;

export type HoosegowParoleTaskFailureErrorNotAssigned = {
  __typename?: 'HoosegowParoleTaskFailureErrorNotAssigned';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type ShawshankDocumentVerificationTaskMutation = {
  __typename?: 'ShawshankDocumentVerificationTaskMutation';
  invalidateFiles: InvalidateFileShawshankDocumentVerificationTaskResponse;
  validateFiles: ValidateFileShawshankDocumentVerificationTaskResponse;
  rework: ReworkShawshankDocumentVerificationTaskResponse;
  approve: ApproveShawshankDocumentVerificationTaskResponse;
  archive: ArchiveShawshankDocumentVerificationTaskResponse;
  reopen: ReopenShawshankDocumentVerificationTaskResponse;
};


export type ShawshankDocumentVerificationTaskMutationInvalidateFilesArgs = {
  taskId: Scalars['ID'];
  fileIds: Array<Scalars['ID']>;
};


export type ShawshankDocumentVerificationTaskMutationValidateFilesArgs = {
  taskId: Scalars['ID'];
  fileIds: Array<Scalars['ID']>;
};


export type ShawshankDocumentVerificationTaskMutationReworkArgs = {
  id: Scalars['ID'];
  comment: Scalars['String'];
};


export type ShawshankDocumentVerificationTaskMutationApproveArgs = {
  id: Scalars['ID'];
};


export type ShawshankDocumentVerificationTaskMutationArchiveArgs = {
  id: Scalars['ID'];
  comment: Scalars['String'];
};


export type ShawshankDocumentVerificationTaskMutationReopenArgs = {
  id: Scalars['ID'];
  comment: Scalars['String'];
};

export type InvalidateFileShawshankDocumentVerificationTaskResponse = InvalidateFileShawshankDocumentVerificationTaskSuccess | InvalidateFileShawshankDocumentVerificationTaskFailure;

export type InvalidateFileShawshankDocumentVerificationTaskSuccess = {
  __typename?: 'InvalidateFileShawshankDocumentVerificationTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
  task: HoosegowTaskShawshank;
};

export type HoosegowTaskShawshank = HoosegowTaskShawshankPerson | HoosegowTaskShawshankProperty;

export type InvalidateFileShawshankDocumentVerificationTaskFailure = {
  __typename?: 'InvalidateFileShawshankDocumentVerificationTaskFailure';
  traceId: Scalars['String'];
  error: ShawshankDocumentVerificationTaskMutationError;
};

export type ShawshankDocumentVerificationTaskMutationError = NotFoundError | InternalError | ShawshankDocumentVerificationTaskAlreadyClosedError | ShawshankDocumentVerificationTaskNotClosedError;

export type ShawshankDocumentVerificationTaskAlreadyClosedError = BaseError & {
  __typename?: 'ShawshankDocumentVerificationTaskAlreadyClosedError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ShawshankDocumentVerificationTaskNotClosedError = BaseError & {
  __typename?: 'ShawshankDocumentVerificationTaskNotClosedError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ValidateFileShawshankDocumentVerificationTaskResponse = ValidateFileShawshankDocumentVerificationTaskSuccess | ValidateFileShawshankDocumentVerificationTaskFailure;

export type ValidateFileShawshankDocumentVerificationTaskSuccess = {
  __typename?: 'ValidateFileShawshankDocumentVerificationTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
  task: HoosegowTaskShawshank;
};

export type ValidateFileShawshankDocumentVerificationTaskFailure = {
  __typename?: 'ValidateFileShawshankDocumentVerificationTaskFailure';
  traceId: Scalars['String'];
  error: ShawshankDocumentVerificationTaskMutationError;
};

export type ReworkShawshankDocumentVerificationTaskResponse = ReworkShawshankDocumentVerificationTaskSuccess | ReworkShawshankDocumentVerificationTaskFailure;

export type ReworkShawshankDocumentVerificationTaskSuccess = {
  __typename?: 'ReworkShawshankDocumentVerificationTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
  task: HoosegowTaskShawshank;
};

export type ReworkShawshankDocumentVerificationTaskFailure = {
  __typename?: 'ReworkShawshankDocumentVerificationTaskFailure';
  traceId: Scalars['String'];
  error: ShawshankDocumentVerificationTaskMutationError;
};

export type ApproveShawshankDocumentVerificationTaskResponse = ApproveShawshankDocumentVerificationTaskSuccess | ApproveShawshankDocumentVerificationTaskFailure;

export type ApproveShawshankDocumentVerificationTaskSuccess = {
  __typename?: 'ApproveShawshankDocumentVerificationTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
  task: HoosegowTaskShawshank;
};

export type ApproveShawshankDocumentVerificationTaskFailure = {
  __typename?: 'ApproveShawshankDocumentVerificationTaskFailure';
  traceId: Scalars['String'];
  error: ShawshankDocumentVerificationTaskMutationError;
};

export type ArchiveShawshankDocumentVerificationTaskResponse = ArchiveShawshankDocumentVerificationTaskSuccess | ArchiveShawshankDocumentVerificationTaskFailure;

export type ArchiveShawshankDocumentVerificationTaskSuccess = {
  __typename?: 'ArchiveShawshankDocumentVerificationTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
  task: HoosegowTaskShawshank;
};

export type ArchiveShawshankDocumentVerificationTaskFailure = {
  __typename?: 'ArchiveShawshankDocumentVerificationTaskFailure';
  traceId: Scalars['String'];
  error: ShawshankDocumentVerificationTaskMutationError;
};

export type ReopenShawshankDocumentVerificationTaskResponse = ReopenShawshankDocumentVerificationTaskSuccess | ReopenShawshankDocumentVerificationTaskFailure;

export type ReopenShawshankDocumentVerificationTaskSuccess = {
  __typename?: 'ReopenShawshankDocumentVerificationTaskSuccess';
  success?: Maybe<Scalars['Boolean']>;
  task: HoosegowTaskShawshank;
};

export type ReopenShawshankDocumentVerificationTaskFailure = {
  __typename?: 'ReopenShawshankDocumentVerificationTaskFailure';
  traceId: Scalars['String'];
  error: ShawshankDocumentVerificationTaskMutationError;
};

export type ParoleBankTaskMutation = {
  __typename?: 'ParoleBankTaskMutation';
  sent: SentParoleBankTaskMutationResponse;
  /** Требуется доработка */
  rework: ReworkParoleBankTaskMutationResponse;
  /** В кредите отказано */
  declined: DeclinedParoleBankTaskMutationResponse;
  /** Кредит одобрен */
  approved: ApprovedParoleBankTaskMutationResponse;
  /** В архив */
  archive: ArchiveParoleBankTaskMutationResponse;
  /** Восстановить */
  reopen: ReopenParoleBankTaskMutationResponse;
  /** Взять в работу */
  assignTask: AssignTaskParoleBankTaskMutationResponse;
  /** Вернуть в очередь */
  unassignTask: UnassignTaskParoleBankTaskMutationResponse;
};


export type ParoleBankTaskMutationSentArgs = {
  taskId: Scalars['ID'];
};


export type ParoleBankTaskMutationReworkArgs = {
  taskId: Scalars['ID'];
  comment: Scalars['String'];
  reworkCauses: Array<BankReworkCauseEnum>;
};


export type ParoleBankTaskMutationDeclinedArgs = {
  taskId: Scalars['ID'];
  comment: Scalars['String'];
};


export type ParoleBankTaskMutationApprovedArgs = {
  taskId: Scalars['ID'];
  mortgage: MortgageApprovedParoleBankTaskInput;
};


export type ParoleBankTaskMutationArchiveArgs = {
  taskId: Scalars['ID'];
  comment: Scalars['String'];
};


export type ParoleBankTaskMutationReopenArgs = {
  taskId: Scalars['ID'];
  comment: Scalars['String'];
};


export type ParoleBankTaskMutationAssignTaskArgs = {
  taskId: Scalars['ID'];
};


export type ParoleBankTaskMutationUnassignTaskArgs = {
  taskId: Scalars['ID'];
  comment: Scalars['String'];
};

export type SentParoleBankTaskMutationResponse = SentParoleBankTaskMutationSuccess | SentParoleBankTaskMutationFailure;

export type SentParoleBankTaskMutationSuccess = {
  __typename?: 'SentParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type SentParoleBankTaskMutationFailure = {
  __typename?: 'SentParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export type ParoleBankTaskMutationError = NotFoundError | InternalError | ParoleBankTaskAlreadyClosedError | ParoleBankTaskNotClosedError | ParoleBankTaskAlreadyHeldError | ParoleBankTaskAlreadyAssignedError | ParoleBankTaskNotAssignedError;

export type ParoleBankTaskAlreadyClosedError = BaseError & {
  __typename?: 'ParoleBankTaskAlreadyClosedError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ParoleBankTaskNotClosedError = BaseError & {
  __typename?: 'ParoleBankTaskNotClosedError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ParoleBankTaskAlreadyHeldError = BaseError & {
  __typename?: 'ParoleBankTaskAlreadyHeldError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ParoleBankTaskAlreadyAssignedError = BaseError & {
  __typename?: 'ParoleBankTaskAlreadyAssignedError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ParoleBankTaskNotAssignedError = BaseError & {
  __typename?: 'ParoleBankTaskNotAssignedError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ReworkParoleBankTaskMutationResponse = ReworkParoleBankTaskMutationSuccess | ReworkParoleBankTaskMutationFailure;

export type ReworkParoleBankTaskMutationSuccess = {
  __typename?: 'ReworkParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type ReworkParoleBankTaskMutationFailure = {
  __typename?: 'ReworkParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export enum BankReworkCauseEnum {
  UnknownReworkCause = 'UNKNOWN_REWORK_CAUSE',
  LowerAmount = 'LOWER_AMOUNT',
  NeedIncomeConfirmation = 'NEED_INCOME_CONFIRMATION',
  NeedDocuments = 'NEED_DOCUMENTS',
  NeedClientCommunication = 'NEED_CLIENT_COMMUNICATION',
  NeedTrustedPersonPhone = 'NEED_TRUSTED_PERSON_PHONE',
  NeedMaternalCapitalInfo = 'NEED_MATERNAL_CAPITAL_INFO',
  NeedMortgageActualize = 'NEED_MORTGAGE_ACTUALIZE',
  DeclineEncumbrance = 'DECLINE_ENCUMBRANCE',
  DeclineWorkExperience = 'DECLINE_WORK_EXPERIENCE',
  DeclinePayability = 'DECLINE_PAYABILITY',
  DeclineMortgageAlreadyExists = 'DECLINE_MORTGAGE_ALREADY_EXISTS',
  DeclinePropertyObject = 'DECLINE_PROPERTY_OBJECT',
  DeclineWithoutReason = 'DECLINE_WITHOUT_REASON',
  DocumentsRework = 'DOCUMENTS_REWORK',
  BankRequirementsNotSatisfied = 'BANK_REQUIREMENTS_NOT_SATISFIED',
  AnalystRequestedAdditionalDocuments = 'ANALYST_REQUESTED_ADDITIONAL_DOCUMENTS',
  AnalystRequestedAdditionalInfo = 'ANALYST_REQUESTED_ADDITIONAL_INFO',
  Clarification = 'CLARIFICATION',
  LowerAmountApproved = 'LOWER_AMOUNT_APPROVED',
  FederalBailiffServiceDebt = 'FEDERAL_BAILIFF_SERVICE_DEBT',
  DebtServiceToIncomeRatioExceeded = 'DEBT_SERVICE_TO_INCOME_RATIO_EXCEEDED',
  ClientNotReachedByPhone = 'CLIENT_NOT_REACHED_BY_PHONE',
  EmployerNotReachedByPhone = 'EMPLOYER_NOT_REACHED_BY_PHONE',
  NeedIncomeConfirmationFromPensionFund = 'NEED_INCOME_CONFIRMATION_FROM_PENSION_FUND',
  EmployerOrClientRejectedToProvideData = 'EMPLOYER_OR_CLIENT_REJECTED_TO_PROVIDE_DATA',
  ClientInforming = 'CLIENT_INFORMING',
  BusinessAndIe = 'BUSINESS_AND_IE',
  OfficialProofOfEmployment = 'OFFICIAL_PROOF_OF_EMPLOYMENT',
  DeclineWithRevisionPossibility = 'DECLINE_WITH_REVISION_POSSIBILITY',
  DeclineBorrower = 'DECLINE_BORROWER',
  ProcessedWithAnotherCategory = 'PROCESSED_WITH_ANOTHER_CATEGORY',
  ApplicationRelevance = 'APPLICATION_RELEVANCE',
  OtherBankReworkCause = 'OTHER_BANK_REWORK_CAUSE'
}

export type DeclinedParoleBankTaskMutationResponse = DeclinedParoleBankTaskMutationSuccess | DeclinedParoleBankTaskMutationFailure;

export type DeclinedParoleBankTaskMutationSuccess = {
  __typename?: 'DeclinedParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type DeclinedParoleBankTaskMutationFailure = {
  __typename?: 'DeclinedParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export type ApprovedParoleBankTaskMutationResponse = ApprovedParoleBankTaskMutationSuccess | ApprovedParoleBankTaskMutationFailure;

export type ApprovedParoleBankTaskMutationSuccess = {
  __typename?: 'ApprovedParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type ApprovedParoleBankTaskMutationFailure = {
  __typename?: 'ApprovedParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export type MortgageApprovedParoleBankTaskInput = {
  /** Одобренная ставка в % */
  rate?: InputMaybe<Scalars['Float']>;
  /** Одобренный ежемесячный платеж в рублях */
  payment?: InputMaybe<Scalars['Float']>;
  /** Одобренный срок кредита в месяцах */
  term?: InputMaybe<Scalars['Int']>;
  /** Одобренная сумма кредита в рублях */
  loanAmount?: InputMaybe<Scalars['Float']>;
  /** Одобренный первоначальный взнос */
  ownAmount?: InputMaybe<Scalars['Float']>;
  /** Первоначальный взнос в процентах */
  ownAmountPercents?: InputMaybe<Scalars['Float']>;
  /** Комментарий банка */
  comment: Scalars['String'];
  /** Срок действия предложения банка */
  validUntilTs?: InputMaybe<Scalars['String']>;
  /** Отлагательные условия */
  conditions?: InputMaybe<Scalars['String']>;
};

export type ArchiveParoleBankTaskMutationResponse = ArchiveParoleBankTaskMutationSuccess | ArchiveParoleBankTaskMutationFailure;

export type ArchiveParoleBankTaskMutationSuccess = {
  __typename?: 'ArchiveParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type ArchiveParoleBankTaskMutationFailure = {
  __typename?: 'ArchiveParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export type ReopenParoleBankTaskMutationResponse = ReopenParoleBankTaskMutationSuccess | ReopenParoleBankTaskMutationFailure;

export type ReopenParoleBankTaskMutationSuccess = {
  __typename?: 'ReopenParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type ReopenParoleBankTaskMutationFailure = {
  __typename?: 'ReopenParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export type AssignTaskParoleBankTaskMutationResponse = AssignTaskParoleBankTaskMutationSuccess | AssignTaskParoleBankTaskMutationFailure;

export type AssignTaskParoleBankTaskMutationSuccess = {
  __typename?: 'AssignTaskParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type AssignTaskParoleBankTaskMutationFailure = {
  __typename?: 'AssignTaskParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export type UnassignTaskParoleBankTaskMutationResponse = UnassignTaskParoleBankTaskMutationSuccess | UnassignTaskParoleBankTaskMutationFailure;

export type UnassignTaskParoleBankTaskMutationSuccess = {
  __typename?: 'UnassignTaskParoleBankTaskMutationSuccess';
  success: Scalars['Boolean'];
  task: HoosegowTaskParole;
};

export type UnassignTaskParoleBankTaskMutationFailure = {
  __typename?: 'UnassignTaskParoleBankTaskMutationFailure';
  traceId: Scalars['String'];
  error: ParoleBankTaskMutationError;
};

export type HoosegowCreateOfficerResponse = HoosegowCreateOfficerSuccess | HoosegowCreateOfficerFailure;

export type HoosegowCreateOfficerSuccess = {
  __typename?: 'HoosegowCreateOfficerSuccess';
  /** id для relay */
  id: Scalars['ID'];
  officerId: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  authUserId?: Maybe<Scalars['String']>;
  status?: Maybe<HoosegowOfficerStatusEnum>;
  contacts?: Maybe<HoosegowOfficerContact>;
  city?: Maybe<Scalars['String']>;
};

export type HoosegowCreateOfficerFailure = {
  __typename?: 'HoosegowCreateOfficerFailure';
  errors: Array<HoosegowOfficerCreateFailureError>;
};

export type HoosegowOfficerCreateFailureError = HoosegowOfficerFailureErrorAlreadyExists | HoosegowOfficerFailureErrorInvalidParam | HoosegowOfficerFailureErrorUnknown;

export type HoosegowOfficerFailureErrorAlreadyExists = {
  __typename?: 'HoosegowOfficerFailureErrorAlreadyExists';
  message: Scalars['String'];
  code: Scalars['String'];
};

/** Поля для создания пользователя */
export type HoosegowOfficerCreateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  status: HoosegowOfficerStatusEnum;
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  telegram?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
};

export type HoosegowEditOfficerResponse = HoosegowEditOfficerSuccess | HoosegowEditOfficerFailure;

export type HoosegowEditOfficerSuccess = {
  __typename?: 'HoosegowEditOfficerSuccess';
  success?: Maybe<Scalars['Boolean']>;
};

export type HoosegowEditOfficerFailure = {
  __typename?: 'HoosegowEditOfficerFailure';
  errors: Array<HoosegowOfficerEditFailureError>;
};

export type HoosegowOfficerEditFailureError = HoosegowOfficerFailureErrorAlreadyExists | HoosegowOfficerFailureErrorInvalidParam | HoosegowOfficerFailureErrorNotFound | HoosegowOfficerFailureErrorUnknown;

export type HoosegowOfficerFailureErrorNotFound = {
  __typename?: 'HoosegowOfficerFailureErrorNotFound';
  message: Scalars['String'];
  code: Scalars['String'];
};

/** Поля для редактирования пользователя */
export type HoosegowOfficerEditInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  status: HoosegowOfficerStatusEnum;
  email: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  telegram?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
};

export type HoosegowMutationsFailure = {
  __typename?: 'HoosegowMutationsFailure';
  traceId: Scalars['String'];
  errors: HoosegowRootErrors;
};

export type UserProfileMutations = {
  __typename?: 'UserProfileMutations';
  updateAgreement: UserProfileMutationsResponse;
};


export type UserProfileMutationsUpdateAgreementArgs = {
  field: UpdateAgreementFieldsEnum;
  value: Scalars['Boolean'];
};

export type UserProfileMutationsResponse = PersonAgreements | InternalError | UnauthenticatedError;

export enum UpdateAgreementFieldsEnum {
  ProcessingPersonalData = 'ProcessingPersonalData',
  SendingSms = 'SendingSms',
  SendingEmail = 'SendingEmail',
  CommonUserAgreement = 'CommonUserAgreement',
  MortgageProcessingPersonalData = 'MortgageProcessingPersonalData',
  PostReviews = 'PostReviews'
}
